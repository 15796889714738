// External
import { Injectable } from '@angular/core';
import { Observable, map, catchError } from 'rxjs';

// Internal
import { POST, RequestsService } from '../../global/request-service/requests.service';
import { ValuesService } from '../../../values/values.service';
import { ConfigService } from '../../../../common/config/config.service';
import { WebmailProtectionRegisterInboxParameters } from '../../../../common/models/security/WebmailProtection.model';

export enum ConnectWebmailRegistrationServiceErrorSubCodes {
    WRONG_CREDENTIALS = 32004,
    INBOX_ALREADY_REGISTERED = 40007,
    NO_FREE_SLOTS = 40003
}

@Injectable({
    providedIn: 'root'
})

export class ConnectWebmailRegistrationService {

    constructor(
        private readonly requestsService: RequestsService,
        private readonly valuesService: ValuesService,
        private readonly configService: ConfigService,
    ) { }

    /**
     * Makes the inbox registraition.
     *
     * @public
     * @memberof ConnectWebmailProtectionService
     * @param {WebmailProtectionRegisterInboxParameters} registerInboxParameter the parameters for the inbox registration
     * @returns {Observable} Response from server
     */
    public registerInbox(registerInboxParameter: WebmailProtectionRegisterInboxParameters): Observable<any> {
        const json = {
            id: parseInt((Math.random() * 100000).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'register_inbox',
            params: {
                connect_source: {
                    user_token: registerInboxParameter.user_token,
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.appWebmailProtection
                },
                email: registerInboxParameter.email,
                inbox_provider: registerInboxParameter.inbox_provider,
                access_token: registerInboxParameter.access_token,
                refresh_token: registerInboxParameter.refresh_token,
                kid: registerInboxParameter.kid,
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.webmailRegistrationMgmtService,
            json,
            POST
        ).pipe(
            map(resp => {
                if (resp?.result) {
                    return resp.result;
                } else {
                    throw resp;
                }
            }),
            catchError(error => {
                throw(error);
            })
        );
    }

}