import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MillisecondsBetweenChecks } from '../../../../common/models/Pwa.model';
import { getPwaActive } from '../../../../../app/app.module';

@Injectable({providedIn: 'root'})
export class CheckForUpdateService {

    constructor(
        private readonly swUpdate: SwUpdate,
    ) {
        if (getPwaActive()) {
            setInterval(async () => {
                try {
                    // Check if Service Worker is supported by the Browser
                    if (this.swUpdate.isEnabled) {
                        // Check if new version is available
                        const isNewVersion = await this.swUpdate.checkForUpdate();
                        console.log(isNewVersion ? 'A new version is available.' : 'Already on the latest version.');
                    }
                } catch (error) {
                    console.log('Service Worker - Error when checking for new version of the application: ', error);
                }
            }, MillisecondsBetweenChecks);
        }
    }
}