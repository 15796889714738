import { Injectable } from '@angular/core';
import { ConnectLoginService } from '../../requests/connect-login-service/connect-login.service';
import { Observable, map, catchError, mergeMap, of } from 'rxjs';
import { LogoutType } from '../../../../common/config/config.service';
import { AuthService } from '../auth/auth-service.service';
import { RequestsService } from '../request-service/requests.service';

export interface SwitchContextResponse {
    switched: boolean
}

@Injectable({
    providedIn: 'root'
})
export class ContextService {
    private reloadAfterContextSwitchFlag = false;
    constructor(
        private readonly connectLoginService: ConnectLoginService,
        private readonly requestsService: RequestsService,
        private readonly authService: AuthService
    ) {}

    /**
     * This method is used to change constext.
     * @param {string} newContextId - The new context id.
     * @returns {Observable<any>} The request response.
     */
    public switchContext(newContextId: string): Observable<SwitchContextResponse> {
        const switchContextResponse: SwitchContextResponse = {
            switched: false
        };

        return this.connectLoginService.switchContext(newContextId)
        .pipe(
            mergeMap(userToken => {
                switchContextResponse.switched = true;
                this.reloadAfterContextSwitchFlag = true;
                return this.requestsService.logout(LogoutType.NO_REDIRECT)
                .pipe(
                    map(() => {
                        this.authService.writeUserTokenCookie(userToken);
                        return switchContextResponse;
                    }),
                    catchError(() => {
                        this.authService.writeUserTokenCookie(userToken);
                        return of(switchContextResponse);
                    })
                );
            }),
            catchError(() => {
                return of(switchContextResponse);
            })
        );
    }

    /**
     * This method is used to see if the window has a pending reload after a context switch
     * @returns {boolean} true if window wsa not reloaded yet and needs to be
     */
    public getReloadAfterContextSwitchFlag(): boolean {
        return this.reloadAfterContextSwitchFlag;
    }
}