
export enum ConversionStatus {
    MANDATORY = 'MANDATORY',
    OPTIONAL = 'OPTIONAL',
    OFF = 'OFF'
}

export enum SecuredPaymentScenarios {
    SUCCESS = 'success',
    ERROR = 'error'
}
