import { trigger, animate, transition, style, query, group, stagger } from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
    transition('* <=> *', [
        query(':enter, :leave', [
            style({ opacity: 0, position: 'absolute', width: '100%' })
        ], { optional: true }),
        group([
            query(':leave', [
                style({ opacity: 1 }),
                animate('0.2s', style({ opacity: 0 }))
            ], { optional: true }),
            query(':enter', [
                style({ opacity: 0 }),
                animate('0.2s', style({ opacity: 1 }))
            ], { optional: true }
            )
        ])
    ])
]);

/**
 * Animation created specificaly for router-outlets
 * * We care only about the entering element (component) to avoid layout shifts in the most basic way
 * * If we would animate both enter and leave states, when routes change we would simultaneously have 2 components in the page
 * thus creating a stacked behaviour which could only be fixed with absolute positioning
 */
export const outletFadeAnimation = trigger('outletFadeAnimation', [
    transition('* <=> *', [
        query(':enter', [
            style({opacity: 0}),
            animate('0.35s ease-in', style({ opacity: 1}))
        ], { optional: true })
    ])
]);

/**
 * Generic animation effect for a grup of items usable with ngFor
 * * it's added to a parent element which contains multiple items generated with ngFor
 * * it relies on array.length change for triggering the state change
 * * has stagger - there's a small delay between element's animation start thus creating a domino efect
 */
export const cardAnimation = trigger('cardAnimation',[
    transition(':enter, * => 0, * => -1', []),
    transition(':increment', [
        query(':enter', [
            style({ opacity: 0, transform: 'translateY(20px)' }),
            stagger(150, [
                animate('300ms ease-in', style({ opacity: 1, transform: 'translateY(0px)' })),
            ]),
        ], { optional: true })
    ]),
    transition(':decrement', [
        query(':leave', [
            style({ opacity: 1, transform: 'translateY(0px)' }),
            stagger(150, [
                animate('300ms ease-out', style({ opacity: 0, transform: 'translateX(-20px)' })),
            ]),
        ])
    ])
]);

/**
 * Generic animation effect for a grup of items usable with ngIf
 * * it's added to a parent element which contains multiple items conditioned by if statements
 * * has stagger
 */
export const itemsEnterLeaveStaggered = trigger('itemsEnterLeaveStaggered', [
    transition(':enter', [
        query(':enter', [
            style({ opacity: 0, transform: 'translateY(20px)' }),
            stagger(150, [
                animate('300ms ease-in', style({ opacity: 1, transform: 'translateY(0px)' })),
            ]),
        ], {optional: true })
    ]),
    transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0px)' }),
        animate('300ms ease-out', style({ opacity: 0, transform: 'translateX(-20px)' })),
    ])
]);

/**
 * Generic animation effect for a single item usable with ngIf
 * * it's added to a an item conditioned by if statements
 */
export const itemBasicEnterLeave = trigger('itemBasicEnterLeave', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('300ms ease-in', style({ opacity: 1, transform: 'translateY(0px)' }))
    ]),
    transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0px)' }),
        animate('300ms ease-out', style({ opacity: 0, transform: 'translateX(100px)' }))
    ]),
]);