// External
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { Observable, of, map, catchError, throwError } from 'rxjs';

// Internal
import { ConfigService } from '../../../../common/config/config.service';
import { Errors, POST, RequestsService } from '../../global/request-service/requests.service';
import { UsefulService } from '../../global/useful/useful.service';
import { UtilsCommonService } from '../../../../common/utils/utils-common.service';
import { ValuesService } from '../../../values/values.service';
import { DefaultError, RequestBody, RequestExtraParams } from '../../../models/Core.model';
import { GroupThreatsParams } from '../../../models/business/Business.model';

@Injectable({
    providedIn: 'root'
})

export class SeccenterService {

    private readonly defaultErrorResponse: DefaultError = {
        code: 104,
        message: Errors.INVALID_PARAMS
    };

    constructor(
        private readonly configService: ConfigService,
        private readonly cookieService: CookieService,
        private readonly requestService: RequestsService,
        private readonly usefulService: UsefulService,
        private readonly utilsCommonService: UtilsCommonService,
        private readonly valuesService: ValuesService
    ) { }

    /**
     * Makes the request call
     * @param {string} method The name of the method to be called
     * @param {RequestExtraParams} extraParams An object containing the extra params needed for the request
     * @returns {Observable} The response from the server
     */
    private makeRequest(method: string, extraParams?: RequestExtraParams): Observable<any> {
        const json: RequestBody = {
            id: parseInt((Math.random() * 1000).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method,
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.appCM
                },
                ...extraParams
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.secCenter,
            json,
            POST
        ).pipe(
            map(resp => {
                if (resp?.result) {
                    return resp.result;
                }
                throw resp;
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * This method is used to get all reported threats by threat_group_id and to get reported threats batch by threat_group_id
     * @param {object} Contains mandatory `notifications`
     * @returns Response from server
    */
    threatsInfo(info): Observable<any> {

        let inf: any = info;
        let jsons = [];
        let json = {};
        let id = parseInt((Math.random() * 10000).toString(), 10)

        if (Array.isArray(inf) && inf.length !== 0) {
            jsons = [];
            for (let i in inf) {
                inf[i].id = id;
                jsons.push({
                    id: id++,
                    jsonrpc: this.valuesService.jsonrpc,
                    method: 'get_group_threat_info',
                    params: {
                        connect_source: {
                            user_token: this.cookieService.get(
                                this.valuesService.cookieToken
                            ),
                            device_id: this.valuesService.connectDeviceId,
                            app_id: this.valuesService.connectAppId
                        },
                        threats_group_id: inf[i].threat_group_id,
                        //threats_count: inf[i].threats_count
                    }
                });
            }
        } else if (this.utilsCommonService.isObject(inf) && !this.utilsCommonService.isEmptyObject(inf)) {
            json = {
                id: parseInt((Math.random() * 100).toString(), 10),
                jsonrpc: this.valuesService.jsonrpc,
                method: 'get_group_threat_info',
                params: {
                    connect_source: {
                        user_token: this.cookieService.get(this.valuesService.cookieToken),
                        device_id: this.valuesService.connectDeviceId,
                        app_id: this.valuesService.connectAppId
                    },
                    threats_group_id: inf.threat_group_id,
                    //threats_count: inf.threats_count
                }
            };
            inf.id = id;
        } else {
            return of('Invalid params');
        }

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.secCenter,
            !this.utilsCommonService.isEmptyObject(json) ? json : jsons,
            'POST'
        )
        .pipe(
            map(
                (res: any) => {
                    if (res.status && res.status === 'error' && res.code) {
                        const data = [{
                            data : {
                                ...res
                            },
                            ...inf
                        }];
                        return data;
                    }
                    
                    if (Array.isArray(res) && res.length !== 0){
                        const temp_arr = [];
                        for(const i in res){
                            const data = res[i].result ? res[i].result : res[i].error.data;

                            for(const j in inf) {
                                if(inf[j].id === res[i].id) {
                                    temp_arr.push({
                                        ...inf[j],
                                        data
                                    });
                                    break;
                                }
                            }
                        }
                        return temp_arr;
                    }
                    return res.result;
                }
            ),
            catchError((error) => {
                throw error;
            }),
        );
    }

    /**
     * This method is used to count how many treats had ocurred in a given interval grouped by day,
     * @param {object} Contains mandatory `date_offset`, `days`
     * @returns Response from server
    */
    countThreats(days, deviceId?) {
        const dateOffset = this.usefulService.computeOffset();

        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'count_threats',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                'date_offset': dateOffset,
                'days': days
            }
        };

        if (deviceId) {
            json.params["device_id"] = deviceId;
        }

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.secCenter,
            json,
            'POST'
        ).pipe(
            map( (resp: any) => {
                return resp.result;
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
     * This method is used to get the top X devices that reported threats
     * @param {object} Contains mandatory `date_offset`, `top`
     * @returns Response from server
    */
    topActiveDevices() {
        let dateOffset  = this.usefulService.computeOffset();
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "get_active_devices",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                date_offset : dateOffset,
                top: 100
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.secCenter,
            json,
            'POST'
        )
        .pipe(
            map( (resp: any) => {
                return resp.result;
            }),
            catchError((error) => {
                throw error;
            })
        );;
    };

    /**
     * This method is used to retrieve threats reported for the current user
     * @param {object} Contains mandatory `info` object with details
     * @returns Response from server
     */
    getThreatsDetails(info): Observable<any> {
        if (!this.utilsCommonService.isObject(info) && this.utilsCommonService.isEmptyObject(info)) {
            return of('Invalid params');
        }

        let json: any = {
            id: 1,
            jsonrpc: this.valuesService.jsonrpc,
            method: 'get_threats_v2',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };

        if (info.device_id) {
            json.params.device_id = info.device_id;
        }

        if (info.count) {
            if (info.count < 100) {
                json.params.count = info.count;
            } else {
                json.params.count = 100;
            }
        }

        if (info.offset) {
            json.params.offset = info.offset;
        }

        if (info.from_date) {
            json.params.from_date = info.from_date;
        }

        if (info.to_date) {
            json.params.to_date = info.to_date;
        }

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.secCenter,
            json,
            'POST'
        ).pipe(
            map( (resp: any) => {
                return resp.result;
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
     * Gets threats for all devices from members of a group for given number of days
     * @param {string} groupId The id of the group to be checked
     * @param {number} daysNo The number of days to be checked
     * @returns {Observable} The request response
     */
    public getGroupThreatReport(groupId: string, daysNo: number): Observable<any> {
        const params = {
            group_id: groupId,
            days: daysNo
        };
        return this.makeRequest('get_group_threat_report', params);
    }

    /**
     * Gets threats details for all devices from members of a group between given dates
     * @param {GroupThreatsParams} requestParams The group threats request params
     * @returns {Observable} The request response
     */
    public getGroupThreats(requestParams: GroupThreatsParams): Observable<any> {
        if (this.utilsCommonService.isEmptyObject(requestParams) || !this.utilsCommonService.isObject(requestParams)) {
            return throwError(() => this.defaultErrorResponse);
        }

        return this.makeRequest('get_group_threats', requestParams);
    }

}
