// External
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, defer, forkJoin, map, of, skipWhile } from 'rxjs';

// Internal
import { ConnectBusinessAssetsService } from '../../requests/connect-business-assets/connect-business-assets.service';
import { ValuesService } from '../../../values/values.service';
import { PrivacyErrorCodes, PrivacyEvents } from '../../../values/privacy.values.service';
import { MessageService } from '../../core/message.service';
import { IdentityModel, Metadata, MetadataSource, MetadataType } from '../../../models/privacy/Identity.model';
import { BusinessValuesService } from '../../../values/business.values.service';
import { ActionsModel, AssetRecommendation, AssetSubtypes, GroupThreatsParams } from '../../../models/business/Business.model';
import { PrivacyIssue, PrivacyIssueType } from '../../../models/privacy/PrivacyIssue.model';
import { WebmailProtectionKeyAlgorithm } from '../../../models/security/WebmailProtection.model';
import { SeccenterService } from '../../requests/connect-seccenter-service/connect-seccenter.service';
import { Security } from '../../../models/security/Security.model';
import { Threat, ThreatsCounter } from '../../../models/Threats.model';
import { SubscriptionsService } from '../subscriptions/subscriptions.service';
import { Errors } from '../../global/request-service/requests.service';

interface BreachModel {
    count: number;
    status: number;
    zero_state: any;
    issues: PrivacyIssue[]
}

@Injectable({
    providedIn: 'root'
})

export class BusinessService {

    private readonly onListActions$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListIdentity$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListBreaches$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onCreateIdentity$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);

    private markToUpdateActions = true;
    private markToUpdateIdentity = true;
    private markToUpdateBreaches = true;
    private markToUpdateCreateIdentity = true;

    private hasGetIdentityError = false;
    private hasCreateIdentityError = false;

    private actions: ActionsModel;
    private identity: IdentityModel;
    private breaches: BreachModel;

    private readonly groupThreatsByTime: Security.Overview.ThreatsProcessLayer = {
        [Security.Overview.Filter.LAST_7D]: {
            markToUpdate: true,
            onList: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
            data: [],
            error: false
        },
        [Security.Overview.Filter.LAST_30D]: {
            markToUpdate: true,
            onList: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
            data: [],
            error: false
        }
    };

    private readonly groupThreatsList: Security.Overview.ThreatsProcessLayer = {
        [Security.Overview.Filter.LAST_7D]: {
            markToUpdate: true,
            onList: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
            data: [],
            error: false
        },
        [Security.Overview.Filter.LAST_30D]: {
            markToUpdate: true,
            onList: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
            data: [],
            error: false
        }
    };

    constructor(
        private readonly connectBusinessAssetsService: ConnectBusinessAssetsService,
        private readonly businessValuesService: BusinessValuesService,
        private readonly seccenterService: SeccenterService,
        private readonly messageService: MessageService,
        private readonly valuesService: ValuesService,
        private readonly subscriptionsService: SubscriptionsService
    ) {}

    /**
     * Updates given metadata with the new id and source
     * @param {Metadata} metadata The metadata object
     * @param {string} metadataId The id of the metadata
     */
    private updateExistingMetadata(metadata: Metadata, metadataId: string): void {
        if (metadataId) {
            metadata.metadata_id = metadataId;
        }
        metadata.source = MetadataSource.CONFIRMED;
    }

    /**
     * Updates the corresponding metdata object for the existing identity
     * @param {Metadata} metadata The metadata object
     */
    private updateIdentityAfterAdding(metadata: Metadata): void {
        if (!this.identity[metadata.type]) {
            this.identity[metadata.type] = [];
        }

        this.identity[metadata.type].push(metadata);
        this.identity.validate_required.push(metadata);
    }

    /**
     * Updates metadata subtypes based on given metadata id
     * @param {Metadata[]} metadatas The metadata info that was updated
     */
    private updateMetadataSubtype(metadatas: Metadata[]): void {
        for (const metadata of metadatas) {
            const emails = this.identity?.emails ?? [];
            for (const email of emails) {
                if (email.metadata_id !== metadata.metadata_id) {
                    continue;
                }

                const existingSubtypes = (email?.subtype ?? []) as AssetSubtypes[];
                const subtypes = metadata?.subtype ?? [];
                for (const subtype of subtypes) {
                    const item = subtype as AssetSubtypes;
                    if (!metadata.remove) {
                        existingSubtypes.push(item);
                    } else {
                        const index: number = existingSubtypes.indexOf(item);
                        if (index !== -1) {
                            existingSubtypes.splice(index, 1);
                        }
                    }
                }
            }
        }
    }

    /**
     * Removes the newly created metadata object from the current identity
     * @param {Metadata} metadata The metadata to be deleted
     */
    private updateIdentityAfterDeleting(metadata: Metadata): void {
        let metadatasList = [];
        for (const data of this.identity[metadata.type]) {
            if (data.metadata_id !== metadata.metadata_id) {
                metadatasList.push(data);
            }
        }
        this.identity[metadata.type] = metadatasList;

        metadatasList = [];
        for (const metadataObj of this.identity.validate_required) {
            if (metadataObj.metadata_id !== metadata.metadata_id) {
                metadatasList.push(metadataObj);
            }
        }
        this.identity.validate_required = metadatasList;
    }

    /**
     * Updates data after metadata validated
     * @param {Metadata} metadata The metadata to be searched
     */
    private updateIdentityAfterValidation(metadata: Metadata): void {
        for (const data of this.identity[metadata.type]) {
            if (data.metadata_id === metadata.metadata_id) {
                data.validated = true;
                data.source = MetadataSource.CONFIRMED;
            }
        }

        for (const metadataType of this.identity.required_metadata) {
            if (metadataType.type === metadata.type) {
                metadataType.count--;
            }
        }

        const metadataList = [];
        for (const metadataObj of this.identity.validate_required) {
            if (metadataObj.metadata_id !== metadata.metadata_id) {
                metadataList.push(metadataObj);
            }
        }
        this.identity.validate_required = metadataList;
    }

    /**
     * Creates the new identity
     * @returns {Observable} the provided identity created
     */
     public createIdentity(): Observable<any> {
        if (!this.markToUpdateCreateIdentity) {
            return of(false);
        }

        if (this.identity) {
            return of(this.identity);
        }

        if (this.onCreateIdentity$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onCreateIdentity$.asObservable()
            .pipe(
                skipWhile(resp => resp !== this.valuesService.processServiceState.DONE)
            );
        }

        this.onCreateIdentity$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectBusinessAssetsService.createIdentity()
        .pipe(
            map(resp => {
                this.onCreateIdentity$.next(this.valuesService.processServiceState.DONE);

                if (!resp) {
                    this.markToUpdateCreateIdentity = true;
                    this.hasCreateIdentityError = true;
                    return of(false);
                }

                this.markToUpdateCreateIdentity = false;
                this.hasCreateIdentityError = false;

                this.identity = resp;
                return of(this.identity);
            }),
            catchError(err => {
                this.markToUpdateCreateIdentity = true;
                this.hasCreateIdentityError = true;
                this.onCreateIdentity$.next(this.valuesService.processServiceState.DONE);
                throw err;
            })
        );
    }

    /**
     * Gets the identity from connect
     * @returns {Observable} the provided identity
     */
    public listIdentity(): Observable<any> {
        if (!this.markToUpdateIdentity || !this.subscriptionsService.hasBusinessAssetsExposure()) {
            return of(this.identity);
        }

        if (this.onListIdentity$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListIdentity$.asObservable()
            .pipe(
                skipWhile(resp => resp !== this.valuesService.processServiceState.DONE)
            );
        }

        this.onListIdentity$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectBusinessAssetsService.getIdentity()
        .pipe(
            map(resp => {
                if (resp?.parking_details?.active) {
                    this.messageService.sendMessage(PrivacyEvents.PARKED_IDENTITY, { parkedIdentity: true });
                } else {
                    this.messageService.sendMessage(PrivacyEvents.PARKED_IDENTITY, { parkedIdentity: false });
                }
                this.identity = resp;
                this.hasGetIdentityError = false;
                this.onListIdentity$.next(this.valuesService.processServiceState.DONE);
                this.markToUpdateIdentity = false;
                return this.identity;
            }),
            catchError(err => {
                if (err.code && err.code === PrivacyErrorCodes.NO_IDENTITY
                    || err.code === PrivacyErrorCodes.EXPIRED_SUBSCRIPTION) {
                    this.identity = null;
                    this.markToUpdateIdentity = false;
                    this.onListIdentity$.next(this.valuesService.processServiceState.DONE);
                    return of(this.identity);
                } else {
                    this.hasGetIdentityError = true;
                    this.markToUpdateIdentity = true;
                    this.onListIdentity$.next(this.valuesService.processServiceState.DONE);
                    throw err;
                }
            })
        );
    }

    /**
     * Checks if the identity is parked
     * @returns {boolean} Returns true if the identity is parked, false otherwise
     */
    public isIdentityParked(): boolean {
        return this.identity?.parking_details?.active ?? false;
    }

    /**
     * Marks identity request to be updated
     */
    public markIdentityToBeUpdated(): void {
        if (this.onListIdentity$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateIdentity = true;
        }
    }

    /**
     * Checks if identity has init done or not
     * @returns {boolean} Return the value of the 'init_done', false if it doesn't exists
     */
    public isIdentityInitDone(): boolean {
        return this.identity?.init_done ?? false;
    }

    /**
     * Gets the provided identity
     * @returns {IdentityModel} the provided identity
     */
    public getIdentity(): IdentityModel {
        return this.identity;
    }

    /**
     * Gets the monitored metadata for the metadata type given
     * @param {MetadataType} metadataType The metadata type
     * @returns {Metadata[]} An raay of monitored metadata objects
     */
    public getMonitoredAssetsByType(metadataType: MetadataType): Metadata[] {
        return this.identity?.[metadataType] ?? [];
    }

    /**
     * Gets the list of all breaches
     * @returns {Observable} The request response
     */
    public listBreaches(): Observable<any> {
        if (!this.markToUpdateBreaches || !this.identity || !this.isIdentityInitDone() || this.isIdentityParked()) {
            return of(this.breaches);
        }

        if (this.onListBreaches$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListBreaches$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        }

        this.onListBreaches$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectBusinessAssetsService.getIssues(PrivacyIssueType.BREACH, true, true)
        .pipe(
            map(resp => {
                if (!resp) {
                    this.markToUpdateBreaches = true;
                    throw resp;
                }

                this.markToUpdateBreaches = false;
                this.onListBreaches$.next(this.valuesService.processServiceState.DONE);
                this.breaches = resp;
                return this.breaches;
            }),
            catchError(err => {
                this.markToUpdateBreaches = true;
                this.onListBreaches$.next(this.valuesService.processServiceState.DONE);
                throw err;
            })
        );
    }

    /**
     * Returns the breaches object
     * @returns {BreachModel} The breaches object
     */
    public getBreaches(): BreachModel {
        return this.breaches;
    }

    /**
     * Checks if breaches exists
     * @returns {boolean} Returns true if bresches exists, false otherwise
     */
    public hasBreaches(): boolean {
        return this.breaches.count > 0;
    }

    /**
     * Calls init_data method after first business asset was added
     * @returns {Observable} Th request response
     */
    public initData(): Observable<any> {
        return this.connectBusinessAssetsService.initData()
        .pipe(
            map(() => {
                this.markIdentityToBeUpdated();
                return true;
            })
        );
    }

    /**
     * Adds a new metadata
     * @param {Metadata} metadata The metadata to be added
     * @returns {Observable} The request response
     */
    public addMetadata(metadata: Metadata): Observable<any> {
        return this.connectBusinessAssetsService.addInfo(metadata)
        .pipe(
            map(resp => {
                this.updateExistingMetadata(metadata, resp.metadata_id);
                this.updateIdentityAfterAdding(metadata);
                return true;
            }),
            catchError(err => {
                // If the domain is invalid (because is a generic one) we should not show the error
                if (err?.internal_code === 32005 && err?.code === 40023 && metadata.type === MetadataType.DOMAIN) {
                    return of(true);
                }

                throw err;
            })
        );
    }

    /**
     * Methods that updates an existing metadata
     * @param {Metadata[]} metadatas The array of metadatas to be updated
     * @returns {Observable} The request response
     */
    public updateMetadata(metadatas: Metadata[]): Observable<any> {
        return this.connectBusinessAssetsService.updateInfo(metadatas)
        .pipe(
            map(() => {
                this.updateMetadataSubtype(metadatas);
                return true;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    /**
     * Deletes given metadata
     * @param {Metadata} metadata The metadata id to be deleted
     * @returns {Observable} Thre request response
     */
    public deleteMetadata(metadata: Metadata): Observable<any> {
        return this.connectBusinessAssetsService.deleteInfo(metadata)
        .pipe(
            map(resp => {
                if (!resp) {
                    return false;
                }

                this.updateIdentityAfterDeleting(metadata);
                return true;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    /**
     * Validates the security code for given metadata type
     * @param {Metadata} metadata The metadata id to be validated
     * @param {string} code The security code used for validation
     * @returns {Observable} The request response
     */
    public validateMetadata(metadata: Metadata, code: string): Observable<any> {
        return this.connectBusinessAssetsService.validateInfo(metadata, code)
        .pipe(
            map(resp => {
                if (!resp) {
                    return false;
                }

                this.updateIdentityAfterValidation(metadata);
                return true;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    /**
     * Resend the security code for given metadata
     * @param {Metadata} metadata The metadata object
     * @returns {Observable} The request response
     */
    public resendCode(metadata: Metadata): Observable<any> {
        return this.connectBusinessAssetsService.resendCode(metadata)
        .pipe(
            map(resp => {
                if (!resp) {
                    return false;
                }
                return true;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    /**
     * Gets the last unvalidated email
     * @returns {Metadata} The unvalidated email
     */
    public getLastUnvalidatedEmail(): Metadata {
        const emails = this.identity.emails ?? [];
        let lastUnvalidatedEmail: Metadata;

        for (const email of emails) {
            if (!email.validated) {
                lastUnvalidatedEmail = email;
            }
        }
        return lastUnvalidatedEmail;
    }

    /**
     * Checks if the email address was already validated
     * @param {string} emailAddress The email address to be checked
     * @returns {boolean} Returns true if the email address was already validated
     */
    public isEmailAddressValidated(emailAddress: string): boolean {
        const identity = this.getIdentity();
        if (!identity?.emails?.length) {
            return false;
        }

        for (const email of identity.emails) {
            if (email.value === emailAddress && email.validated) {
                return true;
            }
        }
        return false;
    }

    /**
     * Gets the metadata id for given email address
     * @param {string} emailAddress The email address
     * @returns {string} The metadata id corresponding to the email address
     */
    public getMetadataIdByEmail(emailAddress: string): string {
        const identity = this.getIdentity();
        if (!emailAddress || !identity?.emails?.length) {
            return null;
        }

        for (const email of identity.emails) {
            if (email.value === emailAddress) {
                return email.metadata_id;
            }
        }
        return null;
    }

    /**
     * Checks if the business assets exposure is set up
     * @returns {boolean} Returns true if the business assets exposure is set up
     */
    public isBusinessAssetsExposureSetUp(): boolean {
        const identity = this.getIdentity();
        const allowedMetadata = identity?.allowed_metadata ?? {};
        for (const metadata in allowedMetadata) {
            if (identity?.[metadata]?.length) {
                return true;
            }
        }
        return false;
    }

    /**
     * Gets the error received on list identity
     * @returns {boolean} Returns true if error was received
     */
    public getIdentityError(): boolean {
        return this.hasGetIdentityError;
    }

    /**
     * Gets the error received while creating the identity
     * @returns {boolean} Returns true if error was received
     */
    public getCreateIdentityError(): boolean {
        return this.hasCreateIdentityError;
    }

    /**
     * Gets the associated social accounts
     * @returns {AssetSubtypes[]} An array of associated social accounts
     */
    public getAssociatedSocialAccounts(): AssetSubtypes[] {
        const identity = this.getIdentity();
        if (!identity?.emails?.length) {
            return;
        }

        const socialAccounts = [];
        for (const email of identity.emails) {
            for (const type of email.subtype) {
                if (this.businessValuesService.socialMediaAccounts.has(type as AssetSubtypes)) {
                    socialAccounts.push(type);
                }
            }
        }
        return socialAccounts;
    }

    /**
     * Encrypts the value of all metadatas found
     * @returns {Observable} The encrypted metadata
     */
    public encryptMonitoredMetadata(): Observable<any> {
        const monitoredMetadata = (this.identity?.emails ?? []).concat(this.identity?.domain ?? []);
        if (!monitoredMetadata.length) {
            return of(true);
        }

        return this.encryptMetadata(monitoredMetadata)
        .pipe(
            map(() => true),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * Encodes the given value to base 16
     * @param {Uint8Array} valueToBeEncoded The value to be encoded
     * @returns {string} The encoded value
     */
    private base16URLEncode(valueToBeEncoded: Uint8Array): string {
        return Array.from(valueToBeEncoded).map(value => value.toString(16).padStart(2, '0')).join('');
    }

    /**
     * Encrypts the given metadata  with sha256
     * @param {Metadata[]} monitoredMetadata The array of metadatas to be encrypted
     * @returns {Observable} The encrypted metadata
     */
    private encryptMetadata(monitoredMetadata: Metadata[]): Observable<any> {
        const encryptedObj = {};
        for (const metadata of monitoredMetadata) {
            const encodedToken = new TextEncoder().encode(metadata.value);
            encryptedObj[metadata.metadata_id] = defer(() => 
                crypto.subtle.digest(WebmailProtectionKeyAlgorithm.SHA_256, encodedToken)
            )
            .pipe(
                map((buffer: ArrayBuffer) => {
                    metadata.encryptedValue = this.base16URLEncode(new Uint8Array(buffer));
                    return true;
                }),
                catchError(error => {
                    throw error;
                })
            );
        }
        return Object.keys(encryptedObj).length === 0 ? of(true) : forkJoin(encryptedObj);
    }

    /**
     * Encrypts the given value with sha256
     * @param {string} value The value to be encrypted
     * @returns {Observable} The encrypted value
     */
    public encryptSingularValue(value: string): Observable<string> {
        if (!value) {
            throw new Error(Errors.INVALID_PARAMS);
        }

        const encodedValue = new TextEncoder().encode(value);
        return defer(() =>
            crypto.subtle.digest(WebmailProtectionKeyAlgorithm.SHA_256, encodedValue)
        )
        .pipe(
            map((buffer: ArrayBuffer) => {
                return this.base16URLEncode(new Uint8Array(buffer));;
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * Updates the action status
     * @param {AssetRecommendation} action The action that was modified
     */
    private updateActionStatus(action: AssetRecommendation): void {
        if (!this.actions?.categories?.length) {
            return;
        }

        for (const category of this.actions.categories) {
            if (category.category !== action.category) {
                continue;
            }

            for (const item of category.category_actions) {
                if (item.type === action.type) {
                    item.status = this.businessValuesService.businessRecommendationStatusByType[action.status];
                }
            }
        }
    }

    /**
     * Gets all global actions
     * @returns {Observable} The request response
     */
    public listGlobalActions(): Observable<any> {
        if (!this.markToUpdateActions || !this.identity || !this.isIdentityInitDone() || this.isIdentityParked()) {
            return of(this.actions);
        }

        if (this.onListActions$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListActions$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        }

        this.onListActions$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectBusinessAssetsService.getGlobalActions()
        .pipe(
            map(resp => {
                if (!resp) {
                    this.markToUpdateActions = true;
                    throw resp;
                }

                this.markToUpdateActions = false;
                this.onListActions$.next(this.valuesService.processServiceState.DONE);
                this.actions = resp;
                return this.actions;
            }),
            catchError(err => {
                this.markToUpdateActions = true;
                this.onListActions$.next(this.valuesService.processServiceState.DONE);
                throw err;
            })
        );
    }

    /**
     * Sets the status for goven global action
     * @param {AssetRecommendation} action The action to be modified
     * @returns {Observable} The request response
     */
     public setGlobalActionStatus(action: AssetRecommendation): Observable<any> {
        return this.connectBusinessAssetsService.setGlobalActionStatus(action)
        .pipe(
            map(resp => {
                if (!resp) {
                    return false;
                }

                this.updateActionStatus(action);
                return true;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    /**
     * Marks actions request to be updated
     */
    public markActionsToBeUpdated(): void {
        if (this.onListActions$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateActions = true;
        }
    }

    /**
     * Gets all global actions for business assets
     * @returns {ActionsModel} The actions object
     */
    public getGlobalActions(): ActionsModel {
        return this.actions;
    }

    /**
     * Lists all threats of a group for given number of days
     * @param {string} groupId The id of the group to be checked
     * @param {Security.Overview.Filter} filter The number of days to be checked
     * @returns {Observable} The request response
     */
    public listGroupThreatReport(groupId: string, filter: Security.Overview.Filter): Observable<any> {
        if (this.groupThreatsByTime[filter].markToUpdate === false) {
            return of(true);
        }

        if (this.groupThreatsByTime[filter].onList.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.groupThreatsByTime[filter].onList.asObservable()
            .pipe(
                skipWhile(resp => resp !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.groupThreatsByTime[filter].onList.next(this.valuesService.processServiceState.INPROGRESS);
            return this.seccenterService.getGroupThreatReport(groupId, filter)
            .pipe(
                map(resp => {
                    if (resp) {
                        this.groupThreatsByTime[filter].data = resp;
                        this.groupThreatsByTime[filter].error = false;
                        this.groupThreatsByTime[filter].markToUpdate = false;
                        this.groupThreatsByTime[filter].onList.next(this.valuesService.processServiceState.DONE);
                    } else {
                        this.groupThreatsByTime[filter].error = true;
                        this.groupThreatsByTime[filter].markToUpdate = true;
                        this.groupThreatsByTime[filter].onList.next(this.valuesService.processServiceState.DONE);
                    }
                    return true;
                }),
                catchError(err => {
                    this.groupThreatsByTime[filter].error = true;
                    this.groupThreatsByTime[filter].markToUpdate = true;
                    this.groupThreatsByTime[filter].onList.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    /**
     * Gets the threats count error for an interval
     * @param {Security.Overview.Filter} filter The interval for the threats
     * @return {boolean} True if error occured, false otherwise
     */
     public getGroupThreatReportError(filter: Security.Overview.Filter): boolean {
        return this.groupThreatsByTime[filter].error;
    }

    /**
     * Gets the threat report for a given interval
     * @param {Security.Overview.Filter} filter The interval for the threats
     * @return {ThreatsCounter[]} The threats count array
     */
    public getGroupThreatReport(filter: Security.Overview.Filter): ThreatsCounter[] {
        return this.groupThreatsByTime[filter].data ?? [];
    }

    /**
     * Computes the group threats info between given dates
     * @param {Security.Overview.Filter} filter The interval for the threats
     * @param {string} groupId The id of the group to be checked
     * @param {number} offset The offset for the threats
     * @returns {GroupThreatsParams} The threats info object
     */
    private computeGroupThreatsInfo(filter: Security.Overview.Filter, groupId: string, offset?: number): GroupThreatsParams {
        const threatsInfo: GroupThreatsParams = {
            group_id: groupId,
            from_date: (new Date()).setDate(new Date().getDate() - filter),
            to_date: new Date().getTime(),
        };

        if (offset) {
            threatsInfo.offset = offset;
        }
        return threatsInfo;
    }

    /**
     * Lists threats details of a group between given dates
     * @param {string} groupId The id of the group to be checked
     * @param {Security.Overview.Filter} filter The interval for the threats
     * @param {number} offset The offset for the threats
     * @returns {Observable} The request response
     */
    public listGroupThreats(groupId: string, filter: Security.Overview.Filter, offset?: number): Observable<any> {
        if (offset) {
            this.groupThreatsList[filter].markToUpdate = true;
        }

        if (this.groupThreatsList[filter].markToUpdate === false) {
            return of(true);
        }

        if (this.groupThreatsList[filter].onList.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.groupThreatsList[filter].onList.asObservable()
            .pipe(
                skipWhile(resp => resp !== this.valuesService.processServiceState.DONE)
            );
        } else {
            const requestParams = this.computeGroupThreatsInfo(filter, groupId, offset);
            return this.seccenterService.getGroupThreats(requestParams)
            .pipe(
                map(resp => {
                    if (!Array.isArray(resp)) {
                        throw resp;
                    }

                    if (offset) {
                        this.groupThreatsList[filter].data = this.groupThreatsList[filter].data.concat(resp);
                    } else {
                        this.groupThreatsList[filter].data = resp;
                    }
                    this.groupThreatsList[filter].markToUpdate = false;
                    this.groupThreatsList[filter].error = false;
                    this.groupThreatsList[filter].onList.next(this.valuesService.processServiceState.DONE);
                    return true;
                }),
                catchError(err => {
                    this.groupThreatsList[filter].error = true;
                    this.groupThreatsList[filter].markToUpdate = true;
                    this.groupThreatsList[filter].onList.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    /**
     * Gets the threats error
     * @returns {boolean} True if error occured, false otherwise
     */
    public getGroupThreatsError(filter: Security.Overview.Filter): boolean {
        return this.groupThreatsList[filter].error;
    }

    /**
     * Gets the array of days with activity summary for the user
     * @returns {Threat[]} The array of days with threats activity
     */
    public getGroupThreats(filter: Security.Overview.Filter): Threat[] {
        return this.groupThreatsList[filter].data ?? [];
    }

}
