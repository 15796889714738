import { Injectable } from '@angular/core';
import { getPwaActive } from '../../../../../app/app.module';
import { HandleUnrecoverableStateService } from '../handle-unrecoverable-state/handle-unrecoverable-state.service';
import { PromptUpdateService } from '../prompt-update/prompt-update.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({providedIn: 'root'})

export class ServiceWorkerHelperService {
    public isDesktop = this.deviceDetectorService.isDesktop();

    constructor(
        private readonly promptUpdateService: PromptUpdateService,
        private readonly handleUnrecoverableStateService: HandleUnrecoverableStateService,
        private readonly deviceDetectorService: DeviceDetectorService
    ) {}

     /**
     * Method used to display banner if the service worker is broken.
     * @returns {boolean} True if the service worker is broken, false otherwise.
     */
     public brokenServiceWorker(): boolean {
        return (this.handleUnrecoverableStateService.getServiceWorkerIsUnavailable()
                || this.handleUnrecoverableStateService.getUnrecoverableStateFlag())
                    && !this.promptUpdateService.getNewVersionReadyFlag()
                    && !this.handleUnrecoverableStateService.thereAreConnectivityProblems();
    }

    public newVersion() {
        return this.promptUpdateService.getNewVersionReadyFlag() && !this.handleUnrecoverableStateService.thereAreConnectivityProblems();
    }

    /**
     * Method used to display banner if the service worker is broken or if there is a new version available, but the user is online.
     * @returns {boolean} True if the service worker is broken, false otherwise.
     */
    public showServiceWorkerBanner(): boolean {
        return getPwaActive()
            && (this.brokenServiceWorker()
                || this.promptUpdateService.getNewVersionReadyFlag()
                || this.handleUnrecoverableStateService.thereAreConnectivityProblems());
    }

    /**
     * Method used to display banner if the service worker is broken or if there is a new version available, but the user is online.
     * @returns {boolean} True if the service worker is broken, false otherwise.
     */
    public showServiceWorkerBannerInHeader(): boolean {
        return getPwaActive()
            && (this.brokenServiceWorker()
                || this.promptUpdateService.getNewVersionReadyFlag()
                || (!this.isDesktop && this.handleUnrecoverableStateService.thereAreConnectivityProblems()));
    }

    /**
     * Method used to reset the flags used for displaying the service worker banner.
     * This method is called when the user clicks on the banner and prevents the banner from being displayed after refresh for a moment.
     */
    public resetBannerFlags(): void {
        this.promptUpdateService.resetNewVersionReadyFlag();
        this.handleUnrecoverableStateService.resetUnrecoverableStateFlag();
        this.handleUnrecoverableStateService.setServiceWorkerIsUnavailable(false);
        this.handleUnrecoverableStateService.setFailedToFetchFlag(false);
    }
}