// External
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { catchError, concat, last, map, Observable, of } from 'rxjs';

// Internal
import { ValuesService } from '../../values/values.service';
import { MessageService } from '../core/message.service';
import { AuthGuard } from './auth.guard';
import { NavigationGuard } from './navigation.guard';
import { AdobeDataLayerService } from '../core/adobe.datalayer.service';
import { QueryParamsService } from '../core/query.params.service';

@Injectable({
    providedIn: 'root'
})

export class CombinedMainGuard {

    private authGuardObservable$ = this.authGuard;
    private navigationGuardObservable$ = this.navigationGuard;
    _activated = false;

    constructor(
        private readonly authGuard: AuthGuard,
        private readonly navigationGuard: NavigationGuard,
        private readonly messageService: MessageService,
        private readonly valuesService: ValuesService,
        private readonly adobeDataLayerService: AdobeDataLayerService,
        private readonly queryParamsService: QueryParamsService
    ) {
        this.messageService.getMessage()
        .subscribe(message => {
            if (message.text === this.valuesService.events.languageChange) {
                this._activated = false;
            }
        });
    }

    activateGuard() {
        this._activated = false;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        const computeResp = (resp) => {
            // Daca returneaza URLTree sau false iese din concat
            // Daca returneaza true continua concat-ul
            if (resp !== true) {
                throw resp;
            } else {
                return resp;
            }
        }

        return concat(
            this.authGuardObservable$.canActivate(route, state).pipe(map(computeResp)),
            this.navigationGuardObservable$.canActivate(route, state).pipe(map(computeResp))
        ).pipe(
            last(),
            map(
                (res) => {
                    this._activated = true;
                    return res;
                }
            ),
            catchError(
                (err) => {
                    //\ Pe cazul de home nu se intra pe navigationGuard si sunt 2 metode de dataLayer necesare
                    this.adobeDataLayerService.setReferringUrl();
                    this.adobeDataLayerService.setTrackingId(this.queryParamsService.getAll());
                    return of(err);
                }
            )
        );
    }

}
