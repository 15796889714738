import { Component, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ServiceWorkerHelperService } from '../../services/global/service-worker-helper/service-worker-helper.service';

export enum ConnectionStatusLayout {
    MENU = 'menu',
    ALERT = 'alert'
};

@Component({
    selector: 'connection-status',
    templateUrl: './connection-status.component.html'
})

export class ConnectionStatusComponent {
    @Input() bg: string; // ex: bg-primary, bg-danger, bg-a3-10
    @Input() offline: boolean;
    @Input() newVersion: boolean;
    @Input() broken: boolean;

    public ConnectionStatusLayout = ConnectionStatusLayout;
    public isDesktop = false;

    constructor(
        public readonly deviceDetectorService: DeviceDetectorService,
        public readonly serviceWorkerHelperService: ServiceWorkerHelperService
    ) {
        this.isDesktop = this.deviceDetectorService.isDesktop();
    }

    /**
     * Method that closes the alert and refreshes the page.
     */
    public applyUpdate(): void {
        this.serviceWorkerHelperService.resetBannerFlags();
        window.location.reload();
    }
}
