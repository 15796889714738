import { NgModule } from "@angular/core";
import { UiButtonComponent } from "./ui/ui-button/ui-button.component";
import { UiIncrementCounterDirective } from "./ui/ui-increment-counter/ui-increment-counter.directive";
import { UiListItemDirective } from "./ui/ui-list/ui-list-item.directive";
import { UiListDirective } from "./ui/ui-list/ui-list.directive";
import { UiIconModule } from "./ui/ui-icon/ui-icon.module";
import { UiButtonModule } from "./ui/ui-button/ui-button.module";

@NgModule({
    declarations: [
        UiListDirective,
        UiListItemDirective,
        UiIncrementCounterDirective
    ],
    imports: [
        UiButtonModule
    ],
    providers: [ ],
    exports: [
        UiButtonModule,
        UiListDirective,
        UiListItemDirective,
        UiIncrementCounterDirective
    ]
})

export class DirectivesModule { }