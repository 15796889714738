<ng-container *ngFor="let toast of toasts">

  <!-- [@slideVertical]  -->
  <div data-id="div_1577441774" class="toast" [ngClass]="toast.state">
    <div data-id="div_1577452854" class="toast-inner">
      <p>{{ toast.message }}</p>
    </div>
    <div data-id="div_1577452867" *ngIf="toast.dismissable" (click)="removeToast(toast)" aria-label="close" class="toast-close">&times;</div>
  </div>

</ng-container>
