// External
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// Internal
import { routes } from './routes';

@NgModule({
    // imports: [ RouterModule.forChild(routes) ],
    imports: [ RouterModule.forRoot(routes,
                {
                    onSameUrlNavigation: 'reload',
                    enableTracing: false,
                    paramsInheritanceStrategy: 'always'
                })
            ],
    exports: [ RouterModule ]
})

export class AppRoutingModule {}
