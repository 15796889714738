import { Injectable } from '@angular/core';
import { ConfigService } from '../../common/config/config.service';
import { OperatingSystems, ValuesService } from './values.service';
import { LinksService } from '../links/links.service';

export enum AppEnableStatus {
    ON = 1,
    OFF = 0
}

export enum AppStateStatus {
    ON = 1,
    OFF = 0
}
@Injectable({
    providedIn: 'root'
})
export class DevicesValuesService {
    constructor(
        readonly configService: ConfigService,
        readonly valuesService: ValuesService,
        readonly linksService: LinksService
    ) {}

    //! https://confluence.bitdefender.biz/pages/viewpage.action?spaceKey=CONNECT&title=Device+fields+detail

    boxVersion = {
        BOX1: 1,
        BOX2: 2
    };

    deviceType = {
        NETWORK_EQUIPMENT: 'network_equipment',
        SPEAKERS: 'speakers',
        IOT: 'iot',
        ROUTER: 'router'
    };

    deviceCategories = {
        computers: ['computer', 'pc_laptop_linux', 'laptop', 'nas'],
        mobile_devices: ['phone', 'tablet']
        //iot: devices that are not computers or mobile_devices
    };

    deviceFilters = {
        status: {
            all: 'devices.filters.title.status.all',
            atrisk: 'devices.filters.title.status.atrisk',
            protected: 'devices.filters.title.status.protected',
            disconnected: 'devices.filters.title.status.disconnected'
        },
        platform: {
            all: 'devices.filters.title.platform.all',
            mobile: 'devices.filters.title.platform.mobile',
            computer: 'devices.filters.title.platform.computer',
            iot: 'devices.filters.title.platform.iot'
        }
    };

    //statusul aplicatiei de pe device
    appState = {
        NEEDS_INSTALLING: 0, //needs installing: state = 0, enable = 0
        PENDING_INSTALLATION: 0, // pending installation: state = 0, enable = 1
        IS_INSTALLED: 1 // is installed: state = 1
    };

    internet = {
        PAUSED: 1,
        UNPAUSED: 0
    };

    tasksName = {
        vulnerability: {
            assessment: 'va_scan',
            scan: 'vulnerability_scan'
        },
        malware: {
            quick: 'quick_scan',
            system: 'scan'
        },
        update: 'update_product',
        optimizer: {
            optimize: 'clean',
            startup: 'optimize_startup'
        }
    };

    riskIdentifiers = {
        'none': 'none',
        'low': 'low',
        'medium': 'medium',
        'high' : 'high'
    };

    riskProperties = {
        [this.riskIdentifiers.none] : {
            label: 'vulnerabilityScan.modal.risk.none',
            type: 'muted'
        },
        [this.riskIdentifiers.low] : {
            label: 'vulnerabilityScan.modal.risk.low',
            type: 'muted'
        },
        [this.riskIdentifiers.medium] : {
            label: 'vulnerabilityScan.modal.risk.medium',
            type: 'warning'
        },
        [this.riskIdentifiers.high] : {
            label: 'vulnerabilityScan.modal.risk.high',
            type: 'danger'
        }
    };

    quickActions = {
        'malwareScan': 'malwareScan',
        'antitheft': 'antitheft',
        'optimizer': 'optimizer',
        'vulnerabilityScan': 'vulnerabilityScan',
        'scan': 'scan'
    };

    taskStates = {
        IDLE: 'idle',
        RUNNING: 'running',
        PENDING: 'pending'
    };

    quickActionsOS = {
        [this.quickActions.malwareScan]: new Set([OperatingSystems.WINDOWS,
                                                OperatingSystems.WINDOWS_SERVER,
                                                OperatingSystems.ANDROID,
                                                OperatingSystems.OSX]),
        [this.quickActions.antitheft]: new Set([OperatingSystems.WINDOWS,
                                                OperatingSystems.WINDOWS_SERVER,
                                                OperatingSystems.ANDROID]),
        [this.quickActions.optimizer]: new Set([OperatingSystems.WINDOWS,
                                                OperatingSystems.WINDOWS_SERVER]),
        [this.quickActions.vulnerabilityScan]: new Set([OperatingSystems.WINDOWS,
                                                OperatingSystems.WINDOWS_SERVER]),
        [this.quickActions.scan]: new Set([OperatingSystems.IOS])
    };

    quickActionsApps = {
        [this.quickActions.malwareScan]: {
            [this.valuesService.appCL]: null,
            [this.valuesService.appWS]: null,
            [this.valuesService.appBMS]: null,
            [this.valuesService.appAVFM]: null
        },
        [this.quickActions.antitheft]: {
            [this.valuesService.appBMS]: null,
            [this.valuesService.appWS]: null,
            [this.valuesService.appCL]: new Set([this.valuesService.appLevels.EPP_PRIVACY,
                                                                this.valuesService.appLevels.TS])
        },
        [this.quickActions.optimizer]: {
            [this.valuesService.appCL]: new Set([this.valuesService.appLevels.EPP_PRIVACY,
                                                                this.valuesService.appLevels.TS,
                                                                this.valuesService.appLevels.EPP]),
            [this.valuesService.appWS]: null
        },
        [this.valuesService.appWS]: null,
        [this.quickActions.vulnerabilityScan]: {
            [this.valuesService.appWS]: null,
            [this.valuesService.appCL]: null
        },
        [this.quickActions.scan]: {
            [this.valuesService.appBIS]: null,
            [this.valuesService.appBIP]: null
        }
    };

    quickActionsAppsIgnoredLevels = {
        [this.quickActions.vulnerabilityScan]: {
            [this.valuesService.appCL]: new Set([this.valuesService.appLevels.LITE])
        }
    };

    vulnerabilityDetailsWhatToDo = {
        pass    : 'vulnerabilityScan.modal.scan.issues.what.to.do.pass',
        auth    : 'vulnerabilityScan.modal.scan.issues.what.to.do.auth',
        default : 'vulnerabilityScan.modal.scan.issues.what.to.do.default'
    };

    vulnerabilityDetailsByDeviceType = {
        'camera'         : 'vulnerabilityScan.modal.scan.issues.type.camera',
        'computer'       : 'vulnerabilityScan.modal.scan.issues.type.computer',
        'homeAutomation' : 'vulnerabilityScan.modal.scan.issues.type.homeAutomation',
        'homeSecurity'   : 'vulnerabilityScan.modal.scan.issues.type.homeSecurity',
        'phone'          : 'vulnerabilityScan.modal.scan.issues.type.phone',
        'router'         : 'vulnerabilityScan.modal.scan.issues.type.router',
        'tablet'         : 'vulnerabilityScan.modal.scan.issues.type.tablet',
        'default'        : 'vulnerabilityScan.modal.scan.issues.type.default'
    };

    vulnerabilityAssesmentNames = {
        cve: {
            'directory traversal': {
                title      : 'vulnerabilityScan.modal.scan.issues.cve.directoryTraversal',
                info       : 'vulnerabilityScan.modal.scan.issues.cve.directoryTraversal.info',
                attack     : 'vulnerabilityScan.modal.scan.issues.cve.directoryTraversal.attack',
                whatToDo   : this.vulnerabilityDetailsWhatToDo.default,
                link       : this.linksService.vulnerabilityLinks.directoryTraversal
            },
            'sql injection': {
                title      : 'vulnerabilityScan.modal.scan.issues.cve.sqlInjection',
                info       : 'vulnerabilityScan.modal.scan.issues.cve.sqlInjection.info',
                attack     : 'vulnerabilityScan.modal.scan.issues.cve.sqlInjection.attack',
                whatToDo   : this.vulnerabilityDetailsWhatToDo.default,
                link       : this.linksService.vulnerabilityLinks.sqlInjection
            },
            'file inclusion': {
                title      : 'vulnerabilityScan.modal.scan.issues.cve.fileInclusion',
                info       : 'vulnerabilityScan.modal.scan.issues.cve.fileInclusion.info',
                attack     : 'vulnerabilityScan.modal.scan.issues.cve.fileInclusion.attack',
                whatToDo   : this.vulnerabilityDetailsWhatToDo.default,
                link       : this.linksService.vulnerabilityLinks.fileInclusion
            },
            'execute code': {
                title      : 'vulnerabilityScan.modal.scan.issues.cve.executeCode',
                info       : 'vulnerabilityScan.modal.scan.issues.cve.executeCode.info',
                attack     : 'vulnerabilityScan.modal.scan.issues.cve.executeCode.attack',
                whatToDo   : this.vulnerabilityDetailsWhatToDo.default,
                link       : this.linksService.vulnerabilityLinks.executeCode
            },
            'bypass a restriction or similar': {
                title      : 'vulnerabilityScan.modal.scan.issues.cve.bypassRestriction',
                info       : 'vulnerabilityScan.modal.scan.issues.cve.bypassRestriction.info',
                attack     : 'vulnerabilityScan.modal.scan.issues.cve.bypassRestriction.attack',
                whatToDo   : this.vulnerabilityDetailsWhatToDo.default,
                link       : this.linksService.vulnerabilityLinks.bypassRestriction
            },
            'gain privileges': {
                title      : 'vulnerabilityScan.modal.scan.issues.cve.gainPrivileges',
                info       : 'vulnerabilityScan.modal.scan.issues.cve.gainPrivileges.info',
                attack     : 'vulnerabilityScan.modal.scan.issues.cve.gainPrivileges.attack',
                whatToDo   : this.vulnerabilityDetailsWhatToDo.default,
                link       : this.linksService.vulnerabilityLinks.gainPrivileges
            },
            'obtain information': {
                title      : 'vulnerabilityScan.modal.scan.issues.cve.obtainInformation',
                info       : 'vulnerabilityScan.modal.scan.issues.cve.obtainInformation.info',
                attack     : 'vulnerabilityScan.modal.scan.issues.cve.obtainInformation.attack',
                whatToDo   : this.vulnerabilityDetailsWhatToDo.default,
                link       : this.linksService.vulnerabilityLinks.obtainInformation
            },
            'cross site scripting': {
                title      : 'vulnerabilityScan.modal.scan.issues.cve.crossSitSecripting',
                info       : 'vulnerabilityScan.modal.scan.issues.cve.crossSitSecripting.info',
                attack     : 'vulnerabilityScan.modal.scan.issues.cve.crossSitSecripting.attack',
                whatToDo   : this.vulnerabilityDetailsWhatToDo.default,
                link       : this.linksService.vulnerabilityLinks.crossSiteScripting
            },
            'denial of service': {
                title      : 'vulnerabilityScan.modal.scan.issues.cve.denialOfService',
                info       : 'vulnerabilityScan.modal.scan.issues.cve.denialOfService.info',
                attack     : 'vulnerabilityScan.modal.scan.issues.cve.denialOfService.attack',
                whatToDo   : this.vulnerabilityDetailsWhatToDo.default,
                link       : this.linksService.vulnerabilityLinks.denialOfService
            },
            'memory corruption': {
                title      : 'vulnerabilityScan.modal.scan.issues.cve.memoryCorruption',
                info       : 'vulnerabilityScan.modal.scan.issues.cve.memoryCorruption.info',
                attack     : 'vulnerabilityScan.modal.scan.issues.cve.memoryCorruption.attack',
                whatToDo   : this.vulnerabilityDetailsWhatToDo.default,
                link       : this.linksService.vulnerabilityLinks.memoryCorruption
            },
            'overflow': {
                title      : 'vulnerabilityScan.modal.scan.issues.cve.overflow',
                info       : 'vulnerabilityScan.modal.scan.issues.cve.overflow.info',
                attack     : 'vulnerabilityScan.modal.scan.issues.cve.overflow.attack',
                whatToDo   : this.vulnerabilityDetailsWhatToDo.default,
                link       : this.linksService.vulnerabilityLinks.overflow
            },
            'http response splitting': {
                title      : 'vulnerabilityScan.modal.scan.issues.cve.httpResponseSplitting',
                info       : 'vulnerabilityScan.modal.scan.issues.cve.httpResponseSplitting.info',
                attack     : 'vulnerabilityScan.modal.scan.issues.cve.httpResponseSplitting.attack',
                whatToDo   : this.vulnerabilityDetailsWhatToDo.default,
                link       : this.linksService.vulnerabilityLinks.httpResponseSplitting
            },
            'csrf': {
                title      : 'vulnerabilityScan.modal.scan.issues.cve.csrf',
                info       : 'vulnerabilityScan.modal.scan.issues.cve.csrf.info',
                attack     : 'vulnerabilityScan.modal.scan.issues.cve.csrf.attack',
                whatToDo   : this.vulnerabilityDetailsWhatToDo.default,
                link       : this.linksService.vulnerabilityLinks.csrf
            },
            default: {
                title      : 'vulnerabilityScan.modal.scan.issues.cve.default',
                whatToDo   : this.vulnerabilityDetailsWhatToDo.default,
                cveLink    : this.linksService.vulnerabilityLinks.cve
            }
        },
        weak_password: {
            title      : 'vulnerabilityScan.modal.scan.issues.weakPassword',
            info       : 'vulnerabilityScan.modal.scan.issues.weakPassword.info',
            whatToDo   : this.vulnerabilityDetailsWhatToDo.pass
        },
        http_basic_auth: {
            title      : 'vulnerabilityScan.modal.scan.issues.httpBasicAuth',
            info       : 'vulnerabilityScan.modal.scan.issues.httpBasicAuth.info',
            whatToDo   : this.vulnerabilityDetailsWhatToDo.auth
        }
    };

    settings = {
        'rename': 'rename',
        'remove': 'remove',
        'update': 'update',
        'profile': 'profile',
        'ip': 'ip',
        'portForwarding': 'portForwarding'
    };

    settingsApps = {
        [this.settings.remove]: null,
        [this.settings.rename]: null,
        [this.settings.profile]: null,
        [this.settings.update]: new Set([
                                        this.valuesService.appCL,
                                        this.valuesService.appWS,
                                        this.valuesService.appAVFM
                                    ]),
        [this.settings.ip]: new Set([
                                        this.valuesService.appBOX2
                                    ]),
        [this.settings.portForwarding]: new Set([
                                        this.valuesService.appBOX2
                                    ])
    };

    filterTypes = {
        PLATFORM: 'platform',
        STATUS: 'status'
    };

    filterNames = {
        computer: 'computer',
        mobile: 'mobile',
        iot: 'iot',
        protectedDevices: 'protected',
        atRiskDevices: 'atrisk',
        disconnectedDevices: 'disconnected',
        all: 'all'
    };

    computerDevices = new Set([
        'computer',
        'virtual machine',
        'vm',
        'laptop'
    ]);

    mobileDevices = new Set([
        'tablet',
        'phone'
    ]);

    iotDevices = new Set([
        'animal tracker',
        'weather sensor',
        'walkman',
        'voip phone',
        'ups',
        'thermostat',
        'speakers',
        'solar panel',
        'smart plug',
        'smart bulbs',
        'projector',
        'printer',
        'power optimizer',
        'ip camera',
        'iot',
        'iot security',
        'iot hub',
        'home monitoring',
        'heating system',
        'health',
        'health device',
        'generic sensor',
        'garage doors',
        'garage door',
        'doorlock',
        'doorbell',
        'camera',
        'baby monitor'
    ]);

    filtersForDevices = {
        [this.filterTypes.STATUS]: {
            text: 'devices.filters.status',
            type: this.filterTypes.STATUS,
            show: this.configService.config.devices.filters.status,
            values: [
                {
                    id: 1,
                    key: this.filterNames.all,
                    text: 'devices.filters.all_devices'
                },
                {
                    id: 2,
                    key: this.filterNames.protectedDevices,
                    text: 'devices.issues.noIssues'
                },
                {
                    id: 3,
                    key: this.filterNames.atRiskDevices,
                    text: 'devices.status.atRisk'
                },
                {
                    id: 4,
                    key: this.filterNames.disconnectedDevices,
                    text: 'devices.disconnected'
                }
            ]
        },
        'platform': {
            text: 'devices.filters.platform',
            type: 'platform',
            show: this.configService.config.devices.filters.platform,
            values: [
                {
                    id: 4,
                    key: this.filterNames.all,
                    text: 'devices.filters.all'
                },
                {
                    id: 1,
                    key: this.filterNames.computer,
                    text: 'devices.filters.computer'
                },
                {
                    id: 2,
                    key: this.filterNames.mobile,
                    text: 'devices.filters.mobile'
                },
                {
                    id: 3,
                    key: this.filterNames.iot,
                    text: 'devices.filters.iot'
                },
            ]
        }
    };

    filterKeyInStorage = '_filterHistory';
    duplicatesBannerInStorage = '_duplicatesBanner';

    texts = {
        nameFormErrorsMsg: {
            boxName: {
                required: 'devices.settings.errorChangedInput',
                pattern: 'devices.settings.deviceName'
            }
        }
    };

    deviceDashboardPage = '/devices/dashboard?device_id=';

    limitReachedCases = {
        oneSubs : {
            text: 'devices.limit.reached.banner.one.subs.text',
            buttonName: 'devices.limit.reached.banner.one.subs.button'
        },
        moreSubs: {
            text: 'devices.limit.reached.banner.more.subs.text',
            buttonName: 'devices.limit.reached.banner.more.subs.button'
        }
    };

    limitReachedStepDays = {
        STEP0: 0,
        STEP1: 1,
        STEP2: 3,
        STEP3: 7,
        STEP4: 14,
        STEP5: 30,
        STEP6: 30
    };

    portLimits = {
        max: 65535,
        min: 1
    };

    ipLimits = {
        max: 254,
        min: 2
    };

    protocols = {
        'tcp': 'tcp',
        'udp': 'udp',
        'tcpudp': 'tcpudp'
    };

    deviceStateBasedOnSubscriptions = {
        ALL_EXPIRED: 'allExpired',
        NO_PROTECTION_IOT: 'noProtectionIot',
        NO_FREE_SLOTS: 'noFreeSlots',
        NO_PROTECTION_NO_AGENT_FREE_SLOTS: 'noProtectionNoAgentFreeSlots',
        NO_PROTECTION_AGENT_FREE_SLOTS: 'noProtectionAgentFreeSlots',
        SLOT_NOT_FOUND: 'slotNotFound',
        AVFREE_PROTECTION: 'avfreeProtection',
        PROTECTION: 'protection',
        FREE_WEB_PROTECTION: 'freeWebProtection',
        PAID_WEB_PROTECTION_NO_SLOT: 'paidWebPritectionNoSlot',
        PAID_WEB_PROTECTION: 'paidWebProtection'
    };


    finalStatus = {
        [this.deviceStateBasedOnSubscriptions.ALL_EXPIRED]: this.valuesService.protectionStatus.AT_RISK,
        [this.deviceStateBasedOnSubscriptions.NO_PROTECTION_IOT]: this.valuesService.protectionStatus.AT_RISK,
        [this.deviceStateBasedOnSubscriptions.NO_FREE_SLOTS]: this.valuesService.protectionStatus.AT_RISK,
        [this.deviceStateBasedOnSubscriptions.NO_PROTECTION_NO_AGENT_FREE_SLOTS]: this.valuesService.protectionStatus.AT_RISK,
        [this.deviceStateBasedOnSubscriptions.NO_PROTECTION_AGENT_FREE_SLOTS]: this.valuesService.protectionStatus.AT_RISK,
        [this.deviceStateBasedOnSubscriptions.SLOT_NOT_FOUND]: this.valuesService.protectionStatus.AT_RISK,
        [this.deviceStateBasedOnSubscriptions.AVFREE_PROTECTION]: this.valuesService.protectionStatus.PROTECTED,
        [this.deviceStateBasedOnSubscriptions.PROTECTION]: this.valuesService.protectionStatus.PROTECTED,
        [this.deviceStateBasedOnSubscriptions.FREE_WEB_PROTECTION]: this.valuesService.protectionStatus.PROTECTED,
        [this.deviceStateBasedOnSubscriptions.PAID_WEB_PROTECTION_NO_SLOT]: this.valuesService.protectionStatus.PROTECTED,
        [this.deviceStateBasedOnSubscriptions.PAID_WEB_PROTECTION]: this.valuesService.protectionStatus.PROTECTED
    };

    issueType = {
        auto: 'auto', // 1
        manual: 'manual' //0
    };

    // se vor schimba textele, momentan le folosesc pe cele deja existente in proiect
    issues = {
        [this.valuesService.appCL]: {
            1: { // auto
                title: 'devices.details.issues.onaccess.RTP.disabled.title',
                details: 'devices.details.issues.onaccess.RTP.disabled.desc',
                titleFixed: 'devices.details.issues.onaccess.RTP.enabled.title',
                howToFix: 'fix.issues.modal.open.and.switch.protection'
            },
            4: { // auto
                title: 'devices.details.issues.update.required.title',
                details: 'devices.details.issues.update.required.desc',
                titleFixed: 'devices.details.issues.update.success.title',
                howToFix: 'devices.details.issues.update.required.howToFix'
            },
            6: { // auto
                title: 'devices.details.issues.antispam.disabled.title',
                details: 'devices.details.issues.antispam.disabled.desc',
                titleFixed: 'devices.details.issues.antispam.enabled.title',
                howToFix: 'fix.issues.modal.open.and.switch.antispam'
            },
            10: { // auto
                title: 'devices.details.issues.firewall.disabled.title',
                details: 'devices.details.issues.firewall.disabled.desc',
                titleFixed: 'devices.details.issues.firewall.enabled.title',
                howToFix: 'fix.issues.modal.open.and.switch.firewall'
            },
            44: { // auto
                title: 'devices.details.issues.update.required.title',
                details: 'devices.details.issues.update.required.more.hours.desc',
                titleFixed: 'devices.details.issues.update.success.title',
                howToFix: 'devices.details.issues.update.required.howToFix'
            },
            100: { // auto
                title: 'devices.details.issues.antiphishing.filter.disabled.title',
                details: 'devices.details.issues.antiphishing.filter.disabled.desc',
                titleFixed: 'devices.details.issues.antiphishing.filter.enabled.title',
                howToFix: 'fix.issues.modal.open.and.switch.antiphishing'
            },
            103: { // auto
                title: 'devices.details.issues.HTTP.scan.disabled.title',
                details: 'devices.details.issues.HTTP.scan.disabled.desc',
                titleFixed: 'devices.details.issues.HTTP.scan.enabled.title',
                howToFix: 'fix.issues.modal.open.and.switch.webAttackPrevention'
            },
            104: { // auto
                title: 'devices.details.issues.SSL.scan.disabled.title',
                details: 'devices.details.issues.SSL.scan.disabled.desc',
                titleFixed: 'devices.details.issues.SSL.scan.enabled.title',
                howToFix: 'fix.issues.modal.open.and.switch.webScan'
            },
            105: { // manual
                title: 'devices.details.issues.scan.files.disabled.title',
                details: 'devices.details.issues.scan.files.disabled.desc',
                titleFixed: 'devices.details.issues.scan.files.enabled.title',
                howToFix: 'devices.details.issues.scan.files.disabled.howToFix'
            },
            400: { // auto
                title: 'devices.details.issues.ATD.disabled.title',
                details: 'devices.details.issues.ATD.disabled.desc',
                titleFixed: 'devices.details.issues.ATD.enabled.title',
                howToFix: 'fix.issues.modal.open.and.switch.advancedThreatDefense'
            },
            500: { // auto
                title: 'devices.details.issues.not.cyber_security.app.title',
                details: 'devices.details.issues.not.cyber_security.app.desc',
                titleFixed: 'devices.details.issues.cyber_security.app.title',
                howToFix: '' // nu are howToFix
            },
            800: { // manual
                title: 'devices.details.issues.malware.protection.disabled.title',
                details: 'devices.details.issues.malware.protection.disabled.desc',
                titleFixed: 'devices.details.issues.malware.protection.enabled.title',
                howToFix: 'devices.details.issues.malware.protection.how.to.fix'
            }
        },

        [this.valuesService.appWS]: {
            1: { // auto
                title: 'devices.details.issues.onaccess.RTP.disabled.title',
                details: 'devices.details.issues.onaccess.RTP.disabled.desc',
                titleFixed: 'devices.details.issues.onaccess.RTP.enabled.title',
                howToFix: 'fix.issues.modal.open.and.switch.protection'
            },
            4: { // auto
                title: 'devices.details.issues.update.required.title',
                details: 'devices.details.issues.update.required.desc',
                titleFixed: 'devices.details.issues.update.success.title',
                howToFix: 'devices.details.issues.update.required.howToFix'
            },
            6: { // auto
                title: 'devices.details.issues.antispam.disabled.title',
                details: 'devices.details.issues.antispam.disabled.desc',
                titleFixed: 'devices.details.issues.antispam.enabled.title',
                howToFix: 'fix.issues.modal.open.and.switch.antispam'
            },
            10: { // auto
                title: 'devices.details.issues.firewall.disabled.title',
                details: 'devices.details.issues.firewall.disabled.desc',
                titleFixed: 'devices.details.issues.firewall.enabled.title',
                howToFix: 'fix.issues.modal.open.and.switch.firewall'
            },
            44: { // auto
                title: 'devices.details.issues.update.required.title',
                details: 'devices.details.issues.update.required.more.hours.desc',
                titleFixed: 'devices.details.issues.update.success.title',
                howToFix: 'devices.details.issues.update.required.howToFix'
            },
            100: { // auto
                title: 'devices.details.issues.antiphishing.filter.disabled.title',
                details: 'devices.details.issues.antiphishing.filter.disabled.desc',
                titleFixed: 'devices.details.issues.antiphishing.filter.enabled.title',
                howToFix: 'fix.issues.modal.open.and.switch.antiphishing'
            },
            103: { // auto
                title: 'devices.details.issues.HTTP.scan.disabled.title',
                details: 'devices.details.issues.HTTP.scan.disabled.desc',
                titleFixed: 'devices.details.issues.HTTP.scan.enabled.title',
                howToFix: 'fix.issues.modal.open.and.switch.webAttackPrevention'
            },
            104: { // auto
                title: 'devices.details.issues.SSL.scan.disabled.title',
                details: 'devices.details.issues.SSL.scan.disabled.desc',
                titleFixed: 'devices.details.issues.SSL.scan.enabled.title',
                howToFix: 'fix.issues.modal.open.and.switch.webScan'
            },
            105: { // manual
                title: 'devices.details.issues.scan.files.disabled.title',
                details: 'devices.details.issues.scan.files.disabled.desc',
                titleFixed: 'devices.details.issues.scan.files.enabled.title',
                howToFix: 'devices.details.issues.scan.files.disabled.howToFix'
            },
            400: { // auto
                title: 'devices.details.issues.ATD.disabled.title',
                details: 'devices.details.issues.ATD.disabled.desc',
                titleFixed: 'devices.details.issues.ATD.enabled.title',
                howToFix: 'fix.issues.modal.open.and.switch.advancedThreatDefense'
            },
            500: { // auto
                title: 'devices.details.issues.not.cyber_security.app.title',
                details: 'devices.details.issues.not.cyber_security.app.desc',
                titleFixed: 'devices.details.issues.cyber_security.app.title',
                howToFix: '' // nu are howToFix
            },
            800: { // manual
                title: 'devices.details.issues.malware.protection.disabled.title',
                details: 'devices.details.issues.malware.protection.disabled.desc',
                titleFixed: 'devices.details.issues.malware.protection.enabled.title',
                howToFix: 'devices.details.issues.malware.protection.how.to.fix'
            }
        },

        [this.valuesService.appBMS]: {
            1: { // manual
                title: 'devices.details.issues.web.protection.off.title',
                details: 'devices.details.issues.web.protection.off.desc',
                titleFixed: 'devices.details.issues.web.protection.on.title',
                howToFix: 'devices.details.issues.web.protection.off.how.to.fix'
            },
            2: { // manual
                title: 'devices.details.issues.web.protection.disabled.title',
                details: 'devices.details.issues.web.protection.disabled.desc',
                titleFixed: 'devices.details.issues.web.protection.enabled.title',
                howToFix: 'devices.details.issues.web.protection.disabled.how.to.fix'
            },
            3: { // manual
                title: 'devices.details.issues.threat.detected.title',
                details: 'devices.details.issues.threat.detected.desc',
                titleFixed: 'devices.details.issues.threat.removed.title',
                howToFix: 'devices.details.issues.threat.detected.how.to.fix'
            },
            4: { // fix
                title: 'devices.details.issues.device.state.not.determined.title',
                details: 'devices.details.issues.device.state.not.determined.desc',
                titleFixed: 'devices.details.issues.device.performed.title',
                howToFix: 'devices.details.issues.device.state.not.determined.howToFix'
            }
        },

        [this.valuesService.appAVFM]: {
            1: { // auto
                title: 'devices.details.issues.onaccess.shield.disabled.title',
                details: 'devices.details.issues.onaccess.shield.disabled.desc',
                titleFixed: 'devices.details.issues.onaccess.shield.enabled.title',
                howToFix: 'fix.issues.modal.open.and.switch.shield'
            },
            2: { // manual
                title: 'devices.details.issues.threats.require.removal.title',
                details: 'devices.details.issues.threats.require.removal.desc',
                titleFixed: 'devices.details.issues.threats.removed.title',
                howToFix: 'devices.details.issues.threats.require.removal.how.to.fix'
            },
            4: { // manual
                title: 'devices.details.issues.threat.update.failed.title',
                details: 'devices.details.issues.threat.update.failed.desc',
                titleFixed: 'devices.details.issues.threat.update.success.title',
                howToFix: 'devices.details.issues.threat.update.failed.how.to.fix'
            }
        },
        // este .iossecurty dar paid (atunci cand e paid, setez ca app de protectie .iosprotection ca sa le diferentiez)
        // issue-ul se refera la faptul ca nu este enabled ios protection
        [this.valuesService.appBIP]: {
            1: { // auto sau manual
                title: 'devices.details.issues.web.protection.off.title',
                details: 'devices.details.issues.web.protection.ios.off.desc',
                titleFixed: 'devices.details.issues.web.protection.on.title',
                howToFix: 'devices.details.issues.web.protection.off.ios.how.to.fix'
            }
        },
        // desi doar BIP raporteaza issues, pe raspunsul requestului care listeaza issues, in cel putin un caz
        // app id vine BIS, asa ca am duplicat continutul issue-ului atat pt BIP cat si pt BIS
        [this.valuesService.appBIS]: {
            1: { // auto sau manual
                title: 'devices.details.issues.web.protection.off.title',
                details: 'devices.details.issues.web.protection.ios.off.desc',
                titleFixed: 'devices.details.issues.web.protection.on.title',
                howToFix: 'devices.details.issues.web.protection.off.ios.how.to.fix'
            }
        }
    };

    actionTypes = {
        openSubscriptions: 'openSubscriptions',
        buySubscriptions: 'buySubscriptions',
        installPtortection: 'installProtection',
        enableProtection: 'enableProtection',
        upgradeProtection: 'upgradeProtection'
    };

    pseudoIssues = {
        [this.deviceStateBasedOnSubscriptions.ALL_EXPIRED]: {
            title: 'devices.details.no.subscriptions',
            list: [
                {
                    title: 'devices.details.no.subscriptions.details',
                    fixable: this.issueType.manual,
                    created: '',
                    appId: this.deviceStateBasedOnSubscriptions.ALL_EXPIRED,
                    issue_id: ''
                }
            ],
            hasIssues: true,
            action: this.actionTypes.buySubscriptions,
            actionName: 'devices.details.action.reactivate'
        },
        [this.deviceStateBasedOnSubscriptions.NO_PROTECTION_IOT]: {
            title: 'devices.details.no.specific.subscriptions',
            list: [
                {
                    title: 'devices.details.no.subscriptions.details',
                    fixable: this.issueType.manual,
                    created: '',
                    appId: this.deviceStateBasedOnSubscriptions.NO_PROTECTION_IOT,
                    issue_id: ''
                }
            ],
            hasIssues: true,
            action: this.actionTypes.openSubscriptions,
            actionName: 'devices.details.action.manage'
        },
        [this.deviceStateBasedOnSubscriptions.NO_FREE_SLOTS]: {
            title: 'devices.limit.reached',
            list: [
                {
                    title: 'devices.limit.reached.details',
                    fixable: this.issueType.manual,
                    created: '',
                    appId: this.deviceStateBasedOnSubscriptions.NO_FREE_SLOTS,
                    issue_id: ''
                }
            ],
            hasIssues: true,
            action: this.actionTypes.buySubscriptions,
            actionName: 'devices.details.action.buyMore'
        },
        [this.deviceStateBasedOnSubscriptions.NO_PROTECTION_NO_AGENT_FREE_SLOTS]: {
            title: 'devices.details.no.protection',
            list: [
                {
                    title: 'devices.details.no.protection.details',
                    fixable: this.issueType.manual,
                    created: '',
                    appId: this.deviceStateBasedOnSubscriptions.NO_PROTECTION_NO_AGENT_FREE_SLOTS,
                    issue_id: ''
                }
            ],
            hasIssues: true,
            action: this.actionTypes.installPtortection,
            actionName: 'devices.details.action.install'
        },
        [this.deviceStateBasedOnSubscriptions.NO_PROTECTION_AGENT_FREE_SLOTS]: {
            title: 'devices.details.no.protection',
            list: [
                {
                    title: 'devices.details.no.protection.details',
                    fixable: this.issueType.auto,
                    created: '',
                    appId: this.deviceStateBasedOnSubscriptions.NO_PROTECTION_AGENT_FREE_SLOTS,
                    issue_id: ''
                }
            ],
            hasIssues: true,
            action: this.actionTypes.enableProtection,
            actionName: 'devices.details.action.enable'
        },
        [this.deviceStateBasedOnSubscriptions.SLOT_NOT_FOUND]: {
            title: 'devices.details.no.fully.protection',
            list: [
                {
                    title: 'devices.details.no.protection.default.details',
                    fixable: this.issueType.manual,
                    created: '',
                    appId: this.deviceStateBasedOnSubscriptions.SLOT_NOT_FOUND,
                    issue_id: ''
                }
            ],
            hasIssues: true,
            action: '',
            actionName: ''
        },
        [this.deviceStateBasedOnSubscriptions.AVFREE_PROTECTION]: {
            title: 'devices.details.no.fully.protection',
            list: [
                {
                    title: 'devices.details.free.protection',
                    fixable: this.issueType.auto,
                    created: '',
                    appId: this.deviceStateBasedOnSubscriptions.AVFREE_PROTECTION,
                    issue_id: ''
                }
            ],
            hasIssues: true,
            action: this.actionTypes.upgradeProtection,
            actionName: 'devices.details.action.upgrade'
        },
        [this.deviceStateBasedOnSubscriptions.FREE_WEB_PROTECTION]: {
            title: 'devices.details.no.fully.protection',
            list: [
                {
                    title: 'devices.details.free.web.protection',
                    fixable: this.issueType.manual,
                    created: '',
                    appId: this.deviceStateBasedOnSubscriptions.FREE_WEB_PROTECTION,
                    issue_id: ''
                }
            ],
            hasIssues: true,
            action: this.actionTypes.buySubscriptions,
            actionName: 'devices.details.action.upgrade'
        },
        [this.deviceStateBasedOnSubscriptions.PAID_WEB_PROTECTION_NO_SLOT]: {
            title: 'devices.details.no.fully.protection',
            list: [
                {
                    title: 'devices.details.no.protection.default.details',
                    fixable: this.issueType.manual,
                    created: '',
                    appId: this.deviceStateBasedOnSubscriptions.PAID_WEB_PROTECTION_NO_SLOT,
                    issue_id: ''
                }
            ],
            hasIssues: true,
            action: '',
            actionName: ''
        }
    };

    appsWithIssues = new Set([
        this.valuesService.appCL,
        this.valuesService.appBMS,
        this.valuesService.appAVFM,
        this.valuesService.appBIP,
        this.valuesService.appWS
    ]);

    issuesAction = {
        fix : 0,
        view: 1,
        install: 2,
        addToAnotherSubs: 3
        // renewSubscription: 5,
        // buyProtection: 6
    };

    // 5 min
    pendingTimeInterval = 5 * 60 * 1000;

    // trebuie sa le mut in values?
    events = {
        reloadListIssues: 'reloadListIssues',
        checkListIssues: 'checkListIssues'
    };

    alertIssues = {
        pending: {
            type: 'primary',
            icon: {
                [this.issueType['auto']]: 'apply-fix',
                [this.issueType['manual']]: 'account-setting'
            },
            title: {
                [this.issueType['auto']]: 'fix.issues.modal.pending',
                [this.issueType['manual']]: 'fix.issues.modal.manual'
            }
        },
        error: {
            type: 'danger',
            icon: 'circle-attention',
            title: 'fix.issues.modal.error'
        },
        success: {
            type: 'primary',
            icon: 'circle-info',
            title: {}
        }
    };

    // Malware

    malwareScanDetails = {
        'quick_scan': 'malwareScan.modal.threats.details.description.quick_scan',
        'scan': 'malwareScan.modal.threats.details.description.scan'
    };

    // End Malware

    // Optimizer

    deviceOptimizeCases = {
        pending: {
            text: 'oneclickOptimizer.modal.optimize.command.sent',
            textColor: 'color-primary',
            icon: 'monitor-cog',
            commandToSend: false,
            disabledCheckbox: true,
            commandMessage: 'oneclickOptimizer.modal.optimize.command.pending'
        },
        running: {
            text: 'oneclickOptimizer.modal.optimize.command.running',
            textColor: 'color-primary',
            icon: 'monitor-cog',
            commandToSend: false,
            disabledCheckbox: true,
            commandMessage: 'malwareScan.modal.scan.command.running.hint'
        },
        hasRaport: {
            text: 'oneclickOptimizer.modal.optimize.lastOptimization',
            textColor: 'color-shade-60',
            icon: 'monitor-cog',
            commandToSend: true
        },
        noReportAvailable: {
            text: 'oneclickOptimizer.modal.optimize.noReport',
            textColor: 'color-shade-60',
            icon: 'monitor-cog',
            commandToSend: true,
            noReportAvailable: true
        }
    };

    deviceStartupCases = {
        noReportAvailable: {
            text: 'oneclickOptimizer.modal.optimize.noReport',
            icon: 'optimization-on',
            textColor: 'color-shade-60',
            content: {
                description: 'oneclickOptimizer.modal.startup.noReport.description'
            },
            displaySettings: false
        },
        rebootNeeded: {
            text: 'oneclickOptimizer.modal.optimize.noReport',
            icon: 'optimization-on',
            textColor: 'color-shade-60',
            content: {
                description: 'oneclickOptimizer.modal.startup.report.restartNeeded',
                appTitle: 'oneclickOptimizer.modal.startup.report.applications'
            },
            displaySettings: true
        },
        reportAvailable: {
            text: 'oneclickOptimizer.modal.optimize.lastOptimization',
            icon: 'optimization-on',
            textColor: 'color-shade-60',
            content: {
                appTitle: 'oneclickOptimizer.modal.startup.report.applications',
                bootTitle: 'oneclickOptimizer.modal.startup.report.boot'
            },
            displaySettings: true
        }
    };

    startupStatus = {
        enabled  : 0,
        delayed  : 1,
        disabled : 2,
        0: 'enabled',
        1: 'delayed',
        2: 'disabled'
    };

    commandsState = {
        pending: 'pending',
        running: 'running',
        idle: 'idle'
    };

    diskSpaceCategories = new Set(['windowsUpdate', 'windowsTemp', 'windowsDumps', 'windowsDangerous', 'werFiles', 'logFiles', 'recycleBin']);
    registryKeysCategories = new Set(['customControls', 'deletedApplications', 'helpFiles', 'missingFonts', 'runList', 'sharedDlls', 'softwareLocations', 'startupItems']);

    flagReportRead = '_reportReadOptimize';
    flagOptimizerSummary = '_optimizerLastSummary';
    clVersion = '24.0.1.1';

    optimizerActionReport = {
        'chromeCache': 'oneclickOptimizer.modal.optimize.report.chromeCache',
        'chromeCookies': 'oneclickOptimizer.modal.optimize.report.chromeCookies',
        'chromeHistory': 'oneclickOptimizer.modal.optimize.report.chromeHistory',
        'chromeTemp': 'oneclickOptimizer.modal.optimize.report.chromeTemp',
        'edgeCache': 'oneclickOptimizer.modal.optimize.report.edgeCache',
        'edgeCookies': 'oneclickOptimizer.modal.optimize.report.edgeCookies',
        'edgeHistory': 'oneclickOptimizer.modal.optimize.report.edgeHistory',
        'firefoxCache': 'oneclickOptimizer.modal.optimize.report.firefoxCache',
        'firefoxCookies': 'oneclickOptimizer.modal.optimize.report.firefoxCookies',
        'firefoxHistory': 'oneclickOptimizer.modal.optimize.report.firefoxHistory',
        'internetCache': 'oneclickOptimizer.modal.optimize.report.internetCache',
        'internetCookies': 'oneclickOptimizer.modal.optimize.report.internetCookies',
        'internetHistory': 'oneclickOptimizer.modal.optimize.report.internetHistory',
        'operaCookies': 'oneclickOptimizer.modal.optimize.report.operaCookies',
        'operaHistory': 'oneclickOptimizer.modal.optimize.report.operaHistory',
        'operaTemp': 'oneclickOptimizer.modal.optimize.report.operaTemp',
        'operaCache': 'oneclickOptimizer.modal.optimize.report.operaCache',
        'logFiles': 'oneclickOptimizer.modal.optimize.report.logFiles',
        'werFiles': 'oneclickOptimizer.modal.optimize.report.werFiles',
        'windowsDangerous': 'oneclickOptimizer.modal.optimize.report.windowsDangerous',
        'windowsDumps': 'oneclickOptimizer.modal.optimize.report.windowsDumps',
        'windowsTemp': 'oneclickOptimizer.modal.optimize.report.windowsTemp',
        'windowsUpdate': 'oneclickOptimizer.modal.optimize.report.windowsUpdate',
        'softwareLocations': 'oneclickOptimizer.modal.optimize.report.softwareLocations',
        'recycleBin': 'oneclickOptimizer.modal.optimize.report.recycleBin',
        'customControls': 'oneclickOptimizer.modal.optimize.report.customControls',
        'sharedDlls': 'oneclickOptimizer.modal.optimize.report.sharedDlls',
        'helpFiles': 'oneclickOptimizer.modal.optimize.report.helpFiles',
        'missingFonts': 'oneclickOptimizer.modal.optimize.report.missingFonts',
        'startupItems': 'oneclickOptimizer.modal.optimize.report.startupItems',
        'runList': 'oneclickOptimizer.modal.optimize.report.runList',
        'userDownloads': 'oneclickOptimizer.modal.optimize.report.userDownloads',
        'quickTime': 'oneclickOptimizer.modal.optimize.report.quickTime',
        'windowStore': 'oneclickOptimizer.modal.optimize.report.windowStore'
    };

    // End Optimizer

    vulnerabilityStorage = 'vulnerability';

    statusInfo = {
        [this.valuesService.protectionStatus.PROTECTED] : {
            color: 'green-state',
            icon: 'connect_icon_protected',
            label: 'devices.details.protection.protected'
        },
        [this.valuesService.protectionStatus.AT_RISK]   : {
            color: 'red-state',
            icon: 'connect_icon_unprotected',
            label: 'devices.details.protection.risk'
        },
        [this.valuesService.protectionStatus.DISCONNECTED]  : {
            color: 'gray-state',
            icon: '',
            label: 'devices.last.seen'
        }
    };

    protectionState = {
        protected    : 'devices.details.protection.protected',
        atRisk       : 'devices.details.protection.risk',
        noSubs       : 'devices.issues.noSubscription',
        boxProtected : 'devices.details.protection.boxProtected',
        lastSeen     : 'devices.last.seen'
    };

    // antitheft
    deviceAdminOff = 'device_admin_off';
    noPermissions = 'permissions_not_granted';
    pinMapLocate = this.configService.config.pathImages + 'marker.png';
    notCoords = -1;

    deviceAccounts = {
        ADMIN_PRIVILEGES_2: 2,
        NO_PASSWORD: 0,
        HAS_PASSWORD: 1,
        ADMIN_NOT_ENABLED: 0,
        MICROSOFT_ACCOUNT: 'MicrosoftAccount'
    };

    lockWindowsCases = {
        WITHOUT_PASS: 0,
        WITH_PASS: 1,
        PASS_MS: 2,
        WITHOUT_ACCOUNTS: 3
    };

    defaultPin = '1234';

    osNames = {
        'windows'             : 'Microsoft Windows',
        'windows_server'      : 'Microsoft Windows Server',
        'linux'               : 'Linux',
        'unix'                : 'Unix',
        'android'             : 'Android',
        'nokia'               : 'Nokia',
        'chromeos'            : 'Chrome OS',
        'win'                 : 'Microsoft Windows',
        'mac'                 : 'macOS',
        'osx'                 : 'macOS',
        'Ix32'                : 'Ix32',
        'lx64'                : 'Ix64',
        'airport firmware'    : 'AirPort firmware',
        'blackberry'          : 'Blackberry OS',
        'fortios'             : 'FortiOS',
        'chrome os'           : 'Chrome OS',
        'gainspan firmware'   : 'GainSpan firmware',
        'ios'                 : 'iOS',
        'kindle'              : 'Kindle',
        'kindle os'           : 'Kindle Fire OS',
        'nas firmware'        : 'NAS firmware',
        'nest firmware'       : 'Nest firmware',
        'openbsd'             : 'OpenBSD',
        'opensolaris'         : 'Open Solaris',
        'palmos'              : 'PalmOS',
        'powertv'             : 'PowerTV',
        'printer firmware'    : 'Printer firmware',
        'proprietary'         : 'Proprietary',
        'pxe'                 : 'PXE',
        'router firmware'     : 'Router firmware',
        'sonos firmware'      : 'Sonos firmware',
        'switch firmware'     : 'Switch firmware',
        'symbian'             : 'Symbian OS',
        'tvos'                : 'TV OS',
        'voip phone firmware' : 'VoIP phone firmware',
        'watchos'             : 'watchOS',
        'xbox os'             : 'Xbox OS',
        'xen'                 : 'Xen',
        'tizen'               : 'Tizen',
        'other'               : 'Other',
        'unknown'             : 'Unknown'
    };

    antitheftScenarios = {
        locate: 'locate',
        snap: 'snap',
        lock: 'lock',
        wipe: 'wipe',
        full: 'full'
    };

    // metrics omniture

    omniture = {
        optimizer: {
            optimize : 'device',
            startup  : 'startup',
            report   : 'report',
            advanced : 'advanced'
        },
        antitheft: {
            locate        : 'locate',
            alert         : 'play',
            lock          : 'lock',
            wipe          : 'erase',
            aquired       : 'aquired',
            deleted       : 'deleted',
            snapshots     : 'snapshots',
            viewSnapshot  : 'view-snapshot',
            noPermissions : 'no-permissions'
        },
        malware: {
            quick_scan : 'quick',
            scan       : 'system',
            report     : 'report'
        },
        vulnerability: {
            report : 'report',
            advanced: 'advanced'
        },
        details: {
            extraSections: {
                add: 'add',
                edit: 'edit'
            }
        },
        closeBuyMoreProtection: 'closeBuyMoreProtection',
        devices: {
            empty: 'empty'
        }
    };

    dashboardCardLimits = {
        firstCaseMinLimit: 1,
        secondCaseMaxLimit: 2,
        thirdCaseMinLimit: 3,
        fourthCaseMaxLimit: 4,
        fifthCaseMinLimit: 5
    };
}
