// External
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiPlaceholderComponent } from './ui-placeholder.component';


@NgModule({
    declarations: [
        UiPlaceholderComponent
    ],
    imports: [
        CommonModule
    ],
    
    exports: [ UiPlaceholderComponent ]
    
})

export class UiPlaceholderModule {}
