// External
import { Injectable } from '@angular/core';

// Internal

@Injectable({
    providedIn: 'root'
})


export class NotTranslatedService {
    notTranslated(key: string) {
       // console.log('Not translated  ====== ', key);
    }
}
