import { Injectable, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subject, takeUntil } from 'rxjs';
import { ServiceWorkerMessageEvent } from '../../../../common/models/Pwa.model';
import { getPwaActive } from '../../../../../app/app.module';
import { HandleUnrecoverableStateService } from '../handle-unrecoverable-state/handle-unrecoverable-state.service';

@Injectable({providedIn: 'root'})

export class PromptUpdateService implements OnDestroy {
    private newVersionReady = false;
    private readonly onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private readonly swUpdate: SwUpdate,
        private readonly handleUnrecoverableStateService: HandleUnrecoverableStateService
    ) {
        if (getPwaActive()) {
            this.swUpdate.versionUpdates
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(event => {
                // Reload the page to update to the latest version.
                if (event.type === ServiceWorkerMessageEvent.VERSION_READY) {
                    this.handleUnrecoverableStateService.resetUnrecoverableStateFlag();
                    this.handleUnrecoverableStateService.setServiceWorkerIsUnavailable(false);
                    this.handleUnrecoverableStateService.setFailedToFetchFlag(false);
                    this.newVersionReady = true;
                }
            });
        }
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    public getNewVersionReadyFlag() {
        return this.newVersionReady;
    }

    public resetNewVersionReadyFlag() {
        this.newVersionReady = false;
    }

}