// External
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';

// Internal
import { ConnectDataPrivacyService } from '../../../services/requests/connect-data-privacy/connect-data-privacy.service';
import {
        FeatureSetEnum,
        IErrorText,
        GmailConnectionState,
        IdentityModel,
        MetaDataEmailTypes,
        Metadata,
        MetadataSource,
        MetadataType,
        MonitoringStateStatus,
        EmailDomains,
        OutlookConnectionState
    } from '../../../models/privacy/Identity.model';
import {
        IDomainServiceBreaches,
        IDomainServiceDetails,
        IExposureGrouppedByService,
        IServiceBreachesImpacting,
        PrivacyExposure,
        PrivacyExposureCouter
    } from '../../../models/privacy/PrivacyExposure.model';
import { UtilsCommonService } from '../../../utils/utils-common.service';
import { ValuesService } from '../../../values/values.service';
import { PrivacyValuesService, SummaryType, PrivacyEvents, PrivacyErrorCodes, ValidationAbuseErrorMessage } from '../../../values/privacy.values.service';
import { MessageService } from '../../core/message.service';
import { RemediationServiceDetails } from '../../../../common/models/privacy/Remediation.model';
import { IssueActionStatus, PrivacyIssue, PrivacyIssueAction, IExposureIssue, PrivacyIssueType, ItemsObjectInfo } from '../../../../common/models/privacy/PrivacyIssue.model';
import {
        FootprintServicesCounts,
        IMarkToUpdateFootprintServiceState,
        IPrivacyFootPrintIssuesType,
        IPrivacyFootprintServiceState,
        ServiceFilterConnectInterface
    } from '../../../../common/models/privacy/PrivacyFootprint.model';
import { IPrivacyScore } from '../../../../common/models/privacy/PrivacyScore.model';
import { AppsConfigService } from '../../../../common/config/apps.config.service';
import { ModalRoutelessService } from '../../../components/ui/ui-modal-routeless/modal.routeless.service';
import { FeedbackModel } from '../../../../common/models/privacy/Feedback.model';
import { SubscriptionsService } from '../subscriptions/subscriptions.service';
import { PrivacyEvent } from '../../../models/privacy/PrivacyEvent.model';
import { User } from '../../../models/privacy/User.model';
import { OrderByEnum } from '../../../../pages/privacy/privacy-footprint-standard/privacy-footprint.component';
import { ISharedSubscriptionInfo } from '../../../../common/models/Services.model';
import { SettingsService } from '../settings/settings.service';
import { IPrivacyBreachesSummary } from '../../../models/privacy/PrivacySummary.model';

export enum ServiceState {
    WAITING = 'waiting',
    INPROGRESS = 'in_progress',
    DONE = 'done'
}

export interface IPaginationObject {
    limit: number;
    offset: number;
}

@Injectable({
    providedIn: 'root'
})
export class PrivacyService {

    has500Error = false;
    hasDIPSubscription = true;

    //* lista de subscriberi pt obiecte -> requesturile se executa o sg data (s-a rezolvat si problema requesturilor simultane)
    private readonly onCreateIdentity$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListProvidedIdentity$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onlistExposure$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onlistFootprintServices$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onlistFootprintServiceExposures$: IPrivacyFootprintServiceState = {};
    private readonly onlistFootprintDetailedServices$: IPrivacyFootprintServiceState = {};
    private readonly onlistBreaches$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onlistImpersonations$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onlistEvents$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListPrivacyScore$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListActivityExposureSummary$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListActivityBreachesSummary$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListActivityImpersonationsSummary$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onListIssueObject$: {[key: string]: BehaviorSubject<string>} = {};

    private providedIdentity: IdentityModel;
    parkedIdentity: boolean = false;
    isDeletedIdentity: boolean = false;
    getIdentityReqHasError = false;
    exposureDigitalFootprint: any;
    exposureDigitalFootprintGroupped: Array<IExposureGrouppedByService>;
    footprintServiceDetails: IDomainServiceDetails;
    footprintServiceExposures: { [key: string]: Array<IExposureIssue> } = {};
    footprintServiceBreaches: { [key: string]: Array<IDomainServiceBreaches | IServiceBreachesImpacting> } = {};
    remediation: { [key: string]: RemediationServiceDetails };
    exposureDashboard: { [key: string]: PrivacyExposure[] };
    exposureDashboardLid = {
        list: [],
        count: 0
    };

    private servicesCounts: any;
    private servicesObj = {} as ItemsObjectInfo;
    private readonly paginationObjectServices: IPaginationObject = {
        limit: 20,
        offset: 0
    };
    private filteredServicesObj = {} as ItemsObjectInfo;
    private readonly paginationObjectFilteredServices: IPaginationObject = {
        limit: 20,
        offset: 0
    };

    privacyScore: IPrivacyScore;
    exposureCountDigitalFootprint: PrivacyExposureCouter;
    exposureCountDigitalFootprintGroupped = 0;
    totalCountDashboard: number;
    exposureCountDashboard: PrivacyExposureCouter;

    privacyModules = {};
    privacyActiveModules = [];

    private _userPrivacyModules = new Set();

    private breachesObj = {} as ItemsObjectInfo;
    private impersonationsObj = {} as ItemsObjectInfo;
    private issueObject: {[key: string]: PrivacyIssue} = {};
    private eventsObj = {} as ItemsObjectInfo;

    private readonly sharedSubscriptionInfo: ISharedSubscriptionInfo = {
        hasSharedSubscription: false,
        isPayer: false,
        payerEmail: '',
        showAlert: false
    };

    private readonly paginationObjectImpersonations: IPaginationObject = {
        limit: 10,
        offset: 0
    };
    private readonly paginationObjectBreaches: IPaginationObject = {
        limit: 10,
        offset: 0
    };
    private readonly paginationObjectEvents: IPaginationObject = {
        limit: 10,
        offset: 0
    };

    activityExposureSummary: any;
    activityBreachesSummary: any;
    activityImpersonationsSummary: any;

    getEntriesErrorIds = new Set([
        this.valuesService.connectErrorIds.DATABASE_ERROR,
        this.valuesService.connectErrorIds.COMMUNICATION_ERROR
    ]);


    private markToUpdate_createIdentity = true;
    private markToUpdate_providedIdentity = true;
    private markToUpdate_exposureData = true;
    private markToUpdate_events = true;
    private markToUpdate_breaches = true;
    private markToUpdate_impersonations = true;
    private markToUpdateIssueObject: {[key: string]: boolean} = {};
    private markToUpdate_privacyScore = true;
    private markToUpdate_activityExposureSummary = true;
    private markToUpdate_activityBreachesSummary = true;
    private markToUpdate_activityImpersonationsSummary = true;
    private markToUpdateFootprintServices = true;
    private markToUpdateFootPrintServiceDetails: IMarkToUpdateFootprintServiceState = {};
    private markToUpdateFootPrintServiceExposures: IMarkToUpdateFootprintServiceState = {};

    constructor(
        private readonly connectDataPrivacyService: ConnectDataPrivacyService,
        private readonly utilsService:              UtilsCommonService,
        private readonly valuesService:             ValuesService,
        private readonly router:                    Router,
        private readonly messageService:            MessageService,
        private readonly modalRoutelessService:     ModalRoutelessService,
        private readonly privacyValuesService:      PrivacyValuesService,
        private readonly appsConfigService:         AppsConfigService,
        private readonly subscriptionsService:      SubscriptionsService,
        private readonly utilsCommonService: UtilsCommonService,
        private readonly settingsService:           SettingsService
    ) { }

    //#region dip active modules

    /**
     * Method that sets a show flag for each module
     */
    private computeAvailableModulesByFeatureSet(): void {
        this._userPrivacyModules = new Set();
        const userFeatureSet = this.providedIdentity.feature_set;
        for (let item in userFeatureSet) {
            this._userPrivacyModules.add(userFeatureSet[item]);
        }
    }

    // ! Task la connect sa adauge si acest feature pe req
    // hasActivityModule() {
    //     return this._userPrivacyModules.has(FeatureSetEnum.ACTIVITY);
    // }

    public hasFootprintModule() {
        return this._userPrivacyModules.has(FeatureSetEnum.EXPOSURE);
    }

    public hasBreachesModule() {
        return this._userPrivacyModules.has(FeatureSetEnum.BREACH);
    }

    public hasImpersonationsModule() {
        return this._userPrivacyModules.has(FeatureSetEnum.IMPERSONATION);
    }

    public hasBrokersModule() {
        return this._userPrivacyModules.has(FeatureSetEnum.DATABROKERS);
    }

    public hasEducationModule() {
        return this._userPrivacyModules.has(FeatureSetEnum.EDUCATION);
    }

    // ! Task la connect sa adauge si acest feature pe req
    // hasHistoryModule() {
    //     return this._userPrivacyModules.has(FeatureSetEnum.HISTORY);
    // }

    // ! Task la connect sa adauge si acest feature pe req
    // hasMonitorModule() {
    //     return this._userPrivacyModules.has(FeatureSetEnum.MONITOR);
    // }

    /**
     * Method that verifies if we can show privacy content
     * @returns {boolean} if we can show privacy content
     */
    public canShowPrivacyContent(): boolean {
        return (this.subscriptionsService.hasDataPrivacy() || this.subscriptionsService.hasDataPrivacyExpired()) && this.isProvidedIdentityInitDone()
    }

    //#endregion

    //#region provided identity methods

    /**
     * Method that creates identity based only on provided name
     * @returns {Observable} the provided identity created
     */
    public createIdentity(user: User): Observable<any> {
        if (!this.markToUpdate_createIdentity || !this.appsConfigService.showApp(this.valuesService.appDIP)) {
            return of(false);
        }
        this.isDeletedIdentity = false;
        this.hasDIPSubscription = true;

        if (this.onCreateIdentity$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onCreateIdentity$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }

        this.onCreateIdentity$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectDataPrivacyService.createIdentityLid(user)
            .pipe(
                map(
                    resp => {
                        this.onCreateIdentity$.next(this.valuesService.processServiceState.DONE);

                        if (!resp) {
                            this.markToUpdate_createIdentity = true;
                            return of(false);
                        }

                        this.providedIdentity = resp;
                        this.markToUpdate_createIdentity = true;

                        return of(this.providedIdentity);
                    }
                ),
                catchError(err => {
                    this.markToUpdate_createIdentity = true;
                    this.onCreateIdentity$.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
    }

    /**
     * Method that gets provided identity from connect
     * @returns {Observable} the provided identity
     */
    public listProvidedIdentity(): Observable<any> {
        if (!this.markToUpdate_providedIdentity || !this.appsConfigService.showApp(this.valuesService.appDIP)) {
            return of(this.providedIdentity);
        }

        this.isDeletedIdentity = false;
        this.hasDIPSubscription = true;

        if (this.onListProvidedIdentity$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListProvidedIdentity$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }

        this.onListProvidedIdentity$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectDataPrivacyService.getIdentity()
            .pipe(
                map(resp => {
                    this.getIdentityReqHasError = false;
                    this.markToUpdate_providedIdentity = false;
                    this.onListProvidedIdentity$.next(this.valuesService.processServiceState.DONE);

                    this.providedIdentity = resp;
                    this.computeAvailableModulesByFeatureSet();
                    this.computeConnectedEmailsInfo();

                    if (resp.parking_details?.active) {
                        this.parkedIdentity = true;
                        this.messageService.sendMessage(PrivacyEvents.PARKED_IDENTITY, {parkedIdentity: true, modalType: this.modalRoutelessService.getIsModalOpened()});
                    } else {
                        // imediat dupa ce identitatea a fost unparked
                        const oldParkedIdentity = JSON.parse(JSON.stringify(this.parkedIdentity));
                        this.parkedIdentity = false;
                        if (oldParkedIdentity) {
                            this.messageService.sendMessage(PrivacyEvents.PARKED_IDENTITY, {parkedIdentity: false});
                        }
                    }

                    return this.providedIdentity;
                }),
                catchError(err => {
                    this.markToUpdate_providedIdentity = true;
                    this.onListProvidedIdentity$.next(this.valuesService.processServiceState.DONE);

                    if (err.code && err.code === PrivacyErrorCodes.NO_IDENTITY) { //no identity found
                        this.isDeletedIdentity = true;
                        this.parkedIdentity = false;
                        this.markToUpdate_providedIdentity = false;
                        this.providedIdentity = null;
                        return of(this.providedIdentity);
                    } else if (err.code === PrivacyErrorCodes.EXPIRED_SUBSCRIPTION) { //no subscription
                        this.markToUpdate_providedIdentity = false;
                        this.providedIdentity = null;
                        this.hasDIPSubscription = false;
                        return of(this.providedIdentity);
                    } else {
                        //throw err;
                        this.getIdentityReqHasError = true;
                    }
                })
            );
    }

    /**
     * Method that calls init_data method after onboarding done
     * @returns {Observable}
     */
    public getInfoAfterOnboarding(): Observable<any> {
        return this.connectDataPrivacyService.initDataLid()
        .pipe(
            map( () => {
                this.updateProvidedIdentity();
            })
        );
    }
    /**
     * Method that gets the provided identity parked status
     * @returns {boolean} the parked status
     */
    public getParkedIdentityStatus(): boolean {
        return this.parkedIdentity;
    }

    /**
     * Method that verifies if identity is parked
     * @returns { MonitoringStateStatus } the status of the identity, if parked (inactive) or not (active)
     */
    public getMonitoringState(): MonitoringStateStatus {
        if (!this.parkedIdentity) {
            return MonitoringStateStatus.ACTIVE;
        } else {
            return MonitoringStateStatus.INACTIVE;
        }
    }

    /**
     * Method that checks if identity is deleted or not
     * @returns {boolean} is deleted or not
     */
    public getIsDeletedIdentity(): boolean {
        return this.isDeletedIdentity;
    }

    /**
     * Method that gets the provided identity
     * @returns {IdentityModel} the provided identity
     */
    public getProvidedIdentity(): IdentityModel {
        return this.providedIdentity;
    }

    /**
     * Function that returns the type of metadata that is needed for onboarding and has the highest priority
     * @returns String that represents the type of metadata that is needed for onboarding, 'email' or 'phone'
     */
    public getNextMetatdataTypeForFinishingOnboarding(): string {
        let maxPriority = Number.MAX_SAFE_INTEGER;
        let type = '';
        const requiredMetatdata = this.providedIdentity?.required_metadata ?? [];
        for (const metatdata of requiredMetatdata) {
            const priority = metatdata?.priority ?? 0;
            if (metatdata?.count && priority < maxPriority) {
                maxPriority = priority;
                type = metatdata?.type ?? '';
            }
        }
        return type;
    }

    /**
     * Method that checks if identity has init done or not
     * @returns {boolean} init complete or not
     */
    public isProvidedIdentityInitDone(): boolean {
        return this.providedIdentity?.init_done ?? false;
    }

    /**
     * Method that gets the full name of the current identity
     * @returns {string} the full name of the identity
     */
    public getIdentityFullName(): string {
        if (this.providedIdentity.middle_name) {
            return this.providedIdentity.first_name.concat(' ', this.providedIdentity.middle_name, ' ', this.providedIdentity.last_name);
        } else {
            return this.providedIdentity.first_name.concat(' ', this.providedIdentity.last_name);
        }
    }

    /**
     * Method that verifies if response error code is EXPIRED_SUBSCRIPTION
     * @param {any} error the error to be checked
     * @param {boolean} callIsMadeFromModal if the call was made from a modal or not
     * @returns {boolean} if identity is parked or not
     */
    public checkParkedIdentity(error: any, callIsMadeFromModal: boolean): boolean {
        if (error?.code === PrivacyErrorCodes.EXPIRED_SUBSCRIPTION) {
            this.parkedIdentity = true;
            this.subscriptionsService.updateSubscriptions();
            if (callIsMadeFromModal) {
                this.modalRoutelessService.close(this.valuesService.centralPaths.privacy.path.concat(this.valuesService.centralPaths.privacy.activity.path));
            } else {
                this.router.navigate([this.valuesService.centralPaths.privacy.path.concat(this.valuesService.centralPaths.privacy.activity.path)]);
            }
            return true;
        }
        return false;
    }

    /**
     * Method that gets onboarding process status
     * @returns {string} the status of onboarding
     */
    private getOnboardingStatus(): string {
        const identity = this.getProvidedIdentity();
        if (!identity) {
            return this.privacyValuesService.onboardingStatus.NOT_STARTED;
        } else {
            if (identity.init_done) {
                return this.privacyValuesService.onboardingStatus.DONE;
            } else if (this.privacyValuesService.onboardingSteps.has(identity.onboarding)) {
                return this.privacyValuesService.onboardingStatus.IN_PROGRESS;
            }
        }
    }

    /**
     * Method that checks if onboarding is done or not
     * @returns {boolean} if onboarding is done
     */
    public onboardingIsDone(): boolean {
        return this.getOnboardingStatus() === this.privacyValuesService.onboardingStatus.DONE;
    }

    /**
     * Method that updateds flag for update identity
     * @returns {void}
     */
    public updateProvidedIdentity(): void {
        if (this.onListProvidedIdentity$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_providedIdentity = true;
        }
    }

    /**
     * Method that resets in progress flag for get identity
     * @returns {void}
     */
    public resetInProgressFlags(): void {
        this.onListProvidedIdentity$.next(this.valuesService.processServiceState.DONE);
    }

    //#endregion

    //#region metadatas

    /**
     * Methods that add a new metadata
     * @param {Metadata} metadata to be added
     * @returns {Observable}
     */
    public addMetadata(metadata: Metadata): Observable<any> {
        return this.connectDataPrivacyService.addInfoLid(metadata)
        .pipe(
            map((res) => {
                if (res.status !== 0) {
                    return of(false);
                }

                this.editCreatedMetadataObject(metadata, res.metadata_id);
                this.addCreatedMetadataToIdentity(metadata);

                return of(true);
            }),
            catchError( err => {
                throw err;
            })
        );
    }

    /**
     * Method that edits phone local object
     * @param metadataObj the phone object to be edited
     * @param metadataId the new id of the phone
     * @returns {void}
     */
    private editCreatedMetadataObject(metadataObj: Metadata, metadataId: string): void {
        if (metadataId) {
            metadataObj.metadata_id = metadataId;
        }
        metadataObj.source = MetadataSource.CONFIRMED;
    }

    /**
     * Method that adds the newly created phone object to current identity
     * @param metadataObj the phone object to be added
     * @returns {void}
     */
    private addCreatedMetadataToIdentity(metadataObj: Metadata): void {
        if (!this.providedIdentity[metadataObj.type]) {
            this.providedIdentity[metadataObj.type] = [];

            this.providedIdentity[metadataObj.type].push(metadataObj);
            this.providedIdentity.validate_required.push(metadataObj);
        }

        let metadataExists = false;
        for (const data of this.providedIdentity[metadataObj.type]) {
            if (data.metadata_id === metadataObj.metadata_id) {
                metadataExists = true;
                break;
            }
        }
        if (!metadataExists) {
            this.providedIdentity[metadataObj.type].push(metadataObj);
            this.providedIdentity.validate_required.push(metadataObj);
        }
    }


    /**
     * Method that deletes metadata
     * @param {Metadata} metadata the metadata to be deleted
     * @returns {Observable}
     */
    public deleteMetadata(metadata: Metadata): Observable<any> {
        return this.connectDataPrivacyService.deleteInfoLid(metadata)
        .pipe(
            map( (res: boolean) => {
                if (!res) {
                    return of(false);
                }

                this.updateIdentityAfterMetadataDeleted(metadata);

                this.updateEventsList();
                return of(true);
            }),
            catchError( err => {
                throw err;
            })
        );
    }

    /**
     * Method that removes the newly created metadata object to current identity
     * @param {Metadata} metadata the metadata to be deleted
     * @returns {void}
     */
    private updateIdentityAfterMetadataDeleted(metadata: Metadata): void {
        let metadatasList = [];
        for (const data of this.providedIdentity[metadata.type]) {
            if (data.metadata_id !== metadata.metadata_id) {
                metadatasList.push(data);
            }
        }
        this.providedIdentity[metadata.type] = metadatasList;

        metadatasList = [];
        for (const metadata of this.providedIdentity.validate_required) {
            if (metadata.metadata_id !== metadata.metadata_id) {
                metadatasList.push(metadata);
            }
        }
        this.providedIdentity.validate_required = metadatasList;
    }

    /**
     * Method that edits name
     * @param {User} user the name object to be edited
     * @returns {Observable}
     */
    public updateName(user: User): Observable<any> {
        return this.connectDataPrivacyService.updateName(user)
        .pipe(
            map( (res: boolean) => {
                if (!res) {
                    return of(false);
                }
                this.providedIdentity.first_name = user.firstname;
                this.providedIdentity.middle_name = user.middlename;
                this.providedIdentity.last_name = user.lastname;

                this.updateEventsList();
                return of(true);
            }),
            catchError( err => {
                if (!this.checkParkedIdentity(err, true)) {
                    throw err;
                } else {
                    return of(true);
                }
            })
        );
    }

    /**
     * Function that tells you if there is at least one metadata that needs validation for onboarding.
     * Due to our flow, there will always be only one metadata that was introduced but was not verified.
     * @returns True of there is one metadata that was introduced but not validated, false otherwise
     */
    public hasRequiredMetadataThatNeedsValidation(): boolean {
        return !!this.providedIdentity?.validate_required?.length;
    }

    /**
     * Function that returns the subtype of the metadata that needs validation
     * @returns String representing the subtype
     */
    public getRequiredMetadataSubtypeThatNeedsValidation(): string {
        return this.providedIdentity?.validate_required?.[0]?.subtype ?? '';
    }

    /**
     * Method that return emails set for current identity
     * @returns {Array<Metadata>} list of emails
     */
    public getIdentityEmails(): Array<Metadata> {
        return this.providedIdentity.emails ?? [];
    }

    /**
     * Method that return phones set for current identity
     * @returns {Array<Metadata>} list of phones
     */
    public getIdentityPhones(): Array<Metadata> {
        return this.providedIdentity.phones ?? [];
    }

    /**
     * Method that formats a phone number
     * @param {Metadata} phone to be formated
     * @returns the string containing formated phone number
     */
    public getFormatedPhoneNumber(phone: Metadata): string {
        return `+(${phone.value.country_code})${phone.value.number}`;
    }

    /**
     * Method that returns the last unvalidated email
     * @returns {Metadata} the unvalidated email
     */
    public getLastUnvalidatedEmail(): Metadata {
        const emails = this.getIdentityEmails();
        let lastUnvalidatedEmail: Metadata;

        for (const email of emails) {
            if (!email.validated) {
                lastUnvalidatedEmail = email;
            }
        }

        return lastUnvalidatedEmail;
    }

    /**
     * Method that returns the last unvalidated phone
     * @returns {Metadata} the unvalidated phone
     */
    public getLastUnvalidatedPhone(): Metadata {
        const phones = this.getIdentityPhones();
        let lastUnvalidatedPhone: Metadata;

        for (const phone of phones) {
            if (!phone.validated) {
                lastUnvalidatedPhone = phone;
            }
        }

        return lastUnvalidatedPhone;
    }

    public verifyCodeForMetadataValidation(metadata: Metadata, code: string): Observable<any> {
        return this.connectDataPrivacyService.validateInfoLid(code, { metadata_id: metadata.metadata_id })
        .pipe(
            map( (res: boolean) => {
                if (!res) {
                    return of(false);
                }

                this.updateIdentityAfterMetadataValidated(metadata);
                return of(true);
            })
        );
    }

    /**
     * Method that updates data after metadata validated
     * @param metadataId the id of the metadata to be searched
     * @returns {void}
     */
    private updateIdentityAfterMetadataValidated(metadata: Metadata): void {
        for (const data of this.providedIdentity[metadata.type]) {
            if (data.metadata_id === metadata.metadata_id) {
                data.validated = true;
                data.source = MetadataSource.CONFIRMED;
            }
        }

        for (const metadataType of this.providedIdentity.required_metadata) {
            if (metadataType.type === metadata.type) {
                metadataType.count--;
            }
        }

        let metadataList = [];
        for (const metadata of this.providedIdentity.validate_required) {
            if (metadata.metadata_id !== metadata.metadata_id) {
                metadataList.push(metadata);
            }
        }
        this.providedIdentity.validate_required = metadataList;

        if (metadata.type === MetadataType.EMAILS) {
            this.computeSingleEmailInfo(metadata);
        }
    }

    //#endregion

    // -- get objects from memory or connect
    /**
     * Method that lists footprint services
     * @param {boolean} isLoadMore if we should load more services
     * @param {OrderByEnum} sort the sorting type
     * @param {ServiceFilterConnectInterface} filter the filter to be used
     * @param {boolean} filterIsReset if the filter is reset (changed) or not
     * @param {boolean} includeFacets if the facets should be returned or not
     * @returns Observable
     */
    public listFootprintServices(isLoadMore?: boolean, orderBy?: OrderByEnum, filter?: ServiceFilterConnectInterface, filterIsReset?: boolean, includeFacets?: boolean): Observable<any> {
        if (!this.markToUpdateFootprintServices && isLoadMore !== true) {
            if (!filter) {
                return of(this.servicesObj);
            } else {
                return of(this.filteredServicesObj);
            }
        }

        if (this.onlistFootprintServices$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistFootprintServices$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }

        const paginationObject = !filter ? this.paginationObjectServices : this.paginationObjectFilteredServices;
        if (filterIsReset) {
            paginationObject.offset = 0;
        }
        this.onlistFootprintServices$.next(this.valuesService.processServiceState.INPROGRESS);

        return this.connectDataPrivacyService.getFootprintServices(paginationObject.offset, paginationObject.limit, orderBy, filter, includeFacets)
        .pipe(
            map((resp: any) => {
                if (!resp) {
                    throw resp;
                }

                if (!filter) {
                    this.servicesCounts = resp.facets;
                    this._computeDFServicesObj(resp, this.servicesObj, paginationObject);
                } else {
                    this._computeDFServicesObj(resp, this.filteredServicesObj, paginationObject);
                }
                this._addServiceCrc();
                this.markToUpdateFootprintServices = false;
                this.onlistFootprintServices$.next(this.valuesService.processServiceState.DONE);
                return of(this.servicesObj);
            }),
            catchError((err) => {
                this.checkParkedIdentity(err, false);
                this.onlistFootprintServices$.next(this.valuesService.processServiceState.DONE);
                throw err;
            })
        );
    }

    private _computeDFServicesObj(servicesResponse: any, servicesObject: ItemsObjectInfo, paginationObject: IPaginationObject): void {
        servicesObject.zero_state = servicesResponse.zero_state ?? {};
        servicesObject.noOfItems = servicesResponse.count;

        if (!servicesObject.listOfItems || servicesObject.listOfItems.length === 0 || this.markToUpdateFootprintServices) {
            servicesObject.listOfItems = servicesResponse.services;
        } else {
            servicesObject.listOfItems = servicesObject.listOfItems.concat(servicesResponse.services);
        }

        if (servicesObject.listOfItems.length === servicesResponse.count) {
            servicesObject.gotAllItemsInList = true;
        } else {
            servicesObject.gotAllItemsInList = false;
        }

        paginationObject.offset = paginationObject.offset + paginationObject.limit;
    }

    /**
     * Method that sets service crc for each service in services list
     * @returns {void}
     */
    private _addServiceCrc(): void {
        const services = this.servicesObj.listOfItems as Array<IDomainServiceDetails>;
        if (!services) {
            return;
        }

        for (const service of services) {
            service.service_crc = this.utilsCommonService.crc32Convert(service.service_id);
        }
    }

    listServiceDetailsLid(serviceId: string, lang: string): Observable<any> {
        if(!this.onlistFootprintDetailedServices$.hasOwnProperty(serviceId)) {
            this.onlistFootprintDetailedServices$[serviceId] = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
        }

        if(!this.markToUpdateFootPrintServiceDetails.hasOwnProperty(serviceId)) {
            this.markToUpdateFootPrintServiceDetails[serviceId] = true;
        }

        if(!this.markToUpdateFootPrintServiceDetails[serviceId]) {
            return of(this.footprintServiceDetails);
        }

        if (this.onlistFootprintDetailedServices$[serviceId].value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistFootprintDetailedServices$[serviceId].asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }
        this.onlistFootprintDetailedServices$[serviceId].next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectDataPrivacyService.getServiceDetailsLid(serviceId, lang)
        .pipe(
            map((response: IDomainServiceDetails) => {
                this.onlistFootprintDetailedServices$[serviceId].next(this.valuesService.processServiceState.DONE);
                this.markToUpdateFootPrintServiceDetails[serviceId] = false;
                if(this.footprintServiceDetails?.service_id !== serviceId) {
                    this.markToUpdateFootPrintServiceDetails[this.footprintServiceDetails?.service_id] = true;
                }
                this.footprintServiceDetails = response;
                return of(this.footprintServiceDetails);
            }),
            catchError((err) => {
                this.markToUpdateFootPrintServiceExposures[serviceId] = !this.checkParkedIdentity(err, false);
                this.onlistFootprintDetailedServices$[serviceId].next(this.valuesService.processServiceState.DONE);
                throw err;
            })
        );
    }

    listServiceIssuesLid(serviceId: string, type: string): Observable<any> {
        if(!this.onlistFootprintServiceExposures$.hasOwnProperty(serviceId)) {
            this.onlistFootprintServiceExposures$[serviceId + type] = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
        }

        if(!this.markToUpdateFootPrintServiceExposures.hasOwnProperty(serviceId + type)) {
            this.markToUpdateFootPrintServiceExposures[serviceId + type] = true;
            if(type === IPrivacyFootPrintIssuesType.BREACH) {
                this.footprintServiceBreaches[serviceId] = new Array<IDomainServiceBreaches>();
            } else {
                this.footprintServiceExposures[serviceId] = new Array<IExposureIssue>();
            }
        }

        if(!this.markToUpdateFootPrintServiceExposures[serviceId + type]) {
            return of(type === IPrivacyFootPrintIssuesType.EXPOSURE ? this.footprintServiceExposures[serviceId] : this.footprintServiceBreaches[serviceId]);
        }

        if (this.onlistFootprintServiceExposures$[serviceId + type].value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistFootprintServiceExposures$[serviceId + type].asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }
        this.onlistFootprintServiceExposures$[serviceId + type].next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectDataPrivacyService.getIssuesByServiceLid(serviceId, type)
        .pipe(
            map((response) => {
                this.onlistFootprintServiceExposures$[serviceId + type].next(this.valuesService.processServiceState.DONE);
                this.markToUpdateFootPrintServiceExposures[serviceId + type] = false;
                if(type === IPrivacyFootPrintIssuesType.EXPOSURE) {
                    this.footprintServiceExposures[serviceId] = response;
                    return of(this.footprintServiceExposures[serviceId]);
                } else {
                    this.footprintServiceBreaches[serviceId] = response;
                    return of(this.footprintServiceBreaches[serviceId]);
                }
            }),
            catchError((err) => {
                this.onlistFootprintServiceExposures$[serviceId + type].next(this.valuesService.processServiceState.DONE);
                this.markToUpdateFootPrintServiceExposures[serviceId + type] = !this.checkParkedIdentity(err, false);
                return of(err);
            })
        );
    }

    listExposureData(): Observable<any> {
        if (!this.markToUpdate_exposureData) {
            this.has500Error = false;
            return of(this.exposureDashboard);
        }

        if (this.onlistExposure$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistExposure$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }
        this.onlistExposure$.next(this.valuesService.processServiceState.INPROGRESS);

        return this.connectDataPrivacyService.getExposureLid()
        .pipe(
            map(
                resp => {
                    this.exposureDashboardLid.list = [];
                    this.exposureDashboardLid.count = resp.count;

                    if (!Array.isArray(resp?.exposure)) {
                        this.markToUpdate_exposureData = true;
                        this.onlistExposure$.next(this.valuesService.processServiceState.DONE);
                        return of(this.exposureDashboard);
                    }

                    for (const exposure of resp.exposure) {
                        if (exposure.exposure_type !== MetadataType.REFERENCES
                            && exposure.exposure_type !== MetadataType.USER_IDS
                            && exposure.exposure_type !== MetadataType.LANGUAGES
                            && exposure.exposure_type !== MetadataType.IMAGES) {
                                this.exposureDashboardLid.list.push(exposure);
                        }
                        exposure['skipped'] = false;
                    }
                    this.markToUpdate_exposureData = false;
                    this.onlistExposure$.next(this.valuesService.processServiceState.DONE);
                    return of(this.exposureDashboard);
                }
            ),
            catchError(err => {
                this.markToUpdate_exposureData = !this.checkParkedIdentity(err, false);
                this.onlistExposure$.next(this.valuesService.processServiceState.DONE);
                throw err;
            })
        );
    }

    /**
     * Method that gets an issues based on issue id
     * @param {string} issue id
     * @param {PrivacyIssueType} the type of the issue
     * @param {boolean} if it will clear the value
     * @returns {Observable}
     */
    public listSingleIssue(issueId: string, type?: PrivacyIssueType, clear?: boolean): Observable<any> {
        if(!this.onListIssueObject$[issueId]) {
            this.onListIssueObject$[issueId] = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
            this.markToUpdateIssueObject[issueId] = true;
        }

        if (!this.markToUpdateIssueObject[issueId]) {
            this.has500Error = false;
            return of(this.issueObject[issueId]);
        }
        if (!type) {
            type = PrivacyIssueType.BREACH;
            clear = true;
        }

        if (this.onListIssueObject$[issueId]?.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListIssueObject$[issueId].asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        }

        if(!this.onListIssueObject$[issueId]) {
            this.onListIssueObject$[issueId] = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
            this.markToUpdateIssueObject[issueId] = true;
        }

        this.onListIssueObject$[issueId].next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectDataPrivacyService.getSingleIssue(issueId, type, clear)
        .pipe(
            map(resp => {
                this.issueObject[issueId] = resp.issues[0];

                this.markToUpdateIssueObject[issueId] = false;
                this.onListIssueObject$[issueId].next(this.valuesService.processServiceState.DONE);

                return this.issueObject[issueId];
            }),
            catchError(err => {
                if (err?.redirect500) {
                    this.has500Error = true;
                } else if (!this.checkParkedIdentity(err, false)
                            && !this.getEntriesErrorIds.has(err?.code)
                            && err?.status !== this.valuesService.requestStatuses.ERROR_STATUS) {
                    this.markToUpdateIssueObject[issueId] = true;
                }

                this.onListIssueObject$[issueId].next(this.valuesService.processServiceState.DONE);
                throw err;
            })
        );
    }

    /**
     * Method that gets the list of breaches
     * @param {boolean} for pagination - if is next page (or click on loard more btn)
     * @returns {Observable}
     */
    public listBreaches(isLoadMore?: boolean): Observable<any> {
        if (!this.markToUpdate_breaches && isLoadMore !== true) {
            this.has500Error = false;
            return of(this.breachesObj);
        }

        if (this.onlistBreaches$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistBreaches$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        }

        this.onlistBreaches$.next(this.valuesService.processServiceState.INPROGRESS);
        const paginationObj = this.paginationObjectBreaches;

        return this.connectDataPrivacyService.getIssues(PrivacyIssueType.BREACH, paginationObj.limit, paginationObj.offset, true)
        .pipe(
            map(resp => {
                this.has500Error = false;

                if (!resp) {
                    throw resp;
                }

                this._computeBreachesObj(resp, paginationObj);

                this.markToUpdate_breaches = false;
                this.onlistBreaches$.next(this.valuesService.processServiceState.DONE);
                return of(this.breachesObj);
            }),
            catchError(err => {
                if (err?.redirect500) {
                    this.has500Error = true;
                } else if (!this.checkParkedIdentity(err, false) && !this.getEntriesErrorIds.has(err?.code)
                            && err?.status !== this.valuesService.requestStatuses.ERROR_STATUS) {
                    this.markToUpdate_breaches = true;
                }

                this.onlistBreaches$.next(this.valuesService.processServiceState.DONE);
                throw err;
            })
        );
    }

    private _computeBreachesObj(resp, paginationObj): void {
        this.breachesObj.zero_state = resp.zero_state;
        this.breachesObj.noOfItems = resp.count;

        if (resp && (resp.count <= resp.issues.length || !resp.issues.length || (resp.offset + paginationObj.limit) >= resp.count)) {
            this.breachesObj.gotAllItemsInList = true;
        } else {
            this.breachesObj.gotAllItemsInList = false;
        }

        for (const issue of resp.issues) {
            issue._internalID = this.utilsService.crc32Convert(issue.issue_id);
        }

        if (!this.breachesObj.listOfItems || this.breachesObj.listOfItems.length === 0 || this.markToUpdate_breaches) {
            this.breachesObj.listOfItems = resp.issues;
        } else {
            this.breachesObj.listOfItems = this.breachesObj.listOfItems.concat(resp.issues);
        }

        paginationObj.offset = paginationObj.offset + paginationObj.limit;
    }

    /**
     * Method that gets the list of impersonations
     * @param {boolean} for pagination - if is next page (or click on loard more btn)
     * @returns {Observable}
     */
    public listImpersonations(isLoadMore?: boolean): Observable<any> {
        if (!this.markToUpdate_impersonations && isLoadMore !== true) {
            this.has500Error = false;
            return of(this.impersonationsObj);
        }

        if (this.onlistImpersonations$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistImpersonations$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }

        const paginationObject = this.paginationObjectImpersonations;
        this.onlistImpersonations$.next(this.valuesService.processServiceState.INPROGRESS);

        return this.connectDataPrivacyService.getIssues(PrivacyIssueType.IMPERSONATION, paginationObject.limit, paginationObject.offset, true)
            .pipe(
                map(resp => {
                    this.has500Error = false;

                    if (!resp) {
                        throw resp;
                    }

                    this._computeImpersonationsObj(resp, paginationObject);

                    this.markToUpdate_impersonations = false;
                    this.onlistImpersonations$.next(this.valuesService.processServiceState.DONE);
                    return of(this.impersonationsObj);
                }),
                catchError(err => {
                    if(err?.redirect500) {
                        this.has500Error = true;
                    } else if (!this.checkParkedIdentity(err, false)
                                && !this.getEntriesErrorIds.has(err?.code)
                                && err?.status !== this.valuesService.requestStatuses.ERROR_STATUS) {
                        this.markToUpdate_impersonations = true;
                    }

                    this.onlistImpersonations$.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );

    }

    private _computeImpersonationsObj(issuesResponse: any, paginationObject: IPaginationObject): void {
        this.impersonationsObj.zero_state = issuesResponse.zero_state;
        this.impersonationsObj.noOfItems = issuesResponse.count;

        if (issuesResponse && (issuesResponse.count <= issuesResponse.issues.length || !issuesResponse.issues.length || (issuesResponse.offset + paginationObject.limit) >= issuesResponse.count)) {
            this.impersonationsObj.gotAllItemsInList = true;
        } else {
            this.impersonationsObj.gotAllItemsInList = false;
        }

        for (const issue of issuesResponse.issues) {
            issue._internalID = this.utilsService.crc32Convert(issue.issue_id);
        }

        if (!this.impersonationsObj.listOfItems || this.impersonationsObj.listOfItems.length === 0 || this.markToUpdate_impersonations) {
            this.impersonationsObj.listOfItems = issuesResponse.issues;
        } else {
            this.impersonationsObj.listOfItems = this.impersonationsObj.listOfItems.concat(issuesResponse.issues);
        }

        paginationObject.offset = paginationObject.offset + paginationObject.limit;
    }

    /**
     * Method that gets the list of events (in history)
     * @param {boolean} for pagination - if is next page (or click on loard more btn)
     * @returns {Observable}
     */
    public listEvents(isLoadMore?: boolean): Observable<any> {
        if (!this.markToUpdate_events && isLoadMore !== true) {
            return of(this.eventsObj);
        }

        if (this.onlistEvents$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistExposure$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        }

        this.onlistEvents$.next(this.valuesService.processServiceState.INPROGRESS);
        const paginationObject = this.paginationObjectEvents;

        return this.connectDataPrivacyService.getEvents(paginationObject.offset, paginationObject.limit)
        .pipe(
            map(resp => {
                this.has500Error = false;

                if (!resp) {
                    throw resp;
                }

                this._computeEventsObj(resp, paginationObject);

                this.markToUpdate_events = false;
                this.onlistEvents$.next(this.valuesService.processServiceState.DONE);
                return of(this.eventsObj);
            }),
            catchError(err => {
                if (err?.redirect500) {
                    this.has500Error = true;
                } else if (!this.checkParkedIdentity(err, false) && !this.getEntriesErrorIds.has(err?.code)
                            && err?.status !== this.valuesService.requestStatuses.ERROR_STATUS) {
                    this.markToUpdate_events = true;
                }

                this.onlistEvents$.next(this.valuesService.processServiceState.DONE);
                throw err;
            })
        );
    }

    /**
     * Method that computes events object
     * @param {PrivacyEvent[]} list the list of events
     * @param {IPaginationObject} paginationObj the pagination object
     * @returns {void}
     */
    private _computeEventsObj(list: PrivacyEvent[], paginationObj: IPaginationObject): void {
        this.eventsObj.noOfItems = list.length;

        if (!this.eventsObj.listOfItems || this.eventsObj.listOfItems.length === 0 || this.markToUpdate_events) {
            this.eventsObj.listOfItems = list;
        } else {
            this.eventsObj.listOfItems = this.eventsObj.listOfItems.concat(list);
        }

        if (list && (!list.length || (paginationObj.offset + paginationObj.limit) > this.eventsObj.listOfItems.length)) {
            this.eventsObj.gotAllItemsInList = true;
        } else {
            this.eventsObj.gotAllItemsInList = false;
        }

        paginationObj.offset = paginationObj.offset + paginationObj.limit;
    }

    listPrivacyScore(): Observable<any> {
        if (!this.markToUpdate_privacyScore) {
            return of(this.privacyScore);
        }

        if (this.onListPrivacyScore$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListPrivacyScore$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        }

        this.onListPrivacyScore$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectDataPrivacyService.getPrivacyScore()
        .pipe(
            map((response: IPrivacyScore) => {
                if (!response) {
                    throw response;
                }
                this.privacyScore = response;
                this.onListPrivacyScore$.next(this.valuesService.processServiceState.DONE);
                this.markToUpdate_privacyScore = false;
                return of(this.privacyScore);
            }),
            catchError(err => {
                this.onListPrivacyScore$.next(this.valuesService.processServiceState.DONE);
                throw err;
            })
        );
    }

    /**
     * Method that undo's event status
     * @param {PrivacyEvent} event the events that gets undone
     * @returns {Observable}
     */
    public undoAction(event: PrivacyEvent): Observable<any> {
        return this.connectDataPrivacyService.undoEventStatus(event)
        .pipe(
            map(resp => {
                event.event_undo = false;
                if (Array.isArray(resp)) {
                    this.eventsObj.listOfItems.unshift(resp[0]);
                }

                if (event.object_type === PrivacyIssueType.IMPERSONATION) {
                    this.updateImpersonationsList();
                    this.updateActivityImpersonationsSummary();
                } else if (event.object_type === PrivacyIssueType.BREACH) {
                    this.updateBreachesList();
                    this.updateActivityBreachesSummary();
                } else {
                    this.updateActivityExposureSummary();
                }
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    listActivityExposureSummary(): Observable<any> {
        if (!this.markToUpdate_activityExposureSummary) {
            this.has500Error = false;
            return of(this.activityExposureSummary);
        }

        //este un request in progress
        if (this.onListActivityExposureSummary$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListActivityExposureSummary$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }

        this.onListActivityExposureSummary$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectDataPrivacyService.getSummary(SummaryType.EXPOSURE)
            .pipe(
                map(
                    resp => {
                        this.has500Error = false;
                        this.onListActivityExposureSummary$.next(ServiceState.DONE);
                        this.activityExposureSummary = resp;
                        this.markToUpdate_activityExposureSummary = false;
                        this.onListActivityExposureSummary$.next(this.valuesService.processServiceState.DONE);
                        return of(this.activityExposureSummary);
                    }
                ),
                catchError(err => {
                    if(err.redirect500) {
                        this.has500Error = true;
                    } else if (!this.checkParkedIdentity(err, false)) {
                        this.markToUpdate_activityExposureSummary = true;
                        this.onListActivityExposureSummary$.next(this.valuesService.processServiceState.DONE);
                    }
                    throw err;
                })
            );

    }

    listActivityBreachesSummary(): Observable<any> {
        if (!this.markToUpdate_activityBreachesSummary) {
            this.has500Error = false;
            return of(this.activityBreachesSummary);
        }

        if (this.onListActivityBreachesSummary$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListActivityBreachesSummary$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }

        this.onListActivityBreachesSummary$.next(this.valuesService.processServiceState.INPROGRESS);
        const summaryType = SummaryType.BREACH;
        return this.connectDataPrivacyService.getSummary(summaryType)
            .pipe(
                map(
                    resp => {
                        this.has500Error = false;
                        this.onListActivityBreachesSummary$.next(ServiceState.DONE);
                        this.activityBreachesSummary = resp;
                        this.markToUpdate_activityBreachesSummary = false;
                        this.onListActivityBreachesSummary$.next(this.valuesService.processServiceState.DONE);
                        return of(this.activityBreachesSummary);
                    }
                ),
                catchError(err => {
                    if(err.redirect500) {
                        this.has500Error = true;
                    } else if (!this.checkParkedIdentity(err, false)) {
                        this.markToUpdate_activityBreachesSummary = true;
                        this.onListActivityBreachesSummary$.next(this.valuesService.processServiceState.DONE);
                    }
                    throw err;
                })
            );

    }

    listActivityImpersonationsSummary(): Observable<any> {
        if (!this.markToUpdate_activityImpersonationsSummary) {
            this.has500Error = false;
            return of(this.activityImpersonationsSummary);
        }

        if (this.onListActivityImpersonationsSummary$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListActivityImpersonationsSummary$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }

        this.onListActivityImpersonationsSummary$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.connectDataPrivacyService.getSummary(SummaryType.IMPERSONATION)
            .pipe(
                map(
                    resp => {
                        this.has500Error = false;
                        this.onListActivityImpersonationsSummary$.next(ServiceState.DONE);
                        this.activityImpersonationsSummary = resp;
                        this.markToUpdate_activityImpersonationsSummary = false;
                        this.onListActivityImpersonationsSummary$.next(this.valuesService.processServiceState.DONE);
                        return of(this.activityImpersonationsSummary);
                    }
                ),
                catchError(err => {
                    if (err.redirect500) {
                        this.has500Error = true;
                    } else if (!this.checkParkedIdentity(err, false)) {
                        this.markToUpdate_activityImpersonationsSummary = true;
                        this.onListActivityImpersonationsSummary$.next(this.valuesService.processServiceState.DONE);
                    }
                    throw err;
                })
            );
    }

    /**
     * Method that set status for the taken action
     * @param {PrivacyIssue} current issue
     * @param {PrivacyIssueAction} current action
     * @param {IssueActionStatus} current status of the action
     * @returns {Observable}
     */
    public setActionStatus(issue: PrivacyIssue, action: PrivacyIssueAction, issueStatus: IssueActionStatus): Observable<any> {
        return this.connectDataPrivacyService.setActionStatus(issue.issue_id, action.type, issueStatus, issue.type)
        .pipe(
            map( (res: boolean) => {
                if (res) {
                    for (const issueAction of issue.issue_actions) {
                        if (issueAction.type === action.type) {
                            issueAction.status = issueStatus === IssueActionStatus.UNDO ? IssueActionStatus.NEW : issueStatus;
                        }
                    }
                    this.updateBreachesList();
                }

                return res;
            }),
            catchError(err => {
                if (err.redirect500) {
                    this.has500Error = true;
                } else if (!this.checkParkedIdentity(err, false)) {
                    this.markToUpdate_breaches = true;
                }
                throw err;
            })
        );
    }

    /**
     * Method that sends feedback
     * @param {FeedbackModel} current feedback to be sent
     * @returns {Observable}
     */
    public sendFeedback(feedBackObj: FeedbackModel): Observable<any>  {
        return this.connectDataPrivacyService.submitFeedback(feedBackObj)
        .pipe(
            map((res: boolean) => {
                return res;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    /**
     * Function that computes data for data privacy shared subscription
     * @private
     * @memberof PrivacyService
     */
    public computeInfoForSharedSubscription(): void {
        const bundle = this.subscriptionsService.getStandaloneBundleEligibleForSharing(this.valuesService.bundleDPI);
        if (bundle) {
            this.sharedSubscriptionInfo.hasSharedSubscription = true;
            this.sharedSubscriptionInfo.isPayer = this.subscriptionsService.accountOwnerIsPayerForBundle(bundle);
            this.sharedSubscriptionInfo.payerEmail = bundle.processed?.sharedInfo?.payer?.email;
            this.sharedSubscriptionInfo.showAlert = this.settingsService.getShowSharedSubscriptionAlert();
        }
    }

    // -- reset objects


    updateExposureData(loadMore?: boolean, notEvent?: boolean) {
        if (this.onlistExposure$.value !== this.valuesService.processServiceState.INPROGRESS) {
            if ((!loadMore) && notEvent) {
                this.exposureDashboard = {};
            }
            this.markToUpdate_exposureData = true;
        }
    }

    updateServiceExposuresStatus(type: string, issueId: string, serviceId: string): void {
        if (!this.footprintServiceExposures[serviceId]) {
            return;
        }
        for (const exposure of this.footprintServiceExposures[serviceId]) {
                if(exposure.issue_id === issueId) {
                    exposure.confirmation_status = type;
                }
        }
        this.markToUpdateFootPrintServiceExposures[serviceId + PrivacyIssueType.EXPOSURE] = true;
    }

    updateServiceExposuresStatusRejected(issueId: string, serviceId): void {
        this.footprintServiceExposures[serviceId] = this.footprintServiceExposures[serviceId].filter(issue => issue.issue_id !== issueId);
    }

    updateRejectedGroupped(issueId: string): void {
        for (const item of this.exposureDigitalFootprintGroupped) {
            item.issues = item.issues.filter(issue => issue.issue_id !== issueId);
            if (item.issues?.length === 0) {
                const index = this.exposureDigitalFootprintGroupped.indexOf(item);
                this.exposureDigitalFootprintGroupped.splice(index, 1);
            }
        }
    }

    /**
     * Method that updates flag for single issue
     * @param {string} the id of the issue to be retrieved
     * @returns {nothing}
     */
    public updateIssue(issueId: string): void {
        if (this.onListIssueObject$[issueId]?.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateIssueObject[issueId] = true;
        }
    }

    /**
     * Method that updates flag and pagination object for breaches list
     * @returns {nothing}
     */
    public updateBreachesList(): void {
        if (this.onlistBreaches$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_breaches = true;
            this.paginationObjectBreaches.offset = 0;
        }
    }

    /**
     * Method that updates flag and pagination object for impersonations list
     * @returns {nothing}
     */
    public updateImpersonationsList(): void {
        if (this.onlistImpersonations$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_impersonations = true;
            this.paginationObjectImpersonations.offset = 0;
        }
    }

    updateEventsList() {
        if (this.onlistEvents$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_events = true;
            this.paginationObjectEvents.offset = 0;
        }
    }

    updateActivityExposureSummary() {
        if (this.onListActivityExposureSummary$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_activityExposureSummary = true;
        }
    }

    updateActivityBreachesSummary() {
        if (this.onListActivityBreachesSummary$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_activityBreachesSummary = true;
        }
    }

    updatePrivacyScore() {
        if (this.onListPrivacyScore$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_privacyScore = true;
        }
    }

    updateActivityImpersonationsSummary() {
        if (this.onListActivityImpersonationsSummary$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_activityImpersonationsSummary = true;
        }
    }

    // -- return objects

    /**
     * Method that gets shared subscription info
     * @public
     * @memberof PrivacyService
     * @returns {ISharedSubscriptionInfo} The info about shared subscriptions
     */
    public getSharedSubscriptionsInfo(): ISharedSubscriptionInfo {
        return this.sharedSubscriptionInfo;
    }

    get500Error() {
        return this.has500Error;
    }

    getHasSubscription() {
        return this.hasDIPSubscription;
    }

    getExposureDigitalFootprint() {
        return this.exposureDigitalFootprint;
    }

    getExposureDigitalFootprintGroupped(): Array<IExposureGrouppedByService> {
        return this.exposureDigitalFootprintGroupped;
    }

    /**
     * Method that returns digital footprint services list
     * @params {boolean} showFilteredItems if we return the all services list or the filtered list
     * @returns {Array<IDomainServiceDetails>} list of services
     */
    public getFootprintServicesList(showFilteredItems = false): Array<IDomainServiceDetails> {
        if (showFilteredItems) {
            return this.filteredServicesObj.listOfItems as Array<IDomainServiceDetails>;
        }
        return this.servicesObj.listOfItems as Array<IDomainServiceDetails>;
    }

    /**
     * Method that returns digital footprint services counts grouped by status, needed for filters
     * @returns {servicesCounts} the counts object
     */
    public getFootprintServicesCountsForFilters(): FootprintServicesCounts {
        return this.servicesCounts;
    }

    /**
     * Method that returns digital footprint services count
     * @returns {number} the number of services
     */
    public getFootprintServicesCount(): number {
        return this.servicesObj.noOfItems;
    }

    /**
     * Method that returns true if we listed all digital footprint services
     * @params {boolean} showFilteredItems if we return the all services list or the filtered list
     * @returns {boolean} true if all services
     */
    public getFootprintServicesIsListFinal(getFilteredItems = false): boolean {
        if (getFilteredItems) {
            return this.filteredServicesObj.gotAllItemsInList;
        }
        return this.servicesObj.gotAllItemsInList;
    }


    getFootprintServiceExposures(serviceId: string): Array<IExposureIssue> {
        return this.footprintServiceExposures[serviceId];
    }

    getFootprintServiceBreaches(serviceId: string): Array<IDomainServiceBreaches | IServiceBreachesImpacting> {
        return this.footprintServiceBreaches[serviceId];
    }

    getFootprintDetailedService(): IDomainServiceDetails {
        return this.footprintServiceDetails;
    }

    getExposureDashboard() {
        return this.exposureDashboard;
    }

    getExposureDashboardLid() {
        return this.exposureDashboardLid.list;
    }

    getRemediation() {
        return this.remediation;
    }

    getTotalCountDashboard() {
        return this.totalCountDashboard;
    }

    getTotalCountDashboardLid() {
        return this.exposureDashboardLid.count;
    }

    getExposureCountDigitalFootprint() {
        return this.exposureCountDigitalFootprint;
    }

    getDigitalFootprintZeroStatePerfect() {
        return this.servicesObj.zero_state?.lte_zero_services ?? 0;
    }

    getDigitalFootprintZeroStateGood() {
        return this.servicesObj.zero_state?.lte_two_services ?? 0;
    }

    getPrivacyScore() {
        return this.privacyScore;
    }
    getExposureCountDigitalFootprintGroupped() {
        return this.exposureCountDigitalFootprintGroupped;
    }



    /**
     * Method that returns breaches list
     * @returns {Array<PrivacyIssue>} list of breaches
     */
    public getBreachesList(): Array<PrivacyIssue> {
        return this.breachesObj.listOfItems as Array<PrivacyIssue>;
    }

    /**
     * Method that returns breaches count
     * @returns {number} the number of breaches
     */
    public getBreachesCount(): number {
        return this.breachesObj.noOfItems;
    }

    /**
     * Method that returns true if we listed all breaches
     * @returns {boolean} true if all breaches
     */
    public getBreachesIsListFinal(): boolean {
        return this.breachesObj.gotAllItemsInList;
    }

    /**
     * Method that returns a single issue
     * @param {string} the id of the issue to be retriev
     * @returns {PrivacyIssue} current issue
     */
    public getIssue(issueId: string): PrivacyIssue {
        return this.issueObject[issueId];
    }

    /**
     * Method that returns impersonations object (will be replaced with commented methods)
     * @returns {ItemsObjectInfo} issues object
     */
    public getImpersonations(): ItemsObjectInfo {
        return this.impersonationsObj;
    }

    public getImpersonationsList(): Array<PrivacyIssue> {
        return this.impersonationsObj.listOfItems as Array<PrivacyIssue>;
    }

    public getImpersonationsCount(): number {
        return this.impersonationsObj.noOfItems;
    }

    public getImpersonationsIsListFinal(): boolean {
        return this.impersonationsObj.gotAllItemsInList;
    }

    resetBreaches() {
        this.breachesObj = {
            gotAllItemsInList: false,
            noOfItems: 0,
            listOfItems: [],
            zero_state: null
        };
    }

    resetImpersonations() {
        this.impersonationsObj = {
            gotAllItemsInList: false,
            noOfItems: 0,
            listOfItems: [],
            zero_state: null
        };
    }

    getBreachesZeroStatePerfect() {
        return this.breachesObj?.zero_state?.lte_zero_breaches ?? 0;
    }

    getBreachesZeroStateGood() {
        return this.breachesObj?.zero_state?.lte_two_breaches ?? 0;
    }

    computeZeroStatePercentage(zeroStateValue) {
        return Math.round(100 - zeroStateValue * 100);
    }

    /**
     * Method that returns events list
     * @returns {Array<PrivacyEvent>} list of events
     */
    public getEventsList(): Array<PrivacyEvent> {
        return this.eventsObj.listOfItems as Array<PrivacyEvent>;
    }

    /**
     * Method that returns events count
     * @returns {number} the number of events
     */
    public getEventsCount(): number {
        return this.eventsObj.noOfItems;
    }

    /**
     * Method that returns true if we listed all events
     * @returns {boolean} true if all events
     */
    public getEventsIsListFinal(): boolean {
        return this.eventsObj.gotAllItemsInList;
    }

    getActivityExposureSummary() {
        return this.activityExposureSummary;
    }

    getActivityBreachesSummary(): IPrivacyBreachesSummary {
        return this.activityBreachesSummary;
    }

    getBreachesActionableIssues(): Array<PrivacyIssue> {
        return this.activityBreachesSummary?.actionable_issues ?? [];
    }

    getDataBreachesTotal() {
        return this.activityBreachesSummary.count?.user ?? 0;
    }

    getActivityImpersonationsSummary() {
        return this.activityImpersonationsSummary;
    }

    updateFootprintServices() {
        if (this.onlistFootprintServices$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateFootprintServices = true;
            this.paginationObjectServices.offset = 0;
        }
    }

    setMarkToUpdateFootPrintServiceDetails(serviceId: string, value: boolean) {
        this.markToUpdateFootPrintServiceDetails[serviceId] = value;
    }

    //#region IDT
    activateIDTTimeout() {
        localStorage.setItem(this.privacyValuesService.idtTimeoutActivatedItem, 'true');
    }

    getIDTTimeoutState() {
        return localStorage.getItem(this.privacyValuesService.idtTimeoutActivatedItem);
    }
    //#endregion

    //#region onboarding
    /**
     * Method that returns differet error message based on received error
     * @param err the received error
     * @returns
     */
    public getOnboardingErrorMessageByCode(err?: any): IErrorText {
        const errorText: IErrorText = {
            translationId: this.privacyValuesService.onboardingErrorMessagesByCodes.default as string
        };

        if (err.code === PrivacyErrorCodes.VALIDATION_ABUSE) {
            const validationAbuseMessage = this.privacyValuesService.onboardingErrorMessagesByCodes[PrivacyErrorCodes.VALIDATION_ABUSE] as ValidationAbuseErrorMessage;
            if (err?.data?.wait_time) {
                const seconds = err.data.wait_time;
                errorText.translationId = validationAbuseMessage.withWaitTime;
                errorText.props = {
                    seconds
                };
            } else {
                errorText.translationId = validationAbuseMessage.withoutWaitTme;
            }
        } else if (this.privacyValuesService.onboardingErrorMessagesByCodes?.[err?.code]) {
            errorText.translationId = this.privacyValuesService.onboardingErrorMessagesByCodes[err.code] as string;
        }

        return errorText;
    }

    /**
     * Check if Gmail is found in user's identity and returns true or false accordingly
     * @returns {boolean}
     */
    public verifyIfAGmailAccountIsConnected(): boolean {
        const emails = this.providedIdentity.emails ?? [];

        for (const email of emails) {
            if (email.computed_isGmailConnectedForServiceDiscovery) {
                return true;
            }
        }

        return false;
    }

    /**
     * Check if Outlook is found in user's identity and returns true or false accordingly
     * @returns {boolean}
     */
    public verifyIfAnOutlookAccountIsConnected(): boolean {
        const emails = this.providedIdentity.emails ?? [];

        for (const email of emails) {
            if (email.computed_isOutlookConnectedForServiceDiscovery) {
                return true;
            }
        }

        return false;
    }

    /**
     * Method that sets all controls of form as touched
     * @param form the form that will have controls marked as touched
     */
    public markFormAsTouched(form: UntypedFormGroup): void {
        for (const key of Object.keys(form.controls)) {
            form.controls[key].markAsTouched();
        }
    }

    /**
     * Method that adds extra metadata to the emails metadata objects.
     * @returns {void}
     */
    public computeConnectedEmailsInfo(): void {
        const emails = this.providedIdentity?.emails ?? [];

        for (const email of emails) {
            this.computeSingleEmailInfo(email);
        }
    }

    private computeSingleEmailInfo(email: Metadata): void {
        if (email.value.toLowerCase().endsWith(EmailDomains.Google)) {
            email.computed_isGmailAccount = true;
        } else if (email.value.toLowerCase().endsWith(EmailDomains.Outlook) || email.value.toLowerCase().endsWith(EmailDomains.Hotmail)) {
            email.computed_isOutlookAccount = true;
        }
        const _scopes = email.authorized_scopes ?? [];
        for (const scope of _scopes) {
            this.setGmailEmailScopes(email, scope);
            this.setOutlookEmailScopes(email, scope);
        }
    }

    /**
     * Method that sets gmail scopes for the email metatdata.
     * @param {Metadata} email the email to be verified
     * @param {string} scope the current scope of the email
     * @returns {void}
     */
    private setGmailEmailScopes(email: Metadata, scope: string): void {
        if (!email.computed_isGmailAccount) {
            return;
        }

        if (scope === GmailConnectionState.SERVICE_DISCOVERY) {
            email.computed_isGmailConnectedForServiceDiscovery = true;
        } else if (scope === GmailConnectionState.SEND_MAIL) {
            email.computed_isGmailConnectedForSend = true;
        }
    }

    /**
     * Method that sets outlook scopes for the email metatdata.
     * @param {Metadata} email the email to be verified
     * @param {string} scope the current scope of the email
     * @returns {void}
     */
    private setOutlookEmailScopes(email: Metadata, scope: string): void {
        if (!email.computed_isOutlookAccount) {
            return;
        }

        if (scope === OutlookConnectionState.SERVICE_DISCOVERY) {
            email.computed_isOutlookConnectedForServiceDiscovery = true;
        } else if (scope === OutlookConnectionState.SEND_MAIL) {
            email.computed_isOutlookConnectedForSend = true;
        }
    }

    /**
     * Method that returns the connected gmail address that is for service discovery
     * @returns {Metadata} the email found
     */
    public getGmailUserEmailForSend(): Metadata {
        const emails = this.providedIdentity.emails ?? [];

        for (const email of emails) {
            if (email.computed_isGmailConnectedForSend) {
                return email;
            }
        }

        return {};
    }

    /**
     * Method that returns the connected outlook address that is for service discovery
     * @returns {Metadata} the email found
     */
    public getOutlookUserEmailForSend(): Metadata {
        const emails = this.providedIdentity.emails ?? [];

        for (const email of emails) {
            if (email.computed_isOutlookConnectedForSend) {
                return email;
            }
        }

        return {};
    }

    /**
     * Gets the email app from the url query param state.
     * @param {string} state The state query param. A string similar to <queryParam1>=<val1>&<queryParam2>=<val2>
     * @returns {MetaDataEmailTypes}
     */
    public getEmailAppFromState(state: string): MetaDataEmailTypes {
        const stateParts = state.split('&');

        for (const part of stateParts) {
            if (part.startsWith(`${this.valuesService.queryParams.emailApp}=`)) {
                return part.replace(`${this.valuesService.queryParams.emailApp}=`, '') as MetaDataEmailTypes;
            }
        }

        return null;
    }

    /**
     * Gets the emails connected for service discovery
     * @returns {Metadata[]} an array containing the emails
     */
    public getEmailsConnectedForServiceDiscovery(): Metadata[] {
        const connectedEmails = [];

        const emails = this.getIdentityEmails();
        for (const email of emails) {
            if (email.validated && (email.computed_isGmailConnectedForServiceDiscovery || email.computed_isOutlookConnectedForServiceDiscovery)) {
                connectedEmails.push(email);
            }
        }

        return connectedEmails;
    }

    public getMainReqHasError(): boolean {
        return this.getIdentityReqHasError;
    }
}
