import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Observable, catchError, map } from "rxjs";
import { RequestsService } from "../../global/request-service/requests.service";
import { ValuesService } from "../../../values/values.service";
import { ConfigService } from "../../../config/config.service";

enum IdtheftApiMethods {
    getOnboardingStatus = 'get_onboarding_status'
}

@Injectable({
    providedIn: "root"
})
export class ConnectIdTheftService {

    constructor(
        private readonly cookieService: CookieService,
        private readonly requestsService: RequestsService,
        private readonly valuesService: ValuesService,
        private readonly configService: ConfigService
    ) { }

    /**
     * Funciton that calls the Nimbus server with the given method and respectiv payload based on given extra params
     * @param {string} method The method to call the nimbus service with
     * @param {any} extraParams The extra params to add to the payload
     * @returns {Observable<any>}
     */
    private makeRequest(method: string, extraParams?: any): Observable<any> {
        const _json = {
            id: parseInt((Math.random() * 1000).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: method,
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.appIdTheft
                }
            }
        };

        if (extraParams) {
            Object.assign(_json.params, extraParams);
        }

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.idTheftLidService,
            _json,
            'POST'
        ).pipe(
            map(resp => {
                if (resp?.result) {
                    return resp.result;
                }
                throw resp;
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * This method is used to get onboarding status for idTheft
     * @returns {Observable<any>} Response from server
    */
    public listOnboardingStatus(): Observable<any> {
        return this.makeRequest(IdtheftApiMethods.getOnboardingStatus);
    }
}