// External
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';

// Internal
import { ValuesService } from '../../../values/values.service';
import { AppConfigurationStatus } from '../../../models/Services.model';
import { ConnectIdTheftService } from '../../requests/connect-idtheft-service/connect-idtheft.service';
import { MessageService } from '../../core/message.service';
import { SubscriptionsService } from '../subscriptions/subscriptions.service';

@Injectable({
    providedIn: 'root'
})

export class IdTheftProtectionService {

    private readonly onListOnboardingStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private markToUpdateOnboardingStatus = true;

    private onboardingDone: boolean = false;
    private appConfigurationStatus: AppConfigurationStatus = AppConfigurationStatus.PENDING;

    constructor(
        private readonly valuesService: ValuesService,
        private readonly connectIdtheftService: ConnectIdTheftService,
        private readonly messageService: MessageService,
        private readonly subscriptionsService: SubscriptionsService
    ) {
        /* Used for updating the request in order to write them into th db by the service worker */
        this.messageService.getDynamicSubject(this.valuesService.events.resetRequestsForServiceWorker)
        .subscribe({
            next: () => {
                this.updateOnboardingStatus();
            }
        });
    }

    /**
     * List Onnboarding Status for IdTheft
     * * sets onboardingDone flag if onboarding_status is true
     * * sets idTheft app configuration status to "configured" if onboarding is done
     * @public
     * @memberof IdTheftProtectionService
     * @return {*} {Observable<any>}
     */
    public listOnboardingStatus(): Observable<any> {
        if (!this.markToUpdateOnboardingStatus || !this.subscriptionsService.hasIdTheftProtection()){
            return of(this.onboardingDone);
        }

        if (this.onListOnboardingStatus$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListOnboardingStatus$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.onListOnboardingStatus$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.connectIdtheftService.listOnboardingStatus()
            .pipe(
                map(resp => {
                    this.onboardingDone = resp.onboarding_status;
                    this.appConfigurationStatus = resp.onboarding_status ? AppConfigurationStatus.CONFIGURED : AppConfigurationStatus.NOT_CONFIGURED;
                    this.messageService.sendDynamicSubjectMessage(this.valuesService.events.idTheftEnrollmentChanged, {});
                    this.markToUpdateOnboardingStatus = false;
                    this.onListOnboardingStatus$.next(this.valuesService.processServiceState.DONE);
                    return of(true);
                }),
                catchError(err => {
                    this.appConfigurationStatus = AppConfigurationStatus.NOT_CONFIGURED
                    this.markToUpdateOnboardingStatus = true;
                    this.onListOnboardingStatus$.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    /**
     * Method used for updating the onbording status request.
     */
    public updateOnboardingStatus(): void {
        if (this.onListOnboardingStatus$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateOnboardingStatus = true;
        }
    }

    /**
     * Get onboarding status is done for IdTheft
     * @public
     * @memberof IdTheftProtectionService
     * @return {boolean} True if the user has finished onboarding, false otherwise
     */
    public getOnboardingDone(): boolean {
        return this.onboardingDone;
    }

    /**
     * Get Configuration status for IdTheft App
     * @public
     * @memberof IdTheftProtectionService
     * @return {AppConfigurationStatus} CONFIGURED if user is enrolled, NOT_CONFIGURED otherwise
     */
    public getConfigurationStatus(): AppConfigurationStatus {
        return this.appConfigurationStatus;
    }
}
