//External
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tv4 } from 'tv4';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map } from 'rxjs/operators';

//Internal
import { ValuesService } from '../../../values/values.service';
import { RequestsService } from '../../global/request-service/requests.service';
import { User } from '../../../models/privacy/User.model';
import { EmailConnectionScope, IdentityModel, MetaDataEmailTypes, Metadata, MetadataType } from '../../../models/privacy/Identity.model';
import { schemas } from '../../../models/schemas';
import { UtilsCommonService } from '../../../utils/utils-common.service';
import { ConfigService } from '../../../config/config.service';

import { PrivacyEvent } from '../../../models/privacy/PrivacyEvent.model';
import { PrivacyIssue, IssueActionStatus, ActionType, PrivacyIssueType } from '../../../models/privacy/PrivacyIssue.model';
import { SummaryType } from '../../../values/privacy.values.service';
import { PrivacyEducationArticle } from '../../../models/privacy/PrivacyEducationArticle.model';
import { AuthorizedRequestInterface, RemediationPrimaryMethod, RemediationServiceDetails } from '../../../models/privacy/Remediation.model';
import { IPrivacyScore } from '../../../models/privacy/PrivacyScore.model';
import { ConfirmationStatusLid, IDomainServiceDetails } from '../../../models/privacy/PrivacyExposure.model';
import { PrivacyFootprintActionType, ServiceFilterConnectInterface } from '../../../models/privacy/PrivacyFootprint.model';
import { DataBrokerAction } from '../../../models/privacy/DataBrokers.model';
import { FeedbackModel } from '../../../models/privacy/Feedback.model';
import { OrderByEnum } from '../../../../pages/privacy/privacy-footprint-standard/privacy-footprint.component';

@Injectable({
    providedIn: 'root'
})
export class ConnectDataPrivacyService {
    errObj = { code: 104, message: 'Invalid params' };

    json = {
        id: 0,
        jsonrpc: this.valuesService.jsonrpc,
        method: '',
        params: {
            connect_source: {
                user_token: this.cookieService.get(this.valuesService.cookieToken),
                device_id: this.valuesService.connectDeviceId,
                app_id: this.valuesService.connectCookie
            }
        }
    };

    constructor(
        private readonly configService: ConfigService,
        private readonly cookieService: CookieService,
        private readonly requestsService: RequestsService,
        private readonly utilsCommonService: UtilsCommonService,
        private readonly valuesService: ValuesService
    ) {
    }

    //#region helper function

    /**
     * Funciton that calls the Nimbus server with the given method and respectiv payload based on given extra params
     * and the app id to overwrite the connect source app id with
     * @param {string} method The method to call the nimbus service with
     * @param {any} extraParams The extra params to add to the payload
     * @returns {Observable<any>}
     */
    make(method: string, extraParams?: any): Observable<any> {
        const _json = {
            id: 0,
            jsonrpc: this.valuesService.jsonrpc,
            method: '',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.appDIP
                }
            }
        };

        _json.params.connect_source.user_token = this.cookieService.get(this.valuesService.cookieToken);
        _json.id = parseInt((Math.random() * 1000).toString(), 10);
        _json.method = method;

        if (extraParams) {
            Object.assign(_json.params, extraParams);
        }

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.privacyLidService,
            _json,
            'POST'
        ).pipe(
            map(resp => {
                if (resp.error || resp.status === 'error') {
                    throw resp.internal_data || resp;
                } else {
                    return resp?.result;
                }
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    validateMetadata(metadata: Metadata) {
        let isOk = true;

        if (!metadata || this.utilsCommonService.isEmptyObject(metadata) || (!metadata.value && !metadata.info_id)) {
            isOk = false;
        }

        if (metadata.value !== undefined) {
            if (metadata.type === MetadataType.EMAILS && !tv4.validate(metadata.value, schemas.schemaONBOARDING_DIP.email.pattern)) {
                isOk = false;
            } else if (metadata.type === MetadataType.PHONES) { //&& !tv4.validate(metadata.value, schemas.schemaPHONE)) {
                const inf = metadata.value;
                if (!tv4.validate(String(inf.number), schemas.schemaPHONE)) {
                    isOk = false;
                }
            }
        } else {
            if (metadata.info_id === undefined) {
                isOk = false;
            }
        }

        return isOk;
    }
    //#endregion

    getIdentity(): Observable<IdentityModel> {
        return this.make('get_identity');
    }

    // Onboarding standard servicesList
    createIdentityLid(user: User): Observable<any> {
        if (!user || !user.firstname || user.firstname === '' || !user.lastname || user.lastname === '') {
            return throwError(this.errObj);
        }

        const extraParams = {
            first_name: user.firstname,
            middle_name: user.middlename,
            last_name: user.lastname
        };


        return this.make('create_identity', extraParams).pipe(
            map(resp => {
                if (resp.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return resp;
                } else {
                    return null;
                }
            })
        );
    }

    addInfoLid(metadata: any): Observable<any> {
        if (!metadata.type || !metadata.value) {
            return throwError(this.errObj);
        }

        const extraParams = {
            type: metadata.type,
            value: metadata.value,
            source: metadata.source
        };

        if (metadata.subtype) {
            extraParams['subtype'] = metadata.subtype;
        }

        return this.make('add_info', extraParams).pipe(
            map(resp => resp)
        );
    }

    deleteInfoLid(metadata: any): Observable<boolean> {

        if (!metadata.metadata_id) {
            return throwError(this.errObj);
        }

        const extraParams = {
            metadata_id: metadata.metadata_id
        };

        return this.make('delete_info', extraParams).pipe(
            map(resp => resp.status === this.valuesService.requestStatuses.SUCCESS_STATUS)
        );
    }

    validateInfoLid(code: string, metadata: any): Observable<boolean> {
        if (!code || !metadata.metadata_id) {
            return throwError(this.errObj);
        }

        const extraParams = {
            code,
            metadata_id: metadata.metadata_id,
        };

        return this.make('validate_info', extraParams).pipe(
            map(resp => resp.status === this.valuesService.requestStatuses.SUCCESS_STATUS)
        );
    }

    resendCodeLid(metadata: any): Observable<boolean> {

        if (!metadata || !metadata?.metadata_id) {
            return throwError(this.errObj);
        }

        const extraParams = {
            metadata_id: metadata.metadata_id
        };

        return this.make('resend_code', extraParams).pipe(
            map(resp => resp.status === this.valuesService.requestStatuses.SUCCESS_STATUS)
        );
    }

    initDataLid(): Observable<boolean> {
        return this.make('init_data', {}).pipe(
            map(resp => {
                if (resp?.status === 0) {
                    return true;
                } else {
                    throw new Error(resp);
                }
            })
        );
    }


    submitFeedback(feedBackObj: FeedbackModel): Observable<boolean> {
        const extraParams = {
            problem: feedBackObj.problem,
            description: feedBackObj.description,
            extras: {
                issue_type: feedBackObj.type,
                action_type: feedBackObj.actionType,
                service_name: feedBackObj.serviceName
            }
        };

        return this.make('submit_feedback', extraParams).pipe(
            map(resp => resp.status === this.valuesService.requestStatuses.SUCCESS_STATUS)
        );
    }

    /**
     * Method that gets events list
     * @param {number} offset of the list request
     * @param {limit} limit of the list request
     * @returns {Observale}
     */
    public getEvents(offset?: number, limit?: number): Observable<PrivacyEvent[]> { //pt pag activity / timeline
        const extraParams = {
            offset,
            limit
        }

        return this.make('get_events', extraParams).pipe(
            map(resp => {
                if (resp.status === this.valuesService.requestStatuses.ERROR_STATUS) {
                    throw resp;
                }

                return resp?.events;
            })
        );
    }

    /**
     * Method that gets the list of issues
     * @param {PrivacyIssueType} the type of the issues
     * @param {number} limit - the no of issues to be retrieved (not all at once)
     * @param {number} offset - the offset of the issues
     * @param {boolean} clear - if the value in clear shoud be returned
     * @returns {Observable}
     */
    public getIssues(type: PrivacyIssueType, limit?: number, offset?: number, clear?: boolean): Observable<any> {
        const extraParams = {
            type,
            limit,
            offset,
            clear: clear || false
        };

        if (type !== PrivacyIssueType.BREACH) {
            delete extraParams.clear;
        }

        return this.make('get_issues', extraParams).pipe(
            map(resp => {
                if (resp.status === this.valuesService.requestStatuses.ERROR_STATUS) {
                    throw resp;
                }
                return resp;
            })
        );
    }

    /**
     * Method that gets the issue based on the id
     * @param {string} the id of the issue
     * @param {PrivacyIssueType} the type of the issues
     * @param {boolean} clear - if the value in clear shoud be returned
     * @returns {Observable}
     */
    public getSingleIssue(issueId: string, type: PrivacyIssueType, clear?: boolean): Observable<any> {
        const extraParams = {
            type,
            clear,
            issue_id: issueId
        };

        if(type !== PrivacyIssueType.BREACH) {
            delete extraParams.clear;
        }

        return this.make('get_issues', extraParams).pipe(
            map(resp => {
                if (resp.status === this.valuesService.requestStatuses.ERROR_STATUS) {
                    throw resp;
                }
                return resp;
            })
        );
    }

    getIssuesByIds(ids: string[]): Observable<PrivacyIssue[]> { //pt pag issues
        if (!ids || ids.length === 0) {
            throw this.errObj;
        }

        const extraParams = {
            issue_ids: ids
        };

        return this.make('get_issues_by_ids', extraParams).pipe(
            map(resp => resp.issues)
        );
    }

    getPrivacyScore(): Observable<IPrivacyScore> {
        return this.make('get_privacy_score');
    }

    //setare stare actiune
    setActionStatus(issue_id: string, type: ActionType, status: IssueActionStatus | IssueActionStatus | ConfirmationStatusLid, issue_type?: PrivacyIssueType): Observable<boolean> {
        if (!issue_id || !type || !status) {
            return throwError(this.errObj);
        }

        const extraParams = { issue_id, type, status, issue_type: issue_type || null };

        return this.make('set_action_status', extraParams).pipe(
            map(resp => {
                if (resp.status === this.valuesService.requestStatuses.ERROR_STATUS) {
                    throw resp;
                }
                return resp.events
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * Method that undo's event status
     * @param {PrivacyEvent} event that events that gets status undone
     * @returns {Observable}
     */
    public undoEventStatus(event: PrivacyEvent): Observable<boolean> {
        if (!tv4.validate(event.object_source, schemas.schemaHistoryEventId)) {
            return throwError(() => new Error('error'));
        }

        const extraParams = {
            issue_id: event.object_source,
            type: event.action_type,
            status: IssueActionStatus.UNDO,
            issue_type: event.object_type
        };

        return this.make('set_action_status', extraParams).pipe(
            map(resp => {
                if (resp.status === this.valuesService.requestStatuses.ERROR_STATUS) {
                    throw resp;
                }
                return resp?.events;
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    getExposureLid(): Observable<any> {
        const extraParams = {
            type: 'exposure',
            list_all_issues: true
        };

        return this.make('get_issues', extraParams).pipe(
            map(resp => {
                if (resp.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    // response should be returned as it is and processed in the process layer
                    return {
                        'exposure': resp.issues,
                        'count': resp.count,
                        'zero_state': resp.zero_state
                    };
                }
                throw resp;
            })
        );
    }

    /**
     * Method that gets the list of the remediations
     * @param {Array<string>} the list of the services
     * @returns {Observable}
     */
    public getRemediation(service_names?: Array<string>): Observable<RemediationServiceDetails[]> {
        const extraParams = { service_names };

        return this.make('list_remediation', extraParams).pipe(
            map(resp => {
                if (resp.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return resp?.remediation;
                }
                throw resp;
            })
        );
    }

    /**
     * Method that gets digital footprint services list
     * @param {number} offset the offset in the list
     * @param {number} limit the max number of services to be retrieved
     * @param {OrderByEnum} orderBy the order for the list
     * @param {ServiceFilterConnectInterface} filter the filter for the lis
     * @param {boolean} includeFacets if the facets should be returned or not
     * @returns {Observable}
     */
    public getFootprintServices(offset?: number, limit?: number, orderBy?: OrderByEnum, filter?: ServiceFilterConnectInterface, includeFacets?: boolean): Observable<any> {
        const extraParams = {
            offset,
            limit,
            include_facets: includeFacets ?? false,
            sort: orderBy ?? undefined,
            filters: filter ?? undefined
        };

        return this.make("get_services", extraParams).pipe(
            map((resp) => {
                if (resp.status === 0) {
                    return {
                        services: resp.services,
                        count: resp.metadata?.count ?? 0,
                        zero_state: resp.zero_state,
                        facets: resp.facets
                    };
                }
                throw resp;
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    getIssuesByServiceLid(serviceId: string, type: string): Observable<any> {
        const extraParams = {
            service_id: serviceId,
            type
        };

        return this.make("get_service_issues", extraParams).pipe(
            map((resp) => {
                if (resp.status === 0) {
                    return resp.issues;
                }
                throw resp;
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    getEmailAuthorizeLink(emailApp: MetaDataEmailTypes, scope: EmailConnectionScope, referral: string, metaDataId?: string): Observable<any> {
        const extraParams = {
            email_app: metaDataId ? undefined : emailApp,
            scope: scope,
            metadata_id: metaDataId,
            referral
        };

        return this.make('email_authorize_init', extraParams).pipe(
            map((resp) => {
                if (resp.status === 0) {
                    return resp.redirect_url;
                }
                throw resp;
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    setEmailAuthorize(code: string, state: string): Observable<any> {
        const extraParams = {
            code,
            state: state
        };

        return this.make('email_authorize_link', extraParams).pipe(
            map((resp) => {
                if (resp.status === 0) {
                    return resp.redirect_url;
                }
                throw resp;
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
     * Method that calls email_authorized_send method
     * @param {AuthorizedRequestInterface} emailObj the object that it will be sent on method call
     * @returns {Observable}
     */
    public sendAuthorizedEmail(emailObj: AuthorizedRequestInterface): Observable<any> {
        return this.make('email_authorized_send', emailObj).pipe(
            map((resp) => {
                if (resp.status === 0) {
                    return true;
                }
                throw resp;
            }),
            catchError((error) => {
                throw error;
            })
        );
    }


    setExposureConfirmation(infoType: string, infoID: string, status: string): Observable<boolean> {
        const extraParams = {
            info_type: infoType,
            info_id: infoID,
            confirmation_status: status
        };

        return this.make('set_exposure_confirmation', extraParams).pipe(
            map(resp => resp.status === this.valuesService.requestStatuses.SUCCESS_STATUS)
        );
    }

    getServiceDetailsLid(serviceId: string, lang: string): Observable<IDomainServiceDetails> {
        const extraParams = {
            service_id: serviceId,
            lang
        };

        return this.make("get_service_info", extraParams).pipe(
            map((resp) => {
                if (resp.status === 0) {
                    return resp?.service;
                }
                throw resp;
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    setServiceStatus(serviceId: string, status: boolean | string, type: PrivacyFootprintActionType, subtype?: RemediationPrimaryMethod  | DataBrokerAction) {
        const extraParams = {
            service_id: serviceId,
            type,
            subtype,
            status
        };

        return this.make('set_service_status', extraParams).pipe(
            map(resp => resp.status === this.valuesService.requestStatuses.SUCCESS_STATUS)
        );
    }


    getSummary(type: SummaryType) {
        if (!type) {
            return throwError(() => new Error('error'));
        }

        const extraParams = {};
        extraParams['type'] = type;

        return this.make('get_summary', extraParams).pipe(
            map(resp => {
                if (resp.status === this.valuesService.requestStatuses.ERROR_STATUS) {
                    throw resp;
                }
                return resp?.summary;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    listEducationArticles(topic: string[] | string, locale: string, tags: string[], count = 10, offset = 0): Observable<PrivacyEducationArticle[]> {
        const extraParams = { topic, tags, count, offset, locale };

        return this.make('list_articles', extraParams).pipe(
            map(resp => {
                if (resp.status === this.valuesService.requestStatuses.ERROR_STATUS) {
                    throw resp;
                }
                return resp?.articles;
            })
        );
    }

    getEducationArticles(ids: string[], locale: string): Observable<PrivacyEducationArticle[]> {
        if (!ids) {
            return throwError(this.errObj);
        }

        const extraParams = {
            article_ids: ids,
            locale
        };

        return this.make('get_articles', extraParams).pipe(
            map(resp => {
                if (resp.status === this.valuesService.requestStatuses.ERROR_STATUS) {
                    throw resp;
                }
                return resp?.articles;
            })
        );
    }

    countEducationArticles(topics: string[], locale: string): Observable<PrivacyEducationArticle[]> {
        const extraParams = { topics, locale };

        return this.make('count_articles', extraParams).pipe(
            map(resp => {
                if (resp.status === this.valuesService.requestStatuses.ERROR_STATUS) {
                    throw resp;
                } else {
                    return resp;
                }
            })
        );
    }

    onDemandScan(email) {
        // TODO should replace vpn app with something suitable for web
        const extraParams = {
            app_id: this.valuesService.appVPN,
            type: 'emails',
            value: email
        };

        return this.make('on_demand_scan', extraParams).pipe(
            map(resp => {
                if (resp.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return resp;
                } else {
                    throw resp;
                }
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    getOnDemandIssues(scanId) {
        const extraParams = {
            scan_id: scanId
        };

        return this.make('get_on_demand_issues', extraParams).pipe(
            map(resp => {
                if (resp.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return resp;
                } else {
                    throw resp;
                }
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    updateName(user: User): Observable<any> {
        if (!user?.firstname || !user?.lastname) {
            return throwError( ()=> this.errObj);
        }

        const extraParams = {
            first_name: user.firstname,
            middle_name: user.middlename,
            last_name: user.lastname
        };

        return this.make('update_name', extraParams).pipe(
            map(resp => {
                if (resp.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return resp;
                } else {
                    throw resp;
                }
            }),
            catchError(error => {
                throw error;
            })
        );
    }

}
