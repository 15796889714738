import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
    providedIn: 'root'
})

// this.wrapper is used to identify iPad os as iOS
// as this point the user agent for iPad is the same as the user agent for Mac
// this wrapper must be deleted when the apple team fixes this problem
export class WrapperDeviceDetector extends DeviceDetectorService {

    constructor(
        @Inject(PLATFORM_ID) platformId: any
    ) {
        super(platformId);
        // window.MSStream -> IE exclusion from being detected as an iOS device
        // first condition is for previous versions
        // second condition is for iPad OS 13 version
        const windowLocal: any = window;
        const isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !windowLocal.MSStream;
        if (isIOS) {
            super.os = 'iOS';
        }
    }
}
