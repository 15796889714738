export const MillisecondsBetweenChecks = 60000;

export enum ServiceWorkerMessageEvent {
    PUSH = 'PUSH',
    NO_NEW_VERSION_DETECTED = 'NO_NEW_VERSION_DETECTED',
    VERSION_READY = 'VERSION_READY',
    NOTIFICATION_CLICK = 'NOTIFICATION_CLICK',
    VERSION_DETECTED = 'VERSION_DETECTED',
    VERSION_INSTALLATION_FAILED = 'VERSION_INSTALLATION_FAILED'
}

export enum NotificationActions {
    UPDATE_APP = 'UPDATE_APP',
    NOTIFICATION_DETAILS = 'NOTIFICATION_DETAILS'
}

export enum DynamicProperties {
    SERVICE_WORKER = 'serviceWorker',
    NOTIFICATION = 'Notification'
}

export enum EventListenerTypes {
    MESSAGE = 'message'
}

export enum ServiceWorkerCommands {
    SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
}

export type ServiceWorkerCommand = {
    command: ServiceWorkerCommands,
    commandData: any
}

export interface ServiceWorkerNotificationCommand extends ServiceWorkerCommand {
    commandData: {
        title: string,
        options?: NotificationOptions
    }
}

export enum PermissionStatuses {
    GRANTED = 'granted',
    DENIED = 'denied',
    PROMT = 'prompt'
}