// External
import { CookieService } from "ngx-cookie-service";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { tv4 } from "tv4";
import { catchError, map } from "rxjs/operators";

// Internal
import { RequestsService } from "../../global/request-service/requests.service";
import { UtilsCommonService } from "src/app/common/utils/utils-common.service";
import { ValuesService } from "../../../values/values.service";
import { schemas } from "src/app/common/models/schemas";
import { ConfigService } from "src/app/common/config/config.service";

@Injectable({
    providedIn: "root"
})
export class ParentalSchedulesMgmtService {
    constructor(
        private cookieService: CookieService,
        private requestsService: RequestsService,
        private utilsCommonService: UtilsCommonService,
        private valuesService: ValuesService,
        private configService: ConfigService
    ) { }

    /**
     * This method is used to show the user's schedules and the paused state
     * @param {object} Contains mandatory `profile_id`
     * @returns Response from server
     */
    listSchedules(profile_id): Observable<any> {
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "getRawSchedules",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                profile_id: profile_id
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalAdvisorSchedulesService,
            json,
            "POST"
        ).pipe(
            map( (resp) => {
                return resp.result;
            }),
            catchError((error) => {
                return throwError(error);
            })
        );
    }

    /**
     * This method is used to add/update extra constraints to a profile
     * @param {object} Contains mandatory `info`
     * @returns Response from server
     */
    editScheduleData(info): Observable<any> {
        if (
            this.utilsCommonService.isEmptyObject(info) ||
            !this.utilsCommonService.isObject(info) ||
            !tv4.validate(info.profile_id, schemas.schemaUuid)
        ) {
            console.error('Invalid params', tv4.error);
            return of('Invalid params');
        }
        const json: any = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'saveScheduleData',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                profile_id: info.profile_id,
                active: true,
                label: info.label,
                schedule: info.schedule
            }
        };

        if (info.schedule_id) {
            if (!info.hasOwnProperty('active')) {
                json.params.schedule_id = info.schedule_id;
                delete json.params.active;
            } else {
                json.params.schedule_id = info.schedule_id;
                json.params.active = info.active;
                delete json.params.schedule;
                delete json.params.label;
            }
        }
        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalAdvisorSchedulesService,
            json,
            'POST'
        )
        .pipe(
            map(resp => {
                if (resp?.result?.status === 0) {
                    return resp.result;
                } else {
                    throw new Error(resp);
                }
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * This method is used to remove constraints from a profile
     * @param {object} Contains mandatory `info.profile_id`
     * @returns Response from server
     */
    removeSchedule(info): Observable<any> {
        if (
            this.utilsCommonService.isEmptyObject(info) ||
            !this.utilsCommonService.isObject(info) ||
            !tv4.validate(info.profile_id, schemas.schemaUuid) ||
            !this.utilsCommonService.checkArray(info.schedules)
        ) {
            console.error('Invalid params', tv4.error);
            return of('Invalid params');
        }

        const arrayIdsSchedule = [];
        for (const schedule of info.schedules) {
            if (tv4.validate(schedule, schemas.schemaUuid)) {
                arrayIdsSchedule.push(schedule);
            }
        }

        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'removeScheduleData',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                profile_id: info.profile_id,
                schedules: arrayIdsSchedule
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalAdvisorSchedulesService,
            json,
            'POST'
        ).pipe(
            map(resp => {
                if (resp?.result?.status === 0) {
                    return resp.result;
                } else {
                    throw new Error(resp);
                }
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * This method is used to retrieve all the user's profiles states
     * @returns Response from server
     */
    getUserProfilesState(): Observable<any> {
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "getUserProfilesState",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalAdvisorSchedulesService,
            json,
            "POST"
        ).pipe(
            map( (resp) => {
                return resp.result;
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
     * This method is used to pause a profile
     * @param {object} Contains mandatory `info.profile_id`
     * @returns Response from server
     */
    pauseProfile(info) {
        if (this.utilsCommonService.isEmptyObject(info) || !this.utilsCommonService.isObject(info)) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "pauseProfile",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                profile_id: info.profile_id,
                user_paused: info.user_paused,
                user_blocked: info.user_blocked
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalAdvisorSchedulesService,
            json,
            "POST"
        ).pipe(
            map( (resp) => {
                return resp.result;
            }),
            catchError((error) => {
                throw error;
            })
        );
    }
}
