// External
import { CookieService } from "ngx-cookie-service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

// Internal
import { RequestsService } from "../../global/request-service/requests.service";
import { ValuesService } from "../../../values/values.service";
import { ConfigService } from "src/app/common/config/config.service";
import { map, catchError } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class ConnectImmService {
    constructor(
        private cookieService: CookieService,
        private requestsService: RequestsService,
        private valuesService: ValuesService,
        private configService: ConfigService
    ) { }

    /**
     * This method returns the commetcial offers for the user
     * @returns Response from server
     */
    getImm(): Observable<any> {
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "retrieve_central",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: this.valuesService.connectAppId,
                    device_id: "web"
                }
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.offersMgmtService,
            json,
            "POST"
        )
            .pipe(
                map(
                    (res: any) => {
                        return res.result;
                    }
                ),
                catchError((error) => {
                    throw error;
                }),
            )
    }
}
