// External
import { CookieService } from "ngx-cookie-service";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { tv4 } from "tv4";

// Internal
import { RequestsService } from "../../global/request-service/requests.service";
import { ValuesService } from "../../../values/values.service";
import { schemas } from "../../../models/schemas";
import { UtilsCommonService } from "../../../utils/utils-common.service";
import { ConfigService } from "../../../config/config.service";
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: "root"
})
export class ConnectTechassistService {
    constructor(
        readonly cookieService: CookieService,
        readonly requestsService: RequestsService,
        readonly utilsCommonService: UtilsCommonService,
        readonly valuesService: ValuesService,
        readonly configService: ConfigService
    ) { }

    /**
    * This method is used to cancel a schedule
    * @param {object} Contains mandatory `schedule_id`
    * @returns Response from server
    */
    cancelSchedule(scheduleId): Observable<any> {
        if (!tv4.validate(scheduleId, schemas.schemaUuid)) {
            return of("Invalid params");
        }
        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "cancel_schedule",
            params: {
                connect_source: {
                user_token: this.cookieService.get(this.valuesService.cookieToken),
                app_id: this.valuesService.connectAppId,
                device_id: this.utilsCommonService.getWebDeviceId()
                },
                schedule_id: scheduleId
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.techassistMgmtService,
            json,
            "POST"
        ).pipe(
            map(
                (resp) => {
                    if (resp.result) {
                        return resp.result;
                    }
                    return of('Invalid params');
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
    * This method is used to add a schedule
    * @param {object} Contains mandatory `schedule_details`
    * @returns Response from server
    */
    addSchedule(scheduleDetails): Observable<any> {
        if (
            this.utilsCommonService.isEmptyObject(scheduleDetails) ||
            !tv4.validate(scheduleDetails, schemas.schemasSchedule)
        ) {
            return of("Invalid params");
        }
        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "add_schedule",
            params: {
                connect_source: {
                user_token: this.cookieService.get(this.valuesService.cookieToken),
                app_id: this.valuesService.connectAppId,
                device_id: this.utilsCommonService.getWebDeviceId()
                },
                name: scheduleDetails.name,
                phone: scheduleDetails.phone,
                dif: scheduleDetails.dif,
                app_id: scheduleDetails.app_id,
                minutes: scheduleDetails.minutes,
                hour: scheduleDetails.hour,
                schedule: scheduleDetails.schedule,
                country: scheduleDetails.country,
                lang: scheduleDetails.lang
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.techassistMgmtService,
            json,
            "POST"
        );
    }

    /**
    * This method is used to get the list of schedules
    * @returns Response from server
    */
    getSchedules(): Observable<any> {
        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "get_schedules",
            params: {
                connect_source: {
                user_token: this.cookieService.get(this.valuesService.cookieToken),
                app_id: this.valuesService.connectAppId,
                device_id: this.utilsCommonService.getWebDeviceId()
                }
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.techassistMgmtService,
            json,
            "POST"
        ).pipe(
            map(
                (resp) => {
                    if (resp.result) {
                        return resp.result;
                    }
                    return of('Invalid params');
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }
}
