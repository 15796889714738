import { PorductsForGroup } from '../../values/business.values.service';
import { Icon } from '../Core.model'

export const ContextBubbleLocalStorage = 'context-bubble';
export const OpenInstallModal = 'open-install-modal';

export enum GroupRoles {
    ADULT = 'adult',
    TEENAGER = 'teenager',
    CHILD = 'child',
    VSB_PRIMARY_ADMIN = 'vsb_primary_admin',
    VSB_SECONDARY_ADMIN = 'vsb_secondary_admin',
    VSB_EMPLOYEE = 'vsb_employee'
}

export enum GroupMemberLabel {
    BUSINESS_OWNER = 'business_owner'
}

export interface Group {
    group_id?: string,
    group_type?: GroupTypes,
    group_label?: string,
    role?: GroupRoles,
    member_label?: GroupMemberLabel,
    context_id?: string
}

export interface GroupProductStatus {
    identity_exists?: boolean,
    init_done?: boolean,
    user_registered?: boolean
}

export interface GroupProductReport {
    product: PorductsForGroup,
    status: GroupProductStatus
}

export interface GroupProductsReport {
    context_id: string,
    reports: GroupProductReport[]
}

type GroupMemberProducts = {
    [key in PorductsForGroup]?: GroupProductStatus
}

export interface GroupMember {
    email?: string,
    name?: string,
    role?: GroupRoles,
    created?: number,
    invite_accepted?: boolean,
    member_id?: string,
    interface?: MemberInterface,
    context_id?: string,
    products?: GroupMemberProducts,
    member_label?: GroupMemberLabel
}

export interface GroupMemberProcessed {
    member: GroupMember,
    color: MembersColors
}

export interface GroupMembersObject {
    [context_id: string]: GroupMemberProcessed
}

export interface CreateGroupResponse extends Group, GroupMember {}

export interface GroupDevices {
    [context_id: string]: Array<any>
}

export interface MemberInterface {
    label: string,
    passwordManager: boolean,
    dataPrivacy: boolean,
    devicesProtected: number,
    devicesProtectedLabel?: string,
    color?: MembersColors
}

export enum MembersColors {
    PRIMARY = 'primary',
    A1 = 'a1',
    A2 = 'a2',
    A4 = 'a4',
    A7 = 'a7'
}

/**
 * GroupTypes && GroupRoles are also applicable to regular shared plans (family, standalone)
 */
export enum GroupTypes {
    STANDALONE = 'standalone',
    VSB = 'vsb',
    FAMILY = 'family'
}

export type GroupTypeToDefaultGroupRole = {
    [key in GroupTypes]?: GroupRoles
}

export type GroupTypeToAdminInvites = {
    [key in GroupTypes]?: number
}

export enum GroupMgmtErrorCodes {
    INVALID_PARAMS_1 = 32601, // internal code: 3260X - starts with 32601 and varies for each invalid parameter
    INVALID_PARAMS_2 = 32602,
    DATA_ERROR = 32005,
    DEFAULT = -1 // our own error code
}

type GroupMgmtErrorDataErrors = {
    [key in GroupMgmtDataErrorCodes]?: string
}

export type GroupMgmtErrors = {
    [key in GroupMgmtErrorCodes]?: GroupMgmtErrorDataErrors|string
}

interface GroupRoleWelcomeDetails {
    description: string,
    secondDescription: string,
    buttonName: string
}

export type GroupRolesWelcomeDetails = {
    [key in GroupRoles]?: GroupRoleWelcomeDetails
}

export enum GroupMgmtDataErrorCodes {
    DATABASE_ERROR = 32701,
    COMMUNICATION_ERROR = 32801,
    INVALID_SUBSCRIPTION_SERVICE = 39104, // Invalid subscription service
    SERVICE_ALLREADY_LINKED = 39105, // Service linked with another group
    USER_IS_SECONDARY_ADMIN = 39106, // User cannot be secondary admin and primary admin
    PRIMARY_ADMIN_CANT_DIFFER = 39107, // Primary admin can differ from current user only for vsb groups
    GROUP_NOT_FOUND = 39101, // Group not found
    INSUFFICIENT_PERMISSIONS = 39109, // Insufficient permissions for this action
    // INVALID_USER_GROUP = 39105, // Invalid group type for user
    MEMBER_NOT_FOUND = 39102, // Member does not exist
    UPDATE_FAILURE = 39103, // Member cannot be updated
    SWITCH_CONTEXT_FAILURE = 500, // Cannot switch context
    USER_NOT_INVITED = 39110, // User not invited to the group
    ALREADY_ACCEPTED = 39111, // User already accepted the invite
    FAMILY_GROUP_LIMIT_REACHED = 39113, // Group limit reached
    GROUP_LIMIT_REACHED = 39118, // Group limit reached
}

export interface GroupModel extends Group {
    members?: Array<GroupMember>
}

export interface ContextUI {
    label: string
}

export type ContextsUI = {
    [key in GroupTypes]?: ContextUI
}

export interface GroupRoleUI {
    icon: Icon;
    label: {
        [key in GroupMemberLabel]?: string
    } & {
        default: string
    };
}

export type GroupRolesUIs = {
    [key in GroupRoles]?: GroupRoleUI
}

export interface CreateGroupParams {
    group_type?: GroupTypes,
    group_label: string,
    service_id: string,
    group_owner?: string
}