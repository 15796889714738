// External
import { CookieService } from "ngx-cookie-service";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { tv4 } from "tv4";
import { catchError, delay, map} from "rxjs/operators";

// Internal
import { RequestsService } from "../../global/request-service/requests.service";
import { UtilsCommonService } from "src/app/common/utils/utils-common.service";
import { ValuesService } from "../../../values/values.service";
import { ConfigService } from "src/app/common/config/config.service";

@Injectable({
providedIn: "root"
})
export class RenewalInfoService {
    constructor(
        private cookieService: CookieService,
        private requestsService: RequestsService,
        private utilsCommonService: UtilsCommonService,
        private valuesService: ValuesService,
        private configService: ConfigService
    ) { }

    /**
     * This method is used to get user Avangate subscriptions from BI based on user email
     * @returns Response from server
     */
    getUserSubscriptions(): Observable<any> {
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "get_bi_user_subscriptions",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectRenewalInfoProxyService,
            json,
            "POST"
        ).pipe(
        //delay(5000),
            map((resp: any) => {
                if (resp?.result?.status === 0) {
                    return resp?.result?.subscriptions ? resp.result.subscriptions : [];
                }
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
     * This method is used to get upgrade link
     * @service_id {string} Contains mandatory service_id
     * @returns Response from server
     */
    getUpgradeLink(service_id: string): Observable<any> {
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "get_upgrade_link",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.configService.config.partner_id
                },
                service_id
            }
        };

        return this.requestsService.make(
            this.configService.config.connectNimbusServer,
            this.valuesService.connectRenewalInfoProxyService,
            json,
            "POST"
        ).pipe(
            map((res: any) => {
                if (res?.result?.url) {
                    return res.result.url;
                } else {
                    throw "error";
                }
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
     * This method is used to retrieve account management URL from Avangate based on subscription identifier
     * @param {object} Contains mandatory `details.id` and `details.account`
     * @returns Response from server
     */
    iframe(details): Observable<any> {
        if (this.utilsCommonService.isEmptyObject(details) 
            || !this.utilsCommonService.isObject(details) 
            || !details.account
            || !details.sub_id
            || !this.utilsCommonService.checkString(details.account)
            || !this.utilsCommonService.checkString(details.sub_id)
        ) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "get_single_sign_on",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                account: details.account,
                subscription: details.sub_id
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectRenewalInfoProxyService,
            json,
            "POST"
        );
    }

}
