
export class PrivacySettings {
    'dip/dialogs/sfa' : boolean = true;  //show footprint about
    'dip/dialogs/sba' : boolean = true;  //show breaches about
    'dip/dialogs/sia' : boolean = true;  //show impersonations about
    'dip/dialogs/sea' : boolean = true;  //show education about
    'dip/dialogs/sha' : boolean = true;  //show history about
    'dip/dialogs/sdma': boolean = true; //show data monitor about

    'dip/dialogs/cit' : boolean = true;  //confirm impersonation threat
    'dip/dialogs/crt' : boolean = true;  //confirm record threat
    'dip/dialogs/ii'  : boolean = true;  //DPY-373 //impersonation info
    'dip/dialogs/cba' : boolean = true;  //DPY-367 //confirm breach action
    'dip/dialogs/cia' : boolean = true;  //DPY-1317 //confirm impersonation action
    'dip/dialogs/li'  : boolean = true;  //DPY-361 //link info
    'dip/dialogs/vmi' : boolean = true;  //DPY-343 //validate metadata info
        
    //data brokers
    'dip/databrokers/banner/legal'     : boolean = true; // banner about legal limitations in Data Brokers tab
    
    // data monitor
    'dip/datamonitor/onboarding': boolean = true // banner with info about data monitor

    //DPY-1920
    'dip/emails/breach'       : boolean = true; //breaches email alerts
    'dip/emails/impersonation': boolean = true; //impersonations email alerts
    'dip/emails/dp'           : boolean = true; //digital footprint emails

    //other settings
    'dip/general/dbv': boolean = false; //DPY-2631 - set default view for 'first time users' to table view 

    constructor() {
    }
}
