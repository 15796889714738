//External
import { Injectable } from '@angular/core';
import { Observable, throwError, catchError, map } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

//Internal
import { ValuesService } from '../../../values/values.service';
import { Errors, POST, RequestsService } from '../../global/request-service/requests.service';
import { ConfigService } from '../../../config/config.service';
import { IdentityModel, Metadata } from '../../../models/privacy/Identity.model';
import { PrivacyIssueType } from '../../../models/privacy/PrivacyIssue.model';
import { AssetRecommendation } from '../../../models/business/Business.model';
import { DefaultError } from '../../../../common/models/Core.model';

@Injectable({
    providedIn: 'root'
})

export class ConnectBusinessAssetsService {

    private readonly defaultErrorResponse: DefaultError = {
        code: 104,
        message: 'Invalid params'
    };

    constructor(
        private readonly requestsService: RequestsService,
        private readonly valuesService: ValuesService,
        private readonly cookieService: CookieService,
        private readonly configService: ConfigService
    ) {}

    /**
     * Makes the request call
     * @param {string} method The name of the method to be called
     * @param {object} params An object containing the params needed for the request
     * @returns {Observable} The response from the server
     */
    private makeRequest(method: string, params?: any): Observable<any> {
        const json = {
            id: parseInt((Math.random() * 1000).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method,
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.appBA
                }
            }
        };

        if (params) {
            Object.assign(json.params, params);
        }

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.privacyLidService,
            json,
            POST
        ).pipe(
            map(resp => {
                if (resp.error || resp.status === Errors.ERROR) {
                    throw resp.internal_data || resp;
                } else {
                    return resp?.result;
                }
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * Makes the batch request call
     * @param {array} batchRequestBody The array of objects
     * @returns {Observable} The request response
     */
     private makeBatchRequest(batchRequestBody: Array<object>): Observable<any> {
        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.privacyLidService,
            batchRequestBody,
            POST
        ).pipe(
            map(results => {
                if (!Array.isArray(results)) {
                    throw new Error(Errors.ERROR);
                }

                for (const resp of results) {
                    if (resp?.result?.status !== 0) {
                        throw new Error(Errors.ERROR);
                    }
                }
                return true;
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * Gets the list of issues of the specified type
     * @param {PrivacyIssueType} issueType The type of the issues
     * @param {boolean} listAllIssues The flag that specifies if all issues should be returned
     * @param {boolean} clear The flag that specifies if the issue should be retrieved in clear
     * @returns {Observable} The request response
     */
    public getIssues(issueType: PrivacyIssueType, listAllIssues: boolean, clear: boolean): Observable<any> {
        const params = {
            type: issueType,
            list_all_issues: listAllIssues,
            clear
        };

        return this.makeRequest('get_issues', params)
        .pipe(
            map(resp => {
                if (resp.status === this.valuesService.requestStatuses.ERROR_STATUS) {
                    throw resp;
                }
                return resp;
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * Creates the new identity
     * @returns {Observable} The request response
     */
    public createIdentity(): Observable<any> {
        return this.makeRequest('create_identity')
        .pipe(
            map(resp => {
                if (resp.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return resp;
                } else {
                    return null;
                }
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * Adds the new metadata
     * @param {Metadata} metadata The metadata info
     * @returns {Observable} The request response
     */
    public addInfo(metadata: Metadata): Observable<any> {
        if (!metadata.type || !metadata.value) {
            return throwError(() => this.defaultErrorResponse);
        }

        const params: Metadata = {
            type: metadata.type,
            value: metadata.value,
            source: metadata.source
        };

        if (metadata.subtype) {
            params.subtype = metadata.subtype;
        }

        if (metadata.masked) {
            params.masked = metadata.masked;
        }

        return this.makeRequest('add_info', params)
        .pipe(
            map(resp => {
                if (resp?.status !== this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    throw resp;
                }
                return resp;
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * Updates the existing info on a metadata type
     * @param {Metadata[]} metadata The array of metadatas to be updated
     * @returns {Observable} The response from the server
     */
    public updateInfo(metadatas: Metadata[]): Observable<any> {
        const batchRequests = [];
        let counter = 1;

        for (const metadata of metadatas) {
            const json = {
                id: counter,
                jsonrpc: this.valuesService.jsonrpc,
                method: 'update_info',
                params: {
                    connect_source: {
                        user_token: this.cookieService.get(this.valuesService.cookieToken),
                        device_id: this.valuesService.connectDeviceId,
                        app_id: this.valuesService.appBA
                    },
                    metadata_id: metadata.metadata_id,
                    subtype: metadata.subtype,
                    remove: metadata.remove
                }
            };

            batchRequests.push(json);
            counter++;
        }
        return this.makeBatchRequest(batchRequests);
    }

    /**
     * Deletes the given metadata
     * @param {Metadata} metadata The metadata info
     * @returns {Observable} The request response
     */
    public deleteInfo(metadata: Metadata): Observable<boolean> {
        if (!metadata.metadata_id) {
            return throwError(() => this.defaultErrorResponse);
        }

        return this.makeRequest('delete_info', {metadata_id: metadata.metadata_id} as Metadata)
        .pipe(
            map(resp => resp.status === this.valuesService.requestStatuses.SUCCESS_STATUS),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * Validates security code for given metadata type
     * @param {string} code The code entered
     * @param {Metadata} metadata The metadata object
     * @returns {Observable} The response from the server
     */
    public validateInfo(metadata: Metadata, code: string): Observable<boolean> {
        if (!code || !metadata.metadata_id) {
            return throwError(() => this.defaultErrorResponse);
        }

        return this.makeRequest('validate_info', {metadata_id: metadata.metadata_id, code})
        .pipe(
            map(resp => resp.status === this.valuesService.requestStatuses.SUCCESS_STATUS),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * Resends the validation code
     * @param {Metadata} metadata The metadata info
     * @returns {Observable} The request response
     */
    public resendCode(metadata: Metadata): Observable<boolean> {
        if (!metadata?.metadata_id) {
            return throwError(() => this.defaultErrorResponse);
        }

        return this.makeRequest('resend_code', {metadata_id: metadata.metadata_id} as Metadata)
        .pipe(
            map(resp => resp.status === this.valuesService.requestStatuses.SUCCESS_STATUS),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * Gets the existing identity
     * @returns {Observable} The request response
     */
    public getIdentity(): Observable<IdentityModel> {
        return this.makeRequest('get_identity');
    }

    /**
     * Initializes the data
     * @returns {Observable} The request response
     */
    public initData(): Observable<boolean> {
        return this.makeRequest('init_data')
        .pipe(
            map(resp => {
                if (resp?.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return true;
                } else {
                    throw new Error(resp);
                }
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * Gets all global actions fot business assets
     * @returns {Observable} The request response
     */
    public getGlobalActions(): Observable<any> {
        return this.makeRequest('get_global_actions')
        .pipe(
            map(resp => {
                if (resp.status === this.valuesService.requestStatuses.ERROR_STATUS) {
                    throw resp;
                }
                return resp;
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * Gets all global actions fot business assets
     * @param {AssetRecommendation} action The action object
     * @returns {Observable} The request response
     */
    public setGlobalActionStatus(action: AssetRecommendation): Observable<any> {
        if (!action.type || !action.category || !action.status) {
            return throwError(() => this.defaultErrorResponse);
        }

        const params: AssetRecommendation = {
            type: action.type,
            category: action.category,
            status: action.status
        };

        return this.makeRequest('set_global_action_status', params)
        .pipe(
            map(resp => resp.status === this.valuesService.requestStatuses.SUCCESS_STATUS),
            catchError(error => {
                throw error;
            })
        );
    }

}
