// External
import { Directive, Input, HostListener, OnDestroy, ElementRef, SimpleChanges, OnChanges } from '@angular/core';

// Internal
import { TooltipService } from '../../../services/global/tooltip/tooltip.service';

@Directive({
    selector: '[tooltip]'
})

export class UiTooltipDirective implements OnDestroy, OnChanges {

    @Input() tooltip: string;
    @Input() position: string;
    @Input() tooltipColor: string;
    @Input() width: number; // if left undefined tooltip will be width: auto
    @Input() hiddenTooltip: boolean; // use when you need to stop showing the tooltip based on condition

    triggered = false;

    constructor(
        readonly element: ElementRef,
        readonly tooltipService: TooltipService
    ) { }


    @HostListener('mouseenter', ['$event']) onMouseEnter(event) {
        if (this.hiddenTooltip) {
            return;
        }
        this.triggered = true;
        this.drawTooltip();
    }

    @HostListener('mouseleave', ['$event']) onMouseLeave(event) {
        this.triggered = false;
        this.destroy();
    }

    @HostListener('keyup.tab', ['$event']) onTabFocus(event) {
        this.onMouseEnter(event);
    }

    @HostListener('focusout', ['$event']) onFocusLost(event) {
        if(this.triggered){
            this.onMouseLeave(event);
        }
    }

    ngOnChanges(changes: SimpleChanges){
        if(this.triggered && changes.tooltip && !changes.tooltip.firstChange && changes.tooltip.previousValue !== changes.tooltip.currentValue){
            this.destroy();
            this.drawTooltip();
        }
    }

    ngOnDestroy(): void {
        this.destroy();
    }

    destroy(): void {
        this.tooltipService.removeTooltip();
    }

    drawTooltip() {
        this.tooltipService.addTooltip(this.element, this.tooltip, this.position, this.tooltipColor, this.width);
    }

}
