// External
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// Internal
import {
    FailedToFetch,
    RequestsDataBaseName,
    ServiceWorkerIsNoLongerResponsive,
    UserShouldNotTryToWriteToDataBase,
    ValuesService
} from '../../../../common/values/values.service';
import { DataTypes } from '../../../../common/models/schemas';

@Injectable({
    providedIn: 'root'
})

export class AuthService {

    defaultLang: string;
    isAuth: boolean = false;
    languages: any;
    path: any;
    urlParams: any;
    private hasActiveSession = false;

    constructor(
        private cookieService: CookieService,
        private valuesService: ValuesService,
        public translateService: TranslateService
    ) { }

    // checks token
    checkLogin() {
        const rsp = this.cookieService.get(this.valuesService.cookieToken);
        if (rsp || rsp !== '') {
            return true;
        } else {
            return false;
        }
    }

    public setHasActiveSession(value: boolean): void {
        this.hasActiveSession = value;
    }

    public getHasActiveSession(): boolean {
        return this.hasActiveSession;
    }

    /**
     * Deletes the service worker cache and the local storage variables at logout/login/unregister.
     */
    public deleteServiceWorkerCacheAndLocalStorageVariables(): void {
        localStorage.removeItem(UserShouldNotTryToWriteToDataBase);
        localStorage.removeItem(FailedToFetch);
        localStorage.removeItem(ServiceWorkerIsNoLongerResponsive);
        const DBDeleteRequest = window?.indexedDB?.deleteDatabase(RequestsDataBaseName);

        DBDeleteRequest.onerror = function(_event) {
            console.log('Error deleting database.');
        };

        DBDeleteRequest.onsuccess = function(_event) {
            console.log('Database deleted successfully');
        };
    }

    /**
     * Rewrites the user token cookie.
     * @param {string} newUserToken - The new user token.
     */
    public writeUserTokenCookie(newUserToken: string): void {
        if (!newUserToken || typeof newUserToken !== DataTypes.STRING) {
            return;
        }
        const now = new Date();
        const exp = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
        // 2 min for test purpose -> expire token
        this.cookieService.set(this.valuesService.cookieToken, newUserToken, exp, '/', '', true, 'Strict');
        // this.cookieService.set(this.valuesService.cookieTokenAlt, newUserToken, exp, '/authorize', '', true, 'Strict');

        //> Do not delete - it's used for target
        // this.cookieService.set(this.valuesService.cookieToken, newUserToken, exp, '/', '', true, 'None');
    }

    /**
     * Method used for computing the logic at user token change.
     * @param {string} newUserToken - The new user token.
     */
    public computeLogicAtUserTokenChange(newUserToken: string): void {
        this.writeUserTokenCookie(newUserToken);
        this.deleteServiceWorkerCacheAndLocalStorageVariables();
    }
}
