// External
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, timer } from 'rxjs';
import { catchError, skipWhile, map, repeat } from 'rxjs/operators';

// Internal
import { SeccenterService } from '../../requests/connect-seccenter-service/connect-seccenter.service';
import { UsefulService } from '../../global/useful/useful.service';
import { ValuesService } from '../../../values/values.service';
import { UtilsCommonService } from '../../../utils/utils-common.service';
import { DashboardValuesService } from '../../../values/dashboard.values.service';
import { ConnectLoginService } from '../../requests/connect-login-service/connect-login.service';
import { SubscriptionsService } from '../subscriptions/subscriptions.service';
import { PrivacyValuesService } from '../../../values/privacy.values.service';
import { AppsConfigService } from '../../../../common/config/apps.config.service';
import { ConfigService } from '../../../../common/config/config.service';
import { ConnectDataPrivacyService } from '../../requests/connect-data-privacy/connect-data-privacy.service';
import { ProfilesService } from '../profiles/profiles.service';
@Injectable({
    providedIn: 'root'
})


export class DashboardService {

    /*
        - obiectul sa fie impartit pe carduri
        dashboard = {
            devices: {},
            threats: {},
            scannedPages: {},
            dip: {
                leaks: {},
                breaches: {}
            }
        };

    */
dashboard: any = {
    dip: {
        breaches: {
            markToUpdateStartScan: true,
            markToUpdateListBreaches: true,
            scan_id: null,
            scan: {}
        }
    }
};

private readonly onCountThreats: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
private readonly onListActiveDevicesIds: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
private readonly onListThreats: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
private readonly onListHasLeaks: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
private readonly onListBreaches: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
private readonly onStartBreachesScan: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);

constructor(
    readonly seccenterService: SeccenterService,
    readonly usefulService: UsefulService,
    readonly valuesService: ValuesService,
    readonly dashboardValuesService: DashboardValuesService,
    readonly utilsCommonService: UtilsCommonService,
    private readonly connectLoginService: ConnectLoginService,
    private readonly subscriptionsService: SubscriptionsService,
    private readonly privacyValuesService: PrivacyValuesService,
    private readonly appsConfigService: AppsConfigService,
    public readonly configService: ConfigService,
    private readonly connectDataPrivacyService: ConnectDataPrivacyService,
    private readonly profilesService: ProfilesService
) {}

    initLeaks() {
        if (!this.dashboard['dip']) {
            this.dashboard['dip'] = {};
        }
        if (!this.dashboard['dip']['leaks']) {
            this.dashboard['dip']['leaks'] = {};
        }
    }

    listHasLeaks() {
        const checkFlag = this.usefulService.getNested(this.dashboard, undefined, 'dip', "leaks", "markToUpdate");

        if ((checkFlag !== undefined && !checkFlag) || !this.appsConfigService.showApp(this.valuesService.appDIP)) {
            return of(this.dashboard);
        }

        if (this.onListHasLeaks.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListHasLeaks.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onListHasLeaks.next(this.valuesService.processServiceState.INPROGRESS);
            return this.connectLoginService.getHasLeaks()
            .pipe(
                map(
                    resp => {
                        this.initLeaks();
                        if (resp) {
                            this.dashboard['dip']['leaks'] = resp;
                            this.dashboard['dip']['leaks'].markToUpdate = false;
                            this.onListHasLeaks.next(this.valuesService.processServiceState.DONE);
                        } else {
                            this.dashboard['dip']['leaks'].markToUpdate = true;
                            this.onListHasLeaks.next(this.valuesService.processServiceState.DONE);
                        }
                        return of(true);
                    }
                ),
                catchError( (err)=> {
                    this.initLeaks();
                    this.dashboard['dip']['leaks'].markToUpdate = true;
                    this.onListHasLeaks.next(this.valuesService.processServiceState.DONE);
                    return of(true);
                })
            );
        }
    }

    showBreachesCard() {
        return this.dashboard?.dip?.breaches?.scan?.last_scan?.status === this.privacyValuesService.breachesStatusScan.done;
    }

    redoBreachesRequest() {
        return !this.showBreachesCard();
    }

    showRecommendationCards() {
        const checkFlag = this.usefulService.getNested(this.dashboard, undefined, 'recommendations');
        if (checkFlag) {
            return ((Object.keys(this.getSecurityBundles()).length > 0) || (Object.keys(this.getUpgradeBundles()).length > 0) || 
                (Object.keys(this.getVpnBundles()).length > 0)) ? true : false;
        } else {
            return false;
        }
    }

    listBreaches(): Observable<any> {
        return new Observable(subscriber => {
            const checkFlag = this.dashboard.dip.breaches.markToUpdateListBreaches;
            const scanId = this.dashboard.dip.breaches.scan_id;

            if ((checkFlag !== undefined && !checkFlag)
                || !scanId
                || !this.appsConfigService.showApp(this.valuesService.appDIP)) {
                subscriber.next(this.dashboard);
                subscriber.complete();
                return;
            }

            if (this.onListBreaches.value === this.valuesService.processServiceState.INPROGRESS) {
                this.onListBreaches.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                )
                .subscribe({
                    next: () => {
                        subscriber.next(true);
                        subscriber.complete();
                    }
                });
            } else {
                this.onListBreaches.next(this.valuesService.processServiceState.INPROGRESS);
                this.connectDataPrivacyService.getOnDemandIssues(scanId)
                .pipe(
                    map((resp) => {
                        if (resp) {
                            this.dashboard.dip.breaches.scan = resp;
                            this.dashboard.dip.breaches.markToUpdateListBreaches = false;
                        } else {
                            this.dashboard.dip.breaches.markToUpdateListBreaches = true;
                            subscriber.error();
                            subscriber.complete();
                        }
                    }),
                    repeat({
                        count: 2,
                        delay: (count) => {
                            if (this.redoBreachesRequest() && count === 1) {
                                this.dashboard.dip.breaches.markToUpdateListBreaches = true;
                                return timer(5000);
                            }
                            return of();
                        },
                    })
                )
                .subscribe({
                    next: () => {
                        this.onListBreaches.next(this.valuesService.processServiceState.DONE);
                        subscriber.next(true);
                        subscriber.complete();
                    },
                    error: (err) => {
                        this.dashboard.dip.breaches.markToUpdateListBreaches = true;
                        this.onListBreaches.next(this.valuesService.processServiceState.DONE);
                        subscriber.error(err);
                        subscriber.complete();
                    }
                });
            }
        });
    }

    startBreachesScan(): Observable<any> {
        return new Observable(subscriber => {
            const checkFlag = this.dashboard.dip.breaches.markToUpdateStartScan;
            const dontDoTheScan = this.getHasLeaks() || this.subscriptionsService.hasDataPrivacy();

            if ((checkFlag !== undefined && !checkFlag)
                || dontDoTheScan
                || !this.appsConfigService.showApp(this.valuesService.appDIP)) {
                subscriber.next(this.dashboard);
                subscriber.complete();
                return;
            }

            if (this.onStartBreachesScan.value === this.valuesService.processServiceState.INPROGRESS) {
                this.onStartBreachesScan.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                )
                .subscribe({
                    next: () => {
                        subscriber.next(true);
                        subscriber.complete();
                    }
                });
            } else {
                this.onStartBreachesScan.next(this.valuesService.processServiceState.INPROGRESS);
                this.connectDataPrivacyService.onDemandScan(this.profilesService.getOwnerEmail())
                .subscribe({
                    next: (resp) => {
                        if (resp) {
                            this.dashboard.dip.breaches.scan_id = resp.scan_id;
                            if (this.dashboard.dip.breaches.scan_id) {
                                this.dashboard.dip.breaches.markToUpdateStartScan = false;
                            } else {
                                this.dashboard.dip.breaches.markToUpdateStartScan = true;
                                subscriber.error();
                                subscriber.complete();
                            }
                        } else {
                            this.dashboard.dip.breaches.markToUpdateStartScan = true;
                        }
                        this.onStartBreachesScan.next(this.valuesService.processServiceState.DONE);
                        subscriber.next(true);
                        subscriber.complete();
                    },
                    error: (err)=> {
                        this.dashboard.dip.breaches.markToUpdateStartScan = true;
                        this.onStartBreachesScan.next(this.valuesService.processServiceState.DONE);
                        subscriber.error(err);
                        subscriber.complete();
                    }
                });
            }
        });
    }

    updateBreaches() {
        if (this.onListBreaches.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.dashboard.dip.breaches.markToUpdateListBreaches = true;
        }
    }

    getBreaches() {
        return this.dashboard.dip.breaches.scan;
    }

    getHasLeaks() {
        return this.usefulService.getNested(this.dashboard, false, 'dip', "leaks", "has_leaks");
    }

    initThreats(field, noDays) {
        const dashboard = this.get();

        if (!dashboard['threats']) {
            dashboard['threats'] = {};
        }
        if (!dashboard['threats'][field]) {
            dashboard['threats'][field] = {};
        }

        if (!dashboard['threats'][field][noDays]) {
            dashboard['threats'][field][noDays] = {};
        }
    }

    countThreats(noDays): Observable<any> {
        const dashboard = this.get();
        const checkFlag = this.usefulService.getNested(dashboard, undefined, 'threats', "countThreats", noDays, "markToUpdate");
        // 6/29 + ziua curenta este 7 / 30
        const days = noDays === 'seven' ? 6 : 29;

        if(checkFlag !== undefined && !checkFlag){
            return of(dashboard);
        }

        if (this.onCountThreats.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onCountThreats.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onCountThreats.next(this.valuesService.processServiceState.INPROGRESS);
            return this.seccenterService.countThreats(days)
            .pipe(
                map(
                    (resp) => {
                        this.initThreats('countThreats', noDays);

                        if (resp) {
                            dashboard['threats']['countThreats'][noDays]['all'] = resp;
                            dashboard['threats']['countThreats'][noDays].markToUpdate = false;
                            this.onCountThreats.next(this.valuesService.processServiceState.DONE);
                        } else {
                            dashboard['threats']['countThreats'][noDays].markToUpdate = true;
                            this.onCountThreats.next(this.valuesService.processServiceState.DONE);
                        }
                        return of(true);
                    }
                ),
                catchError( 
                    (err) => {
                        this.initThreats('countThreats', noDays);
                        dashboard['threats']['countThreats'][noDays].markToUpdate = true;
                        this.onCountThreats.next(this.valuesService.processServiceState.DONE);
                        throw err;
                })
            );
        }
    }

    initActiveDevices() {
        const dashboard = this.get();

        if (!dashboard['threats']) {
            dashboard['threats'] = {};
        }
        if (!dashboard['threats']['activeDevices']) {
            dashboard['threats']['activeDevices'] = {};
        }
    }

    listActiveDevicesIds(): Observable<any> {
        const dashboard = this.get();
        const checkFlag = this.usefulService.getNested(dashboard, undefined, 'threats', "activeDevices", "markToUpdate");

        if (checkFlag !== undefined && !checkFlag) {
            return of(dashboard);
        }

        if (this.onListActiveDevicesIds.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListActiveDevicesIds.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onListActiveDevicesIds.next(this.valuesService.processServiceState.INPROGRESS);
            return this.seccenterService.topActiveDevices()
            .pipe(
                map(
                    resp => {
                        this.initActiveDevices();

                        if (resp) {
                            dashboard['threats']['activeDevices']['listIds'] = resp;
                            dashboard['threats']['activeDevices'].markToUpdate = false;
                            this.onListActiveDevicesIds.next(this.valuesService.processServiceState.DONE);
                        } else {
                            dashboard['threats']['activeDevices'].markToUpdate = true;
                            this.onListActiveDevicesIds.next(this.valuesService.processServiceState.DONE);
                            throw resp;
                        }
                        return of(true);
                    }
                ),
                catchError( (err)=> {
                    this.initActiveDevices();
                    dashboard['threats']['activeDevices'].markToUpdate = true;
                    this.onListActiveDevicesIds.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    updateActiveDeviceIds() {
        const checkFlag = this.usefulService.getNested(this.dashboard, false, 'threats', "activeDevices", "markToUpdate");

    }

    initDetails(noDays) {
        const dashboard = this.get();

        if (!dashboard['threats']) {
            dashboard['threats'] = {};
        }
        if (!dashboard['threats']['details']) {
            dashboard['threats']['details'] = {};
        }

        if (!dashboard['threats']['details'][noDays]) {
            dashboard['threats']['details'][noDays] = {};
        }
    }

    addResponse(resp, noDays) {
        const dashboard = this.get();

        if (!dashboard['threats']['details'][noDays]['list']) {
            dashboard['threats']['details'][noDays]['list'] = resp;
        } else {
            dashboard['threats']['details'][noDays]['list'] = dashboard['threats']['details'][noDays]['list'].concat(resp);
        }
    }

    updateThirtyDaysThreats() {
        const dashboard = this.get();
        // daca lista pe 7 zile contine < 10 threats atunci pe 30 de zile trebuie sa aducem 10 threats
        if (dashboard['threats']['details']['thirty']['list'].length < this.dashboardValuesService.countNumber 
        && !dashboard['threats']['details']['thirty'].final) {
            dashboard['threats']['details']['thirty']['list'] = [];
            dashboard['threats']['details']['thirty'].markToUpdate = true;
        }
    }

    listThreatDetails(info): Observable<any> {
        const dashboard = this.get();
        const checkFlag = this.usefulService.getNested(dashboard, undefined, 'threats', "details", info.noDays, "markToUpdate");

        if (checkFlag !== undefined && !checkFlag) {
            return of(dashboard);
        }

        if (this.onListThreats.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListThreats.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onListThreats.next(this.valuesService.processServiceState.INPROGRESS);
            return this.seccenterService.getThreatsDetails(info)
            .pipe(
                map(
                    resp => {
                        if (this.utilsCommonService.checkArray(resp)) {
                            this.initThreats('details', 'seven');
                            this.initThreats('details', 'thirty');
                            this.addResponse(resp, 'seven');
                            this.addResponse(resp, 'thirty');

                            if (!resp.length || resp.length < this.dashboardValuesService.countNumber) {
                                dashboard['threats']['details'][info.noDays].final = true;
                            }

                            dashboard['threats']['details']['seven'].markToUpdate = false;
                            dashboard['threats']['details']['thirty'].markToUpdate = false;

                            this.updateThirtyDaysThreats();

                            this.onListThreats.next(this.valuesService.processServiceState.DONE);
                        } else {
                            dashboard['threats']['details'][info.noDays].markToUpdate = true;
                            this.onListThreats.next(this.valuesService.processServiceState.DONE);
                            throw(new Error('malformed request'));
                        }
                    }
                ),
                catchError( (err)=> {
                    this.initThreats('details', info.noDays);
                    dashboard['threats']['details'][info.noDays].markToUpdate = true;
                    this.onListThreats.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    get() {
        return this.dashboard;
    }

    getThreatsInterface() {
        return this.dashboard['threats']['interface'];
    }

    getDevicesInterface() {
        return this.dashboard['devices']['interface'];
    }

    getSecurityBundles() {
        return this.dashboard['recommendations']['interface']['firstCard'];
    }

    getUpgradeBundles() {
        return this.dashboard['recommendations']['interface']['secondCard'];
    }

    getVpnBundles() {
        return this.dashboard['recommendations']['interface']['thirdCard'];
    }

    updateThreatsDetails(noDays) {
        if (this.onListThreats.value !== this.valuesService.processServiceState.INPROGRESS) {
            const dashboard = this.get();
            dashboard['threats']['details'][noDays].markToUpdate = true;
        }
    }

    updateAllThreats() {
        let seven = this.usefulService.getNested(this.dashboard, {}, 'threats', "details", 'seven');
        let thirty = this.usefulService.getNested(this.dashboard, {}, 'threats', "details", 'thirty');
        seven.markToUpdate = true;
        thirty.markToUpdate = true;
    }

    updateActiveDevicesIds() {
        let activeDevices = this.usefulService.getNested(this.dashboard, {}, 'threats', "activeDevices");
        if (this.onListActiveDevicesIds.value !== this.valuesService.processServiceState.INPROGRESS) {
            activeDevices.markToUpdate = true;
        }
    }

}
