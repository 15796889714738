// External
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// Internal
import { ConfigService } from 'src/app/common/config/config.service';
import { ValuesService } from 'src/app/common/values/values.service';

@Injectable({
    providedIn: 'root'
})
export class HideComponentService {

    visibleNavMenu: boolean = true;
    visibleSideMenu: boolean = true;
    languages = ['en_US', 'en_AU', 'en_GB', 'de_DE', 'fr_FR', 'es_ES', 'ro_RO'];
    invokeEventSetTabs: Subject<any> = new Subject();

    constructor(
        private configService: ConfigService,
        private cookiesService: CookieService,
        private valuesService: ValuesService,
    ) {
        this.visibleSideMenu = false;
        this.visibleNavMenu = true;
    }

    hideNavMenu() { this.visibleNavMenu = false; }

    hideSideMenu() { this.visibleSideMenu = false; }

    showNavMenu() { this.visibleNavMenu = true; }

    showSideMenu() { setTimeout(() => { this.visibleSideMenu = true; }, 0); }

    toggleNavMenu() { this.visibleNavMenu = !this.visibleNavMenu; }

    toggleSideMenu() { this.visibleSideMenu = !this.visibleSideMenu; }

    hideTabsSideMenu(userDetails) {
        this.invokeEventSetTabs.next(userDetails);
    }
}
