<connection-status *ngIf="canShowServiceWorkerBannerForPublicPages && serviceWorkerHelperService.showServiceWorkerBanner()"
    [broken]="serviceWorkerHelperService.brokenServiceWorker()"
    [offline]="handleUnrecoverableStateService.thereAreConnectivityProblems()"
    [newVersion]="serviceWorkerHelperService.newVersion()"
    bg="bg-a8-10">
</connection-status>

<router-outlet></router-outlet>

<!-- * Modal Lazy Without Routes -->
<ui-modal-routeless></ui-modal-routeless>

<ui-toast></ui-toast>
<ui-tooltip *ngFor="let tooltip of tooltipService.tooltipsList" [title]="tooltip.title" [ref]="tooltip.ref"
    [position]="tooltip.position" [color]="tooltip.color" [width]="tooltip.width"></ui-tooltip>
