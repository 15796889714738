import { BehaviorSubject } from "rxjs";
import { ThreatInterface, ThreatTypes } from "../Threats.model";
import { WebmailProtectionInbox, WebmailProtectionInboxActivity, WebmailProtectionInboxSummary, WebmailProtectionSummary } from "./WebmailProtection.model";

export interface SecurityTabInterface {
    id: string;
    label?: string;
    path: string;
    hasNewBadge?: boolean;
    name?: () => string;
    show: () => boolean;
}

export type SecurityTabsInterface = Array<SecurityTabInterface>;

export namespace Security {

    export namespace EmailProtection {
        export type TooltipTexts = {
            [key in ThreatTypes]?: string
        };

        export interface ChartMaxValues {
            yAxisValue: number,
            xAxisValue: number
        };

        export interface InboxesProcessLayer {
            markToUpdate: boolean,
            onList: BehaviorSubject<string>,
            data: WebmailProtectionInbox[],
            error: boolean
        };

        export interface InboxesActivityProcessLayer {
            markToUpdate: boolean,
            onList: BehaviorSubject<string>,
            data: WebmailProtectionInboxActivity[],
            error: boolean
        };

        export interface InboxesActivityProcessLayer {
            markToUpdate: boolean,
            onList: BehaviorSubject<string>,
            data: WebmailProtectionInboxActivity[],
            error: boolean
        };

        export interface SummayProcessLayer {
            onList: BehaviorSubject<string>,
            byDay: WebmailProtectionSummary[],
            byInbox: WebmailProtectionInboxSummary[],
            error: boolean
        };
    }
    export namespace Overview {

        export enum Filter {
            LAST_7D  = 7,
            LAST_30D = 30
        };

        export interface ThreatsGroupedByTime {
            labels?: any[],
            threatsPerDay?: any[],
            threatsPerWeek?: number[],
            max?: number
            days?: DaysThreats,
            daysArray?: DayThreats[],
            weeks?: WeekThreats[],
            total?: number,
        };

        export interface WeekThreats {
            label: string,
            totalThreats: number,
            days: DayThreats[]
        }

        export interface DayThreats {
            threatsInfoPerDay: DayThreatsInfo,
            totalThreats: number,
            date: string,
            label?: string
        }

        export interface DayThreatsInfo {
            [key: string]: number
        }

        export interface DaysThreats {
            [key: string] : DayThreats
        }

        export type ThreatsProcessLayer = {
            [Key in Filter]: {
                markToUpdate: boolean,
                onList: BehaviorSubject<string>,
                data: any[],
                error: boolean
            }
        };

        export type ThreatsListInterfaceLayer = {
            [Key in Filter]: ThreatInterface[]
        };

        export type ThreatsGroupedByTimeInterfaceLayer = {
            [Key in Filter]: ThreatsGroupedByTime
        };

        export interface WeekTooltip {
            title: string,
            moreThreats: string,
            oneThreat: string
        };
    }
}