export interface Threat {
    app_id: string,
    blocked: number,
    date: number
    device_id: string,
    object: any,
    object_type: string
    router_id: string,
    threat: string,
    threat_subtype: string
    threat_type: string
    timestamp: number,
    fileName: string,
    _id: string
};

export interface ThreatInterface {
    text: object,

    threatType   : string,
    threatSubtype: string,
    objectType   : string,
    object       : any,

    ip           : string,
    lanInitiated : string,
    srcIp        : string,
    protocol     : string,
    port         : string,
    extPort      : string,
    malware      : string,
    fileName     : string,
    url          : string,

    device: {
        boxName: string,
        name: string,
        protectionStatus: number,
        icon: string,
        onBox: boolean,
    },
    date: {
        timestamp: number
    },

    appOrFile?: string
}

export enum ThreatTypes {
    DANGEROUS = 'dangerous',
    MALWARE = 'malware',
    PHISHING = 'phishing',
    FRAUD = 'fraud',
    PUA = 'pua',
    RANSOMWARE = 'ransomware',
    CERTIFICATE = 'certificate',
    UNTRUSTED = 'untrusted',
    EXPLOIT = 'exploit',
    ATTACK = 'attack',
    PRIVACY = 'privacy',
    ANOMALY = 'anomaly',
    BOT = 'bot',
    SPAM = 'spam',
    MINER = 'miner',
    TROJAN = 'trojan',
    MALICIOUS_HOST = 'malicious_host',
    INFECTED = 'infected',
    DEFAULT = 'default'
}

export enum ThreatSubtypes {
    URL = 'url',
    APP = 'app',
    BOX = 'box',
    BMS = 'bms',
    LFI = 'lfi',
    CMD = 'cmd',
    SQL = 'sql',
    FILE = 'file',
    DATA = 'data',
    LOGIN = 'login',
    SYSTEM = 'system',
    GENERIC ='generic',
    BANKING = 'banking',
    SCANNING = 'scanning',
    CRITICAL = 'critical',
    PASSWORD = 'password',
    TRAVERSAL = 'traversal',
    EXTENSION = 'extension',
    BRUTEFORCE = 'bruteforce',
    UNTRUSTED_ROOT = 'untrusted_root',
    BRUTEFORCE_HTTP = 'bruteforce_http',
    CERTIFICATE_ERROR = 'certificate_error',
    CERTIFICATE_EXPIRED = 'certificate_expired',
    CERTIFICATE_REVOKED = 'certificate_revoked',
    PROCESS_MEMORY_SCAN = 'process_memory_scan',
    CERTIFICATE_WRONG_HOST = 'certificate_wrong_host',
    CERTIFICATE_SELF_SIGNED = 'certificate_self_signed',
    DEFAULT = 'default'
}

export enum ThreatSubSubtypes {
    LFI = 'lfi',
    DOS = 'dos',
    AUTH = 'auth',
    BRUTE = 'brute',
    UNSAFE = 'unsafe',
    TROJAN = 'trojan',
    BANKING = 'banking',
    EXPLOIT = 'exploit',
    SCANNING = 'scanning',
    METASPLOIT = 'metasploit',
    AUTH_BYPASS = 'auth_bypass',
    CERTIFICATE_EXPIRED = 'certificate_expired',
    CIPHER_SUITE_INSECURE = 'cipher_suite_insecure',
    KEY_EXCHANGE_INSECURE = 'key_exchange_insecure',
    CERTIFICATE_WRONG_HOST = 'certificate_wrong_host',
    DEFAULT = 'default'
}

export enum ThreatSubSubtypeDOS {
    FALSE = 'false',
    TRUE = 'true'
}

type ThreatsSubCounter = {
    [key in ThreatTypes]: number
}

export type ThreatsCounter = {[key in ThreatTypes]: ThreatsSubCounter} & {date: string};

export interface ThreatsGetDetailsRequestParameters {
    count: number,
    to_date: number
    from_date: number,
    offset?: number
}
