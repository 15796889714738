// External
import { CookieService } from "ngx-cookie-service";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { tv4 } from "tv4";

// Internal
import { RequestsService } from "../../global/request-service/requests.service";
import { schemas } from "../../../../common/models/schemas";
import { ValuesService } from "../../../../common/values/values.service";
import { UtilsCommonService } from "../../../utils/utils-common.service";
import { ConfigService } from "src/app/common/config/config.service";
import { map, catchError } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class ConnectUserInfoService {
    constructor(
        private cookieService: CookieService,
        private requestService: RequestsService,
        private utilsCommon: UtilsCommonService,
        private router: Router,
        private valuesService: ValuesService,
        private configService: ConfigService
    ) { }

    /**
     * Function that creates a new profile with certain information
     * @param {object} profile Profile that will be created. Property `first_name` is mandatory
     * @example profile_create( {first_name: 'Alex' } )
     * @returns Status from server if the profile was created or not
     */
    profile_create(profile): Observable<any> {
        if (this.utilsCommon.isEmptyObject(profile)) {
            console.error("Invalid params", tv4.error);
            return throwError("Invalid params");
        }

        let json: any = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "profiles_create",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                first_name: profile.first_name
            }
        };

        if (profile.type) {
            json.params.type = profile.type;
        }

        if (profile.profile_pic) {
            json.params.profile_pic = profile.profile_pic;
        }

        if (profile.profile_pic_id) {
            json.params.profile_pic_id = profile.profile_pic_id;
        }

        if (profile.type) {
            json.params.type = profile.type;
        } else {
            json.params.type = "user";
        }

        if (profile.gender) {
            json.params.gender = profile.gender;
        } else {
            json.params.gender = "u";
        }

        if (profile.birthday) {
            json.params.birthday = profile.birthday;
        } else {
            json.params.birthday = "";
        }

        if (profile.phone) {
            json.params.phone = profile.phone;
        }

        if (profile.email) {
            json.params.email = profile.email;
        }

        if (profile.last_name) {
            json.params.last_name = profile.last_name;
        }

        if (profile.profile_created) {
            json.params.profile_created = profile.profile_created;
        }

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.userinfoMgmtService,
            json,
            "POST"
        ).pipe(
            map((resp) => {
                if (resp.result) {
                    return resp.result;
                } else {
                    throw resp;
                }
            }),
            catchError((error) => {
                    return throwError(error);
                }
            )
        );
    }

    account_profile_set(data): Observable<any> {

        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "setInfo",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                info: data
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.userinfoMgmtService,
            json,
            "POST"
        ).pipe(
            map((resp) => {
                return resp.result;
            }),
            catchError((error) => {
                    return throwError(error);
                }
            )
        );

    };

    /**
     * Function that modifies an existing profile
     * @param {object} profile Profile that will be created. Property `first_name` and `profile_id` are mandatory
     * @example profile_edit( {first_name: 'Alex', profile_id: '<uuid>' } )
     * @returns Status from server if the profile was edited or not
     */
    profile_edit(profile): Observable<any> {
        if (!tv4.validate(profile, schemas.schemaEditProfile)) {
            if (!(tv4.error && tv4.error.dataPath === "/phone" && tv4.error.params && tv4.error.params.pattern && !profile.phone)
                && !(tv4.error && tv4.error.dataPath === "/email" && tv4.error.params && tv4.error.params.pattern && !profile.email)
                && !(tv4.error && tv4.error.dataPath === "/gender" && tv4.error.params && tv4.error.params.length && !profile.gender)
                && !(profile.birthday === "" && profile.type === "user")) {
                    return throwError("Invalid params");
            }
        }

        let json: any = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "profiles_set",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                profile_id: profile.profile_id,
                first_name: profile.first_name
            }
        };

        if (profile.profile_pic || profile.hasOwnProperty("profile_pic")) {
            json.params.profile_pic = profile.profile_pic;
        }

        if (profile.profile_pic_id) {
            json.params.profile_pic_id = profile.profile_pic_id;
        }

        if (profile.type) {
            json.params.type = profile.type;
        } else {
            json.params.type = "user";
        }

        if (profile.gender) {
            json.params.gender = profile.gender;
        } else {
            json.params.gender = "u";
        }

        if (profile.birthday || profile.hasOwnProperty("birthday")) {
            json.params.birthday = profile.birthday;
        } else {
            json.params.birthday = "";
        }

        if (profile.phone || profile.hasOwnProperty("phone")) {
            json.params.phone = profile.phone;
        }

        if (profile.email || profile.hasOwnProperty("email")) {
            json.params.email = profile.email;
        }

        if (profile.last_name) {
            json.params.last_name = profile.last_name;
        }

        if (profile.profile_created) {
            json.params.profile_created = profile.profile_created;
        }

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.userinfoMgmtService,
            json,
            "POST"
        ).pipe(
            map((resp) => {
                if (resp.result) {
                    return resp.result;
                } else {
                    throw resp;
                }
            }),
            catchError((error) => {
                    throw error;
                }
            )
        );
    }

    editProfile(profile): Observable<any> {
        let json: any = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "profiles_set",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };

        json.params = {...json.params, ...profile};

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.userinfoMgmtService,
            json,
            "POST"
        ).pipe(
            map((resp) => {
                if (resp.result) {
                    return resp.result;
                } else {
                    throw resp;
                }
            }),
            catchError((error) => {
                    throw error;
                }
            )
        );
    }

    /**
     * Function that retrieves an existing profile
     * @param {object} profile_id Profile that will be created.
     * @returns Response from server with the specific profile.
     */
    profile_get(profile_id) {
        if (!profile_id) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }

        if (this.utilsCommon.checkArray(profile_id)) {
            let json: any = [];
            for (let i in profile_id) {
                json = {
                    id: parseInt((Math.random() * 100).toString(), 10),
                    jsonrpc: this.valuesService.jsonrpc,
                    method: "profiles_get",
                    params: {
                        connect_source: {
                            user_token: this.cookieService.get(
                                this.valuesService.cookieToken
                            ),
                            device_id: this.valuesService.connectDeviceId,
                            app_id: this.valuesService.connectAppId
                        },
                        profile_id: profile_id[i]
                    }
                };
            }
            return this.requestService.make(
                this.configService.config.nimbusServer,
                this.valuesService.userinfoMgmtService,
                json,
                "POST"
            );
        } else {
            let json: any = {
                id: parseInt((Math.random() * 100).toString(), 10),
                jsonrpc: this.valuesService.jsonrpc,
                method: "profiles_get",
                params: {
                    connect_source: {
                        user_token: this.cookieService.get(this.valuesService.cookieToken),
                        device_id: this.valuesService.connectDeviceId,
                        app_id: this.valuesService.connectAppId
                    },
                    profile_id: profile_id
                }
            };

            return this.requestService.make(
                this.configService.config.nimbusServer,
                this.valuesService.userinfoMgmtService,
                json,
                "POST"
            );
        }
    }

    /**
     * Function that retrieves all existing profiles on account
     * @returns Response from server with the profiles.
     */
    profiles_list() {
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "profiles_list",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.userinfoMgmtService,
            json,
            "POST"
        ).pipe(
            map(
                (res: any) => {
                    return res.result;
                }
            ),
            catchError((error) => {
                throw error;
            }),
        )
    }

    /**
     * Function that retrieves active profile on device
     * @param {object} device_id Device for which you need the profile_id.
     * @returns Response from server with the specific profile.
     */
    profile_get_active(device_id) {
        if (!tv4.validate(device_id, schemas.schemaUuid)) {
            return of("Invalid params");
        }

        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "profile_get_active",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: device_id
                }
            }
        };
        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.userinfoMgmtService,
            json,
            "POST"
        );
    }

    /**
     * Function that sets active profile on device
     * @param {object} Contains `profile_id` and `device_id` that are mandatory
     * @returns Response from server with the status of action.
     */
    profileSetActive(opt) {
        if (!tv4.validate(opt, schemas.schemaProfileSetActive)) {
            return throwError("Invalid params");
        }

        let json: any = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "profile_set_active",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: opt.device_id
                },
                profile_id: opt.profile_id
            }
        };

        if (opt.device_account_sid) {
            json.params.device_account_sid = opt.device_account_sid;
        }

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.userinfoMgmtService,
            json,
            "POST"
        ).pipe(
            map((resp) => {
                if (resp?.result?.status === 0) {
                    return resp.result;
                }
                throw resp;
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    /**
     * Function that sets inactive profile on device
     * @param {object} Contains `profile_id` and `device_id` that are mandatory
     * @returns Response from server with the status of action.
     */
    profile_set_inactive(opt) {
        if (!tv4.validate(opt, schemas.schemaProfileSetActive)) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }

        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "profile_set_inactive",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: opt.device_id
                },
                profile_id: opt.profile_id
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.userinfoMgmtService,
            json,
            "POST"
        ).pipe(
            map((resp) => {
                if (resp?.result?.status === 0) {
                    return resp.result;
                }
                throw resp;
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    /**
     * Function that removes a profile
     * @param {uuid} profile_id
     * @returns Response from server with the status of action.
     */
    remove_profile(profile_id) {
        if (!tv4.validate(profile_id, schemas.schemaUuid)) {
            console.error("Invalid params", tv4.error);
            return throwError("Invalid params");
        }

        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "profiles_rm",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                profile_id: profile_id
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.userinfoMgmtService,
            json,
            "POST"
        ).pipe(
            map((resp) => {
                if (resp?.result) {
                    return resp.result;
                } else {
                    throw resp;
                }
            }),
            catchError((error) => {
                    throw error;
                }
            )
        );
    }

    getInfoUser(): Observable<any> {
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "getInfo",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.userinfoMgmtService,
            json,
            "POST"
        ).pipe(
            map(
                (res: any) => {
                    return res.result;
                }
            ),
            catchError((error) => {
                console.error("Error mgmt >> dupa req", error);
                throw error;
            })
        )
    }


    /**
     * This function calls 'get_invoices' to 'connect/user_info' and gets all the invoices
     * @return {Observable<Object>} an array of objects (invoices)
     */
    getInvoices(): Observable<Object> {
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "get_invoices",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.userinfoMgmtService,
            json,
            "POST"
        ).pipe(
            map(
                (res: any) => {
                    return res.result;
                }
            ),
            catchError((error) => {
                console.error("Error mgmt >> dupa req", error);
                throw error;
            })
        )
    }

    /**
     * Gets invoice file
     * @param invoiceId 
     */
    getInvoiceFile(invoiceId: string): Observable<any> {
        let json = {
            id: 1,
            jsonrpc: this.valuesService.jsonrpc,
            method: "get_invoice_file",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                invoice_id: invoiceId
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.userinfoMgmtService,
            json,
            "POST",
            'blob'
        ).pipe(
            map(
                (res: any) => {
                    return res;
                }
            ),
            catchError((error) => {
                console.error("Error mgmt >> dupa req", error);
                throw error;
            })
        );
    }

    getCreditMemos(): Observable<Object> {
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "get_credit_memos",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.userinfoMgmtService,
            json,
            "POST"
        ).pipe(
            map(
                (res: any) => {
                    return res.result;
                }
            ),
            catchError((error) => {
                console.error("Error mgmt >> dupa req", error);
                throw error;
            })
        )
    }

    getCreditMemoFile(creditMemoId: string): Observable<any> {
        let json = {
            id: 1,
            jsonrpc: this.valuesService.jsonrpc,
            method: "get_credit_memo_file",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                credit_memo_id: creditMemoId
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.userinfoMgmtService,
            json,
            "POST",
            'blob'
        ).pipe(
            map(
                (res: any) => {
                    return res;
                }
            ),
            catchError((error) => {
                console.error("Error mgmt >> dupa req", error);
                throw error;
            })
        );
    }
}