import { Injectable } from '@angular/core';
import { CookieService } from "ngx-cookie-service";
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ConfigService } from '../../../config/config.service';
import { ValuesService } from '../../../values/values.service';
import { RequestsService } from '../../global/request-service/requests.service';
import {
    IParentalNccOptions,
    ParentalNccMgmtOptions,
    IUpdateDtl,
    parentalNccApiSettingsFields,
    IUpdateException,
    IUpdateRoutine,
    IUpdateReplacements,
    Filters,
    NccBlockedContentType } from '../../../models/parental-ncc/ParentalNcc.model';
@Injectable({
  providedIn: 'root'
})
export class ParentalcontrolNccMgmtService {

    constructor(
        private readonly cookieService: CookieService,
        private readonly requestService: RequestsService,
        private readonly valuesService: ValuesService,
        private readonly configService: ConfigService
    ) {}

    //#region General
    generateJson (method: string, params?: {} ): object {

        const counter  = parseInt((Math.random() * 1000).toString(), 10);
        return {
            id: counter,
            jsonrpc: this.valuesService.jsonrpc,
            method: method,
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: this.valuesService.appPANCC,
                    device_id: this.valuesService.connectDeviceId
                },
                ...params
            }
        };
    }

    makeRequest (options: IParentalNccOptions): Observable<any> {
        const jsonBody = this.generateJson(options.method, options.params);

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalControlNccManagement,
            jsonBody,
            "POST"
        ).pipe(
            map((resp: any) => {
                    if (resp?.result) {
                        return resp.result;
                    } else {
                        throw resp;
                    }
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }
    //#endregion

    //#region  Profile Stats
    getProfileStats (profileId) : Observable<any> {
        const options = ParentalNccMgmtOptions.getProfileStats(profileId);
        return this.makeRequest(options);
    }

    getTimeSpentToday(profileId: string): Observable<any> {
        const options = ParentalNccMgmtOptions.getTimeSpentToday(profileId);
        return this.makeRequest(options);
    }
    //#endregion

    //#region Rewards
    getMoreTimeRequests(profileId: string): Observable<any> {
        const options = ParentalNccMgmtOptions.getMoreTimeRequests(profileId);
        return this.makeRequest(options);
    }

    grantMoreTime(profileId: string, grantedTime: number): Observable<any> {
        const options = ParentalNccMgmtOptions.grantMoreTime(profileId, grantedTime);
        return this.makeRequest(options);
    }
    //#endregion

    //#region Devices & Location
    getProfileLocations(profileId: string): Observable<any> {
        const options = ParentalNccMgmtOptions.getProfileLocations(profileId);
        return this.makeRequest(options);
    }

    getDeviceLocation(profileId: string, deviceId: string): Observable<any> {
        const options = ParentalNccMgmtOptions.getDeviceLocation(profileId, deviceId);
        return this.makeRequest(options);
    }

    getDevicesStatus(profileId: string): Observable<any> {
        const options = ParentalNccMgmtOptions.getDevicesStatus(profileId);
        return this.makeRequest(options);
    }
    //#endregion

    //#region Settings (One ore more fields)
    getSettings(profileId: string, settings: parentalNccApiSettingsFields[]): Observable<any> {
        const options = ParentalNccMgmtOptions.getSettings(profileId, settings);
        return this.makeRequest(options);
    }
    //#endregion

    //#region DTL
    getDtl(profileId: string): Observable<any> {
        const options = ParentalNccMgmtOptions.getDtl(profileId);
        return this.makeRequest(options);
    }

    updateDtl(profileId: string, update: IUpdateDtl[]): Observable<any> {
        const options = ParentalNccMgmtOptions.updateDtl(profileId, update);
        return this.makeRequest(options);
    }

    setDtlStatus(profileId: string, enable: boolean): Observable<any> {
        const options = ParentalNccMgmtOptions.setDtlStatus(profileId, enable);
        return this.makeRequest(options);
    }

    removeLimitDtl(profileId: string, day: number): Observable<any> {
        const options = ParentalNccMgmtOptions.removeLimitDtl(profileId, day);
        return this.makeRequest(options);
    }

    recomandedAllDaysDtl(profileId: string): Observable<any> {
        const options = ParentalNccMgmtOptions.recomandedAllDaysDtl(profileId);
        return this.makeRequest(options);
    }
    //#endregion

    //#region Replacements (Youtube | Google)
    getReplacements(profileId: string): Observable<any> {
        const options = ParentalNccMgmtOptions.getReplacements(profileId);
        return this.makeRequest(options);
    }

    updateReplacements(profileId: string, update: IUpdateReplacements[] ): Observable<any> {
        const options = ParentalNccMgmtOptions.updateReplacements(profileId, update);
        return this.makeRequest(options);
    }
    //#endregion

    //#region Exceptions (Categories, Topics, Websites and BlockAll)
    getExceptions(profileId: string): Observable<any> {
        const options = ParentalNccMgmtOptions.getExceptions(profileId);
        return this.makeRequest(options);
    }

    updateExceptions(profileId: string, updates: IUpdateException[], routineType?: string): Observable<any> {
        let options;
        if (routineType) {
            options = ParentalNccMgmtOptions.updateRoutinesException(profileId, routineType, updates);
        } else {
            options = ParentalNccMgmtOptions.updateExceptions(profileId, updates);
        }
        return this.makeRequest(options);
    }

    resetExceptions(profileId: string, routineType?: string): Observable<any> {
        let options;
        if (routineType) {
            options = ParentalNccMgmtOptions.resetRoutineExceptions(profileId, routineType);
        } else {
            options = ParentalNccMgmtOptions.resetExceptions(profileId);
        }
        return this.makeRequest(options);
    }

    blockAllInternet(profileId: string, enable: boolean, routineType?: string): Observable<any> {
        let options;
        if (routineType) {
            options = ParentalNccMgmtOptions.setRoutinesBlockAll(profileId, routineType, enable);
        } else {
            options = ParentalNccMgmtOptions.setExceptionsblockAll(profileId, enable);
        }
        return this.makeRequest(options);
    }
    //#endregion

    //#region Routines
    getRoutines(profileId: string): Observable<any> {
        const options = ParentalNccMgmtOptions.getRoutines(profileId);
        return this.makeRequest(options);
    }

    setRoutinesStatus(profileId: string, routine: string, enabled: boolean): Observable<any> {
        const options = ParentalNccMgmtOptions.setRoutinesStatus(profileId, routine, enabled);
        return this.makeRequest(options);
    }

    updateRoutinesSchedule(profileId: string, routine: string, updates: IUpdateRoutine[]): Observable<any> {
        const options = ParentalNccMgmtOptions.updateRoutinesSchedule(profileId, routine, updates);
        return this.makeRequest(options);
    }

    setRoutinesblockAl(profileId: string, routine: string, blockAll: boolean): Observable<any> {
        const options = ParentalNccMgmtOptions.setRoutinesBlockAll(profileId, routine, blockAll);
        return this.makeRequest(options);
    }
    //#endregion

    //#region Generic Info (Categories, Topics and Hosts)
    getGenericInfo(profileId: string, fields: parentalNccApiSettingsFields[]): Observable<any> {
        const options = ParentalNccMgmtOptions.getGenericInfo(profileId, fields);
        return this.makeRequest(options);
    }

    getHostInfo(host: string): Observable<any> {
        const options = ParentalNccMgmtOptions.getHostInfo(host);
        return this.makeRequest(options);
    }
    //#endregion

    //#region Blocked Content
    /**
     *
     * Get Api methods options for dasboard card request
     * @param {string} profileId
     * @param {Filters} period
     * @param {NccBlockedContentType} type
     * @return {*}  {Observable<any>}
     * @memberof ParentalcontrolNccMgmtService
     */
    public getTopBlockedContent(profileId: string, period: Filters, type: NccBlockedContentType): Observable<any> {
        let options;
        if (type === NccBlockedContentType.categories) {
            options = ParentalNccMgmtOptions.getTopBlockedCategories(profileId, period);
        } else {
            options = ParentalNccMgmtOptions.getTopBlockedAppsAndWebsites(profileId, period);
        }
        return this.makeRequest(options);
    }

    /**
     *
     * Get Api methods options for Blocked content page
     * @param {string} profileId
     * @param {NccBlockedContentType} type
     * @param {Filters} period
     * @param {number} [page]
     * @param {number} [limit]
     * @return {*}  {Observable<any>}
     * @memberof ParentalcontrolNccMgmtService
     */
    public getAllBlockedContent(profileId: string, type: NccBlockedContentType, period: Filters, page?: number, limit?: number): Observable<any>  {
        let options;
        if (type === NccBlockedContentType.categories) {
            options = ParentalNccMgmtOptions.getAllBlockedCategories(profileId, period);
        } else if (type === NccBlockedContentType.websites) {
            options = ParentalNccMgmtOptions.getAllBlockedWebsites(profileId, period, page, limit);
        } else {
            options = ParentalNccMgmtOptions.getAllBlockedApps(profileId, period, page, limit);
        }
        return this.makeRequest(options);
    }

    /**
     *
     * Get Api methods options for website or app details
     * @param {string} profileId
     * @param {NccBlockedContentType} type
     * @param {string} contentId
     * @param {number} page
     * @param {number} limit
     * @return {*}  {Observable<any>}
     * @memberof ParentalcontrolNccMgmtService
     */
    public getDetailsBlockedContent(profileId: string, type: NccBlockedContentType, contentId: string, page: number, limit: number): Observable<any> {
        let options;
        if (type === NccBlockedContentType.websites) {
            options = ParentalNccMgmtOptions.getDetailsBlockedWebsites(profileId, contentId, page, limit);
        } else {
            options = ParentalNccMgmtOptions.getDetailsBlockedTopics(profileId, contentId, page, limit);
        }
        return this.makeRequest(options);
    }
    //#endregion
}
