// External
import { Injectable } from "@angular/core";
import { Location } from '@angular/common';

// Internal
import { ValuesService } from "src/app/common/values/values.service";
import { ParentalValuesService } from "src/app/common/values/parental.values.service";
import { DevicesService } from "src/app/common/services/process/devices/devices.service";
import { AdobeDataLayerService } from "../../common/services/core/adobe.datalayer.service";

 
@Injectable({
	providedIn: 'root'
})
export class ParentalHelperService {

    lastDate: any;

    constructor(
        private location: Location,
        private readonly adobeDataLayerService: AdobeDataLayerService,
        private valuesService: ValuesService,
        private parentalValuesService: ParentalValuesService,
        private devicesService: DevicesService
    ) { }

    timestampToDailyHours(timestamp_arr) {
        let array: any = [];
        let days: any = {};
        let scheduleObj: any = {};
        let day_s, day_e, h_s, h_e, min_s, min_e: number = 0;
        let format_start_pm, format_end_pm: boolean = false;

        for(let i in timestamp_arr){
            day_s = Math.floor(timestamp_arr[i][0] / 86400);
            day_e =  Math.floor(timestamp_arr[i][1] / 86400);
            h_s =  Math.floor((timestamp_arr[i][0] % 86400) / 3600);
            h_e =  Math.floor((timestamp_arr[i][1] % 86400) / 3600);
            min_s =  Math.floor(((timestamp_arr[i][0] % 86400) % 3600) / 60);
            min_e =  Math.floor(((timestamp_arr[i][1] % 86400) % 3600) / 60);

            array.push({
                start_day: day_s,
                end_day: day_e,
                start_hour: h_s,
                end_hour: h_e,
                start_minute: min_s,
                end_minute: min_e
            });

            if (day_s == 0) {
                days[6] = true;
            } else {
                days[day_s - 1] = true;
            }
        }

        if (array.length > 0) {
            format_start_pm = array[0].start_hour >= 12 ? true : false;
            array[0].start_hour = array[0].start_hour % 12 ? array[0].start_hour % 12 : 12;
            array[0].start_minute = array[0].start_minute < 10 ? '0' + array[0].start_minute : array[0].start_minute;

            format_end_pm = array[0].end_hour >= 12 ? true : false;
            array[0].end_hour =  array[0].end_hour % 12 ? array[0].end_hour % 12 : 12;
            array[0].end_minute = array[0].end_minute < 10 ? '0' + array[0].end_minute : array[0].end_minute;

            scheduleObj = {
                hours: {
                    start_h: array[0].start_hour,
                    start_m: array[0].start_minute,
                    start_pm: format_start_pm,
                    end_h: array[0].end_hour,
                    end_m: array[0].end_minute,
                    end_pm : format_end_pm
                },
                textIndex: days,
            };
        }

        return scheduleObj;
    }

    getArgFromURL(path, index){
        let arrayParams = path.split(/[:/]+/);
        return arrayParams[index] ? arrayParams[index] : false;
    }

    computeIndexDTL(miliseconds) {
        let index = null;
        index = Math.floor(miliseconds/(3600*1000))*6 + (miliseconds%(3600*1000))/(60*1000*10) - 1; // get the index in interval
        index = Math.floor(index);
        return index;
    }

    computeIndexDayFromTimestamp(timestamp) {
        let date = new Date(timestamp*1000);
        return date.getDay();
    }

    computeDateFromTimestamp(timestamp) {
        let dateObj: any = timestamp ? new Date(timestamp*1000): '';
        if (dateObj) {
            this.lastDate = dateObj;
            return { 'day': dateObj.getDate(), 'month': dateObj.getMonth() + 1, 'year': dateObj.getFullYear()};
        } else {
            this.lastDate = new Date(this.lastDate.getFullYear(), this.lastDate.getMonth(), this.lastDate.getDate() + 1);
            return { 'day': this.lastDate.getDate(), 'month': this.lastDate.getMonth() + 1, 'year': this.lastDate.getFullYear() };
        }
    }

    countDevices() {
        let count = 0;
        let parental_devices = this.devicesService.getDevicesList().parental;
        if (parental_devices.length) {
            for (let device of parental_devices) {
                if (device.apps) {
                    for(let app of device.apps) {
                        if (app["app_id"] === this.valuesService.bundlePA && app.state === 1) {
                            count++;
                            break;
                        }
                    }
                }
            }
        }
        return {
            "all": parental_devices.length ? parental_devices.length : '',
            "pa": count
        };
    }

    computeTime(roundType, time, needSeconds?) {
        let hours   = Math.floor(time / 3600);
        let minutes = Math.floor(time % 3600 / 60);
        let seconds = Math.floor(time % 3600 % 60);

        if (needSeconds) {
            return {"hours": hours, "minutes": minutes, "seconds": seconds};
        }

       
        if (seconds > 0) {
            if (roundType === "ceil") {
                minutes += 1;
                if (minutes === 60) {
                    minutes = 0;
                    hours += 1;
                }
            }
        }
        return {"hours": hours, "minutes": minutes};
    }

    getMaxTimeSpent(reportsArray, prop) {
        let max_time_spent = 0;
        if (reportsArray) {
            for (let i = 0; i < reportsArray.length; i++) {
                if (reportsArray[i][prop] > max_time_spent) {
                    max_time_spent = reportsArray[i][prop];
                }
            }
        }
        return max_time_spent;
    }

    getHashPositionPath() {
        let path = this.location.path();
        let pathArray = path.split('/');
        return pathArray.length === 3 ? 2 : 3;
    }

    getArticleSupport() {
        window.open(this.adobeDataLayerService.addRefAndVisitor(this.parentalValuesService.supportPCArtLink), "_blank");
    }

    // adds minutes to timestamp
    setTimeAfterMin(timestamp, minutes) {
        let date = new Date(timestamp*1000);
        date.setMinutes(date.getMinutes() + minutes);
        return Math.floor(date.getTime()/1000);
    }


    convertDayWeek(timestamp) {
        let date = new Date(timestamp*1000);
        return this.parentalValuesService.daysOfWeek[date.getDay()];
    }

    convertMonth(timestamp) {
        let date = new Date(timestamp*1000);
        return 'parental.screentime.month.' + (date.getMonth()+1);
    }

    convertDay(timestamp) {
        let date = new Date(timestamp*1000);
        return date.getDate();
    }

    convertLabel(timestamp) {
        let date = new Date(timestamp*1000);
        let yesterday = ( d => new Date(d.setDate(d.getDate()-1)) )(new Date);
        if (new Date().setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0)) {
            return 'parental.key.today';
        } else if (yesterday.setHours(0, 0, 0, 0) ===  date.setHours(0, 0, 0, 0)) {
            return 'parental.key.yesterday';
        } else {
            return '';
        }
    }

}