// External
import { Injectable } from '@angular/core';

// Internal
import { DevicesService } from '../../process/devices/devices.service';
import { ProfilesService } from '../../process/profiles/profiles.service';
import { UtilsCommonService } from '../../../../common/utils/utils-common.service';
import { ValuesService } from 'src/app/common/values/values.service';

@Injectable({
    providedIn: 'root'
})

export class IProfilesService {

    mainUser: any = {};

    constructor(
        private readonly devicesService: DevicesService,
        private readonly profilesService: ProfilesService,
        private readonly utilsCommonService: UtilsCommonService,
        private readonly valuesService: ValuesService
    ) { }

    // the profile details are created for each device
    manage_device_profiles() {
        const devices = this.devicesService.getDevicesList().all;
        const profiles = this.profilesService.getAllProfiles();

        if (!devices) {
            return;
        }
        for (let i in devices) {
            let profile: any = {};

            if (this.utilsCommonService.isEmptyObject(profiles) === true) {
                profile.profile_name = '';
                devices[i].processed.device_profile = profile;
            }
            for (let j in profiles) {
               
                if (devices[i].profile_id === undefined || devices[i].profile_id === 0) {
                    profile.profile_name = '';
                    devices[i].processed.device_profile = profile;
                }
                
                if (devices[i].profile_id === profiles[j].profile_id) {
                    profile.profile_id = devices[i].profile_id;
                    profile.profile_name = (profiles[j].first_name !== undefined ? profiles[j].first_name : '');
                    profile.profile_pic = profiles[j].profile_pic;
                    profile.type = profiles[j].type;
                    devices[i].processed.device_profile = {...profile};
                }
            }

            if (!devices[i].processed.hasOwnProperty('device_profile')) {
              devices[i].processed.device_profile = {};
            }
        }
    }

    // create interface for profile page
    createProfileInterface() {
        const owner = this.profilesService.getOwner();
        if(!owner.hasOwnProperty('interface')){
            owner.interface = {}
        }    
        owner.interface.lang = owner.lang ? this.valuesService.languages[owner.lang].value : null;
        owner.interface.fname = owner.firstname ? owner.firstname : "";
        owner.interface.email = owner.email;
        owner.interface.birthdate = owner.birthdate ? owner.birthdate : null;
        owner.interface.phone = owner.phone ? owner.phone : "";
        owner.interface.country = owner.country ? this.valuesService.countries.indexOf(this.valuesService.countriesISO[owner.country]) : null;
        owner.interface.city = owner.city ? owner.city : "";
        owner.interface.address = owner.address ? owner.address : "";
    }

}
