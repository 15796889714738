// External
import { NgModule } from '@angular/core';
import { UiModalRoutelessComponent } from './ui-modal-routeless.component';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../../directives.module';
import { UiIconModule } from '../ui-icon/ui-icon.module';

// Internal


@NgModule({
    declarations: [
        UiModalRoutelessComponent,
    ],
    imports: [
        CommonModule,
        DirectivesModule,
        UiIconModule
    ],
    exports: [ UiModalRoutelessComponent ]
})

export class UiModalRoutelessModule {}
