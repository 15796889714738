//External
import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { catchError, map, mergeMap, Observable, of, Subject, takeUntil } from 'rxjs';

// Internal
import { ModalName, ModalRoutelessService } from '../../../../common/components/ui/ui-modal-routeless/modal.routeless.service';
import { AppsConfigService } from '../../../../common/config/apps.config.service';
import { ConfigService } from '../../../../common/config/config.service';
import { UtilsCommonService } from '../../../../common/utils/utils-common.service';
import { ReferralValuesService } from '../../../../common/values/referral.values.service';
import { QueryParamsValues, SubscriptionsValuesService } from '../../../../common/values/subscriptions.values.service';
import { ProductsToInstall, ValuesService } from '../../../../common/values/values.service';
import { MessageService } from '../../core/message.service';
import { QueryParamsService } from '../../core/query.params.service';
import { UrlProperties } from '../../guards/models/UrlProperties.model';
import { DevicesService } from '../../process/devices/devices.service';
import { ProfilesService } from '../../process/profiles/profiles.service';
import { SettingsService } from '../../process/settings/settings.service';
import { RedeemService } from '../../process/subscriptions/redeem.service';
import { SubscriptionsService } from '../../process/subscriptions/subscriptions.service';
import { SubscriptionsTrialService } from '../../process/subscriptions/subscriptionTrial.service';
import { ConnectReferralService } from '../../requests/connect-referral/connect-referral.service';
import { False, ModalAction } from './models/special.cases.model';
import { SubscriptionInviteService } from '../../requests/subscription-invite-service/subscription-invite.service';
import { ModalSize, AllModalInformation, ModalContainerOptions, ModalContentOptionsError } from '../../global/modal/Modal.model';
import { InvitesService } from '../../process/subscriptions/invites.service';
import { CommercialIdsMgmtService } from '../../requests/connect-commercial-ids-service/connect-commercial-ids.service';
import { BundleModel, RedeemDataModel } from '../../../models/Services.model';
import { ConversionStatus, SecuredPaymentScenarios } from '../../../models/subscriptions/Subscriptions.model';
import { ConnectGroupMgmtService } from '../../requests/connect-group-mgmt/connect-group-mgmg.service';
import { ContextBubbleLocalStorage, Group, GroupTypes, OpenInstallModal } from '../../../../common/models/subscriptions/Groups.model';
import { ContextService, SwitchContextResponse } from '../../global/context/context.service';
import { BrowserBooleanValues } from '../../../../common/models/Core.model';

@Injectable({
    providedIn: 'root'
})

export class SpecialCasesService {

    private readonly onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private readonly profilesService: ProfilesService,
        private readonly valuesService: ValuesService,
        private readonly configService: ConfigService,
        private readonly subscriptionsService: SubscriptionsService,
        private readonly settingsService: SettingsService,
        private readonly modalRoutelessService: ModalRoutelessService,
        private readonly appsConfigService: AppsConfigService,
        private readonly queryParamsService: QueryParamsService,
        private readonly connectReferralService: ConnectReferralService,
        private readonly referralValuesService: ReferralValuesService,
        private readonly messageService: MessageService,
        private readonly redeemService: RedeemService,
        private readonly inviteService: InvitesService,
        private readonly utilsCommonService: UtilsCommonService,
        private readonly subscriptionsValuesService: SubscriptionsValuesService,
        private readonly subscriptionsTrialService: SubscriptionsTrialService,
        private readonly subscriptionInviteService: SubscriptionInviteService,
        private readonly commercialIdsMgmtService: CommercialIdsMgmtService,
        private readonly devicesService: DevicesService,
        private readonly connectGroupMgmtService: ConnectGroupMgmtService,
        private readonly contextService: ContextService
    ) {}

    /**
     * This function will decide if the account is MSP type and the specific modals will be shown
     * 1. Daca are msp lvl 1, e pe dashboard si e prima oara pe cont -> afisam onboarding
     * 2. Daca are msp lvl 1, e pe dashboard si nu e prima oara pe cont dar are sub -> afisam install
     * 3. Daca are msp lvl 1, e pe dashboard si nu e prima oara pe cont dar nu are sub -> afisam onboarding
     * 4. Daca are msp lvl 2, e prima oara pe cont si are subscriptie managed -> afisam onboarding de msp
     * 5. Daca are msp lvl 2, e prima oara pe cont si nu are subscriptie managed -> nu afisam nimic
     * @param {UrlProperties} urlProperties
     * @return {*}  {(Observable<boolean|UrlTree>)}
     */
    public _decideMspXspState(urlProperties: UrlProperties): Observable<False|ModalAction> {
        return new Observable( subscriber => {
            const hasMspOrXspLevelOne   = this.profilesService.hasMspOrXspLevelOne();
            const hasMspOrXspLevelTwo   = this.profilesService.hasMspOrXspLevelTwo();
            const firstPathSegment      = `/${urlProperties.pathPieces[0]}`;
            const dashboardPath         = this.valuesService.centralPaths.dashboard.path;
            const hasOnboardingMSP      = this.configService.config.onboardingMSP;
            const hasSubscription       = this.subscriptionsService.getFilteredActiveBundles().length;
            const isFirstLogin          = this.settingsService.isFirstLogin();
            const storageFirstLoginMade = sessionStorage.getItem(this.valuesService.sessionStorageModalStatus.firstLoginMade);

            if (hasMspOrXspLevelOne && firstPathSegment === dashboardPath) {
                if (isFirstLogin && hasOnboardingMSP && !storageFirstLoginMade) {
                    this.settingsService.setBoarded()
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe()
                    .add(
                        () => {
                            if (!this.subscriptionsService.getNoBundles()) {
                                setTimeout(() => {
                                    this.subscriptionsService.updateSubscriptions();
                                    this.subscriptionsService.list()
                                    .pipe(takeUntil(this.onDestroy$))
                                    .subscribe()
                                    .add(
                                        () => {
                                            this.subscriptionsService.processSubscriptions();
                                            this.subscriptionsService.populateFilteredSubscriptionsStructures();
                                            this.subscriptionsService.setHasZuora();
                                            this.modalRoutelessService.setBufferItem(this.valuesService.modalBufferKeys.MSP_INSTALL_MODAL, true);
                                            subscriber.next(this.openOnboardingMspXspModal());
                                            subscriber.complete();
                                        });
                                }, 5000);
                            } else {
                                subscriber.next(this.openOnboardingMspXspModal());
                                subscriber.complete();
                            }
                        }
                    );
                } else {
                    if (hasSubscription && this.appsConfigService.getShowInstallButtonForAllApps()) {
                        const devicesNo = this.devicesService.getDevicesNr();
                        if (devicesNo) {
                            // When devices exist on account we do not show install protection modal
                            subscriber.next(false);
                            subscriber.complete();
                            return;
                        }

                        if (this.modalRoutelessService.getBufferItem(this.valuesService.modalBufferKeys.MSP_INSTALL_MODAL)) {
                            subscriber.next(false);
                            subscriber.complete();
                            return;
                        }

                        this.modalRoutelessService.setBufferItem(this.valuesService.modalBufferKeys.MSP_INSTALL_MODAL, true);
                        subscriber.next(this.openInstallModal());
                        subscriber.complete();
                    } else {
                        if (urlProperties.pathPieces[0] === this.valuesService.centralPaths.dashboard.id && hasOnboardingMSP) {
                            if (this.modalRoutelessService.getBufferItem(this.valuesService.modalBufferKeys.MSP_ONBOARDING)) {
                                subscriber.next(false);
                                subscriber.complete();
                            } else {
                                this.modalRoutelessService.setBufferItem(this.valuesService.modalBufferKeys.MSP_ONBOARDING, true);
                                subscriber.next(this.openOnboardingMspXspModal());
                                subscriber.complete();
                            }
                        } else {
                            subscriber.next(false);
                            subscriber.complete();
                        }
                    }
                }
            } else if (hasMspOrXspLevelTwo && hasOnboardingMSP && isFirstLogin && !storageFirstLoginMade) {
                this.markOnboardingAsDone()
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(resp => {
                    subscriber.next(resp);
                    subscriber.complete();
                });
            } else {
                subscriber.next(false);
                subscriber.complete();
            }
        });
    }

    /**
     * Mark onboarding as done and decide if onboarding msp modal should be shown
     * @private
     * @memberof SpecialCasesService
     * @returns {Observable}
     */
    private markOnboardingAsDone(): Observable<False|ModalAction> {
        return new Observable(subscriber => {
            this.settingsService.setBoarded()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe()
            .add(() => {
                if (!this.subscriptionsService.getNoBundles()) {
                    setTimeout(() => {
                        this.redoSubscriptionsRequestForOnboarding()
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe(resp => {
                            subscriber.next(resp);
                            subscriber.complete();
                        });
                    }, 7000);
                } else {
                    const response = this.subscriberValueForOnboarding();
                    subscriber.next(response);
                    subscriber.complete();
                }
            });
        });
    }

    /**
     * Makes 'list' subscriptions request and decide if onboarding msp modal should be shown
     * @private
     * @memberof SpecialCasesService
     * @returns {Observable}
     */
    private redoSubscriptionsRequestForOnboarding(): Observable<False|ModalAction> {
        return new Observable(subscriber => {
            this.subscriptionsService.updateSubscriptions();
            this.subscriptionsService.list()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: () => {
                    this.subscriptionsService.processSubscriptions();
                    this.subscriptionsService.populateFilteredSubscriptionsStructures();
                    this.subscriptionsService.setHasZuora();

                    const response = this.subscriberValueForOnboarding();
                    subscriber.next(response);
                    subscriber.complete();
                },
                error: () => {
                    subscriber.next(false);
                    subscriber.complete();
                }
            });
        });
    }

    /**
     * Decides if onboarding msp modal should be opened
     * @private
     * @memberof SpecialCasesService
     * @returns {AllModalInformation|boolean} Returns an object if the modal should be displayed and 'false' otherwise
     */
    private subscriberValueForOnboarding(): False|AllModalInformation {
        if (this.subscriptionsService.hasMspManagedBundle()) {
            return this.openOnboardingMspXspModal();
        }
        return false;
    }

    /**
     * Computes the modal info for the onboarding msp modal
     * @returns {AllModalInformation} The object containing the modal properties
     */
    private openOnboardingMspXspModal(): AllModalInformation {
        return {
            name: ModalName.onboardingMsp,
            containerOptions: {},
            contentOptions: {}
        } as AllModalInformation;
    }

    /**
     * Computes the modal info for the referral congrats activation modal
     * @param referralResponse The object containing the referral response data
     * @returns {AllModalInformation} The object containing the modal properties
     */
    private openReferralCongratsModal(referralResponse): AllModalInformation {
        return {
            name: ModalName.referralCongratsModal,
            containerOptions: {
                size: ModalSize.SM
            },
            contentOptions: {
                referralResponse,
                case: this.referralValuesService.referralCongratsCase.CONGRATS_ACTIVATE
            }
        } as AllModalInformation;
    }

    /**
     * Computes the modal info for the congrats activation modal, error case, depending on the error code
     * @param error The object containing the error data
     * @returns {AllModalInformation} The object containing the modal properties
     */
    private openReferralErrorModal(error): AllModalInformation {
        let modalCase = this.referralValuesService.referralCongratsCase.ERROR_ACTIVATION;
        // trebuie sa tinem cont si de 'data' pentru ca nu avem un cod diferit pt aceasta eroare
        // nu se poate schimba in connect pentru ca se strica compatibilitatea cu BMS-ul...
        const errorCode = error?.error?.data?.code;
        const errorMessage = error?.error?.data?.data;

        if (errorCode === this.referralValuesService.codeError.code && this.referralValuesService.congratsCaseBasedOnError[errorMessage]) {
            modalCase = this.referralValuesService.congratsCaseBasedOnError[errorMessage];
        }
        return {
            name: ModalName.referralCongratsModal,
            containerOptions: {
                size: ModalSize.SM
            },
            contentOptions: {
                case: modalCase
            }
        } as AllModalInformation;
    }

    public _decideReferral(): Observable<boolean|any> {
        return new Observable( subscriber => {
            const referralCode = this.queryParamsService.get(this.valuesService.queryParams.referralCode);
            if (referralCode) {
                this.connectReferralService.applyReferral(referralCode)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe({
                    next: resp => {
                        this.subscriptionsService.updateSubscriptions();
                        this.messageService.sendMessage(this.valuesService.events.triggerStart, {});
                        this.queryParamsService.remove([this.valuesService.queryParams.referralCode]);
                        subscriber.next(this.openReferralCongratsModal(resp));
                        subscriber.complete();
                    },
                    error: err => {
                        this.queryParamsService.remove([this.valuesService.queryParams.referralCode]);
                        subscriber.next(this.openReferralErrorModal(err));
                        subscriber.complete();

                    }
                });
            } else {
                subscriber.next(false);
                subscriber.complete();
            }
        });
    }

    automaticRedeemActivate(redeemCode, checkRedeemResponse) {
        return this.redeemService.activateRedeemCode(redeemCode)
        .pipe(
            map(resp => {
                const reason = resp?.reason;
                const response = this.redeemService.handleRedeemResponse(reason);
                const serviceId = resp?.service_id;
                if (response.valid) {
                    if (serviceId && checkRedeemResponse?.data) {
                        checkRedeemResponse.data.service_id = serviceId;
                    }
                    return this.openRedeemAutomaticActivationModal(checkRedeemResponse)
                } else {
                    return this.openOnboardingErrorModal(response, redeemCode);
                }
            }),
            catchError(err => {
                if (err.message) {
                    const response = this.redeemService.handleRedeemResponse(err.message);
                    if (!response.valid) {
                        return of(this.openOnboardingErrorModal(response, redeemCode));
                    } else {
                        return of(false);
                    }
                } else {
                    return of(false);
                }
            })
        );
    }

    /**
     * Function used to compute the opening properities for onboarding modal after an activation code is checked and a new subscription is added
     * @param {RedeemDataModel} redeemResponse The response from the code check
     * @param {boolean} trial The flag that shows if the subscription is a trial
     * @returns {AllModalInformation} The object containing the modal properties
     */
    private openRedeemAutomaticActivationModal(redeemResponse: RedeemDataModel, trial?: boolean): AllModalInformation {
        const bundleId = redeemResponse?.data?.bundle_id;
        let scenario = this.valuesService.onboardingScenario.AUTOMATIC_ACTIVATION;

        if (this.valuesService.identityProducts.has(bundleId)) {
            scenario = this.valuesService.onboardingScenario.AUTOMATIC_ACTIVATION_IDENTITY;
        }

        if (bundleId === this.valuesService.bundlePM) {
            scenario = this.valuesService.onboardingScenario.AUTOMATIC_ACTIVATION_PASSMANAGER;
        }

        const modalData: AllModalInformation = {
            name: ModalName.onboardingModal,
            containerOptions: {
                size: ModalSize.LG,
                backdropDismissable: false
            },
            contentOptions: {
                redeemResponse,
                scenario
            }
        };

        if (trial) {
            modalData.contentOptions.trial = true;
        }
        return modalData;
    }

    /**
     * Function used to compute the opening properities for the autorenewal modal after an activation code is checked and it has a auto renewal flow
     * @param {RedeemDataModel} redeemResponse The response from the code check
     * @param {string} redeemCode The activation code
     * @param {ConversionStatus} status The status of the conversion
     * @returns {AllModalInformation} The object containing the modal properties
     */
    private openAutoRenewalModal(redeemResponse: RedeemDataModel, redeemCode: string, status: ConversionStatus): AllModalInformation {
        return {
            name: ModalName.autoRenewalModal,
            containerOptions: {
                size: ModalSize.LG,
                backdropDismissable: ConversionStatus.MANDATORY !== status,
                buttonDismissable: ConversionStatus.MANDATORY !== status
            },
            contentOptions: {
                redeemResponse,
                redeemCode,
                flow: ConversionStatus[status],
                scenario: this.valuesService.onboardingScenario.CONFIRM_ACTIVATION,
            }
        } as AllModalInformation
    }

    /**
     * Function used to compute the opening properities for the onboarding modal after an activation code is activated successfully
     * @param {RedeemDataModel} redeemResponse The response from the code check
     * @param {string} redeemCode The activation code
     * @returns {AllModalInformation} The object containing the modal properties
     */
    private openExtendOrActivateOnboardingModal(redeemResponse: RedeemDataModel, redeemCode: string): AllModalInformation {
        return {
            name: ModalName.onboardingModal,
            containerOptions: {
                size: ModalSize.LG
            },
            contentOptions: {
                redeemResponse,
                scenario: this.valuesService.onboardingScenario.CONFIRM_ACTIVATION,
                redeemCode
            }
        } as AllModalInformation;
    }

    /**
     * Checks if subscription is eligible for conversion and decides what modal to open based on server response
     * @private
     * @memberof SpecialCasesService
     * @param {RedeemDataModel} redeemResponse The object containing the redeem response data
     * @param {string} redeemCode The redeem code used to activate the subscription
     * @param {boolean} hasCompatibleBundles The flag that shows if the subscription has compatible bundles
     * @returns {Observable} The modal that should be opened
     */
    private getSubscriptionConversion(redeemResponse: RedeemDataModel, redeemCode: string, hasCompatibleBundles: boolean): Observable<any> {
        return this.commercialIdsMgmtService.getConversionStatus(redeemResponse?.data?.source?.partner_id, redeemResponse?.data?.commercial_id)
        .pipe(
            map(response => {
                if (response !== ConversionStatus.OFF) {
                    return this.openAutoRenewalModal(redeemResponse, redeemCode, response);
                } else if (hasCompatibleBundles) {
                    return this.openExtendOrActivateOnboardingModal(redeemResponse, redeemCode);
                } else {
                    throw of(response);
                }
            }),
            catchError(error => {
                if (hasCompatibleBundles) {
                    return of(this.openExtendOrActivateOnboardingModal(redeemResponse, redeemCode));
                } else {
                    throw of(error);
                }
            })
        );
    }

    /**
     * Decides if any modal should open and what flow should be accessed (onboarding/redeemconfirmation/autorenewal)
     * @private
     * @memberof SpecialCasesService
     * @param {string} redeemCode The redeem code used to activate the subscription
     * @returns {Observable} The info about what modal should open, or not
     */
    private _processAutomaticRedeemRedirect(redeemCode: string): Observable<boolean|any> {
        return new Observable( subscriber => {
            const hasValidGroups = this.profilesService.hasValidGroups();
            let request = null;
            if (hasValidGroups) {
                request = this.redeemService.listEligibleServices(redeemCode);
            } else {
                request = this.redeemService.checkRedeemCode(redeemCode);
            }

            request
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: resp => {
                    const response = this.redeemService.handleRedeemResponse(resp?.reason);
                    if (response?.valid && hasValidGroups && this.redeemService.showExtraConfirmationStepForCodeActivation(resp)) {
                        subscriber.next(this.openOnboardingModalForSwitchScenario(redeemCode, resp, QueryParamsValues.ADD_CODE));
                        subscriber.complete();
                        return;
                    }

                    const checkRedeemResponse = {...resp};
                    const serviceId = resp?.service_id;
                    const bundle = resp?.data;
                    const bundleId = bundle?.bundle_id;

                    if (response?.valid
                        && bundleId
                        && !this.utilsCommonService.isEmptyObject(bundle)) {

                        // VSB unfinished onboarding
                        if (bundleId === this.valuesService.bundleVSB && this.subscriptionsService.hasActiveVSBServiceOnNonVSBContext()) {
                            subscriber.next(this.openRedeemAutomaticActivationModal({data: this.subscriptionsService.getActiveVSBService()}));
                            subscriber.complete();
                            return;
                        }
                        const isEligibleForConversion = this.subscriptionsService.isEligibleForOfflineToOnlineConversion(bundle);
                        const compatibleBundlesInfo = this.redeemService.getCompatibleBundleInfoByServiceId(resp);
                        const hasCompatibleBundles = compatibleBundlesInfo.compatibleServices.length > 0;
                        const hasAutoRenewalFlow = this.configService.getAutoRenewalFlow();
                        if (serviceId && checkRedeemResponse?.data) {
                            checkRedeemResponse.data.service_id = serviceId;
                        }
                        if (isEligibleForConversion && hasAutoRenewalFlow) {
                            this.getFlowForConversionEligibility(checkRedeemResponse, redeemCode, hasCompatibleBundles)
                            .pipe(takeUntil(this.onDestroy$))
                            .subscribe(_responseConversion => {
                                subscriber.next(_responseConversion);
                                subscriber.complete();
                            });
                        } else if (hasCompatibleBundles) {
                            subscriber.next(this.openExtendOrActivateOnboardingModal(checkRedeemResponse, redeemCode));
                            subscriber.complete();
                        } else {
                            this.automaticRedeemActivate(redeemCode, checkRedeemResponse)
                            .pipe(takeUntil(this.onDestroy$))
                            .subscribe({
                                next: res => {
                                    subscriber.next(res);
                                    subscriber.complete();
                                },
                                error: err => {
                                    subscriber.next(err);
                                    subscriber.complete();
                                }
                            });
                        }
                    } else {
                        let service = null;
                        if (serviceId) {
                            service = this.redeemService.getService(serviceId);
                            if (service && !this.subscriptionsService.isExpired(service)) {
                                subscriber.next(this.openCongratsModal({data: service}));
                                subscriber.complete();
                            } else {
                                const expiredResponse = this.redeemService.handleRedeemResponse(this.subscriptionsValuesService.redeemReasons.expired);
                                subscriber.next(this.openOnboardingErrorModal(expiredResponse, redeemCode));
                                subscriber.complete();
                            }
                        } else {
                            subscriber.next(this.openOnboardingErrorModal(response, redeemCode));
                            subscriber.complete();
                        }
                    }
                },
                error: () => {
                    subscriber.next(false);
                    subscriber.complete();
                }
            });
        });
    }

    /**
     * Computes the modal info for the switch scenario onboarding modal (you are trying to activate a redeem that is not suitable for the current context)
     * @param {string} redeemCode The activation code
     * @param {RedeemDataModel} redeemResponse The redeem data object
     * @param {QueryParamsValues} serviceAction The action to be performed for the redeem, the one that comes from the query params ex. add_code
     * @returns {AllModalInformation} The object containing the modal properties
     */
    private openOnboardingModalForSwitchScenario(redeemCode: string, redeemResponse: RedeemDataModel, serviceAction: QueryParamsValues): AllModalInformation {
        return {
            name: ModalName.onboardingModal,
            containerOptions: {
                size: ModalSize.LG,
                backdropDismissable: false
            },
            contentOptions: {
                scenario: this.valuesService.onboardingScenario.SWITCH_SCENARIO,
                redeemCode,
                redeemResponse,
                serviceAction
            }
        } as AllModalInformation;
    }

    /**
     * Function used to compute the opening properities for onboarding modal after an activation code is checked and fails
     * @param {any} response The response from the code check
     * @param {string} redeemCode The activation code
     * @param {ModalContentOptionsError} extraContentOptionsParameters The extra parameters for the modal, trialId, inviteId, groupId
     * @returns {AllModalInformation} The object containing the modal properties
     */
    private openOnboardingErrorModal(response: any, redeemCode: string, extraContentOptionsParameters?: ModalContentOptionsError): AllModalInformation {
        let scenario = this.valuesService.onboardingScenario.ERROR;
        if (extraContentOptionsParameters?.trialId) {
            scenario = this.valuesService.onboardingScenario.ERROR_TRIAL;
        } else if (extraContentOptionsParameters?.inviteId) {
            scenario = this.valuesService.onboardingScenario.ERROR_INVITE;
        } else if (extraContentOptionsParameters?.groupId) {
            scenario = this.valuesService.onboardingScenario.ERROR_JOIN_GROUP;
        }

        return {
            name: ModalName.onboardingModal,
            containerOptions: {
                size: ModalSize.LG,
                backdropDismissable: false
            },
            contentOptions: {
                redeemCode,
                errorObject: response,
                scenario,
                ...extraContentOptionsParameters
            }
        };
    }

    /**
     * Computes the modal info for the secure payment modal error
     * @returns {AllModalInformation} The object containing the modal properties
     */
    private openSecurePaymentModalError(): AllModalInformation {
        return {
            name: ModalName.securedPaymentModal,
            containerOptions: {
                size: ModalSize.MD,
                backdropDismissable: false,
                buttonDismissable: false
            } as ModalContainerOptions,
            contentOptions: {
                scenario: SecuredPaymentScenarios.ERROR
            }
        } as AllModalInformation;
    }

    /**
     * Get flow for bundle eligible for conversion, or continue to existing onboarding flow
     * @private
     * @memberof SpecialCasesService
     * @param {RedeemDataModel} redeemResponse The redeem data object
     * @param {string} redeemCode The redeem code
     * @param {bundle} hasCompatibleBundles Shows if there are compatible bundles with the new one added
     * @param {UrlProperties} urlProperties The object that contains all url properties
     * @returns {Observable} The modal that should be opened
     */
    private getFlowForConversionEligibility(redeemResponse: RedeemDataModel, redeemCode: string, hasCompatibleBundles: boolean): Observable<any> {
        return new Observable(subscriber => {
            this.getSubscriptionConversion(redeemResponse, redeemCode, hasCompatibleBundles)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: resp => {
                    subscriber.next(resp);
                    subscriber.complete();
                },
                error: () => {
                    this.automaticRedeemActivate(redeemCode, redeemResponse)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe({
                        next: res => {
                            subscriber.next(res);
                            subscriber.complete();
                        },
                        error: err => {
                            subscriber.next(err);
                            subscriber.complete();
                        }
                    });
                }
            });
        });
    }

    /**
     * @private
     * @return {*}  {(Observable<boolean|UrlTree>)}
     */
    public _decideAutomaticRedeemRedirect(): Observable<boolean|UrlTree|any> {
        return new Observable( subscriber => {
            const service = this.queryParamsService.get(this.valuesService.queryParams.service);
            const code = this.queryParamsService.get(this.valuesService.queryParams.code);

            if (this.profilesService.hasMspOrXspLevelOne()) {
                subscriber.next(false);
                subscriber.complete();
                return;
            }

            if (service && code && service === QueryParamsValues.ADD_CODE) {
                if (this.profilesService.ownerHasSubsV4()) {
                    this._processAutomaticRedeemRedirect(code)
                    .subscribe(
                        (res) => {
                            this.queryParamsService.remove([this.valuesService.queryParams.service, 'ac_code']);
                            subscriber.next(res);
                            subscriber.complete();
                        }
                    );
                }
                else {
                    this._processAutomaticRedeemRedirect(code)
                    .subscribe( (res) => {
                            this.queryParamsService.remove([this.valuesService.queryParams.service, 'ac_code']);
                            subscriber.next(res);
                            subscriber.complete();
                    });
                    // Do the same redirect but to the new page.
                }
            } else {
                subscriber.next(false);
                subscriber.complete();
            }
        });
    }

    automaticTrialActivate(trialId, subscription) {
        return this.subscriptionsTrialService.activateTrialId(trialId)
        .pipe(
            map(resp => {
                const reason = resp?.reason;
                const response = this.subscriptionsTrialService.handleTrialResponse(reason);
                if (response.valid) {
                    const redeemData: RedeemDataModel = { data: subscription };
                    return this.openRedeemAutomaticActivationModal(redeemData, true);
                } else {
                    return this.openOnboardingErrorModal(response, null, { trialId });
                }
            }),
            catchError(err => {
                const response = this.subscriptionsTrialService.handleTrialResponse(err.reason);
                if (!response.valid) {
                    return of(this.openOnboardingErrorModal(response, null, { trialId }));
                } else {
                    return of(false);
                }
            })
        );
    }

    _processAutomaticTrialRedirect(trialId): Observable<boolean|any> {
        return new Observable( subscriber => {

            this.subscriptionsTrialService.checkTrialId(trialId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: resp => {

                    this.automaticTrialActivate(trialId, resp)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe({
                        next: respTrialActivate => {
                            subscriber.next(respTrialActivate);
                            subscriber.complete();
                        }
                    });
                },
                error: err => {
                    const response = this.subscriptionsTrialService.handleTrialResponse(err.reason);
                    if (!response.valid) {
                        subscriber.next(this.openOnboardingErrorModal(response, null, { trialId }));
                        subscriber.complete();
                    } else {
                        subscriber.next(false);
                        subscriber.complete();
                    }
                }
            });
        });
    }

    _decideAutomaticTrialRedirect(): Observable<boolean|UrlTree> {
        return new Observable( subscriber => {
            const service = this.queryParamsService.get(this.valuesService.queryParams.service);
            const code = this.queryParamsService.get(this.valuesService.queryParams.code);

            if (service && code && service === QueryParamsValues.ADD_TRIAL) {
                this.queryParamsService.remove([this.valuesService.queryParams.service, this.valuesService.queryParams.code]);
                const automaticTrialId = code;

                if (this.profilesService.ownerHasSubsV4()) {
                    this._processAutomaticTrialRedirect(automaticTrialId)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe(res => {
                        subscriber.next(res);
                        subscriber.complete();
                    });
                } else {
                    subscriber.next(false);
                    subscriber.complete();
                    // Do the same redirect but to the new page.
                }
            }
            else {
                subscriber.next(false);
                subscriber.complete();
            }
        });
    }

    /**
     * Function that decides if the joining group flow should be triggered
     * @returns {Observable<boolean|UrlTree>} The observable that contains false or the response from the joining group flow
     */
    _decideAutomaticJoinGroup(): Observable<boolean|UrlTree> {
        const service = this.queryParamsService.get(this.valuesService.queryParams.service);
        const groupId = this.queryParamsService.get(this.valuesService.queryParams.groupId);
        if (service && groupId && service === QueryParamsValues.JOIN_GROUP) {
            this.queryParamsService.remove([this.valuesService.queryParams.service, this.valuesService.queryParams.groupId]);
            if (this.profilesService.ownerHasSubsV4()) {
                return this._processAutomaticJoinGroup(groupId);
            }
        }
        return of(false);
    }

    /**
     * Process automatic group join and reloads the page if the context switch is successful or shows onboarding modal with error
     *
     * @private
     * @memberof SpecialCasesService
     * @param {string} groupId representing the group id to join
     * @returns {Observable<boolean|any>}
     */
     private _processAutomaticJoinGroup(groupId: string): Observable<boolean|any> {
        if (this.profilesService.currentGroupIsVSB()) {
            return of(this.openOnboardingErrorJoinGroupSwitchModal(groupId));
        } else {
            return this.connectGroupMgmtService.acknowledgeInvite(groupId)
            .pipe(takeUntil(this.onDestroy$),
                mergeMap((resp: Group) => {
                    return this.contextService.switchContext(resp.context_id)
                }),
                map((resp: SwitchContextResponse) => {
                    if (resp.switched) {
                        localStorage.setItem(ContextBubbleLocalStorage, BrowserBooleanValues.TRUE);
                        window.location.href = window.location.href.split('?')[0];
                    } else {
                        throw resp;
                    }
                }),
                catchError(err => {
                    const errorObject = err?.error?.data || err?.message?.internal_data;
                    if (errorObject) {
                        const errorData = this.inviteService.handleJoinGroupInviteErrorResponse(err);
                        if (errorData) {
                            return of(this.openOnboardingErrorModal(errorData, null, { groupId }));
                        }
                    }
                    return of(false);
                })
            );
        }
    }

    public _decideOnboardingVSB(): Observable<boolean|any> {
        return new Observable( subscriber => {
            if (!this.settingsService.isOnboardingComplete()
                && this.profilesService.getCurrentGroupType() === GroupTypes.VSB) {
                const vsbService = this.subscriptionsService.getActiveVSBService();
                if (vsbService) {
                    localStorage.setItem(ContextBubbleLocalStorage, BrowserBooleanValues.TRUE);
                    subscriber.next(this.openOnboardingModalForInviteScenario(vsbService, null));
                    subscriber.complete();
                }
            }
            subscriber.next(false);
            subscriber.complete();
        });

    }

    public _decideOpenInstallModalToSetupVSB(): Observable<boolean|any> {
        return new Observable( subscriber => {
            const newContextId = localStorage.getItem(OpenInstallModal);
            if (this.profilesService.getCurrentGroupType() === GroupTypes.VSB
                && this.profilesService.getCurrentContextId() === newContextId) {
                const vsbService = this.subscriptionsService.getActiveVSBService();
                if (vsbService) {
                    localStorage.removeItem(OpenInstallModal);
                    subscriber.next(this.openInstallModalForBundle(vsbService));
                    subscriber.complete();
                }
            }
            subscriber.next(false);
            subscriber.complete();
        });
    }

    /**
     * Function that computes the modal info for the install modal, and opens it for the given bundle, the default step (first one)
     * @param {BundleModel} bundle The bundle object
     * @returns {AllModalInformation} The object containing the modal properties
     */
    private openInstallModalForBundle(bundle: BundleModel): AllModalInformation {
        const contentOptions = {
            bundle_id : bundle?.bundle_id,
            newBundle: bundle
        };
        this.modalRoutelessService.open(ModalName.installModal, {size: ModalSize.LG}, contentOptions, true);

        const _response: AllModalInformation = {
            name: ModalName.installModal,
            containerOptions: {
                size: ModalSize.LG
            },
            contentOptions: {
                bundle_id : bundle?.bundle_id,
                newBundle: bundle
            }
        };
        return _response;
    }

    /**
     * Computes the modal info for the install modal, the default step (first one)
     * @returns {AllModalInformation} The object containing the modal properties
     */
    private openInstallModal(): AllModalInformation {
        return {
            name: ModalName.installModal,
            containerOptions: {
                size: ModalSize.LG
            },
            contentOptions: {}
        } as AllModalInformation;
    }

    /**
     * Function that computes the modal info for the onboarding modal to be opened for the invite scenario
     * @param {BundleModel} bundle The bundle object
     * @param {string} inviteId The invite id
     * @returns {AllModalInformation} The object containing the modal properties
     */
    private openOnboardingModalForInviteScenario(bundle: BundleModel, inviteId: string): AllModalInformation {
        return {
            name: ModalName.onboardingModal,
            containerOptions: {
                size: ModalSize.LG,
                backdropDismissable: false
            },
            contentOptions: {
                scenario: this.valuesService.onboardingScenario.AUTOMATIC_ACTIVATION_INVITE,
                bundleId: bundle?.bundle_id,
                bundleName: bundle?.bundle_friendly_name,
                inviteId
            }
        } as AllModalInformation;
    }

    /**
     * Process automatic invite redirect and open onboarding modal for corresponding scenario
     *
     * @private
     * @memberof SpecialCasesService
     * @param {String} inviteId representing the invite link sent on email
     * @returns {Observable}
     */
    private _processAutomaticInviteRedirect(inviteId: string): Observable<boolean|AllModalInformation> {
        return new Observable( subscriber => {
            this.subscriptionInviteService.activateInvite(inviteId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: resp => {
                    subscriber.next(this.openOnboardingModalForInviteScenario(resp, inviteId));
                    subscriber.complete();
                },
                error: err => {
                    const errorObject = err?.error?.data || err?.message?.internal_data;
                    if (errorObject) {
                        const errorData = this.inviteService.handleInviteServiceErrorResponse(errorObject);
                        if (errorData) {
                            subscriber.next(this.openOnboardingErrorModal(errorData, null, { inviteId }));
                            subscriber.complete();
                        } else {
                            subscriber.next(false);
                            subscriber.complete();
                        }
                    } else {
                        subscriber.next(false);
                        subscriber.complete();
                    }
                }
            });
        });
    }

    /**
     * If user is on 'add_invite' scenario process automatic invite redirect
     *
     * @public
     * @memberof SpecialCasesService
     * @returns {Observable}
     */
    public _decideAutomaticInviteRedirect(): Observable<boolean|AllModalInformation> {
        return new Observable( subscriber => {
            const service = this.queryParamsService.get(this.valuesService.queryParams.service);
            const inviteId = this.queryParamsService.get(this.valuesService.queryParams.inviteId);

            if (this.profilesService.hasMspOrXspLevelOne()) {
                subscriber.next(false);
                subscriber.complete();
                return;
            }

            if (service && inviteId && service === QueryParamsValues.ADD_INVITE) {
                this.queryParamsService.remove([this.valuesService.queryParams.service, this.valuesService.queryParams.inviteId]);
                if (this.profilesService.currentGroupIsVSB()) {
                    subscriber.next(this.openOnboardingErrorInviteSwitchModal(inviteId));
                    subscriber.complete();
                } else if (this.profilesService.ownerHasSubsV4()) {
                    this._processAutomaticInviteRedirect(inviteId)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe(res => {
                        subscriber.next(res);
                        subscriber.complete();
                    });
                } else {
                    subscriber.next(false);
                    subscriber.complete();
                }
            } else {
                subscriber.next(false);
                subscriber.complete();
            }
        });
    }

    /**
     * Function that computes the modal info for the onboarding modal to be opened for the error invite switch scenario
     * @param {string} inviteId The invite id
     * @returns {AllModalInformation} The object containing the modal properties
     */
    private openOnboardingErrorInviteSwitchModal(inviteId: string): AllModalInformation {
        return {
            name: ModalName.onboardingModal,
            containerOptions: {
                size: ModalSize.LG,
                backdropDismissable: false
            },
            contentOptions: {
                scenario: this.valuesService.onboardingScenario.ERROR_INVITE_SWITCH,
                inviteId,
            }
        } as AllModalInformation;
    }

    /**
     * Computes the modal info for the onboarding modal to be opened for the error join group switch scenario
     * @param {string} groupId The group id
     * @returns {AllModalInformation} The modal properties object
     */
    private openOnboardingErrorJoinGroupSwitchModal(groupId: string): AllModalInformation {
        return {
            name: ModalName.onboardingModal,
            containerOptions: {
                size: ModalSize.LG,
                backdropDismissable: false
            },
            contentOptions: {
                scenario: this.valuesService.onboardingScenario.ERROR_JOIN_GROUP_SWITCH,
                groupId
            }
        } as AllModalInformation;
    }

    /**
     * Function that computes data for the onboarding modal to be opened
     * @returns {AllModalInformation} The object containing the modal properties
     */
    private openOnboardingModal(): AllModalInformation {
        return {
            name: ModalName.onboardingModal,
            containerOptions: {
                size: ModalSize.LG,
                backdropDismissable: false
            },
            contentOptions: {
                scenario: this.valuesService.onboardingScenario.ACTIVATION
            }
        } as AllModalInformation;
    }

    _decideOnboarding(): Observable<any|boolean> {
        return new Observable( subscriber => {
            if (this.profilesService.hasMspOrXspLevelOne()) {
                subscriber.next(false);
                subscriber.complete();
                return;
            }

            const _storageOnboardingComplete = sessionStorage.getItem(this.valuesService.sessionStorageModalStatus.onboardingComplete);
            if (!this.subscriptionsService.getAllServices().length && !this.settingsService.isOnboardingComplete() && !_storageOnboardingComplete) {
                subscriber.next(this.openOnboardingModal());
                subscriber.complete();
            } else {
                subscriber.next(false);
                subscriber.complete();
            }
        });
    }

    /**
     * Function that computes data for the install modal to be opened for the campaign
     * @param {string} cidCode The campaign cid code
     * @returns {AllModalInformation} The object containing the modal properties
     */
    private openOldInstallModalForCampaign(cidCode: string): AllModalInformation {
        return {
            name: ModalName.installProtectionModal,
            containerOptions: {
                size: ModalSize.LG
            },
            contentOptions: {
                scenario: ProductsToInstall.PROTECTION,
                cidCode
            }
        } as AllModalInformation;
    }

    _decideInstallRedirectFromQueryParams(): Observable<boolean|any> {
        return new Observable( subscriber => {
            const modal = this.queryParamsService.get(this.valuesService.queryParams.modal);
            const hasDeployFlow = this.appsConfigService.showDeployFlow(this.valuesService.appProtection);

            if (modal && modal === QueryParamsValues.INSTALL && hasDeployFlow) {
                const cid = this.queryParamsService.get(this.valuesService.queryParams.campaign);
                subscriber.next(this.openOldInstallModalForCampaign(cid));
                subscriber.complete();
                this.queryParamsService.remove([this.valuesService.queryParams.campaign]);
                this.queryParamsService.remove([this.valuesService.queryParams.modal]);
            } else {
                subscriber.next(false);
                subscriber.complete();
            }
        });
    }

    /**
     * Function that computes data for the onboarding modal to be opened for the expired subscription
     * @param {boolean} paid Ture if the subscription is paid
     * @param {boolean} protection True if the subscription has protection
     * @returns {AllModalInformation} The object containing the modal properties
     */
    private openOnboardingModalForExpiredSubscription(paid: boolean, protection: boolean): AllModalInformation {
        return {
            name: ModalName.onboardingModal,
            containerOptions: {
                size: ModalSize.LG,
                backdropDismissable: false
            },
            contentOptions: {
                scenario: this.valuesService.onboardingScenario.EXPIRED,
                paid,
                protection
            }
        } as AllModalInformation;
    }

    _decideRedirectFromExpiredSubscription(urlProperties: UrlProperties): Observable<any|boolean> {
        return new Observable( subscriber => {
            if (urlProperties.entirePath.indexOf(this.valuesService.centralPaths.privacy.id) !== -1) {
                subscriber.next(false);
                subscriber.complete();
                return;
            }

            if (this.profilesService.hasMspOrXspLevelOne()) {
                subscriber.next(false);
                subscriber.complete();
                return;
            }

            const _storageModalStatus = sessionStorage.getItem(this.valuesService.sessionStorageModalStatus.expiredOnboardingModal);
            if (this.subscriptionsService.hasAllBundlesAreExpired() && !this.modalRoutelessService.getIsModalOpened() && !_storageModalStatus) {
                if (this.subscriptionsService.hasPaidProtectionBundleExpired()) {
                    sessionStorage.setItem(this.valuesService.sessionStorageModalStatus.expiredOnboardingModal, 'true');
                    subscriber.next(this.openOnboardingModalForExpiredSubscription(true, true));
                    subscriber.complete();
                    return;
                }

                if (this.subscriptionsService.hasPaidNonprotectionBundleExpired()) {
                    sessionStorage.setItem(this.valuesService.sessionStorageModalStatus.expiredOnboardingModal, 'true');
                    subscriber.next(this.openOnboardingModalForExpiredSubscription(true, false));
                    subscriber.complete();
                    return;
                }

                if (this.subscriptionsService.hasFreeProtectionBundleExpired()) {
                    sessionStorage.setItem(this.valuesService.sessionStorageModalStatus.expiredOnboardingModal, 'true');
                    subscriber.next(this.openOnboardingModalForExpiredSubscription(false, true));
                    subscriber.complete();
                    return;
                }

                if (this.subscriptionsService.hasFreeNonprotectionBundleExpired()) {
                    sessionStorage.setItem(this.valuesService.sessionStorageModalStatus.expiredOnboardingModal, 'true');
                    subscriber.next(this.openOnboardingModalForExpiredSubscription(false, false));
                    subscriber.complete();
                    return;
                }
                subscriber.next(false);
                subscriber.complete();
            }
            subscriber.next(false);
            subscriber.complete();
        });
    }

    _decideCongrats(): Observable<boolean|any> {
        return new Observable( subscriber => {

            const newDevicesIds = this.redeemService.getNewDevicesIds();
            if (!newDevicesIds.size) {
                subscriber.next(false);
                subscriber.complete();
                return;
            }

            for (const deviceId of newDevicesIds) {
                const device = this.devicesService.retrieveDeviceById(deviceId);
                const validProtectionApp = device?.processed?.protectionApp ?? [];
                const hasVPN = !!device?.processed?.hasVPN;
                const hasParental = !!device?.processed?.hasParental;
                const hasParentalNCC = !!device?.processed?.hasParentalNCC;
                // verificam daca are vreun app instalat (protection sau vpn)
                if ((validProtectionApp && this.valuesService.protectionApps.has(validProtectionApp))
                    || hasVPN || hasParental || hasParentalNCC) {
                    this.redeemService.removeNewDeviceId(deviceId);

                    setTimeout(() => {
                        this.messageService.sendMessage(this.valuesService.events.openCongratsNewDevice, {deviceId: deviceId});
                    }, 350);

                    const modalOptions = this.openCongratsModalForParental(device);
                    if (!modalOptions) {
                        continue;
                    }
                    subscriber.next(modalOptions);
                    subscriber.complete();
                    return;
                }
            }

            subscriber.next(false);
            subscriber.complete();
        });
    }

    /**
     * Function that decides if the onboarding modal should be opened for the unfinished VSB onboarding
     * @public
     * @memberof SpecialCasesService
     * @returns {Observable<boolean|any>}
     */
    public _decideUnfinishedVSBOnboarding(): Observable<boolean|any> {
        return new Observable( subscriber => {
            if (this.subscriptionsService.hasActiveVSBServiceOnNonVSBContext() && !this.modalRoutelessService.getIsModalOpened()) {
                subscriber.next(this.openRedeemAutomaticActivationModal({data: this.subscriptionsService.getActiveVSBService()}));
                subscriber.complete();
            } else {
                subscriber.next(false);
                subscriber.complete();
            }
        });
    }

    /**
     * Function that decides what scenario to be displayed for 3dsecure redirects
     * @public
     * @memberof SpecialCasesService
     * @returns {Observable} Specifies which modal to open if 'paymentStatus' param is present
     */
    public _decideSecuredPayment(): Observable<any> {
        return new Observable(subscriber => {
            const paymentStatus = this.queryParamsService.get(this.valuesService.queryParams.paymentStatus);

            if (!paymentStatus) {
                subscriber.next(false);
                subscriber.complete();
                return;
            }

            // If success, open redeem confirmation modal
            // If error, open secured payment modal
            if (paymentStatus === SecuredPaymentScenarios.SUCCESS) {
                subscriber.next(this.openCongratsModalForSecurePayment());
                subscriber.complete();
            } else {
                subscriber.next(this.openSecurePaymentModalError());
                subscriber.complete();
            }
            this.queryParamsService.remove([this.valuesService.queryParams.paymentStatus]);
        });
    }

    /**
     * Computes the modal info for the redeem confirmation modal for installing parental ncc on a device, adding some device specific data
     * @param device The device object
     * @returns {AllModalInformation|null} The object containing the modal properties
     */
    private openCongratsModalForParental(device): AllModalInformation|null {
        const hasParental = !!device?.processed?.hasParental;
        const hasParentalNCC = !!device?.processed?.hasParentalNCC;
        let scenario = this.valuesService.onboardingScenario.CONGRATULATIONS;

        const aditionalParams: any = {};

        if (device?.profile_id) {
            const profileHash = this.profilesService.getProfileById(device?.profile_id)?.profile_hash;
            if (profileHash) {
                aditionalParams.profileHash = profileHash;
            }
        }

        if (hasParentalNCC && aditionalParams?.profileHash) {
            scenario = this.valuesService.onboardingScenario.PARENTALNCC;
        }

        if (hasParental && aditionalParams?.profileHash) {
            scenario = this.valuesService.onboardingScenario.PARENTAL;
        }
        //Daca nu are profile_id dar are parental nu afisam modala de congrats
        if (!device?.profile_id && (hasParental || hasParentalNCC)) {
            return null;
        }

        return {
            name: ModalName.redeemConfirmationModal,
            containerOptions: {
                size: ModalSize.MD
            },
            contentOptions:{
                deviceId: device?.device_id,
                scenario,
                ...aditionalParams
            }
        } as AllModalInformation;
    }

    /**
     * Computes the modal info for the redeem confirmation modal for automatic activation
     * @param {RedeemDataModel} redeemResponse The redeem data object
     * @returns {AllModalInformation} The object containing the modal properties
     */
    private openCongratsModal(redeemResponse: RedeemDataModel): AllModalInformation {
        return {
            name: ModalName.redeemConfirmationModal,
            containerOptions: {
                size: ModalSize.MD
            },
            contentOptions: {
                redeemResponse,
                alreadyActive: true,
                scenario: this.valuesService.onboardingScenario.CONGRATULATIONS_ACTIVATE
            }
        } as AllModalInformation;
    }

    /**
     * Computes the modal info for the secure payment modal for successful payment
     * @returns {AllModalInformation} The object containing the modal properties
     */
    private openCongratsModalForSecurePayment(): AllModalInformation {
        return {
            name: ModalName.redeemConfirmationModal,
            containerOptions: {
                size: ModalSize.MD
            } as ModalContainerOptions,
            contentOptions: {
                scenario: this.valuesService.onboardingScenario.SECURED_PAYMENT
            }
        } as AllModalInformation;
    }

    /**
     * Computes the modal info for the onboarding modal for the scenario when the user has no msp or xsp, wants to activate a code and has subs v3
     * @returns {AllModalInformation} The object containing the modal properties
     */
    private openOnboardingModalForSubsV3NoMspXsp(): AllModalInformation {
        return {
            name: ModalName.onboardingModal,
            containerOptions: {
                size: ModalSize.LG,
                backdropDismissable: false
            },
            contentOptions: {
                scenario: this.valuesService.onboardingScenario.JUST_ACTIVATION
            }
        } as AllModalInformation;
    }

    /**
     *
     * Open redeem modal for accounts without msp
     * @return {*}  {(Observable<boolean|any>)}
     * @memberof SpecialCasesService
     */
    public _decideRedeemRedirectFromQueryParams(): Observable<boolean|any> {
        return new Observable( subscriber => {
            const modal = this.queryParamsService.get(this.valuesService.queryParams.modal);
            const hasMspOrXsp = this.profilesService.hasMspOrXspAnyLevel();

            if (modal && modal === QueryParamsValues.REDEEEM && !hasMspOrXsp) {
                subscriber.next(this.openOnboardingModalForSubsV3NoMspXsp());
                subscriber.complete();
                this.queryParamsService.remove([this.valuesService.queryParams.modal]);
            } else {
                subscriber.next(false);
                subscriber.complete();
            }
        });
    }
}
