import { Component, OnInit, Input, ElementRef, Renderer2, OnChanges, SimpleChanges } from '@angular/core';
import { ConfigService } from '../../../../common/config/config.service';

// how to use
// <ui-icon shape="squircle" type="device" icon="boxv2" bg="warning-5" color="warning-20"></ui-icon>
// To update [attr.xlink:href] to [attr.href] when we no longer support safari 11.1

@Component({
    selector: 'ui-icon',
    template:
        `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" [style.height]="shape && shape !== 'icon' ? iconSize : '100%'" [style.rotate.deg]="rotate">
            <use [attr.xlink:href]="configService.config.pathIcons + type + '/' + icon + '.svg#' + icon" />
        </svg>
        <ng-content></ng-content>
    `,
    styleUrls: ['./ui-icon.component.scss'],
    host: {
        '[class.ui-icon]': 'true'
    }
})
export class UiIconComponent implements OnInit, OnChanges {

    // icon path inputs
    @Input() type = 'device-v2'; // generic / device / app
    @Input() icon = 'other';

    // layout and design inputs
    @Input() shape: string; //  icon / circle / square / squircle
    @Input() size = 48; // if no shape is defined, size is ignored
    // size is dependent on shape, if no shape is set ui-icon will act as an usual 24x24, inlined with text, icon

    @Input() bg: string; // if bg (color) is set, the icon will not have a border
    @Input() color = 'inherit';
    @Input() iconSize = '70%';
    @Input() rotate = 0;

    constructor(
        readonly configService: ConfigService,
        readonly el: ElementRef,
        readonly renderer: Renderer2
    ) {}

    ngOnInit() {
        if (this.shape) {
            this.el.nativeElement.style.height = this.size + 'px';
            this.el.nativeElement.style.width = this.size + 'px';

            this.renderer.addClass(this.el.nativeElement, this.shape);
        }

        if (this.bg) {
            const isElevation = this.bg.indexOf('elevation') !== -1;
            this.renderer.addClass(this.el.nativeElement, isElevation ? this.bg : `bg-${this.bg}`);
        }

        this.renderer.addClass(this.el.nativeElement, `color-${this.color}`);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.color) {
            this.renderer.removeClass(this.el.nativeElement, `color-${changes.color.previousValue}`);
            this.renderer.addClass(this.el.nativeElement, `color-${changes.color.currentValue}`);
        }
    }

    // do not use (error) event, it's unreliable in chrome

}
