export enum NetgearDevices {
    CAXE500 = "caxe500",
    RAX20 = "rax20",
    RAX28 = "rax28",
    RAX80 = "rax80",
    RAX120 = "rax120",
    RAX120V2 = "ax120v2",
    CAX20 = "cax20",
    CAX25 = "cax25",
    CAX28 = "cax28",
    NBR750 = "nbr750",
    R6700AX = "r6700ax",
    RAX6700AX = "x6700ax",
    RAX5 = "rax5",
    RAX15 = "rax15",
    RAX30 = "rax30",
    RAX29 = "rax29",
    RAX41 = "rax41",
    RAX42 = "rax42",
    RAX43 = "rax43",
    RAX45 = "rax45",
    RAX48 = "rax48",
    RAX49 = "rax49",
    RAX50 = "rax50",
    RAX50S = "rax50s",
    RAX75 = "rax75",
    RAX200 = "rax200",
    RAX220 = "rax220",
    RAXE450 = "raxe450",
    RAXE500 = "raxe500",
    RBR750 = "rbr750",
    RBR760 = "rbr760",
    RBR850 = "rbr850",
    RBRE960 = "rbre960",
    CBR40 = "cbr40",
    R6400 = "r6400",
    R6400V2 = "r6400v2",
    R6700V3 = "r6700v3",
    R6700 = "r6700",
    R6900 = "r6900",
    R6900P = "r6900p",
    R7000 = "r7000",
    R7000P = "r7000p",
    R7850 = "r7850",
    R7900 = "r7900",
    R7900P = "r7900p",
    R7960P = "r7960p",
    R8000 = "r8000",
    R8000P = "r8000p",
    RAX40 = "rax40",
    RAX70 = "rax70",
    RBR10 = "rbr10",
    RBR350 = "rbr350",
    RAX78 = "rax78",
    RAXE300 = "raxe300",
    RAXE290 = "raxe290",
    RBRE950 = "rbre950",
    RBR20 = "rbr20",
    RBR40 = "rbr40",
    RBR50 = "rbr50",
    ORBI = "orbi",
    LBR20 = "lbr20",
    RAX10 = "rax10",
    RAX35V2 = "rax35v2",
    RAX38V2 = "rax38v2",
    RAX40V2 = "rax40v2",
    MR60 = "mr60",
    MR70 = "mr70",
    MR80 = "mr80",
    CAX80 = "cax80",
    CAX30 = "cax30",
    CBR750 = "cbr750",
    LAX20 = "lax20",
    XR1000 = "xr1000",
    BOXV2 = "boxv2",
    RS150 = "rs150",
    RS200 = "rs200",
    RS400 = "rs400",
    RS500 = "rs500",
    RS600 = "rs600"
};

export const OrbiModels = new Set([
    NetgearDevices.RBR10,
    NetgearDevices.RBR20,
    NetgearDevices.RBR40,
    NetgearDevices.RBR50,
    NetgearDevices.ORBI,
    NetgearDevices.CBR40,
    NetgearDevices.LBR20,
    NetgearDevices.RBR750,
    NetgearDevices.RBR850,
    NetgearDevices.RBR350,
    NetgearDevices.CBR750,
    NetgearDevices.NBR750,
    NetgearDevices.RBRE960,
    NetgearDevices.RBR760
]);