<div *ngIf="isDesktop && offline"
    class="color-dark mt-auto d-flex align-items-center py-1 px-4"
    [ngClass]="bg ? bg : 'bg-a8'">
    <ui-icon type="action" icon="wifi-disconnected" class="mr-1"></ui-icon>
    {{'pwa.offline.title' | translate}}
    <ui-icon type="feedback" icon="circle-info" class="ml-auto"
        [tooltip]="'pwa.offline.description' | translate"
        [width]="200"
        position="right"
        tooltipColor="secondary">
    </ui-icon>
</div>

<div *ngIf="newVersion"
    class="color-dark mt-auto d-flex align-items-center py-1 px-2 bg-a8">
    <ui-icon type="action" icon="update-v2" color="dark" class="mr-12"></ui-icon>
    <p class="b1">{{'pwa.newVersionReady.title' | translate}}</p>
    <button ui-button model="link" (click)="applyUpdate()" class="ml-auto">{{'pwa.newVersionReady.cta' | translate}}</button>
</div>

<div *ngIf="broken"
    class="color-dark mt-auto d-flex align-items-center py-1 px-2 bg-a8">
    <ui-icon type="action" icon="update-v3" color="dark" class="mr-12"></ui-icon>
    <p class="b1">{{'pwa.brokenServiceWorker.title' | translate}}</p>
    <button ui-button model="link" (click)="applyUpdate()" class="ml-auto">{{'pwa.brokenServiceWorker.cta' | translate}}</button>
</div>

<div *ngIf="!isDesktop && offline"
    class="d-flex align-items-center p-2"
    [ngClass]="bg ? bg : 'bg-a8-10'">
    <ui-icon type="action" icon="wifi-disconnected" class="mr-2" shape="icon" size="32"></ui-icon>
    <div>
        <h5 class="m-0">{{'pwa.offline.title' | translate}}</h5>
        <p class="m-0">{{'pwa.offline.description' | translate}}</p>
    </div>
</div>