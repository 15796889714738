// External
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

// Internal
import { IToast } from '../../../services/global/toast/iToast.interface';
import { ToastService } from '../../../services/global/toast/toast.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ui-toast',
    templateUrl: './ui-toast.component.html',
    host: {
        '[class.toasts]': 'true'
    },
    animations: [
        trigger('slideVertical', [
            transition('void => *', [
                style({transform: 'translateY(-15px)', opacity:0}), // initial style/position
                animate('0.3s')
            ]),
            transition('* => void', [
                animate('0.15s', style({transform: 'translateY(8px)', opacity: 0})) // leave style/position
            ])
        ])
    ]
})

export class UiToastComponent implements OnInit {

    @Output() emitToasts: EventEmitter<IToast[]> = new EventEmitter();
    toasts: IToast[] = [];
    private onDestroy$: Subject<void> = new Subject<void>();

    constructor(private toastService: ToastService) { }

    ngOnInit() {
        this.toastService.getToast()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((toast: IToast) => {
            if (!toast) {
                this.toasts = [];
                return;
            }
            this.toasts.push(toast);
            if (toast.duration > 0) {
                setTimeout(() => this.removeToast(toast), toast.duration);
            }
        });
    }

    ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}

    removeToast(toast: IToast): void {
        this.toasts = this.toasts.filter(x => x !== toast);
    }
}
