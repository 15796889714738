import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ValuesService } from 'src/app/common/values/values.service';
import { skipWhile, map, catchError } from 'rxjs/operators';
import { ConnectUserInfoService } from '../../requests/connect-user-info-service/connect-user-info-service';
import { SubscriptionsValuesService } from 'src/app/common/values/subscriptions.values.service';
import { CookieService } from "ngx-cookie-service";

@Injectable({
    providedIn: 'root'
})
export class InvoicesService {

    invoices: any;
    creditMemos: any;
    invoicesCookie: boolean = false;

    currentDate = Math.floor(new Date().getTime()/1000);

    private onlistInvoices$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    markToUpdateInvoices: boolean = true;

    private onlistCreditMemos$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    markToUpdateCreditMemos: boolean = true;

    constructor(
        private valuesService: ValuesService,
        private connectUserInfoService: ConnectUserInfoService,
        private subscriptionsValuesService: SubscriptionsValuesService,
        private cookieService: CookieService
    ) { }

    /**
     * Show only invoices with status: 'Posted'
     * Show only invoices with amount !== 0
     * Show only invoices with dueDate < 1 day
     */
    filterInvoices(invoices) {
        return invoices.filter(invoice => invoice.status && invoice.status === 'Posted')
                       .filter(invoice => invoice.amount && invoice.amount !== 0)
                       .filter(invoice => invoice.dueDate && Math.floor(new Date(invoice.dueDate).getTime()/1000) - this.currentDate < this.subscriptionsValuesService.day);
    }

    list(): Observable<any> {
        if (!this.markToUpdateInvoices) {
            return of(this.invoices);
        }
        if (this.onlistInvoices$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistInvoices$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onlistInvoices$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.connectUserInfoService.getInvoices().pipe(
                map(
                    resp => {
                        this.invoices = resp;
                        this.invoicesCookie = this.get().length >= 1;
                        this.cookieService.set(this.valuesService.cookieShowInvoices, this.invoicesCookie.toString(), null, '/', "", true);
                        this.markToUpdateInvoices = false;
                        this.onlistInvoices$.next(this.valuesService.processServiceState.DONE);
                        return of(this.invoices);
                    }
                ),
                catchError( err => {
                    this.onlistInvoices$.next(this.valuesService.processServiceState.DONE);
                    throw of(err);
                })
            );
        }
    }

    listCreditMemos() {
        if (!this.markToUpdateCreditMemos) {
            return of(this.creditMemos);
        }

        if (this.onlistCreditMemos$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistCreditMemos$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onlistCreditMemos$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.connectUserInfoService.getCreditMemos().pipe(
                map(
                    resp => {
                        this.creditMemos = resp;
                        this.markToUpdateCreditMemos = false;
                        this.onlistCreditMemos$.next(this.valuesService.processServiceState.DONE);
                        return of(this.creditMemos);
                    }
                ),
                catchError( err => {
                    this.onlistCreditMemos$.next(this.valuesService.processServiceState.DONE);
                    throw of(err);
                })
            );
        }
    }

    hasInvoicesCookie() {
        let cookie = this.cookieService.get(this.valuesService.cookieShowInvoices);
        if (cookie === 'true') {
            this.invoicesCookie = true;
        }
        return this.invoicesCookie;
    }

    getFiltered() {
        return this.filterInvoices(this.invoices);
    }

    get() {
        return this.invoices;
    }

    getCreditMemos() {
        return this.creditMemos;
    }

    getInvoiceFile(id: string): Observable<any> {
        return this.connectUserInfoService.getInvoiceFile(id).pipe(
            map(
                resp => {
                    return resp;
                }
            )
        )
    }

    getCreditMemoFile(id: string): Observable<any> {
        return this.connectUserInfoService.getCreditMemoFile(id).pipe(
            map(
                resp => {
                    return resp;
                }
            )
        )
    }
}
