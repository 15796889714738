// External
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { catchError, concat, concatMap, forkJoin, last, map, Observable, of, Subject, takeUntil } from 'rxjs';

// Internal
import { AppsConfigService } from '../../config/apps.config.service';
import { ConfigService, BITDEFENDER_PARTNER_ID } from '../../config/config.service';
import { NavigationService } from '../../navigation/navigation.service';
import { ReferralValuesService } from '../../values/referral.values.service';
import { ValuesService } from '../../values/values.service';
import { AdobeDataLayerService } from '../core/adobe.datalayer.service';
import { LanguageService } from '../core/language.service';
import { QueryParamsService } from '../core/query.params.service';
import { ProfilesService } from '../process/profiles/profiles.service';
import { ReferralService } from '../process/referral/referral.service';
import { SettingsService } from '../process/settings/settings.service';
import { SubscriptionsService } from '../process/subscriptions/subscriptions.service';
import { UrlProperties } from './models/UrlProperties.model';
import { AccountInfo } from './models/AccountInfo.model';
import { GroupManagementService } from '../process/subscriptions/group-management.service';
import { IContextService } from '../interfaces/icontext/icontext.service';

@Injectable({
    providedIn: 'root'
})

export class NavigationGuard {

    private readonly onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        @Inject(DOCUMENT) readonly document: any,
        private readonly router: Router,
        private readonly valuesService: ValuesService,
        private readonly appsConfigService: AppsConfigService,
        private readonly configService: ConfigService,
        private readonly languageService: LanguageService,
        private readonly profilesService: ProfilesService,
        private readonly subscriptionsService: SubscriptionsService,
        private readonly settingsService: SettingsService,
        private readonly referralService: ReferralService,
        private readonly referralValuesService: ReferralValuesService,
        private readonly navigationService: NavigationService,
        private readonly queryParamsService: QueryParamsService,
        private readonly adobeDataLayerService: AdobeDataLayerService,
        private readonly groupManagementService: GroupManagementService,
        private readonly icontextService: IContextService
    ) {}

    /**
     * Group all methods that process subscriptions info
     *
     * @private
     * @memberof NavigationGuard
     * @param {none}
     * @returns {void}
     */
    private _doSubscriptionsProcessing(): void {
        // cele 2 procesari pe subscriptii sunt mutate aici pt ca am nevoie de infoarmtii despre cont
        // sa recalculez anumite prorpietati ale subsciptiilor pt subscriptiile shared
        this.subscriptionsService.processSubscriptions();
        this.subscriptionsService.populateFilteredSubscriptionsStructures();
        this.subscriptionsService.setHasZuora();
        this.appsConfigService.computeAppProtectionAllApps({[this.valuesService.appWS]: this.subscriptionsService.hasWinserver()});
    }

    /**
     * Compute data for checking available modules
     *
     * @private
     * @memberof NavigationGuard
     * @param {none}
     * @returns {AccountInfo} the computed info agregated in an object
     */
    private _computeModulesData(): AccountInfo {
        const hasDataPrivacy = this.subscriptionsService.hasDataPrivacy();
        const hasIdTheftProtection = this.subscriptionsService.hasIdTheftProtection();
        const hasWebmailProtection = this.subscriptionsService.hasWebmailProtection();
        const hasOnlyIdentitySubscriptions = this.subscriptionsService.hasOnlyIdentitySubscriptions();
        const hasNoIdentitySubscription = !(hasDataPrivacy || hasIdTheftProtection);
        const noIdentityStandalone = hasNoIdentitySubscription || !hasOnlyIdentitySubscriptions;
        const lang = this.languageService.getLang();
        const hasTechassistLang = this.valuesService.techassistLangs.indexOf(lang) !== -1;
        const isVsbAdmin = this.profilesService.isOwnerVSBAdmin();
        const hasIndividualPlanWithoutParental = this.subscriptionsService.hasIndividualPlanWithNoParental();

        const accountInfo: AccountInfo = {
            lang,
            hasTechassistLang,
            hasDataPrivacy,
            hasIdTheftProtection,
            hasWebmailProtection,
            noIdentityStandalone,
            hasNoIdentitySubscription,
            hasOnlyIdentitySubscriptions,
            hasIndividualPlanWithoutParental,
            hasVpn: this.subscriptionsService.hasVpn(),
            hasMspOrXspLevelOne: this.profilesService.hasMspOrXspLevelOne(),
            hasParentalIncorporated: this.subscriptionsService.hasParental(),
            hasSohoSubscription: this.subscriptionsService.hasSohoSubscription(),
            isPartner: this.configService.config.partner_id === BITDEFENDER_PARTNER_ID,
            hasParentalNCCIncorporated: this.subscriptionsService.hasParentalNCC(),
            hasParentalNCCPremium: this.subscriptionsService.hasParentalNCCPremium(),
            hasReferral: this.referralService.hasReferral(this.referralValuesService.nameSpaces.central),
            isVsbAdmin,
            isVSBGroup: this.profilesService.currentGroupIsVSB(),
            isVSBEmployee: this.profilesService.isOwnerVSBEmployee(),
        };
        return accountInfo;
    }

    // TODO maybe call this function when a new subscription has been bought
    /**
     * Determine if the user has access to the current page and what he can see.
     *
     * @private
     * @memberof NavigationGuard
     * @param {none}
     * @returns {void}
     */
    private _computeAvailableModules(): void {
        // daca ai soho, nu poti sa ai parentalPremium, dataPrivacy, mspSubscription
        // daca ai mspSubscription poti sa ai doar parentalBasic/parentalPremium (nu poti sa ai soho, dpi, parentalStandalone)

        // cand ai msp/xsp nu mai conteaza ce subscriptii vin pe request, contul e considerat de msp
        // si se aplica regulile de la msp
        // la fel si la soho

        // nu poti sa ai parentalStandalone sau dpi concomitent cu soho sau msp/xsp
        // in concluzie nu poti sa ai un alt standalone concomitent cu msp/xsp si soho

        // daca ai dpy atunci apare mereu tabul de dpy
        // daca nu ai dpy, o sa ai un landing page pt marketing dpy
        // acest landing page apare doar cand partenerul e bitdefender(nu oem),
        // cand nu e cont de soho/msp/xsp si cand limba e engleza sau franceza (DPY-2887)

        // Compute account info
        const accountInfo = this._computeModulesData();

        // Compute available modules to be displayed for user
        const showPasswordManager = this._showPasswordManager(accountInfo);
        this.appsConfigService.setShowApp(this.valuesService.appPassManager, showPasswordManager);

        const showPasswordManagerSfr = this._showPasswordManagerSfr();
        this.appsConfigService.setShowApp(this.valuesService.appPassManagerSfr, showPasswordManagerSfr);

        const showDashboard = this._showDashboard(accountInfo);
        this.configService.setDashboard(showDashboard);

        const showBusinessDashboard = this._showBusinessDashboard(accountInfo);
        this.configService.setBusinessDashboard(showBusinessDashboard);

        const showDevices = this._showDevices(accountInfo);
        this.configService.setDevices(showDevices);

        const showIdTheft = this._showIdTheft(accountInfo);
        this.appsConfigService.setShowApp(this.valuesService.appIdTheft, showIdTheft);

        const showDip = this._showDip(accountInfo);
        this.appsConfigService.setShowApp(this.valuesService.appDIP, showDip);

        const showParental = this._showParental(accountInfo);
        this.appsConfigService.setShowApp(this.valuesService.appPA, showParental);

        const showParentalNcc = this._showParentalNCC(accountInfo);
        this.appsConfigService.setShowApp(this.valuesService.appPANCC, showParentalNcc);

        const showVpn = this._showVpn(accountInfo);
        this.appsConfigService.setShowApp(this.valuesService.appVPN, showVpn);

        const showPremiumServices = this._showPremiumServices(accountInfo);
        this.configService.setPremiumServices(showPremiumServices);

        const showOffers = this._showOffers(accountInfo);
        this.configService.setOffers(showOffers);

        const showNotifications = this._showNotifications(accountInfo);
        this.configService.setNotifications(showNotifications);

        const showReferral = this._showReferral(accountInfo);
        this.configService.setReferral(showReferral);

        const showAccountPassword = this._showAccountPassword(accountInfo);
        this.configService.setAccountPaswword(showAccountPassword);

        const showCommunity = this._showCommunity(accountInfo);
        this.configService.setCommunity(showCommunity);

        // Compute available NCC elements to be displayed
        this.setParentalNccElements(accountInfo);
        this.setSecurityModules(accountInfo);
    }

    /**
     * Determine if the user has access to Parental NCC pages and what he can see.
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {void}
     */
    private setParentalNccElements(accountInfo: AccountInfo): void {
        const showTopics = this._showNCCTopics(accountInfo);
        this.configService.setNCCTopics(showTopics);

        const showSafeSearch = this._showNCCSafeSearch(accountInfo);
        this.configService.setNCCSafeSearch(showSafeSearch);

        const showYoutubeRestricted = this._showNCCYoutubeRestricted(accountInfo);
        this.configService.setNCCYoutubeRestricted(showYoutubeRestricted);

        const showReward = this._showNCCReward(accountInfo);
        this.configService.setNCCReward(showReward);

        const showNeedMoreTime = this._showNCCNeedMoreTime(accountInfo);
        this.configService.setNCCNeedMoreTime(showNeedMoreTime);

        const showRoutines = this._showNCCRoutines(accountInfo);
        this.configService.setNCCRoutines(showRoutines);

        const showLocation = this._showNCCLocation(accountInfo);
        this.configService.setNCCLocation(showLocation);
    }

    /**
     * Determine if Password Manager module is available for user
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showPasswordManager(accountInfo: AccountInfo): boolean {
        let showPassManager = false;

        if (accountInfo?.hasMspOrXspLevelOne) {
            showPassManager = this.subscriptionsService.hasPasswordManager();
        } else {
            showPassManager = this.subscriptionsService.hasNoSubscriptions()
                            || this.subscriptionsService.hasPasswordManagerOrCompatibleBundle()
                            || this.subscriptionsService.hasExpiredPasswordManagerOrTrial()
                            || this.subscriptionsService.hasNoActiveSubscriptions();
        }
        return this.appsConfigService.showAppOriginal(this.valuesService.appPassManager) && showPassManager;
    }

    /**
     * Determine if Password Manager Sfr module is available for user
     *
     * @private
     * @memberof NavigationGuard
     * @param {none}
     * @returns {boolean}
     */
    private _showPasswordManagerSfr(): boolean {
        return this.appsConfigService.showAppOriginal(this.valuesService.appPassManagerSfr) && this.subscriptionsService.hasPasswordManager();
    }

    /**
     * Determine which Security modules are accessible for user
     *
     * @private
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see.
     * @memberof NavigationGuard
     */
    private setSecurityModules(accountInfo: AccountInfo): void {
        let showSecurityMenuEntry = (accountInfo?.noIdentityStandalone || accountInfo?.hasWebmailProtection) && this.configService.getOriginalSecurityModule();
        const showSecurityOverview = this.configService.getOriginalSecurityOverview();
        const showSecurityWebmailProtection = this.configService.getOriginalSecurityWebmailProtection()
                                                && accountInfo?.hasWebmailProtection
                                                && this.appsConfigService.showAppOriginal(this.valuesService.appWebmailProtection);
        showSecurityMenuEntry = showSecurityMenuEntry && (showSecurityOverview || showSecurityWebmailProtection);

        this.configService.setSecurityModule(showSecurityMenuEntry);
        this.configService.setSecurityOverview(showSecurityOverview);
        this.configService.setSecurityWebmailProtection(showSecurityWebmailProtection);
    }

    /**
     * Determine if Dashboard module is available for user
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showDashboard(accountInfo: AccountInfo): boolean {
        return this.configService.getOriginalDashboard() && (accountInfo?.noIdentityStandalone || accountInfo?.hasMspOrXspLevelOne);
    }

    /**
     * Determine if Business Dashboard module is available for user
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean} true if the user can see the Business Dashboard, false otherwise
     */
    private _showBusinessDashboard(accountInfo: AccountInfo): boolean {
        return this.configService.getOriginalBusinessDashboard() && accountInfo?.isVsbAdmin;
    }

    /**
     * Determine if Devices module is available for user
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showDevices(accountInfo: AccountInfo): boolean {
        return this.configService.getOriginalDevices() && (accountInfo?.noIdentityStandalone || accountInfo?.hasMspOrXspLevelOne);
    }

    /**
     * Determie if IdTheft module is available for user
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showIdTheft(accountInfo: AccountInfo): boolean {
        let showIdTheft = false;

        if (!this.profilesService.hasMspOrXspAnyLevel()) {
            const hasActivePrimaryIdTheftBundle = this.subscriptionsService.hasActivePrimaryBundle(this.valuesService.appIdTheft);
            const canShowIdTheft = !accountInfo?.hasDataPrivacy
                                    && accountInfo?.lang === this.valuesService.languages.en_US.id
                                    && !accountInfo?.hasSohoSubscription;

            showIdTheft = hasActivePrimaryIdTheftBundle || canShowIdTheft;
        }
        return this.appsConfigService.showAppOriginal(this.valuesService.appIdTheft) && showIdTheft;
    }

    /**
     * Determine if DIP module is available for user
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showDip(accountInfo: AccountInfo): boolean {
        let showDip = true;

        if (!accountInfo?.hasDataPrivacy) {
            showDip = accountInfo?.isPartner
                    && !accountInfo?.hasIdTheftProtection
                    && !accountInfo?.hasSohoSubscription
                    && !accountInfo?.hasMspOrXspLevelOne;
        }
        return this.appsConfigService.showAppOriginal(this.valuesService.appDIP) && showDip;
    }

    /**
     * Determine if VPN module is available for user
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showVpn(accountInfo: AccountInfo): boolean {
        return this.appsConfigService.showAppOriginal(this.valuesService.appVPN)
                && accountInfo?.noIdentityStandalone
                && (!accountInfo?.hasMspOrXspLevelOne || accountInfo?.hasVpn);
    }

    /**
     * Determine if Parental module is available for user
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showParental(accountInfo: AccountInfo): boolean {
        let showParental = false;

        if ((accountInfo?.noIdentityStandalone || accountInfo?.hasNoIdentitySubscription)
            && !(!accountInfo?.hasParentalIncorporated && accountInfo?.hasIndividualPlanWithoutParental)
            && !accountInfo?.hasMspOrXspLevelOne
            && !accountInfo?.hasSohoSubscription
            && !accountInfo?.isVSBGroup) {
            showParental = true;
        }
        if ((accountInfo?.hasMspOrXspLevelOne || accountInfo?.hasSohoSubscription || accountInfo?.isVSBGroup)
            && accountInfo?.hasParentalIncorporated) {
            showParental = true;
        }
        return this.appsConfigService.showAppOriginal(this.valuesService.appPA) && showParental;
    }

    /**
     * Determine if Parental NCC module is available for user
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showParentalNCC(accountInfo: AccountInfo): boolean {
        let showParentalNCC = false;

        if ((accountInfo?.noIdentityStandalone || accountInfo?.hasNoIdentitySubscription)
            && !(!accountInfo?.hasParentalNCCIncorporated && accountInfo?.hasIndividualPlanWithoutParental)
            && !accountInfo?.hasMspOrXspLevelOne
            && !accountInfo?.hasSohoSubscription
            && !accountInfo?.isVSBGroup) {
            showParentalNCC = true;
        }
        if ((accountInfo?.hasMspOrXspLevelOne || accountInfo?.hasSohoSubscription)
            && accountInfo?.hasParentalNCCIncorporated) {
            showParentalNCC = true;
        }

        return this.appsConfigService.showAppOriginal(this.valuesService.appPANCC) && showParentalNCC;
    }

    /**
     * Determine if Premium Services module is available for user
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showPremiumServices(accountInfo: AccountInfo): boolean {
        let showPremiumServices = false;

        if (accountInfo?.hasTechassistLang && this.subscriptionsService.hasPremiumServices()) {
            showPremiumServices = true;
        }
        return this.configService.getOriginalPremiumServices() && showPremiumServices;
    }

    /**
     * Determine if Offers module is available for user
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showOffers(accountInfo: AccountInfo): boolean {
        return this.configService.getOriginalOffers() && !accountInfo?.hasMspOrXspLevelOne && !accountInfo?.isVSBEmployee;
    }

    /**
     * Determine if Notifications are available for user
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showNotifications(accountInfo: AccountInfo): boolean {
        return this.configService.getOriginalNotifications() && accountInfo?.noIdentityStandalone;
    }

    /**
     * Determine if Referral Module is available for user
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showReferral(accountInfo: AccountInfo): boolean {
        return this.configService.getOriginalReferral() && accountInfo?.hasReferral;
    }

    /**
     * Determine if Account Password page is available for user
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showAccountPassword(accountInfo: AccountInfo): boolean {
        return accountInfo?.hasSohoSubscription && this.configService.getOriginalAdminPassword();
    }

    /**
     * Determine if Community Module is available for user
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showCommunity(accountInfo: AccountInfo): boolean {
        return !accountInfo?.hasMspOrXspLevelOne && this.configService.getOriginalCommunity();
    }

    /**
     * Determine if user can see Parental NCC topics
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showNCCTopics(accountInfo: AccountInfo): boolean {
        return this.configService.getOriginalNCCTopics() && accountInfo?.hasParentalNCCPremium;
    }

    /**
     * Determine if user can see Parental NCC safe search
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showNCCSafeSearch(accountInfo: AccountInfo): boolean {
        return this.configService.getOriginalNCCSafeSearch() && accountInfo?.hasParentalNCCPremium;
    }

    /**
     * Determine if user can see Parental NCC youtube restrictions
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showNCCYoutubeRestricted(accountInfo: AccountInfo): boolean {
        return this.configService.getOriginalNCCYoutubeRestricted() && accountInfo?.hasParentalNCCPremium;
    }

    /**
     * Determine if user can see Parental NCC reward card
     *
     * @private
     * @memberof NavigationGuard
    * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showNCCReward(accountInfo: AccountInfo): boolean {
        return this.configService.getOriginalNCCReward() && accountInfo?.hasParentalNCCPremium;
    }

    /**
     * Determine if user can see Parental NCC need more time card
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showNCCNeedMoreTime(accountInfo: AccountInfo): boolean {
        return this.configService.getOriginalNCCNeedMoreTime() && accountInfo?.hasParentalNCCPremium;
    }

    /**
     * Determine if user can see Parental NCC routines card
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showNCCRoutines(accountInfo: AccountInfo): boolean {
        return this.configService.getOriginalNCCRoutines() && accountInfo?.hasParentalNCCPremium;
    }

    /**
     * Determine if user can see Parental NCC locations card
     *
     * @private
     * @memberof NavigationGuard
     * @param {AccountInfo} accountInfo The agregated account info used to determine what the user can see
     * @returns {boolean}
     */
    private _showNCCLocation(accountInfo: AccountInfo): boolean {
        return this.configService.getOriginalNCCLocation() && accountInfo?.hasParentalNCCPremium;
    }

    /**
     * Clean query params from URL if they exist
     *
     * @private
     * @memberof NavigationGuard
     * @param {UrlProperties} urlProperties
     * @returns {UrlTree|boolean}
     */
    private _cleanQueryParams(urlProperties: UrlProperties): Observable<UrlTree|boolean> {
        return of(this.queryParamsService.getCleanedRouteOfCleanQueryParams(urlProperties));
    }

    /**
     * Generate an array with available paths in order of priority
     * Available paths can be turned off from config.service or based on specific use cases
     *
     * @private
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {UrlTree|boolean}
     */
    private _decideMainRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree|boolean> {
        return new Observable(subscriber => {
            const urlProperties = this.navigationService.processUrl(route, state);
            const redirect = [];

            if (this.configService.getDashboard()) {
                redirect.push(this.valuesService.centralPaths.dashboard.id);
            }

            if (this.configService.getDevices()) {
                redirect.push(this.valuesService.centralPaths.devices.id);
            }

            if (this.configService.getBusinessDashboard()) {
                redirect.push(this.valuesService.centralPaths.business.id);
            }

            if (this.appsConfigService.showApp(this.valuesService.appIdTheft)) {
                redirect.push(this.valuesService.centralPaths.idtheftprotection.id);
            }

            if (this.appsConfigService.showApp(this.valuesService.appDIP)) {
                redirect.push(this.valuesService.centralPaths.privacy.id);
            }

            if (this.appsConfigService.showApp(this.valuesService.appPassManager)) {
                redirect.push(this.valuesService.centralPaths.passwordmanager.id);
            }

            if (this.appsConfigService.showApp(this.valuesService.appVPN)) {
                redirect.push(this.valuesService.centralPaths.vpn.id);
            }

            if (this.appsConfigService.showApp(this.valuesService.appPA)) {
                redirect.push(this.valuesService.centralPaths.parental.id);
            }

            if (this.appsConfigService.showApp(this.valuesService.appPANCC)) {
                redirect.push(this.valuesService.centralPaths.parentalncc.id);
            }

            if (this.configService.getSecurityModule()) {
                redirect.push(this.valuesService.centralPaths.security.id);
            }

            if (this.configService.getPremiumServices()) {
                redirect.push(this.valuesService.centralPaths.premium.id);
            }

            if (this.configService.getSubscriptions()) {
                redirect.push(this.valuesService.centralPaths.subscriptions.id);
            }

            if (this.configService.getOffers()) {
                redirect.push(this.valuesService.centralPaths.offers.id);
            }

            if (this.configService.getInternalSupport()) {
                redirect.push(this.valuesService.centralPaths.support.id);
            }

            // pe premium services te lasa mereu sa intri, chiar daca nu apare in stanga, for some reason
            // dar ii scad prioritatea, pentru ca pe pagina apare o modala care te redirecteaza
            if (!this.configService.getPremiumServices() && this.configService.getOriginalPremiumServices()) {
                redirect.push(this.valuesService.centralPaths.premium.id);
            }

            if (this.configService.getReferral()) {
                redirect.push(this.valuesService.centralPaths.referral.id);
            }

            const firstPathSegment = urlProperties.pathPieces[0];
            if (this.valuesService.mainPaths.has(firstPathSegment) && redirect.indexOf(firstPathSegment) === -1) {

                if (!urlProperties.queryString) {
                    const emitedValue = this.router.parseUrl(`/${redirect[0]}`);
                    subscriber.next(emitedValue);
                    subscriber.complete();
                } else {
                    const emitedValue = this.router.parseUrl(`/${redirect[0]}?${urlProperties.queryString}`);
                    subscriber.next(emitedValue);
                    subscriber.complete();
                }
            }

            subscriber.next(true);
            subscriber.complete();

        });
    }

    /**
     * Save all query params before cleaning the URL
     *
     * @private
     * @memberof NavigationGuard
     * @param {UrlProperties} urlProperties
     * @returns {Observable}
     */
    private _saveAllQueryParams (urlProperties: UrlProperties): Observable<boolean> {
        this.queryParamsService.saveQueryParamsNoPersistance(urlProperties.queryObject);
        return of(true);
    }

    /**
     * Contains the requests necessary for deciding the final user interface and redirect
     * All entries in concats are put in order of priorities. Only one path redirect and/or modal redirect should be made.
     * !List owner request must be made first because subscriptionsService.list needs info of the owner
     * ! for (const account of service.accounts) { if (account.email === this.profilesService.getOwnerEmail()).....
     *
     * @private
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {(Observable<boolean|UrlTree>)}
     */
    private processGuardRequests(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree> {
        return new Observable( subscriber => {
            this.profilesService.listOwner()
            .pipe(
                takeUntil(this.onDestroy$),
                concatMap(() => {
                    return forkJoin({
                        subscriptionsServiceList: this.subscriptionsService.list(),
                        profilesServiceList: this.profilesService.list(),
                        settingsServiceListAccountSettings: this.settingsService.listAccountSettings(),
                        settingsServiceListNccSettings: this.settingsService.listNccSettings(),
                        referralServiceListCampaigns: this.referralService.listCampaigns(),
                        groupManagementList: this.groupManagementService.listGroupMembers(this.profilesService.getCurrentGroupId())
                    });
                }),
                map(
                    () => {
                        this.icontextService.createContextsInterface();
                        const urlProperties = this.navigationService.processUrl(route, state);
                        // Process stuff
                        this._doSubscriptionsProcessing();
                        // After processing whatever is needed we compute available modules to display to user
                        this._computeAvailableModules();
                        this.appsConfigService.showInstallButtonForAllApps();
                        concat(
                            this._saveAllQueryParams(urlProperties),
                            // Check if path from link is available for user
                            this._decideMainRoute(route, state),
                            this._cleanQueryParams(urlProperties)
                        )
                        .pipe(
                            takeUntil(this.onDestroy$),
                            map( finalRoute => {
                                if (finalRoute !== true) {
                                    subscriber.next(finalRoute);
                                    subscriber.complete();
                                    // ! Please re test. Concat still goes on despite the fact that we have already a redirect
                                    // ! We should throw error to break the concat.
                                    // throw '';
                                }
                            }),
                            last()
                        ).subscribe({
                            next: () => {
                                this.adobeDataLayerService.setReferringUrl();
                                this.adobeDataLayerService.setTrackingId(this.queryParamsService.getAll());
                                this.adobeDataLayerService.setInternalCampaignId(this.queryParamsService.get(this.valuesService.queryParams.icid));
                                subscriber.next(true);
                                subscriber.complete();
                            }
                        });
                    }
                ),
                catchError(err => {
                    console.error(err);
                    return of(err);
                 })
            ).subscribe();
        });
    }

    /**
     * Guard entrypoint
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {(Observable<boolean|UrlTree>)}
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree> {
        return this.processGuardRequests(route, state);
    }
}
