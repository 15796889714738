// External
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiTooltipComponent } from './ui-tooltip.component';
import { UiTooltipDirective } from './ui-tooltip.directive';
import { DeviceDetectorService } from 'ngx-device-detector';


@NgModule({
    declarations: [
        UiTooltipComponent,
        UiTooltipDirective
    ],
    imports: [
        CommonModule
    ],    
    exports: [ 
        UiTooltipComponent,
        UiTooltipDirective
    ],
    providers: [DeviceDetectorService]
    
})

export class UiTooltipModule {}
