// External
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';

// Internal
import { ValuesService } from '../../../../common/values/values.service';
import { ConnectImmService } from '../../requests/connect-imm-service/connect-imm.service';
import { CookieService } from 'ngx-cookie-service';
import { MessageService } from '../../core/message.service';
import { ConfigService } from '../../../../common/config/config.service';
import { AdobeDataLayerService } from '../../core/adobe.datalayer.service';

@Injectable({
    providedIn: 'root'
})

export class OffersService {

    private readonly onlistOffers$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    markToUpdateOffers = true;

    private readonly offers: any = {};

    constructor(
        private readonly connectImmService: ConnectImmService,
        private readonly cookieService: CookieService,
        private readonly valuesService: ValuesService,
        private readonly messageService: MessageService,
        private readonly adobeDataLayerService: AdobeDataLayerService,
        private readonly configService: ConfigService

    ) { }

    noListing() {
        return !this.configService.getOffers();
    }

    obtainOffers(resp) {
        const offerIds = new Set();
        for (const offer in resp) {
            if (!resp.hasOwnProperty('status') && resp[offer]['width'] !== 1 && resp[offer]['height'] !== 1) {

                if (resp[offer].hasOwnProperty('url')) {
                    resp[offer].url = this.adobeDataLayerService.addRefAndVisitor(resp[offer].url);
                    if (!offerIds.has(resp[offer].url)) {
                        this.offers.templates.push(resp[offer]);
                        this.offers.no++;
                        offerIds.add(resp[offer].url);
                    }
                } else {
                    resp[offer].link = this.adobeDataLayerService.addRefAndVisitor(resp[offer].link);
                    if (!offerIds.has(resp[offer].link)) {
                        this.offers.templates.push(resp[offer]);
                        this.offers.no++;
                        offerIds.add(resp[offer].link);
                    }
                }

            }
        }
    }

    list(): Observable<any> {
        if (!this.markToUpdateOffers || this.noListing()){
            return of(this.offers);
        }

        if (this.onlistOffers$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistOffers$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );

        } else { 
            this.onlistOffers$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.connectImmService.getImm()
                .pipe(
                    map((resp: any) => {
                            if (resp) {
                                this.offers.no = 0;
                                this.offers.templates = [];
                                this.obtainOffers(resp);

                                this.writeOffersCookie();
                                this.markToUpdateOffers = false;
                                this.onlistOffers$.next(this.valuesService.processServiceState.DONE);
                            } else {
                                this.offers.no = 0;
                                this.offers.templates = [];
                                this.writeOffersCookie();
                                this.markToUpdateOffers = true;
                                this.onlistOffers$.next(this.valuesService.processServiceState.DONE);
                                return of('malformed_request');
                            }
                        }
                    ),
                    catchError(err => {
                        this.offers.no = 0;
                        this.offers.templates = [];
                        this.writeOffersCookie();
                        this.markToUpdateOffers = true;
                        this.onlistOffers$.next(this.valuesService.processServiceState.DONE);
                        throw err;
                    }),
                );
        }
    }

    writeOffersCookie(seen = false, sendMessage = false) {
        let value = this.valuesService.offersSeenValue;
        if (!seen && this.offers.no > 0) {
            value = this.valuesService.offersNotSeenValue;
        }
        const oldValue = this.getShowOffers();
        // cookie-ul nu se rescrie la refresh daca el exista deja si e "false"
        if (oldValue !== value && oldValue !== this.valuesService.offersSeenValue) {
            this.cookieService.set(this.valuesService.cookieShowOffers, value, null, '/', '', true);
            if (sendMessage) {
                this.messageService.sendMessage(this.valuesService.events.offersSeen, { id: 'val' });
            }
        }
    }

    private get() {
        return this.offers;
    }

    getNumber(): number {
        return this.offers.no;
    }

    getTemplates() {
        return this.offers.templates ?? [];
    }

    getShowOffers() {
        return this.cookieService.get(this.valuesService.cookieShowOffers);
    }

    updateOffers() {
        if (this.onlistOffers$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateOffers = true;
        }
    }

    resetInProgressFlags() {
        this.onlistOffers$.next(this.valuesService.processServiceState.DONE);
    }

    /**
     * Decides if offers campaign should be shown taking into consideration:
     * * if start + end dates were both set in global config
     * * if the user has offers to be shown
     * @public
     * @param {none}
     * @returns {boolean} if conditions are met the campaign will be shown else it wil be not
     */
    public showOffersCampaign(): boolean {

        const campaignStart = this.valuesService.offersCampaign.date.start();
        const campaignEnd = this.valuesService.offersCampaign.date.end();

        if (!campaignStart || !campaignEnd || !this.getNumber()) {
            return false;
        }
        const nowTimestamp = new Date().getTime();
        const startTimestamp = new Date(campaignStart).getTime();
        const endTimestamp = new Date(campaignEnd).getTime();

        return (nowTimestamp >= startTimestamp && nowTimestamp < endTimestamp);
    }
}
