// External
import { Injectable } from '@angular/core';
import { Observable, of, throwError, BehaviorSubject, forkJoin } from 'rxjs';
import { catchError, skipWhile, map } from 'rxjs/operators';

// Internal
import { SettingsMgmtService } from '../../requests/connect-settings/connect-settings.service';
import { ValuesService } from '../../../../common/values/values.service';
import { UsefulService } from '../../global/useful/useful.service';
import { AccountSetting, AccountSettings, DeviceSetting, NccSetting, NccSettings } from './settings.model';
import { ConfigService } from '../../../../common/config/config.service';
import { MessageService } from '../../core/message.service';

@Injectable({
    providedIn: 'root'
})

export class SettingsService {

    registerSettings: any = [];
    settings: any = {};
    newSettings = {};

    accountSettings: AccountSettings = {};
    dipSettings: any = {};
    private nccSettings: NccSettings = {};

    private atLeastOneSuccessfulSettingsRequest = false;

    private markToUpdateAccountSettings = true;
    readonly onListAccountSettings$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);

    private markToUpdateDipSettings = true;
    readonly onListDipSettings$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);

    private markToUpdateNccSettings = true;
    private readonly onListNccSettings$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);

    readonly onSetBoarded$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    readonly onSetOnboardingComplete$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    readonly onSetShowTwoFaAlert$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    readonly onSetShowSharedSubscriptionAlert$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    readonly onSetShowMasterPasswordAlert$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    readonly onSetSecurityWasAccessed$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onSetNccPin$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onSetUpgradeParentalAlert$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);

    readonly onListDevicesSettings$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);

    private markToUpdate = {
        [this.valuesService.deviceSettings.pin]: {},
        [this.valuesService.deviceSettings.network]: {},
        [this.valuesService.deviceSettings.automaticUpgrade]: {}
    };

    private readonly settingsInProgress = {
        [this.valuesService.deviceSettings.pin]: new Set(),
        [this.valuesService.deviceSettings.network]: new Set(),
        [this.valuesService.deviceSettings.automaticUpgrade]: new Set()
    };

    constructor(
        private readonly settingsMgmtService:   SettingsMgmtService,
        private readonly valuesService:         ValuesService,
        private readonly usefulService:         UsefulService,
        private readonly configService:         ConfigService,
        private readonly messageService:        MessageService
    ) {
        /* Used for updating the request in order to write them into th db by the service worker */
        this.messageService.getDynamicSubject(this.valuesService.events.resetRequestsForServiceWorker)
        .subscribe({
            next: () => {
                this.updateAccountSettings();
                this.updateNccSettings();
                this.updateAllDeviceSettings();
            }
        });
    }

    getBatchSettingsForDevices(devices): Array<any> {
        let batchSettings = [];
        const batchesOfBatchSettings = [];
        this.newSettings = {};

        for (const device of devices) {
            if (!device.apps) {
                continue;
            }

            const deviceId = device.device_id;
            this.newSettings[deviceId] = {};
            for (const app of device.apps) {
                const appId = app.app_id;
                const arrayOfSettingsPerApp = this.valuesService.settingsForApps[appId];

                if (!arrayOfSettingsPerApp) {
                    continue;
                }

                this.newSettings[deviceId][appId] = new Set();
                for (const appSetting of arrayOfSettingsPerApp) {
                    this.newSettings[deviceId][appId].add(appSetting);
                    batchSettings = this.addSettingToBatches(batchSettings, batchesOfBatchSettings, deviceId, appId, appSetting);
                }
            }
        }

        if (batchSettings.length > 0) {
            batchesOfBatchSettings.push([...batchSettings]);
        }

        return batchesOfBatchSettings;
    }

    addSettingToBatches(batchSettings, batchesOfBatchSettings, deviceId, appId, appSetting) {
        const markToUpdateDevice = this.usefulService.getNested(this.markToUpdate, true, appSetting, deviceId);
        const inProgress = this.settingsInProgress[appSetting] ? this.settingsInProgress[appSetting].has(deviceId): false;

        if (markToUpdateDevice && !inProgress) {
            batchSettings.push({
                device_id: deviceId,
                app_id: appId,
                setting: appSetting
            });
        }

        if (batchSettings.length >= this.valuesService.serverRequestsLimit) {
            batchesOfBatchSettings.push([...batchSettings]);
            batchSettings = [];
        }

        return batchSettings;
    }

    listOneBatchOfDeviceSettings(batchSettings) {
        return this.settingsMgmtService.getOne(batchSettings)
        .pipe(
            map(resp => {
                const batchResponseLength = Math.min(resp.length, batchSettings.length);
                for (let i = 0; i <  batchResponseLength; i++) {
                    const batchSetting = batchSettings[i];
                    this.settings[batchSetting.device_id] = this.settings[batchSetting.device_id] ? this.settings[batchSetting.device_id] : {};
                    this.settings[batchSetting.device_id][batchSetting.app_id] = this.settings[batchSetting.device_id][batchSetting.app_id]
                                                                                ? this.settings[batchSetting.device_id][batchSetting.app_id] : {};
                    this.settings[batchSetting.device_id][batchSetting.app_id][batchSetting.setting] = resp[i];
                    this.markToUpdate[batchSetting.setting][batchSetting.device_id] = false;
                    this.settingsInProgress[batchSetting.setting].delete(batchSetting.device_id);
                }
                this.atLeastOneSuccessfulSettingsRequest = true;
                return of(true);
            }),
            catchError(err => of(err))
        );
    }

    deleteOldSettings() {
        for (const deviceId in this.settings) {
            if (!this.newSettings[deviceId]) {
                delete this.settings[deviceId];
                continue;
            }

            for (const app in this.settings[deviceId]) {
                if (!this.newSettings[deviceId].hasOwnProperty(app)) {
                    delete this.settings[deviceId][app];
                    continue;
                }

                for (const setting in this.settings[deviceId][app]) {
                    if (!this.newSettings[deviceId][app].has(setting)) {
                        delete this.settings[deviceId][app][setting];
                    }
                }
            }
        }
    }

    listDeviceSettings(devices): Observable<any> {
        const batchesOfBatchSettings = this.getBatchSettingsForDevices(devices);
        const batchesRequests = [of(true)];

        for (const batch of batchesOfBatchSettings) {
            batchesRequests.push(this.listOneBatchOfDeviceSettings(batch));
        }

        return forkJoin(batchesRequests)
        .pipe(
            map(() => of(true)),
            catchError(() => of(true))
        );
    }

    updateAllDeviceSettings() {
        this.markToUpdate[this.valuesService.deviceSettings.pin] = {};
        this.markToUpdate[this.valuesService.deviceSettings.network] = {};
        this.markToUpdate[this.valuesService.deviceSettings.automaticUpgrade] = {};
    }

    updateDeviceSettings(deviceId) {
        if (!deviceId) {
            return;
        }

        for (const setting in this.markToUpdate) {
            this.markToUpdate[setting][deviceId] = true;
        }
    }

    /**
     * Marks given setting as needing an update for given device id
     * @public
     * @memberof SettingsService
     * @param {DeviceSetting} setting The name of the setting
     * @param {string} deviceId The device id
     */
    public updateOneDeviceSetting(setting: DeviceSetting, deviceId: string): void {
        if (!setting || !deviceId) {
            return;
        }

        if (this.markToUpdate[setting]) {
            this.markToUpdate[setting][deviceId] = true;
        }
    }

    devicesNeedUpdate() {
        return this.atLeastOneSuccessfulSettingsRequest;
    }

    listAccountSettings(): Observable<any> {
        if (!this.markToUpdateAccountSettings) {
            return of(this.accountSettings);
        }

        if (this.onListAccountSettings$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListAccountSettings$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.onListAccountSettings$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.settingsMgmtService.getAll()
            .pipe(
                map(resp => {
                    if (resp) {
                        this.accountSettings = resp;
                        this.markToUpdateAccountSettings = false;
                        this.onListAccountSettings$.next(this.valuesService.processServiceState.DONE);
                    }
                    else {
                        this.markToUpdateAccountSettings = true;
                        this.onListAccountSettings$.next(this.valuesService.processServiceState.DONE);
                    }
                }),
                catchError(err => {
                    this.markToUpdateAccountSettings = true;
                    this.onListAccountSettings$.next(this.valuesService.processServiceState.DONE);
                    return of(err);
                })
            );
        }
    }

    setSnapPhoto(newSnapSetting) {
        return this.settingsMgmtService.setOne(AccountSetting.snapPhoto, newSnapSetting, this.valuesService.connectAppId)
        .pipe(
            map(resp => {
                if (resp) {
                    this.accountSettings.snapPhoto = newSnapSetting;
                } else {
                    return throwError(resp);
                }
            }),
            catchError(err => {
                throw(err);
            })
        );
    }

    /**
     * Sets the value for 'device_list_banner_appearance' account setting
     * @public
     * @memberof SettingsService
     * @param {string} newBannerSetting The banner setting, if exists
     * @returns {Observable} Success when everything finished
     */
    public setBannerSetting(newBannerSetting?: string): Observable<any> {
        const value = newBannerSetting ?? null;
        return this.settingsMgmtService.setOne(AccountSetting.device_list_banner_appearance, value)
        .pipe(
            map(resp => {
                if (resp) {
                    this.accountSettings[AccountSetting.device_list_banner_appearance] = value;
                }
            }),
            catchError(err => of(err))
        );
    }

    /**
     * Sets the value for 'securityWasAccessed' setting.
     * @public
     * @memberof SettingsService
     * @param {boolean} newSecurityWasAccessedSeeting True if security was accessed, false otherwise.
     * @returns {Observable<any>} Success when everything finished.
     */
    public setSecurityWasAccessed(newSecurityWasAccessedSeeting?: boolean): Observable<any> {
        const value = newSecurityWasAccessedSeeting ?? null;

        if (this.accountSettings[AccountSetting.securityWasAccessed] === value || (!this.accountSettings[AccountSetting.securityWasAccessed] && !value)) {
            return of(true);
        }

        if (this.onSetSecurityWasAccessed$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListDipSettings$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            return this.settingsMgmtService.setOne(AccountSetting.securityWasAccessed, value)
            .pipe(
                map(resp => {
                    if (resp) {
                        this.accountSettings[AccountSetting.securityWasAccessed] = !!value;
                    }
                    this.onSetSecurityWasAccessed$.next(this.valuesService.processServiceState.DONE);
                }),
                catchError(err => {
                    this.onSetSecurityWasAccessed$.next(this.valuesService.processServiceState.DONE);
                    return of(err);
                })
            );
        }
    }

    /**
     * Function that returns 'securityWasAccessed' value from account settings.
     * @returns {boolean} the value of securityWasAccessed setting, with default being false (security was not accessed).
     */
    public getSecurityWasAccessedSetting(): boolean {
        return !!this.accountSettings?.[AccountSetting.securityWasAccessed];
    }

    listDipSettings(): Observable<any> {
        if(!this.markToUpdateDipSettings){
            return of(this.dipSettings);
        }

        if (this.onListDipSettings$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListDipSettings$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.onListDipSettings$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.settingsMgmtService.getAll(this.valuesService.appDIP)
            .pipe(
                map(resp => {
                    if (resp) {
                        this.dipSettings = resp;
                        this.markToUpdateDipSettings = false;
                        this.onListDipSettings$.next(this.valuesService.processServiceState.DONE);
                    }
                    else {
                        this.markToUpdateDipSettings = true;
                        this.onListDipSettings$.next(this.valuesService.processServiceState.DONE);
                    }
                }),
                catchError(err => {
                    this.markToUpdateDipSettings = true;
                    this.onListDipSettings$.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    /**
     * List nccparental settings
     * @param {none}
     * @returns {Observable} Success when everything finished
     */
    public listNccSettings(): Observable<any> {
        if (!this.markToUpdateNccSettings || !this.configService.getNCCPin()) {
            return of(this.nccSettings);
        }

        if (this.onListNccSettings$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListNccSettings$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.onListNccSettings$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.settingsMgmtService.getAll(this.valuesService.appPANCC, this.valuesService.parentalControl.device_id)
            .pipe(
                map(resp => {
                    if (resp) {
                        this.nccSettings = resp;
                        this.markToUpdateNccSettings = false;
                        this.onListNccSettings$.next(this.valuesService.processServiceState.DONE);
                    }
                    else {
                        this.markToUpdateNccSettings = true;
                        this.onListNccSettings$.next(this.valuesService.processServiceState.DONE);
                    }
                }),
                catchError(err => {
                    this.markToUpdateNccSettings = true;
                    this.onListNccSettings$.next(this.valuesService.processServiceState.DONE);
                    return of(err);
                })
            );
        }
    }

    /**
     * Sets the value for 'ncc_pin' setting
     * @param {number} nccPin
     * @returns {Observable} Success when everything finished
     */
    public setNccPin(nccPin: number): Observable<any> {
        if (this.onSetNccPin$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onSetNccPin$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.onSetNccPin$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.settingsMgmtService.setOne(NccSetting.ncc_pin, nccPin, this.valuesService.appPANCC, this.valuesService.parentalControl.device_id)
            .pipe(
                map(resp => {
                    if (resp) {
                        this.onSetNccPin$.next(this.valuesService.processServiceState.DONE);
                    }
                }),
                catchError(err => {
                    this.onSetNccPin$.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    /**
     * Set ncc setting value
     * @public
     * @memberof SettingsService
     * @param {string} setting setting name
     * @param {boolean|number} value setting value
     * @returns {Observable} Success when everything finised
     */
    public setNccSetting(setting: string, value: boolean|number): Observable<any> {
        return this.settingsMgmtService.setOne(setting, value, this.valuesService.appPANCC, this.valuesService.parentalControl.device_id)
        .pipe(
            map(resp => {
                if (resp) {
                    this.nccSettings[setting] = value;
                }
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    /**
     * Get parental pin code setting
     * @param {none}
     * @returns {NccSettings} settings
     */
    public getPinCodeSetting(): number {
        return this.nccSettings[NccSetting.ncc_pin];
    }

    /**
     * Function that returns 'nccOnboardingComplete' value from ncc settings
     * @param {nothing}
     * @returns {boolean} the value of nccOnboardingComplete setting, with default being false
     */
    public getNccOnboardingComplete(): boolean {
        return this.nccSettings?.[NccSetting.nccOnboardingComplete] ?? false;
    }

    /**
     * Function that returns 'showUpdateDevicesAlert' value from ncc settings
     * @param {nothing}
     * @returns {boolean} the value of showUpdateDevicesAlert setting, with default being true
     */
    public getShowUpdateDevicesAlert(): boolean {
        return this.nccSettings?.[NccSetting.showUpdateDevicesAlert] ?? true;
    }

    getDeviceSettings(deviceId) {
        return this.settings[deviceId];
    }

    getAccountSettings() {
        return this.accountSettings;
    }

    getLimitReachedBannerSetting() {
        return this.accountSettings?.device_list_banner_appearance ? this.accountSettings?.device_list_banner_appearance : '';
    }

    getDipSettings() {
        return this.dipSettings;
    }

    /**
     * Sets the value for 'boarded' account setting
     * @public
     * @memberof SettingsService
     * @returns {Observable} Success when everything finised
     */
    public setBoarded(): Observable<any> {
        if (this.onSetBoarded$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListAccountSettings$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.onSetBoarded$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.settingsMgmtService.setOne(AccountSetting.boarded, true)
            .pipe(
                map(resp => {
                    if (resp) {
                        this.accountSettings[AccountSetting.boarded] = true;
                        this.onSetBoarded$.next(this.valuesService.processServiceState.DONE);
                    }
                }),
                catchError(err => {
                    sessionStorage.setItem(this.valuesService.sessionStorageModalStatus.firstLoginMade, 'true');
                    this.onSetBoarded$.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    isFirstLogin() {
        // boarded = true inseamna ca nu este primul login
        // daca nu gasesc campul boarded = false, sau acesta nu exista, inseamna ca e primul login
        const boarded = this.usefulService.getNested(this.accountSettings, false, AccountSetting.boarded);
        return !boarded;
    }

    /**
     * Sets the value for 'onbordingComplete' account setting
     * @public
     * @memberof SettingsService
     * @returns {Observable} Success when everything finised
     */
    public setOnboardingComplete(): Observable<any> {
        if (this.accountSettings[AccountSetting.onbordingComplete]) {
            return of(true);
        }

        if (this.onSetOnboardingComplete$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListAccountSettings$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.onSetOnboardingComplete$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.settingsMgmtService.setOne(AccountSetting.onbordingComplete, true)
            .pipe(
                map(resp => {
                    if (resp) {
                        this.accountSettings[AccountSetting.onbordingComplete] = true;
                        this.onSetOnboardingComplete$.next(this.valuesService.processServiceState.DONE);
                    }
                }),
                catchError(err => {
                    this.onSetOnboardingComplete$.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    /**
     * Resets the value for 'onbordingComplete' account setting
     */
    public resetOnboardingComplete(): void {
        this.onSetOnboardingComplete$.next(this.valuesService.processServiceState.DONE);
        this.accountSettings[AccountSetting.onbordingComplete] = false;
    }

    isOnboardingComplete() {
        // daca onboardingComplete = true => onboardingul a fost facut
        // daca onboardingComplete nu exista in accountSettings => false => onboardingul nu a fost facut
        return this.usefulService.getNested(this.accountSettings, false, AccountSetting.onbordingComplete);
    }

    /**
     * Sets the value for 'showTwoFaAlert' setting
     * @param {boolean} value
     * @returns {Observable} Success when everything finished
     */
    public setShowTwoFaAlert(value: boolean): Observable<any> {
        if (this.accountSettings[AccountSetting.showTwoFaAlert] === value) {
            return of(true);
        }

        if (this.onSetShowTwoFaAlert$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListAccountSettings$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.onSetShowTwoFaAlert$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.settingsMgmtService.setOne(AccountSetting.showTwoFaAlert, value)
            .pipe(
                map(resp => {
                    if (resp) {
                        this.accountSettings[AccountSetting.showTwoFaAlert] = value;
                        this.onSetShowTwoFaAlert$.next(this.valuesService.processServiceState.DONE);
                        return of(true);
                    }
                }),
                catchError(err => {
                    this.onSetShowTwoFaAlert$.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    /**
     * Function that returns 'showTwoFaAlert' value from account settings
     * @param {nothing}
     * @returns {boolean} the value of showTwoFaAlert setting, with default being true (shown 2FA)
     */
    public getShowTwoFaAlert(): boolean {
        return this.accountSettings?.[AccountSetting.showTwoFaAlert] ?? true;
    }

    /**
     * Sets the value for 'showMasterPasswordAlert' setting
     * @param {boolean} value
     * @returns {Observable} Success when everything finished
     */
    public setShowMasterPasswordAlert(value: boolean): Observable<any> {
        if (this.accountSettings[AccountSetting.showMasterPasswordAlert] === value) {
            return of(true);
        }

        if (this.onSetShowMasterPasswordAlert$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListAccountSettings$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.onSetShowMasterPasswordAlert$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.settingsMgmtService.setOne(AccountSetting.showMasterPasswordAlert, value)
            .pipe(
                map(resp => {
                    if (resp) {
                        this.accountSettings[AccountSetting.showMasterPasswordAlert] = value;
                        this.onSetShowMasterPasswordAlert$.next(this.valuesService.processServiceState.DONE);
                        return of(true);
                    }
                }),
                catchError(err => {
                    this.onSetShowMasterPasswordAlert$.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    /**
     * Function that returns 'showMasterPasswordAlert' value from account settings
     * @param {nothing}
     * @returns {boolean} the value of showMasterPasswordAlert setting, with default being true (shown master password alert)
     */
    public getShowMasterPasswordAlert(): boolean {
        return this.accountSettings?.[AccountSetting.showMasterPasswordAlert] ?? true;
    }

    /**
     * Sets the value for 'showParentalUpgradeAlert' setting
     * @param {boolean} value
     * @returns {Observable} Success when everything finished
     */
    public setShowParentalUpgradeAlert(value: boolean): Observable<any> {
        if (this.accountSettings[AccountSetting.showParentalUpgradeAlert] === value) {
            return of(true);
        }

        if (this.onSetUpgradeParentalAlert$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListAccountSettings$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.onSetUpgradeParentalAlert$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.settingsMgmtService.setOne(AccountSetting.showParentalUpgradeAlert, value)
            .pipe(
                map(resp => {
                    if (resp) {
                        this.accountSettings[AccountSetting.showParentalUpgradeAlert] = value;
                        this.onSetUpgradeParentalAlert$.next(this.valuesService.processServiceState.DONE);
                        return of(true);
                    }
                }),
                catchError(err => {
                    this.onSetUpgradeParentalAlert$.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    /**
     * Function that returns 'showParentalUpgradeAlert' value from account settings
     * @param {nothing}
     * @returns {boolean} the value of showParentalUpgradeAlert setting, with default being true (shown upgrade parental alert)
     */
    public getShowParentalUpgradeAlert(): boolean {
        return this.accountSettings?.[AccountSetting.showParentalUpgradeAlert] ?? true;
    }

    get() {
        return this.settings;
    }

    /**
     * Marks list account settings request to be updated
     * @public
     * @memberof SettingsService
     */
    public updateAccountSettings(): void {
        this.markToUpdateAccountSettings = true;
    }

    /**
     * Marks list ncc settings request to be updated
     * @public
     * @memberof SettingsService
     */
    public updateNccSettings(): void {
        this.markToUpdateNccSettings = true;
    }

    /**
     * Sets the value for 'showSharedSubscriptionAlert' setting
     * @public
     * @memberof SettingsMgmtService
     * @param {boolean} settingValue False if user closed the DIP alert, true otherwise
     * @returns {Observable} Request response
     */
    public setShowSharedSubscriptionAlert(settingValue: boolean): Observable<any> {
        if (this.accountSettings[AccountSetting.showSharedSubscriptionAlert] === settingValue) {
            return of(true);
        }

        if (this.onSetShowSharedSubscriptionAlert$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListDipSettings$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            return this.settingsMgmtService.setOne(AccountSetting.showSharedSubscriptionAlert, settingValue)
            .pipe(
                map(resp => {
                    if (resp) {
                        this.accountSettings[AccountSetting.showSharedSubscriptionAlert] = !!settingValue;
                        this.onSetShowSharedSubscriptionAlert$.next(this.valuesService.processServiceState.DONE);
                    }
                }),
                catchError(err => {
                    this.onSetShowSharedSubscriptionAlert$.next(this.valuesService.processServiceState.DONE);
                    return of(err);
                })
            );
        }
    }

    /**
     * Returns 'showSharedSubscriptionAlert' value from account settings
     * @public
     * @memberof SettingsMgmtService
     * @returns {boolean} The value of 'showSharedSubscriptionAlert' setting if exists, otherwise returns True
     */
    public getShowSharedSubscriptionAlert(): boolean {
        return this.accountSettings?.[AccountSetting.showSharedSubscriptionAlert] ?? true;
    }

}
