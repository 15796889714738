// External
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, map, catchError } from 'rxjs';

// Internal
import { Errors, POST, RequestsService } from '../../global/request-service/requests.service';
import { ValuesService } from '../../../values/values.service';
import { ConfigService } from '../../../../common/config/config.service';
import { LinksService } from '../../../../common/links/links.service';

@Injectable({
    providedIn: 'root'
})

export class ConnectWebmailProtectionService {

    constructor(
        private readonly cookieService: CookieService,
        private readonly requestsService: RequestsService,
        private readonly valuesService: ValuesService,
        private readonly configService: ConfigService,
        private readonly linksService: LinksService,
        private readonly http: HttpClient
    ) { }

    /**
     * This method is used to ist all inboxes registered on the user’s account
     *
     * @public
     * @memberof ConnectWebmailProtectionService
     * @param {none}
     * @returns {Observable} Response from server
     */
    public listInboxes(): Observable<any> {
        const json = {
            id: parseInt((Math.random() * 100000).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'list_inboxes',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.webmailProtectionMgmtService,
            json,
            POST
        ).pipe(
            map(resp => {
                if (resp?.result) {
                    return resp.result;
                } else {
                    throw resp;
                }
            }),
            catchError(error => {
                throw(error);
            })
        );
    };

    /**
     * This method is used to retrieve the scan statistics for webmail protection for the last 30 days
     *
     * @public
     * @memberof ConnectWebmailProtectionService
     * @param {none}
     * @returns {Observable} Response from server
     */
    public listProtectionStats(): Observable<any> {
        const json = {
            id: parseInt((Math.random() * 100000).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'get_protection_stats',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.webmailProtectionMgmtService,
            json,
            POST
        ).pipe(
            map(resp => {
                if (resp?.result) {
                    return resp.result;
                } else {
                    throw resp;
                }
            }),
            catchError(error => {
                throw(error);
            })
        );
    }

    /**
     * This method is used to retrieve the encryption keys for the webmail protection service
     *
     * @public
     * @memberof ConnectWebmailProtectionService
     * @param {none}
     * @returns {Observable} Response from server
     */
    public listKeys(): Observable<any> {
        return this.http.get(this.linksService.getWebmailProtectionListKeysUrl())
        .pipe(
            map((resp: any) => {
                if (Array.isArray(resp?.keys)) {
                    return resp.keys;
                } else {
                    throw resp;
                }
            }),
            catchError(error => {
                throw(error);
            })
        );
    }

    /**
     * This method is used to update the inbox configuration (pause or resume scanning)
     *
     * @public
     * @memberof ConnectWebmailProtectionService
     * @param {string} emailAddress the email address to be configured
     * @param {boolean} isScanActive the id of the services used for subscription by the device registering the inbox
     * @param {string} deviceId the id of the device registered on the inbox
     * @returns {Observable} Response from server
     */
    public updateInbox(emailAddress: string, isScanActive: boolean): Observable<any> {
        if (!emailAddress) {
            return of(Errors.INVALID_PARAMS);
        }

        const json = {
            id: parseInt((Math.random() * 100000).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'update_inbox',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                email: emailAddress,
                scan_active: isScanActive
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.webmailProtectionMgmtService,
            json,
            POST
        ).pipe(
            map(resp => {
                if (resp?.result) {
                    return resp.result;
                } else {
                    throw resp;
                }
            }),
            catchError(error => {
                throw(error);
            })
        );
    }

    /**
     * This method is used to remove an inbox from the list of registered inboxes for webmail protection scanning
     *
     * @public
     * @memberof ConnectWebmailProtectionService
     * @param {string} emailAddress the email address to be removed
     * @param {string} deviceId the id of the device registered on the inbox
     * @returns {Observable} Response from server
     */
    public removeInbox(emailAddress: string): Observable<any> {
        if (!emailAddress) {
            return of(Errors.INVALID_PARAMS);
        }

        const json = {
            id: parseInt((Math.random() * 100000).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'remove_inbox',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                email: emailAddress
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.webmailProtectionMgmtService,
            json,
            POST
        ).pipe(
            map(resp => {
                if (resp?.result) {
                    return resp.result;
                } else {
                    throw resp;
                }
            }),
            catchError(error => {
                throw(error);
            })
        );
    }

    /**
     * This method is used to retrieve the account email activity for the last 30 days.
     *
     * @public
     * @memberof ConnectWebmailProtectionService
     * @param {none}
     * @returns {Observable} Response from server
     */
    public listInboxActivity(): Observable<any> {
        const json = {
            id: parseInt((Math.random() * 100000).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'get_inbox_activity',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.webmailProtectionMgmtService,
            json,
            POST
        ).pipe(
            map(resp => {
                if (resp?.result) {
                    return resp.result;
                } else {
                    throw resp;
                }
            }),
            catchError(error => {
                throw(error);
            })
        );
    }

}
