export enum RecommendationCardTypes {
    PROTECTION = 'protection',
    UPGRADE = 'upgrade',
    VPN = 'vpn'
}

export class RecommendationCardsData {
    [RecommendationCardTypes.PROTECTION] = {};
    [RecommendationCardTypes.UPGRADE] = {};
    [RecommendationCardTypes.VPN] = {};
}

export class ActiveScenario  {
    flowSteps: number[] = [];
    currentStep: number = 0;
    event: null;
    params: ScenarioParams;
};

export class ScenarioParams {
    bundleId: string;
    bundleType: string;

    constructor(id = '', type = '') {
        this.bundleId = id;
        this.bundleType = type;
    }
}
