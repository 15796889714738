import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ReferralValuesService {

    constructor() {}

    nameSpaces = {
        central: 'central',
        dataPrivacy: 'dataprivacy'
    };

    centralCampaignId = 'CentralWeb'; // trebuie schimbat cu numele campaniei care o sa fie

    invalidEmailCodeError = 32001;

    referralCodeParam = 'referral_code';

    expiresInLimit = 43200000; // 12h in milliseconds

    referralCongratsCase = {
        CONGRATS_ACTIVATE     : 'congratsActivate',
        ALREADY_ACTIVATED     : 'alreadyActivated',
        ERROR_ACTIVATION      : 'errorActivation',
        ERROR_SOURCE_USE_CODE : 'errorSourceUseCode',
        ERROR_EXPIRED_CODE    : 'errorExpiredCode'
    };

    codeError = {
        code: 39001,
        messages: {
            alreadyUsed: 'User is not eligible',
            sourceUseCode: 'Could not use your own referral code',
            expiredCode: 'Referral code is expired'
        }
    }

    congratsCaseBasedOnError = {
        [this.codeError.messages.alreadyUsed]   : this.referralCongratsCase.ALREADY_ACTIVATED,
        [this.codeError.messages.sourceUseCode] : this.referralCongratsCase.ERROR_SOURCE_USE_CODE,
        [this.codeError.messages.expiredCode]   : this.referralCongratsCase.ERROR_EXPIRED_CODE
    };

    omnitureEvents = {
        invite: 'invite',
        copyLink: 'copyLink',
        modalCases: {
            [this.referralCongratsCase.ERROR_ACTIVATION]      : 'error',
            [this.referralCongratsCase.ERROR_SOURCE_USE_CODE] : 'sourceUseCode',
            [this.referralCongratsCase.ERROR_EXPIRED_CODE]    : 'expiredCode',
            [this.referralCongratsCase.ALREADY_ACTIVATED]     : 'guestAlreadyUsedCode',
            [this.referralCongratsCase.CONGRATS_ACTIVATE]     : {
                source: 'congratsSource',
                guest: 'congratsGuest'
            }
        }
    };
}