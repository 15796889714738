// External
import { CookieService } from "ngx-cookie-service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";

// Internal
import { RequestsService } from "../../global/request-service/requests.service";
import { ValuesService } from "../../../values/values.service";
import { ConfigService } from "../../../../common/config/config.service";

@Injectable({
    providedIn: "root"
})

export class ParentalcontrolSettingsService {
    constructor(
        readonly cookieService: CookieService,
        readonly requestsService: RequestsService,
        readonly valuesService: ValuesService,
        readonly configService: ConfigService
    ) { }

    getAccountSettings(fields): Observable<any> {
        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "getAccountSettings",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: this.valuesService.parentalControl.device_id,
                    app_id: this.valuesService.parentalControl.app_id
                }
            }
        };

        if (fields && fields.length) {
            json.params["fields"] = fields;
        }

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalControlSettingsService,
            json,
            "POST"
        ).pipe(
            map((resp) => {
                return resp.result;
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    editAccountSettings(info) {
        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "editAccountSettings",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: this.valuesService.parentalControl.device_id,
                    app_id: this.valuesService.parentalControl.app_id
                }
            }
        };

        if (info.hasOwnProperty("alert")) {
            json["params"]["alert"] = info["alert"];
        }

        if (info.hasOwnProperty("email")) {
            json["params"]["email"] = info["email"];
        }

        if (info.hasOwnProperty("report_frequency")) {
            json["params"]["report_frequency"] = info["report_frequency"];
        }

        if (info.hasOwnProperty("report_active")) {
            json["params"]["report_active"] = info["report_active"];
        }

        json["params"]["alert_active"] = true;

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalControlSettingsService,
            json,
            'POST'
        ).pipe(
            map( (resp: any) => {
                return resp.result;
            }),
            catchError((error) => {
                throw error;
            })
        );
    };

}
