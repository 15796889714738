// External
import { Injectable } from '@angular/core';
import { Observable, map, catchError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

// Internal
import { ConfigService } from '../../../../common/config/config.service';
import { RequestsService } from '../../global/request-service/requests.service';
import { ValuesService } from '../../../values/values.service';

@Injectable({
    providedIn: 'root'
})

export class SubscriptionInviteService {

    private readonly counterMaxValue = 100;

    constructor(
        private readonly cookieService      : CookieService,
        private readonly requestsService    : RequestsService,
        private readonly valuesService      : ValuesService,
        private readonly configService      : ConfigService
    ) {}

    /**
     * Makes the request and returns the response from server
     * @private
     * @memberof SubscriptionInviteService
     * @param {object} requestBody The request object
     * @returns {Observable} The request response
     */
    private makeRequest(requestBody: object): Observable<any> {
        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.subscriptionInviteService,
            requestBody,
            'POST'
        ).pipe(
            map(resp => {
                if (resp?.result?.status === 0) {
                    return resp.result;
                } else {
                    throw resp;
                }
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * Makes the batch request and returns the response from server
     * @private
     * @memberof SubscriptionInviteService
     * @param {array} batchRequestBody The array of objects
     * @returns {Observable} The request response
     */
    private makeBatchRequest(batchRequestBody: Array<object>): Observable<any> {
        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.subscriptionInviteService,
            batchRequestBody,
            'POST'
        ).pipe(
            map(results => {
                const response = {
                    error: false,
                    success: false
                };

                if (!Array.isArray(results)) {
                    throw results;
                }

                for (const resp of results) {
                    if (resp?.result?.status === 0) {
                        response.success = true;
                    } else {
                        response.error = true;
                    }
                }

                // If no valid response was received, throw error
                if (!response.success) {
                    throw new Error('error');
                }
                return response;
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    /**
     * Sends a batch request to create an invite for a specified email address.
     * Only one invite can be present on a service at a time.
     * The invite will expire after 24 hours if the invited user won't activate the invite.
     * @public
     * @memberof SubscriptionInviteService
     * @param {array} emailsArr The array of emails to be invited
     * @param {string} serviceId The service id of the subscription
     * @param {string} ageGroup The account category used for family plans (adult/teenager)
     * @returns {Observable} The request response
     */
    public createInvite(serviceId: string, emailsArr: Array<string>, ageGroup?: string): Observable<any> {
        const batch = [];
        let counter = parseInt((Math.random() * this.counterMaxValue).toString(), 10);

        for (const email of emailsArr) {
            const json: any = {
                id: counter,
                jsonrpc: this.valuesService.jsonrpc,
                method: 'create_invite',
                params: {
                    connect_source: {
                        user_token: this.cookieService.get(this.valuesService.cookieToken),
                        device_id: this.valuesService.connectDeviceId,
                        app_id: this.valuesService.connectAppId
                    },
                    service_id: serviceId,
                    email
                }
            };

            if (ageGroup) {
                json.params.age_group = ageGroup;
            }

            batch.push(json);
            counter++;
        }
        return this.makeBatchRequest(batch);
    }

    /**
     * Sends a batch of requests to cancel an invite by removing it from the pending invites collection.
     * @public
     * @memberof SubscriptionInviteService
     * @param {array} inviteIds The array of invite links
     * @returns {Observable} The request response
     */
    public cancelInvite(inviteIds: Array<string>): Observable<any> {
        const batch = [];
        let counter = parseInt((Math.random() * this.counterMaxValue).toString(), 10);

        for (const inviteId of inviteIds) {
            const json = {
                id: counter,
                jsonrpc: this.valuesService.jsonrpc,
                method: 'cancel_invite',
                params: {
                    connect_source: {
                        user_token: this.cookieService.get(this.valuesService.cookieToken),
                        device_id: this.valuesService.connectDeviceId,
                        app_id: this.valuesService.connectAppId
                    },
                    invite_id: inviteId
                }
            };
            batch.push(json);
            counter++;
        }
        return this.makeBatchRequest(batch);
    }

    /**
     * Activates the invite using the 'invite_link' and the 'connect_source' of the invited user.
     * @public
     * @memberof SubscriptionInviteService
     * @param {string} inviteLink The invite link
     * @returns {Observable} The request response
     */
    public activateInvite(inviteLink: string): Observable<any> {
        const json = {
            id: parseInt((Math.random() * this.counterMaxValue).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'activate_invite',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                invite_link: inviteLink
            }
        };
        return this.makeRequest(json);
    }

    /**
     * Retrieves the sent invites with it's information from a user.
     * @public
     * @memberof SubscriptionInviteService
     * @returns {Observable} The request response
     */
    public retrieveInvites(): Observable<any> {
        const json = {
            id: parseInt((Math.random() * this.counterMaxValue).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'retrieve_invites',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };
        return this.makeRequest(json);
    }
}
