// External
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, Observable, throwError } from 'rxjs';
import { timeout, catchError, map, finalize } from 'rxjs/operators';
import { tv4 } from 'tv4';

// Internal
import { IssuesMgmtService } from '../../requests/connect-issues-mgmt/connect-issues-mgmt.service';
import { UtilsCommonService } from '../../../../common/utils/utils-common.service';

@Injectable({
    providedIn: 'root'
})

export class IssuesService {

    issues: any = {};
    lock: boolean = false;

    constructor(
        private iIssuesMgmt: IssuesMgmtService,
        private router: Router,
        private utilsCommonService: UtilsCommonService
    ) {}

    list(): Observable<any> {
        if (!this.utilsCommonService.isEmptyObject(this.issues)) {
            return of(this.issues);
        }

        return this.iIssuesMgmt.getAllIssues()
            .pipe(
                map(
                    resp => {
                        this.lock = true;
                        if (!resp.issues) {
                            this.issues = {};
                        } else {
                            this.issues = resp.issues;
                        }
    
                        return of(this.issues);
                    },
                    err => {
                        this.lock = false;
                        return of(err);
                    }
                ),
                catchError( (err)=> {
                    throw err;
                }),
                finalize(
                    () => {
                        this.lock = false;
                        console.log('onCompleted');
                    }
                )
            )

    }

    get() {
        return this.issues;
    }

    set(data) {
        if (this.utilsCommonService.isObject(data)) {
            this.issues = JSON.parse(JSON.stringify(data));
        } else {
            console.error('Invalid params', tv4.error);
        }
    }

    remove() {
        this.issues = JSON.parse(JSON.stringify({}));
    }
}
