import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValuesSupportService } from '../../pages/support/values/values-support.service';
import { ConfigService } from '../config/config.service';
import { AllocationCases, DeviceAllocationDisplayComponents, ServiceApplicationsDevicesAllocationCases, ServiceSlotsAllocationCases, ServiceType } from '../models/Services.model';
import { ValuesService } from './values.service';
import { ModalName } from '../components/ui/ui-modal-routeless/modal.routeless.service';
import { ALL_PLATFORMS, ALL_PLATFORMS_NO_WINSERVER } from '../services/interfaces/isubscriptions/isubscriptions.service';
import { UiButton } from '../components/ui/ui-button/ui-button.models';

export enum AppLevels {
    BASIC = 'basic',
    PREMIUM = 'premium',
    STANDARD = 'standard',
    IS = 'is',
    TS = 'ts',
    AV = 'av',
    EPP = 'epp',
    EPP_PRIVACY = 'epp_privacy',
    LITE = 'lite',
    NONE = 'none'
}

export type AppByLevelStrings = {
    [key in AppLevels]?: string;
};

export interface AppExtraDetails {
    [key: string]: AppByLevelStrings
}


//*
//*  Upsell types, interfaces and design
//*
//#region
export interface UpsellScenario {
    description: string;
    cta: string;
}
export type UpsellScenariosByType = {
    [key in UpsellTypes]?: UpsellScenario;
}
export type UpsellScenarioByStatus = {
    [key in SubscriptionStatus]?: UpsellScenario;
}
export type UpsellScenariosByTypeAndStatus = {
    [key in UpsellTypes]?: UpsellScenarioByStatus;
}

export enum UpsellDesigns {
    DARK = 'dark',
    LIGHT = 'light'
}
export interface UpsellDesign {
    className: UpsellDesigns,
    ctaModel: UiButton.Model,
    ctaColor: string
}

export type UpsellDesignTypes = {
    [key in UpsellDesigns]?: UpsellDesign;
}

export const upsellDesign: UpsellDesignTypes = {
    [UpsellDesigns.DARK]: {
        className: UpsellDesigns.DARK,
        ctaModel: UiButton.Model.OUTLINE,
        ctaColor: 'inherit'
    },
    [UpsellDesigns.LIGHT]: {
        className: UpsellDesigns.LIGHT,
        ctaModel: UiButton.Model.SOLID,
        ctaColor: 'primary'
    }
};
//#endregion

export const SubscriptionsV3Prefix = 'V3SUBS';

export const QueryParams = {
    SUB_ID: 'sub_id',
    CODE: 'code',
    SERVICE: 'service'
};

export enum QueryParamsValues {
    ADD_CODE = 'add_code',
    ADD_TRIAL = 'add_trial',
    ADD_INVITE = 'add_invite',
    INSTALL = 'install',
    REDEEEM = 'redeem',
    JOIN_GROUP = 'join_group'
};

export enum SubscriptionsSdkErrors {
    SERVICE_ERROR = 'service_error',
    SERVICE_TIMEOUT = 'service_timeout',
    INTERNAL_ERROR = 'internal_error',
    DEFAULT_ERROR = 'error'
}

export enum SubscriptionStatus {
    EXPIRED = 'EXPIRED',
    ACTIVE = 'ACTIVE',
    CANCELED ='CANCELED'
}

export enum SubscriptionInviteStatus {
    ACTIVE = 'active',
    INVITED = 'invited',
    REMOVING = 'removing'
}

export enum SharedSubscriptionScenarios {
    STANDALONE = 'standalone',
    FAMILYPLAN = 'familyPlan',
    BUSINESSPLAN = 'businessPlan'
}

export enum UpsellTypes {
    ZUORA = 'zuora', // not used rigth now showConvert is hardcoded to be false
    FREE = 'free',
    TRIAL = 'trial',
    EXPIRED = 'expired',
    ARROFF = 'autoRenewOFF',
    USEDUP = 'usedUp'
};

export const DigitalRiverPlatformAccount = 'defender';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionsValuesService {

    constructor(
        readonly valuesService: ValuesService,
        readonly configService: ConfigService,
        readonly translate: TranslateService,
        readonly supportValuesService: ValuesSupportService
    ) { }

    defaultCurrency = "EUR";

    cancelationPeriod = 90;
    refundPeriod = 30;
    limitYearPayments = 3000;
    softBankValidity = 5;

    softBankBundle = this.valuesService.bundleBOX2;

    // In days:
    year  = 365;
    triennal = 1095;
    biennal = 730;
    monthDays = 30;
    fourMonthsDays = 120;
    twoMonthsDays = 60;
    weekDays = 7;
    // In seconds:
    month = 2592000;
    day = 86400;
    fiveYears = 157680000;

    // Slots numbers limit cases
    slotsNo = {
        limit: 50,
        intermediate: 45,
        single: 1,
        none: 0
    };

    serviceType = {
        license: 'license',
        subscription: 'subscription'
    };

    accountType = {
        primary: 'primary',
        secondary: 'secondary'
    };

    type = {
        nfr: 'nfr',
        trial: 'trial',
        free: 'free',
        end_user: 'end_user',
        registered: 'registered',
        invalid: 'invalid'  // Not a real subscription type, used for expired buy links
    };

    lifeCycle = {
        lifetime: 'lifetime',
        one_time: 'one-time',
        recurrent: 'recurrent'
    };

    status = {
        expired: 'EXPIRED',
        active: 'ACTIVE',
        canceled: 'CANCELED'
    };

    uncountableApps = new Set([
        this.valuesService.appPA,
        this.valuesService.appPANCC
    ]);

    // Messages sent to other components
    messages = {
        uiModalClose: 'uiModalClose'
    };

    // Campaigns
    campaigns = {
        fake: 'fake',
        churnPrevention: 'ChurnPrevention',
        centralConvert: 'CENTRAL-CONVERT',
        subscription50Off: '50OFF-subscription'
    };

    // Subscriptions plans
    plans = {
        startPlan: "START PLAN",
        personalPlan: "PERSONAL PLAN",
        familyPlan: "FAMILY PLAN",
        ultimatePlan: "ULTIMATE PLAN"
    };

    sourceType = {
        zuora: 'zuora',
        internal: 'internal',
        gplay: 'gplay',
        appstore: 'appstore',
        offline: 'offline',
        avangate: 'avangate',
        digitalRiver: 'digital_river',
        verifone: 'verifone',
        msp: 'msp'
    };

    excludedSourceTypesForARFlow = new Set([
        this.sourceType.zuora,
        this.sourceType.avangate,
        this.sourceType.digitalRiver
    ]);

    cycles = {
        monthlyPaymentPeriod:   'Month',
        monthlyBillingPeriod:   'Month',
        yearlyPaymentPeriod:    'Year',
        yearlyBillingPeriod:    'Annual',
        biennialPaymentPeriod:  'Two Years',
        biennialBillingPeriod:  'Two_Years',
        triennialPaymentPeriod: 'Three years',
        triennialBillingPeriod: 'Three_Years'
    };

    termsAndConditionsTLD = {
        'cs_CZ': 'com',
        'de_DE': 'de',
        'ar_SA': 'com',
        'en_AU': 'com',
        'en_GB': 'com',
        'en_US': 'com',
        'el_GR': 'com',
        'es_ES': 'es',
        'fr_CA': 'fr',
        'fr_FR': 'fr',
        'it_IT': 'it',
        'ja_JP': 'com',
        'ko_KR': 'com',
        'nl_NL': 'nl',
        'pl_PL': 'pl',
        'pt_BR': 'pt',
        'pt_PT': 'pt',
        'ro_RO': 'ro',
        'tr_TR': 'com',
        'vi_VN': 'com',
        'ru_RU': 'ru',
        'hu_HU': 'com',
        'sv_SE': 'com',
        'th_TH': 'com'
    };

    avangateMessages = {
        autoRenewalOff: "CONFIRM AR OFF",
        autoRenewalOn:  "CONFIRM AR ON",
        autoRenewalEnabled: "license-renewal-auto-enabled",
        autoRenewalDisabled: "license-renewal-auto-disabled"
    };

    avangateOmnitureEvents = {
        stopAutoRenewal: "stop-ar",
        enableAutoRenewal: "enable-ar"
    };

    paymentScenarios = {
        updateAccountBillingDetails: 'updateAccountBillingDetails'
    };

    churnPreventionOmnitureEvents = {
        offer:    'offer',
        reason:   'reason',
        benefits: 'benefits',
        finish:   'finish',
        disabled: 'disabled',
        refunded: 'refunded',
        cancel:   'cancel',
        enabled:   'enabled'
    };

    churnPreventionConfirmScenarios = {
        acceptOffer: 'acceptOffer'
    };

    openBillingModal = "openBillingModal";

    churnPreventionCycles = {
        [this.year]: {
            payment: this.cycles.yearlyPaymentPeriod,
            billing: this.cycles.yearlyBillingPeriod
        },
        [this.biennal]: {
            payment: this.cycles.biennialPaymentPeriod,
            billing: this.cycles.biennialBillingPeriod
        },
        [this.triennal]: {
            payment: this.cycles.triennialPaymentPeriod,
            billing: this.cycles.triennialBillingPeriod
        },
        [this.monthDays]: {
            payment: this.cycles.monthlyPaymentPeriod,
            billing: this.cycles.monthlyBillingPeriod
        },
        default: {
            payment: this.cycles.monthlyPaymentPeriod,
            billing: this.cycles.monthlyBillingPeriod
        }
    };

    endReasonsChurnPrevention = [
        {
            label: 'subscriptions.endservice.reason.purchasedifferentproduct',
            refundReason: this.supportValuesService.refundReasons['purchasedifferentproduct'],
            clickEventName: 'PurchaseDifferentProduct'
        },
        {
            label: 'subscriptions.endservice.reason.dontneedtheproduct',
            refundReason: this.supportValuesService.refundReasons['dontneedtheproduct'],
            clickEventName: 'DontNeedProduct'
        },
        {
            label: 'subscriptions.endservice.reason.issueswiththeproduct',
            clickEventName: 'IssuesWithProduct'
        },
        {
            label: 'subscriptions.endservice.reason.notsatisfiedwithproduct',
            refundReason: this.supportValuesService.refundReasons['notsatisfiedwithproduct'],
            clickEventName: 'NotSatisfiedWithProduct'
        }
    ];

    schemaRedeemErrorMessages = {
        200: 'subscription.redeem.error.EMPTY',
        202: 'subscription.redeem.error.INVALID'
    };

    deviceAllocationOptions = {
        remove: 'remove',
        change: 'change'
    };

    deviceAllocationChoice = {
        remove: -1,
        keep: -2
    };

    strings = {
        // AR
        autoRenewError:  'subscriptions.cancel.autorenew.error',
        // Device Allocation Modal
        usedByDevicesLicense: 'subscriptions.deviceallocation.usedbydevices.license',
        usedByDevicesSubscription: 'subscriptions.deviceallocation.usedbydevices.subscription',
        usedByDeviceLicense: 'subscriptions.deviceallocation.usedbydevice.license',
        usedByDeviceSubscription: 'subscriptions.deviceallocation.usedbydevice.subscription',
        freeSlotDevicesLicense: 'subscriptions.deviceallocation.freeslotdevices.license',
        freeSlotDevicesSubscription: 'subscriptions.deviceallocation.freeslotdevices.subscription',
        freeSlotDeviceLicense: 'subscriptions.deviceallocation.freeslotdevice.license',
        freeSlotDeviceSubscription: 'subscriptions.deviceallocation.freeslotdevice.subscription',
        freeSlotDevicesOutOfLicense: 'subscriptions.deviceallocation.freeslotdevicesoutof2.license',
        freeSlotDevicesOutOfSubscription: 'subscriptions.deviceallocation.freeslotdevicesoutof2.subscription',
        willHaveDevicesLicense: 'subscriptions.deviceallocation.willhavedevices.license',
        willHaveDevicesSubscription: 'subscriptions.deviceallocation.willhavedevices.subscription',
        willHaveDeviceLicense: 'subscriptions.deviceallocation.willhavedevice.license',
        willHaveDeviceSubscription: 'subscriptions.deviceallocation.willhavedevice.subscription',
        usedByBoxLicense: 'subscriptions.deviceallocation.usedbybox.license',
        usedByBoxSubscription: 'subscriptions.deviceallocation.usedbybox.subscription',
        serviceWillEndLicense: 'subscriptions.deviceallocation.servicewillend.license',
        willHaveDevicesOutOfLicense: 'subscriptions.deviceallocation.willhavedevicesoutof.license',
        willHaveDevicesOutOfSubscription: 'subscriptions.deviceallocation.willhavedevicesoutof.subscription',
        willHaveDeviceOutOfLicense:  'subscriptions.deviceallocation.willhavedeviceoutof.license',
        willHaveDeviceOutOfSubscription:  'subscriptions.deviceallocation.willhavedeviceoutof.subscription',
        // other
        freeSlotDevicesOutOf: 'subscriptions.deviceallocation.freeslotdevicesoutof2',
        notUsed: 'subscriptions.servicedetails.notused',
        // Service Item Details
        wasActiveOnDevices: 'subscriptions.services.wasactivedevices',
        wasActiveOnDevice: 'subscriptions.services.wasactivedevice',
        // Common strings:
        activeOnDevices: 'subscriptions.services.activeondevices',
        activeOnDevice: 'subscriptions.services.activeondevice',
        activeOutOfDevice: 'subscriptions.services.activeoftotaldevice',
        activeOutOfDevices: 'subscriptions.services.activeoftotal',
        limitReached: 'subscriptions.services.limitreached',
        // shorter texts
        onDevices: 'subscriptions.services.ondevices',
        onDevice: 'subscriptions.services.ondevice',
        outOfDevice: 'subscriptions.services.oftotaldevice',
        outOfDevices: 'subscriptions.services.oftotal'
    };

    generalUsageCases = {
        [AllocationCases.LIMIT_REACHED]: {
            [AllocationCases.ABOVE_INTERMIDIATE]: this.strings.activeOutOfDevices,
            [AllocationCases.ABOVE_INTERMIDIATE_BOX]: this.strings.activeOnDevices,
            [AllocationCases.ONE]: this.strings.activeOnDevice,
            [AllocationCases.UNDER_INTERMIDIATE]: this.strings.activeOnDevices,
            [AllocationCases.LIMIT_REACHED]: this.strings.limitReached,
            [AllocationCases.ZERO]: this.strings.activeOnDevices
        },
        [AllocationCases.MORE_THAN_ONE]: this.strings.activeOutOfDevices,
        [AllocationCases.ONE]: this.strings.activeOutOfDevice
    };

    public readonly appExtraDetails: AppExtraDetails = {
        [this.valuesService.appVPN]: {
            [AppLevels.BASIC]: 'subscriptions.appVPN.usage.new',
            [AppLevels.PREMIUM]: 'subscriptions.appVPN.premium.usage'
        }
    };

    installIncentives = {
        [this.valuesService.appAVFM]: 'subscriptions.install.incentive.avformac',
        [this.valuesService.appCL]: {
            [AppLevels.AV]: 'subscriptions.install.incentive.cl.av',
            [AppLevels.IS]: 'subscriptions.install.incentive.cl.is',
            [AppLevels.TS]: 'subscriptions.install.incentive.cl.ts',
            [AppLevels.EPP]: 'subscriptions.install.incentive.cl.av',
            [AppLevels.EPP_PRIVACY]: 'subscriptions.install.incentive.cl.ts'
        },
        [this.valuesService.appBMS]: 'subscriptions.install.incentive.bms',
        [this.valuesService.appBIP]: 'subscriptions.install.incentive.iosprotection',
        [this.valuesService.appBIS]: 'subscriptions.install.incentive.iossecurity',
        [this.valuesService.appWS]: 'subscriptions.install.incentive.ws',
        [this.valuesService.appPA]:  'subscriptions.install.incentive.parentaladvisor',
        [this.valuesService.appPANCC]: 'subscriptions.install.incentive.parentaladvisor',
        [this.valuesService.appVPN]: {
            [AppLevels.BASIC]: 'subscriptions.install.incentive.vpn.basic',
            [AppLevels.PREMIUM]: 'subscriptions.install.incentive.vpn.basic'
        }
    };

    serviceSlotsAllocationCases: ServiceSlotsAllocationCases = {
        [AllocationCases.LIMIT_REACHED]: {
            [AllocationCases.LIMIT_REACHED]: {
                [DeviceAllocationDisplayComponents.STEP1_HEADER]: {
                    [ServiceType.LICENSE]: this.strings.usedByDevicesLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.usedByDevicesSubscription
                },
                [DeviceAllocationDisplayComponents.STEP2_REMOVE]: {
                    [ServiceType.LICENSE]: this.strings.freeSlotDevicesLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.freeSlotDevicesSubscription
                },
                [DeviceAllocationDisplayComponents.STEP2_USAGE]: {
                    [ServiceType.LICENSE]: this.strings.limitReached,
                    [ServiceType.SUBSCRIPTION]: this.strings.limitReached
                },
                [DeviceAllocationDisplayComponents.STEP2_WILL_HAVE]: {
                    [ServiceType.LICENSE]: this.strings.willHaveDevicesLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.willHaveDevicesSubscription
                },
                [DeviceAllocationDisplayComponents.USED_BY_BOX]: {
                    [ServiceType.LICENSE]: this.strings.usedByBoxLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.usedByBoxSubscription
                },
                [DeviceAllocationDisplayComponents.SERVICE_WILL_END]: {
                    [ServiceType.LICENSE]: this.strings.serviceWillEndLicense
                }
            },
            [AllocationCases.ABOVE_INTERMIDIATE]: {
                [DeviceAllocationDisplayComponents.STEP1_HEADER]: {
                    [ServiceType.LICENSE]: this.strings.usedByDevicesLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.usedByDevicesSubscription
                },
                [DeviceAllocationDisplayComponents.STEP2_REMOVE]: {
                    [ServiceType.LICENSE]: this.strings.freeSlotDevicesLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.freeSlotDevicesSubscription
                },
                [DeviceAllocationDisplayComponents.STEP2_USAGE]: {
                    [ServiceType.LICENSE]: this.strings.activeOutOfDevices,
                    [ServiceType.SUBSCRIPTION]: this.strings.activeOutOfDevices
                },
                [DeviceAllocationDisplayComponents.STEP2_WILL_HAVE]: {
                    [ServiceType.LICENSE]: this.strings.willHaveDevicesLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.willHaveDevicesSubscription
                },
                [DeviceAllocationDisplayComponents.USED_BY_BOX]: {
                    [ServiceType.LICENSE]: this.strings.usedByBoxLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.usedByBoxSubscription
                },
                [DeviceAllocationDisplayComponents.SERVICE_WILL_END]: {
                    [ServiceType.LICENSE]: this.strings.serviceWillEndLicense
                },
            },
            [AllocationCases.ABOVE_INTERMIDIATE_BOX]: {
                [DeviceAllocationDisplayComponents.STEP1_HEADER]: {
                    [ServiceType.LICENSE]: this.strings.usedByDevicesLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.usedByDevicesSubscription
                },
                [DeviceAllocationDisplayComponents.STEP2_REMOVE]: {
                    [ServiceType.LICENSE]: this.strings.freeSlotDevicesLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.freeSlotDevicesSubscription
                },
                [DeviceAllocationDisplayComponents.STEP2_USAGE]: {
                    [ServiceType.LICENSE]: this.strings.activeOutOfDevices,
                    [ServiceType.SUBSCRIPTION]: this.strings.activeOutOfDevices
                },
                [DeviceAllocationDisplayComponents.STEP2_WILL_HAVE]: {
                    [ServiceType.LICENSE]: this.strings.willHaveDevicesLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.willHaveDevicesSubscription
                },
                [DeviceAllocationDisplayComponents.USED_BY_BOX]: {
                    [ServiceType.LICENSE]: this.strings.usedByBoxLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.usedByBoxSubscription
                },
                [DeviceAllocationDisplayComponents.SERVICE_WILL_END]: {
                    [ServiceType.LICENSE]: this.strings.serviceWillEndLicense
                },
            },
            [AllocationCases.ONE]: {
                [DeviceAllocationDisplayComponents.STEP1_HEADER]: {
                    [ServiceType.LICENSE]: this.strings.usedByDeviceLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.usedByDeviceSubscription
                },
                [DeviceAllocationDisplayComponents.STEP2_REMOVE]: {
                    [ServiceType.LICENSE]: this.strings.freeSlotDeviceLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.freeSlotDeviceSubscription
                },
                [DeviceAllocationDisplayComponents.STEP2_USAGE]: {
                    [ServiceType.LICENSE]: this.strings.activeOnDevice,
                    [ServiceType.SUBSCRIPTION]: this.strings.activeOnDevice
                },
                [DeviceAllocationDisplayComponents.STEP2_WILL_HAVE]: {
                    [ServiceType.LICENSE]: this.strings.willHaveDevicesLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.willHaveDevicesSubscription
                },
                [DeviceAllocationDisplayComponents.USED_BY_BOX]: {
                    [ServiceType.LICENSE]: this.strings.usedByBoxLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.usedByBoxSubscription
                },
                [DeviceAllocationDisplayComponents.SERVICE_WILL_END]: {
                    [ServiceType.LICENSE]: this.strings.serviceWillEndLicense
                },
            },
            [AllocationCases.UNDER_INTERMIDIATE]: {
                [DeviceAllocationDisplayComponents.STEP1_HEADER]: {
                    [ServiceType.LICENSE]: this.strings.usedByDevicesLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.usedByDevicesSubscription
                },
                [DeviceAllocationDisplayComponents.STEP2_REMOVE]: {
                    [ServiceType.LICENSE]: this.strings.freeSlotDevicesLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.freeSlotDevicesSubscription
                },
                [DeviceAllocationDisplayComponents.STEP2_USAGE]: {
                    [ServiceType.LICENSE]: this.strings.activeOnDevices,
                    [ServiceType.SUBSCRIPTION]: this.strings.activeOnDevices
                },
                [DeviceAllocationDisplayComponents.STEP2_WILL_HAVE]: {
                    [ServiceType.LICENSE]: this.strings.willHaveDevicesLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.willHaveDevicesSubscription
                },
                [DeviceAllocationDisplayComponents.USED_BY_BOX]: {
                    [ServiceType.LICENSE]: this.strings.usedByBoxLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.usedByBoxSubscription
                },
                [DeviceAllocationDisplayComponents.SERVICE_WILL_END]: {
                    [ServiceType.LICENSE]: this.strings.serviceWillEndLicense
                }
            },
            [AllocationCases.ZERO]: {
                [DeviceAllocationDisplayComponents.STEP1_HEADER]: {
                    [ServiceType.LICENSE]: this.strings.usedByDevicesLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.usedByDevicesSubscription
                },
                [DeviceAllocationDisplayComponents.STEP2_REMOVE]: {
                    [ServiceType.LICENSE]: this.strings.freeSlotDevicesLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.freeSlotDevicesSubscription
                },
                [DeviceAllocationDisplayComponents.STEP2_USAGE]: {
                    [ServiceType.LICENSE]: this.strings.activeOnDevices,
                    [ServiceType.SUBSCRIPTION]: this.strings.activeOnDevices
                },
                [DeviceAllocationDisplayComponents.STEP2_WILL_HAVE]: {
                    [ServiceType.LICENSE]: this.strings.willHaveDeviceLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.willHaveDeviceSubscription
                },
                [DeviceAllocationDisplayComponents.USED_BY_BOX]: {
                    [ServiceType.LICENSE]: this.strings.usedByBoxLicense,
                    [ServiceType.SUBSCRIPTION]: this.strings.usedByBoxSubscription
                },
                [DeviceAllocationDisplayComponents.SERVICE_WILL_END]: {
                    [ServiceType.LICENSE]: this.strings.serviceWillEndLicense
                }
            }
        },
        [AllocationCases.MORE_THAN_ONE]: {
            [DeviceAllocationDisplayComponents.STEP1_HEADER]: {
                [ServiceType.LICENSE]: this.strings.usedByDevicesLicense,
                [ServiceType.SUBSCRIPTION]: this.strings.usedByDevicesSubscription
            },
            [DeviceAllocationDisplayComponents.STEP2_REMOVE]: {
                [ServiceType.LICENSE]: this.strings.freeSlotDevicesOutOfLicense,
                [ServiceType.SUBSCRIPTION]: this.strings.freeSlotDevicesOutOfSubscription
            },
            [DeviceAllocationDisplayComponents.STEP2_USAGE]: {
                [ServiceType.LICENSE]: this.strings.activeOutOfDevices,
                [ServiceType.SUBSCRIPTION]: this.strings.activeOutOfDevices
            },
            [DeviceAllocationDisplayComponents.STEP2_WILL_HAVE_SINGLE]: {
                [ServiceType.LICENSE]: this.strings.willHaveDeviceOutOfLicense,
                [ServiceType.SUBSCRIPTION]: this.strings.willHaveDeviceOutOfSubscription
            },
            [DeviceAllocationDisplayComponents.STEP2_WILL_HAVE_MULTI]: {
                [ServiceType.LICENSE]: this.strings.willHaveDevicesOutOfLicense,
                [ServiceType.SUBSCRIPTION]: this.strings.willHaveDevicesOutOfSubscription
            },
            [DeviceAllocationDisplayComponents.USED_BY_BOX]: {
                [ServiceType.LICENSE]: this.strings.usedByBoxLicense,
                [ServiceType.SUBSCRIPTION]: this.strings.usedByBoxSubscription
            },
            [DeviceAllocationDisplayComponents.SERVICE_WILL_END]: {
                [ServiceType.LICENSE]: this.strings.serviceWillEndLicense
            }
        },
        [AllocationCases.ONE]: {
            [DeviceAllocationDisplayComponents.STEP1_HEADER]: {
                [ServiceType.LICENSE]: this.strings.usedByDeviceLicense,
                [ServiceType.SUBSCRIPTION]: this.strings.usedByDeviceSubscription
            },
            [DeviceAllocationDisplayComponents.STEP2_REMOVE]: {
                [ServiceType.LICENSE]: this.strings.freeSlotDeviceLicense,
                [ServiceType.SUBSCRIPTION]: this.strings.freeSlotDeviceSubscription
            },
            [DeviceAllocationDisplayComponents.STEP2_USAGE]: {
                [ServiceType.LICENSE]: this.strings.activeOutOfDevice,
                [ServiceType.SUBSCRIPTION]: this.strings.activeOutOfDevice
            },
            [DeviceAllocationDisplayComponents.STEP2_WILL_HAVE_SINGLE]: {
                [ServiceType.LICENSE]: this.strings.willHaveDeviceOutOfLicense,
                [ServiceType.SUBSCRIPTION]: this.strings.willHaveDeviceOutOfSubscription
            },
            [DeviceAllocationDisplayComponents.STEP2_WILL_HAVE_MULTI]: {
                [ServiceType.LICENSE]: this.strings.willHaveDevicesOutOfLicense,
                [ServiceType.SUBSCRIPTION]: this.strings.willHaveDevicesOutOfSubscription
            },
            [DeviceAllocationDisplayComponents.USED_BY_BOX]: {
                [ServiceType.LICENSE]: this.strings.usedByBoxLicense,
                [ServiceType.SUBSCRIPTION]: this.strings.usedByBoxSubscription
            },
            [DeviceAllocationDisplayComponents.SERVICE_WILL_END]: {
                [ServiceType.LICENSE]: this.strings.serviceWillEndLicense
            }
        }
    };

    serviceApplicationsDevicesAllocationCases: ServiceApplicationsDevicesAllocationCases = {
        [AllocationCases.LIMIT_REACHED]: this.strings.onDevices,
        [AllocationCases.ABOVE_INTERMIDIATE]: this.strings.onDevices,
        [AllocationCases.ONE]: this.strings.onDevice,
        [AllocationCases.UNDER_INTERMIDIATE]: this.strings.onDevices,
        [AllocationCases.ZERO]: this.strings.notUsed,
    };

    public readonly serviceAppsPriority = [
        ALL_PLATFORMS,
        ALL_PLATFORMS_NO_WINSERVER,
        this.valuesService.appProtection,
        ...this.valuesService.freeProtectionAppsArray,
        ...this.valuesService.paidProtectionAppsArray,
        this.valuesService.appVPN,
        this.valuesService.appPassManager,
        ... this.valuesService.parentalAppsArray,
        this.valuesService.appDIP,
        this.valuesService.appBA,
        this.valuesService.appIdTheft
    ];

    hiddenCardDigits = '**** **** **** ';

    cards = {
        applepay:      'Apple Pay',
        mastercard:    'Mastercard',
        maestro:       'Maestro',
        visa:          'Visa',
        amex:          'Amex card',
        bancontact:    'Bancontact debit card',
        cartebancaire: 'Carte Bancaire',
        unionpay:      'UnionPay card',
        diners:        'Diners card',
        discover:      'Discover card',
        jcb: {
            card:      'JCB card',
            credit:    'JCB credit card',
            debit:     'JCB debit card',
            prepaid:   'JCB prepaid card'
        }
    };

    cardTypes = {
        // Mastercard
        mc:                               this.cards.mastercard,
        mcdebit:                          this.cards.mastercard,
        mccredit:                         this.cards.mastercard,
        mcstandardcredit:                 this.cards.mastercard,
        mcstandarddebit:                  this.cards.mastercard,
        mcpremiumcredit:                  this.cards.mastercard,
        mcpremiumdebit:                   this.cards.mastercard,
        mcsuperpremiumcredit:             this.cards.mastercard,
        zer:                              this.cards.mastercard,
        mccommercialcredit:               this.cards.mastercard,
        mccommercialdebit:                this.cards.mastercard,
        mccommercialpremiumcredit:        this.cards.mastercard,
        mccommercialpremiumdebit:         this.cards.mastercard,
        mccorporatecredit:                this.cards.mastercard,
        mccorporatedebit:                 this.cards.mastercard,
        mcpurchasingcredit:               this.cards.mastercard,
        mcpurchasingdebit:                this.cards.mastercard,
        mcfleetcredit:                    this.cards.mastercard,
        mcfleetdebit:                     this.cards.mastercard,
        mcpro:                            this.cards.mastercard,
        mc_applepay:                      this.cards.mastercard,
        mc_androidpay:                    this.cards.mastercard,
        bijcard:                          this.cards.mastercard,
        // Maestro
        maestro:                          this.cards.maestro,
        // Visa
        visa:                             this.cards.visa,
        visadebit:                        this.cards.visa,
        visacredit:                       this.cards.visa,
        visastandardcredit:               this.cards.visa,
        visastandarddebit:                this.cards.visa,
        visapremiumcredit:                this.cards.visa,
        visapremiumdebit:                 this.cards.visa,
        visasuperpremiumcredit:           this.cards.visa,
        visasuperpremiumdebit:            this.cards.visa,
        visacommercialcredit:             this.cards.visa,
        visacommercialdebit:              this.cards.visa,
        visacommercialpremiumcredit:      this.cards.visa,
        visacommercialpremiumdebit:       this.cards.visa,
        visacommercialsuperpremiumcredit: this.cards.visa,
        visacommercialsuperpremiumdebit:  this.cards.visa,
        visacorporatecredit:              this.cards.visa,
        visacorporatedebit:               this.cards.visa,
        visapurchasingcredit:             this.cards.visa,
        visapurchasingdebit:              this.cards.visa,
        visafleetcredit:                  this.cards.visa,
        visafleetdebit:                   this.cards.visa,
        visadankort:                      this.cards.visa,
        visaproprietary:                  this.cards.visa,
        visa_applepay:                    this.cards.visa,
        visa_androidpay:                  this.cards.visa,
        vpay:                             this.cards.visa,
        electron:                         this.cards.visa,
        // Amex card
        amex:                             this.cards.amex,
        amex_applepay:                    this.cards.amex,
        // Bancontact debit card
        bcmc:                             this.cards.bancontact,
        // Carte Bancaire
        cartebancaire:                    this.cards.cartebancaire,
        // UnionPay card
        cup:                              this.cards.unionpay,
        cupcredit:                        this.cards.unionpay,
        cupdebit:                         this.cards.unionpay,
        cupprepaid:                       this.cards.unionpay,
        // Diners card
        diners:                           this.cards.diners,
        diners_applepay:                  this.cards.diners,
        // Discover card
        discover:                         this.cards.discover,
        discover_applepay:                this.cards.discover,
        // JCB card
        jcb:                              this.cards.jcb.card,
        jcbcredit:                        this.cards.jcb.credit,
        jcbdebit:                         this.cards.jcb.debit,
        jcbprepaid:                       this.cards.jcb.prepaid
    };

    paymentType = {
        card:   'card',
        ideal:  'ideal',
        sepa:   'sepa',
        paypal: 'paypal',
        applepay: 'applepay'
    };

    defaultCardType = 'card';

    applePayCardTypes = new Set([
        'mc_applepay',
        'visa_applepay',
        'amex_applepay',
        'diners_applepay',
        'discover_applepay'
    ]);

    cardsImages = {
        [this.paymentType.card]: [
            { path: this.valuesService.centralImages.visaPng.link,     alt: this.valuesService.centralImages.visaPng.alt },
            { path: this.valuesService.centralImages.mcPng.link,       alt: this.valuesService.centralImages.mcPng.alt },
            { path: this.valuesService.centralImages.amexPng.link,     alt: this.valuesService.centralImages.amexPng.alt },
            { path: this.valuesService.centralImages.discoverPng.link, alt: this.valuesService.centralImages.discoverPng.alt }
        ],
        [this.paymentType.sepa]: [
            { path: this.valuesService.centralImages.sepaPng.link,     alt: this.valuesService.centralImages.sepaPng.alt }
        ],
        [this.paymentType.ideal]: [
            { path: this.valuesService.centralImages.idealPng.link,    alt: this.valuesService.centralImages.idealPng.alt }
        ],
        [this.paymentType.paypal]: [
            { path: this.valuesService.centralImages.paypalPng.link,   alt: this.valuesService.centralImages.paypalPng.alt }
        ],
        [this.paymentType.applepay]: [
            { path: this.valuesService.centralImages.applepaySvg.link,   alt: this.valuesService.centralImages.applepaySvg.alt }
        ]
    };

    availableForConversionBundleSlots = {
        [this.valuesService.bundleAV]: new Set([1, 3, 5, 10]),
        [this.valuesService.bundleAVFM]: new Set([1, 3]),
        [this.valuesService.bundleBMS]: new Set([50]),
        [this.valuesService.bundleBIP]: new Set([50]),
        [this.valuesService.bundleIS]: new Set([1, 3, 5, 10]),
        [this.valuesService.bundleTS]: new Set([1, 3, 5, 10]),
        [this.valuesService.bundleFP]: new Set([15]),
        [this.valuesService.bundlePSec]: new Set([10]),
        [this.valuesService.bundleTSMD]: new Set([1, 3, 5, 10]),
        [this.valuesService.bundleSOHO]: new Set([5, 10, 20]),
        [this.valuesService.bundleVPN]: new Set([10]),
        [this.valuesService.bundleVPNSOHO]: new Set([5, 10, 20]),
    };

    paymentCardType = {
        page: "page",
        modal: "modal"
    };

    manageButtonActions = {
        cancelAutoRenewal: 'cancelAutoRenewal',
        activateAutoRenewal: 'activateAutoRenewal'
    };

    manageButtons = {
        autoRenewON: {
            button_name: 'subscriptions.service.subscription.protection',
            modal_name: ModalName.endServiceModal,
            action: this.manageButtonActions.cancelAutoRenewal
        },
        autoRenewOFF: {
            [this.status.active]: {
                button_name: 'subscriptions.service.reactivateprotection',
                modal_name: ModalName.reactivateServiceModal,
                action: this.manageButtonActions.activateAutoRenewal
            },
            [this.status.canceled]: {
                button_name: 'subscriptions.service.subscribeagain',
                modal_name: ModalName.reactivateServiceModal,
                action: this.manageButtonActions.activateAutoRenewal
            },
            [this.status.expired]: {
                button_name: 'subscriptions.service.resumesubscription',
                modal_name: ModalName.reactivateServiceModal,
                action: this.manageButtonActions.activateAutoRenewal
            }
        }
    };

    public readonly upsellScenariosByTypeAndStatus: UpsellScenariosByTypeAndStatus = {
        [UpsellTypes.ZUORA]: {
            [SubscriptionStatus.ACTIVE]: {
                description: 'subscriptions.service.convert.description.active',
                cta: 'subscriptions.service.convert.cta'
            },
            [SubscriptionStatus.EXPIRED]: {
                description: 'subscriptions.service.convert.description.expired',
                cta: 'subscriptions.service.convert.cta'
            }
        }
    }

    public readonly upsellScenariosByType: UpsellScenariosByType = {
        [UpsellTypes.FREE]: {
            description: 'subscriptions.app.upsell.free',
            cta: 'subscriptions.buy.button'
        },
        [UpsellTypes.TRIAL]: {
            description: 'subscriptions.app.upsell.trial',
            cta: 'subscriptions.buy.button'
        },
        [UpsellTypes.EXPIRED]: {
            description: 'subscriptions.app.upsell.expired',
            cta: 'subscriptions.service.renewNOW'
        },
        [UpsellTypes.ARROFF]: {
            description: 'subscriptions.app.upsell.arrOff',
            cta: 'subscriptions.cta.reactivate'
        },
        [UpsellTypes.USEDUP]: {
            description: 'subscriptions.app.upsell.usedAll',
            cta: 'subscriptions.cta.buy.more'
        }
    };

    redeemReasons = {
        valid                        : 'VALID',
        invalid                      : 'INVALID',
        alreadyUsed                  : 'ALREADY_USED',
        alreadyUsedSameUser          : 'ALREADY_USED_SAME_USER',
        consumerIncompatible         : 'CONSUMER_INCOMPATIBLE',
        sohoIncompatible             : 'SOHO_INCOMPATIBLE',
        eolProductDisabled           : 'EOL_PRODUCT_DISABLED',
        nfrActivationLimitation      : 'NFR_ACTIVATION_LIMITATION',
        handlerError                 : 'Handler function error',
        empty                        : 'EMPTY',
        notSuitableForMigration      : 'NOT_SUITABLE_FOR_MIGRATION',
        unexpectedError              : 'UNEXPECTED_ERROR',
        malformedSubscriptionRequest : 'MALFORMED_SUBSCRIPTION_REQUEST',
        subscriptionDBError          : 'SUBSCRIPTION_DB_ERROR',
        systemError                  : 'SYSTEM_ERROR',
        unknownLicenseStatus         : 'UNKNOWN_LICENSE_STATUS',
        forbidenMSPRedeem            : 'FORBIDDEN_MSP_REDEEM',
        partenerRedeemMatchFailed    : 'PARTNER_REDEEM_MATCH_FAILED',
        nfrExtensionsLimitation      : 'NFR_EXTENSION_LIMITATION',
        someUnknownError             : 'SOME_UNKNOWN_ERROR',
        geolocationLimitation        : 'GEOLOCATION_LIMITATION',
        expired                      : 'EXPIRED',
        noOptions                    : 'NO_OPTIONS',
        noRouter                     : 'NO_ROUTER',
        levelTwo                     : 'LEVEL_TWO'
    };

    trialReasons = {
        empty                        : 'EMPTY',
        valid                        : 'VALID',
        invalid                      : 'INVALID',
        alreadyUsed                  : 'ALREADY_USED',
        subscriptionDBError          : 'SUBSCRIPTION_DB_ERROR',
        systemError                  : 'SYSTEM_ERROR',
        unexpectedError              : 'UNEXPECTED_ERROR',
        sohoIncompatible             : 'SOHO_INCOMPATIBLE',
        consumerIncompatible         : 'CONSUMER_INCOMPATIBLE',
        paramsOrTypeDoNotMatch       : 'PARAMS_OR_TYPE_DO_NOT_MATCH'
    };

    badgesClasses = {
        success: 'success',
        warning: 'warning',
        danger:  'danger',
        gray: 'shade-40'
    };

    activationTypes = {
        NEW: "new",
        EXTEND: "extend"
    };

    badgesTypes = {
        new: "new",
        endingInDay: "endingInDay",
        endingInDays: "endingInDays",
        trial: "trial",
        expiringToday: "expiringToday",
        expiredToday: "expiredToday",
        expiredDayAgo: "expiredDayAgo",
        expiredDaysAgo: "expiredDaysAgo",
        expired: 'expired'
    }

    badges = {
        [this.badgesTypes.new]: {
            class: this.badgesClasses.success,
            text: 'devices.new'
        },
        [this.badgesTypes.endingInDay]: {
            class: this.badgesClasses.warning,
            text:'subscriptions.service.dayRemaining.new'
        },
        [this.badgesTypes.endingInDays]: {
            class: this.badgesClasses.warning,
            text: 'subscriptions.service.daysRemaining.new'
        },
        [this.badgesTypes.trial]: {
            class: this.badgesClasses.warning,
            text: 'subscriptions.services.trial.new'
        },
        [this.badgesTypes.expiringToday]: {
            class: this.badgesClasses.warning,
            text: 'subscriptions.service.expiringToday.new'
        },
        [this.badgesTypes.expiredToday]: {
            class: this.badgesClasses.danger,
            text: 'subscriptions.service.expiredToday'
        },
        [this.badgesTypes.expiredDayAgo]: {
            class: this.badgesClasses.danger,
            text: 'subscriptions.service.expireddayago'
        },
        [this.badgesTypes.expiredDaysAgo]: {
            class: this.badgesClasses.danger,
            text: 'subscriptions.service.expireddaysago'
        },
        [this.badgesTypes.expired]: {
            class: this.badgesClasses.gray,
            text: 'subscriptions.service.expired'
        }
    };

    benefitsAR = [
        'subscriptions.endservice.benefits.safety',
        'subscriptions.endservice.benefits.peaceofmind',
        'subscriptions.endservice.benefits.freedom',
        'subscriptions.endservice.benefits.onlineprivacy',
        'subscriptions.endservice.benefits.transparency',
        'subscriptions.endservice.benefits.help'
    ];

    subscriptionInviteStatusTexts = {
        [SubscriptionInviteStatus.ACTIVE]: 'subscriptions.servicedetails.shared.invite.status.active',
        [SubscriptionInviteStatus.INVITED]: 'subscriptions.servicedetails.shared.invite.status.invited',
        [SubscriptionInviteStatus.REMOVING]: 'subscriptions.servicedetails.shared.invite.status.removing'
    };

    omnitureInviteServiceModal = {
        chooseMember: 'choose-member',
        addMember: 'add-member',
        inviteSent: 'invite-sent'
    };

    omnitureEventsSharedSubscription = {
        editMembers: 'editMembers',
        finishEditMembers: 'finishEditMembers',
        cancelEditMembers: 'cancelEditMembers',
        closeInvite: 'closeInvite',
        inviteMoreMembers: 'inviteMoreMembers',
        setUpForYourself: 'setUpForYourself'
    };
}
