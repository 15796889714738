// External
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tv4 } from 'tv4';
import { map, catchError } from 'rxjs/operators';

// Internal
import { RequestsService } from '../../global/request-service/requests.service';
import { ValuesService } from '../../../values/values.service';
import { schemas } from '../../../../common/models/schemas';
import { ConfigService } from '../../../../common/config/config.service';
import { ConnectDestinationDeviceRequest } from '../../../../common/models/devices/DeviceDetails.model';

@Injectable({
    providedIn: "root"
})
export class IssuesMgmtService {
    constructor(
        readonly cookieService   : CookieService,
        readonly requestsService : RequestsService,
        readonly valuesService   : ValuesService,
        readonly configService   : ConfigService
    ) { }

    /**
     * Returns the issues for all the devices on the account. Used by the dashboard part to get issues for all devices
     * @returns Response from server
     */
    getAllIssues(): Observable<any> {
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "get_all_issues",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.issuesMgmtService,
            json,
            "POST"
        )
            .pipe(
                map(
                    (res: any) => {
                        return res.result;
                    }
                ),
                catchError((error) => {
                    console.error("Error mgmt >> dupa req", error);
                    throw error;
                }),
            )
    }

    /**
     * Returns the issues saved for one device
     * @param {object} Contains mandatory `device_id`
     * @returns Response from server
     */
    getIssues(connectDestination: ConnectDestinationDeviceRequest): Observable<any> {
        if (!tv4.validate(connectDestination, schemas.schemaConnectDestinationNoApp)) {
            return of("Invalid params");
        }
        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "get_device_issues",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: connectDestination
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.issuesMgmtService,
            json,
            "POST"
        ).pipe(
            map(
                (resp: any) => {
                    return resp.result;
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
     * Sends a "fix_issues" command to every application on the device specified in the connect_destination
     * @param {object} Contains mandatory `device_id`
     * @returns Response from server
     */
    fixIssues(connectDestination: ConnectDestinationDeviceRequest): Observable<any> {
        if (!tv4.validate(connectDestination, schemas.schemaConnectDestinationNoApp)) {
            return of("Invalid params");
        }

        const json: any = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "fix_device_issues",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: connectDestination
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.issuesMgmtService,
            json,
            "POST"
        ).pipe(
            map(
                (resp: any) => {
                    return resp.result;
                }
            ),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
     * Sends a "fix_issues" command to every application on every device the user has registered
     * @returns Response from server
     */
    fixAllIssues(): Observable<any> {
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "fix_all_issues",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };
        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.issuesMgmtService,
            json,
            "POST"
        );
    }
}
