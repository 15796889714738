// External
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { timeout, catchError, map, finalize } from 'rxjs/operators';
import { tv4 } from 'tv4';

// Internal
import { ConnectLoginService } from '../../requests/connect-login-service/connect-login.service';
import { Observable, of, throwError } from 'rxjs';
import { UtilsCommonService } from 'src/app/common/utils/utils-common.service';
import { ValuesService } from '../../../../common/values/values.service';

@Injectable({
    providedIn: 'root'
})
export class TtokenService {

    tempToken: any = null;
    lock: boolean = false;

    constructor(
        private connectLoginService: ConnectLoginService,
        private cookieService: CookieService,
        private router: Router,
        private utilsCommonService: UtilsCommonService,
        private valuesService: ValuesService
    ) { }


    // returns the temporary token
    aquire(): Observable<any> {
        this.tempToken = this.cookieService.get(this.valuesService.cookieTempToken);
        if (this.tempToken) {
            return of(true);
        }
        
        return this.connectLoginService.temp_token()
        .pipe(
            map(
                resp => {
                    this.lock = true;
                    this.tempToken = resp.user_token;
                    const expireDate = new Date();
                    expireDate.setDate(expireDate.getDate() + 1);
                    this.cookieService.set(this.valuesService.cookieTempToken, this.tempToken, expireDate, '/', "", true);

                    return of(resp);
                }
            ),
            catchError( (err)=> {
                return of(true);
            }),
            finalize(
                () => {
                    this.lock = false;
                }
            )
        )
    }

    get() {
        return this.tempToken;
    }

    set(data) {
        if (this.utilsCommonService.checkString(data)) {
            this.tempToken = JSON.parse(JSON.stringify(data));
        } else {
            console.error('Invalid params', tv4.error);
        }
    }

    remove() {
        this.tempToken = JSON.parse(JSON.stringify(''));
    }
}
