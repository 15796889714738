// External
import { CookieService } from "ngx-cookie-service";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { tv4 } from "tv4";

// Internal
import { RequestsService } from "../../global/request-service/requests.service";
import { ValuesService } from "../../../values/values.service";
import { UtilsCommonService } from "src/app/common/utils/utils-common.service";
import { ConfigService } from "src/app/common/config/config.service";
import { map, catchError } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class SettingsMgmtService {
    constructor(
        private cookieService: CookieService,
        private requestsService: RequestsService,
        private utilsService: UtilsCommonService,
        private valuesService: ValuesService,
        private configService: ConfigService
    ) { }

    /**
     * This method is used to return all the settings for a tuple (user, application, device)
     * @returns Response from server
     */
    getAll(appId?: string, deviceId?: string): Observable<any> {
        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "get_all",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: deviceId ? deviceId : this.valuesService.connectDeviceId,
                    app_id: appId ? appId : this.valuesService.connectAppId
                },
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.settingsMgmtService,
            json,
            "POST"
        ).pipe(
            map(
                (res: any) => {
                return res.result.settings;
                }
            ),
            catchError((error) => {
                throw error;
            }),
        );
    }

    /**
     * This method is used to set/rewrite all the settings for a tuple (user, application, device)
     * @returns Response from server
     */
    setAll(settings, appId?: string): Observable<any> {
        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "set_all",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    app_id: appId ? appId : this.valuesService.connectAppId,
                    device_id: this.valuesService.connectDeviceId
                },
                settings: settings
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.settingsMgmtService,
            json,
            "POST"
        ).pipe(
            map(
                (res: any) => {
                return res.result;
                }
            ),
            catchError((error) => {
                throw error;
            }),
        );
    }

    /**
     * This method is used to overwrite the setting given as parameter for a tuple (user, application, device)
     * @param {object} Contains mandatory `info.device_id` and `activityDetails`
     * @returns Response from server
     */
    setOne(setting, value, appId?: string, deviceId?: string): Observable<any> {
        if (!setting ||
            !this.utilsService.checkString(setting) ||
            this.utilsService.checkUndefined(value)) {
                console.error("Invalid params", tv4.error);
                return of("Invalid params");
        }
        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "set_one",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: deviceId ? deviceId : this.valuesService.connectDeviceId,
                    app_id: appId ? appId : this.valuesService.connectAppId
                },
                setting_name: setting,
                setting_value: value
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.settingsMgmtService,
            json,
            "POST"
        ).pipe(
            map(
                (res: any) => {
                    return res.result;
                }
            ),
            catchError(
                (error) => {
                    throw error;
                }
            ),
        ); //TODO de verificat partea asta, daca vine sau nu resp cu campul result cand totul e ok
    }


    /**
     * This method is used to return the value of the setting given as a parameter for a tuple (user, application, device)
     * @param {object} Contains mandatory `array.settings`
     * @returns Response from server
     */
    getOne(array): Observable<any> {
        if (array.length === 0 || !this.utilsService.checkArray(array)) {
            return of("Invalid params or array empty");
        }
        const jsons = [];
        let id = parseInt((Math.random() * 100000).toString(), 10);

        for (const arr of array) {
            if (arr.setting) {
                const json = {
                    id: id++,
                    jsonrpc: this.valuesService.jsonrpc,
                    method: "get_one",
                    params: {
                        connect_source: {
                            user_token: this.cookieService.get(this.valuesService.cookieToken),
                            device_id: this.valuesService.connectDeviceId,
                            app_id: this.valuesService.connectAppId
                        },
                        connect_destination: {
                            device_id: arr.device_id,
                            app_id: arr.app_id
                        },
                        setting_name: arr.setting
                    }
                };

                jsons.push(json);
            }
        }

        if (jsons.length !== 0) {
            return this.requestsService.make(
                this.configService.config.nimbusServer,
                this.valuesService.settingsMgmtService,
                jsons,
                "POST"
            ).pipe(
                map(
                    responses => {
                        const newResponses = [];
                        for (const resp of responses) {
                            newResponses.push(resp.hasOwnProperty('result') ? resp.result : null);
                        }
                        return newResponses;
                    }
                ),
                catchError(
                    err => {
                        return of([]);
                    }
                )
            );
        }
    }
}
