// External
import { Injectable } from '@angular/core';
import { Observable, forkJoin, of  } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

//Internal
import { ProfilesService } from '../../../../common/services/process/profiles/profiles.service';
import { Capabilities, ParentalNccValuesService } from '../../../../common/values/parental-ncc.values.service';
import { OperatingSystems, ValuesService } from '../../../../common/values/values.service';
import { ConnectUserInfoService } from '../../requests/connect-user-info-service/connect-user-info-service';
import { DevicesService } from '../devices/devices.service';
import { MessageService } from '../../core/message.service';
import { DoughnutChartDataset } from '../../../../common/pie-chart/doughnut-chart.model';
import { ParentalcontrolNccMgmtService } from '../../requests/parentalcontrol-ncc-mgmt/parentalcontrol-ncc-mgmt.service';
import {
    ILocation,
    parentalNccDeviceSoftware,
    parentalNccApiProfileFields,
    parentalNccApiSettingsFields,
    IUpdateDtl,
    NccUpdateActionType,
    NccExceptionType,
    NccReplacementsType,
    IUpdateReplacements,
    IUpdateException,
    IUpdateRoutine,
    API_PROCESSING_ERROR_CODE,
    EXCEPTION_UNKNOWN,
    Filters,
    NccBlockedContentType,
    parentalNccBlockedContentFields,
    NccUsageCardTypes,
    NccAvailabilityType
} from '../../../../common/models/parental-ncc/ParentalNcc.model';
import { ConfigService } from '../../../../common/config/config.service';
import { SubscriptionsService } from '../subscriptions/subscriptions.service';
import { SettingsService } from '../settings/settings.service';

@Injectable({
    providedIn: 'root'
})
export class ParentalNccService {

    showMainRoutes = {
        [this.valuesService.centralPaths.parentalncc.content.id]: {
            show: () => true
        },
        [this.valuesService.centralPaths.parentalncc.dtl.id]: {
            show: () => true
        },
        [this.valuesService.centralPaths.parentalncc.routines.focustime.id]: {
            show: () => this.configService.getNCCRoutines()
        },
        [this.valuesService.centralPaths.parentalncc.routines.bedtime.id]: {
            show: () => this.configService.getNCCRoutines()
        },
        [this.valuesService.centralPaths.parentalncc.routines.dinnertime.id]: {
            show: () => this.configService.getNCCRoutines()
        },
        [this.valuesService.centralPaths.parentalncc.usage.id]: {
            show: (profileHash) => !this.profileHasOnlyIosDevices(profileHash),
            profileHash: true
        },
        [this.valuesService.centralPaths.parentalncc.blockedcontent.id]: {
            show: () => this.configService.getNCCBlockedContent()
        }
    };

    profilesNcc = {};

    parentalFields = {};
    needMoreTime = {};
    devices = {};

    screenTimeYesterday = {};
    screenTimeByDays = {};

    timeSpentToday = {};
    categoriesUsage = {};
    topicsUsage = {};

    constructor(
        private readonly parentalNccValuesService: ParentalNccValuesService,
        private readonly profilesService: ProfilesService,
        private readonly valuesService: ValuesService,
        private readonly connectUserInfoService: ConnectUserInfoService,
        private readonly devicesService: DevicesService,
        private readonly parentalNccMgmt: ParentalcontrolNccMgmtService,
        private readonly messageService: MessageService,
        private readonly configService: ConfigService,
        private readonly subscriptionsService: SubscriptionsService,
        private readonly settingsService: SettingsService
    ) {}

    showMainRoute(pathId, profileHash) {
        const mainRouteInfo: any = this.showMainRoutes?.[pathId] ?? null;
        if (!mainRouteInfo) {
            return false;
        }
        if (mainRouteInfo?.profileHash) {
            return mainRouteInfo?.show(profileHash) ?? false;
        }

        return  mainRouteInfo?.show() ?? false;
    }

    getMarkToupdateName(fields) {
        return 'markToUpdate_'.concat(...fields);
    }
    //#region Devices & Profiles
    listDeviceLocation(profileHash, deviceId) {
        return this.parentalNccMgmt.getDeviceLocation(this.profilesNcc?.[profileHash]?.profile_id, deviceId)
        .pipe(
            map(resp => {
                const location = resp;
                if (location) {
                    return location;
                } else {
                    throw new Error(JSON.stringify(resp));
                }
            }),
            catchError((err)=> {
                throw err;
            })
        );
    }

    listDevicesStatus(profileHash) {
        if (!this.parentalFields[profileHash]) {
            this.parentalFields[profileHash] = {};
        }

        if (!this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats]) {
            this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats] = {};
        }

        if (!this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.deviceStatus]) {
            this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.deviceStatus] = {};
        }

        const markToUpdateName = this.getMarkToupdateName(parentalNccApiProfileFields.deviceStatus);
        if (this.parentalFields?.[profileHash]?.[markToUpdateName] || this.parentalFields?.[profileHash]?.[markToUpdateName] === undefined) {
            this.parentalFields[profileHash][markToUpdateName] = false;
        } else {
            return of(true);
        }

        return this.parentalNccMgmt.getDevicesStatus(this.profilesNcc?.[profileHash]?.profile_id)
        .pipe(
            map(resp => {
                this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.deviceStatus] = resp;
                return true;
            }),
            catchError( (err)=> {
                if(err?.error?.data?.code !== API_PROCESSING_ERROR_CODE) {
                    this.parentalFields[profileHash][markToUpdateName] = true;
                }
                throw err;
            })
        );
    }

    addSortedDevicesToProfiles() {
        const profiles = this.profilesService.getParentalProfiles();
        for (const profileHash in profiles) {
            const profile = profiles[profileHash];
            this.addSortedDevicesToProfile(profile);
        }
    }

    addSortedDevicesToProfile(profile) {
        if (!profile || !profile?.profile_id) {
            return;
        }
        const devices = this.devicesService.getDevicesListArray();
        const android = [];
        const ios = [];
        const windows = [];
        const mac = [];
        for (const device of devices) {
            if (device.profile_id !== profile.profile_id) {
                continue;
            }

            switch (device.device_os) {
                case OperatingSystems.ANDROID: {
                    android.push(device);
                    break;
                }
                case OperatingSystems.IOS: {
                    ios.push(device);
                    break;
                }
                case OperatingSystems.MAC:
                case OperatingSystems.OSX: {
                    mac.push(device);
                    break;
                }
                case OperatingSystems.WINDOWS: {
                    windows.push(device);
                    break;
                }
                default: {
                    break;
                }

            }
        }

        const profileHash = profile.profile_hash;
        if (!this.devices[profileHash]) {
            this.devices[profileHash] = {};
        }
        if (!this.devices[profileHash]?.list) {
            this.devices[profileHash].list = [];
        }
        this.devices[profileHash].list = android.concat(ios, mac, windows);
        this.devices[profileHash].iosOnly = ios.length && ios.length === this.devices[profileHash].list.length;
        this.devices[profileHash].iosAndOtherDevices = ios.length && ios.length !== this.devices[profileHash].list.length;
        this.devices[profileHash].otherDevicesOnly = !ios.length;
        this.devices[profileHash].noDevicesAssigned = !this.devices[profileHash].list.length;
    }

    get() {
        return this.profilesService.getParentalProfiles();
    }

    getProfiles() {
        return this.profilesNcc;
    }

    getProfile(profileHash) {
        return this.profilesNcc[profileHash] ?? null;
    }

    getProfileDevicesNumber(profileHash) {
        return this.devices?.[profileHash]?.list?.length ?? 0;
    }

    getProfileDevicesList(profileHash) {
        return this.devices?.[profileHash]?.list ?? [];
    }

    getDeviceLocationPermission(profileHash, deviceId) {
        return !!this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.deviceStatus]?.[deviceId]?.permissions?.location;
    }

    getDeviceLocation(profileHash, deviceId): ILocation {
        return this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.deviceLocation]?.[deviceId];
    }

    getDeviceVpnPermission(profileHash, deviceId) {
        return !!this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.deviceStatus]?.[deviceId]?.permissions?.vpn;
    }

    getDeviceLocationStatus(profileHash, deviceId) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.deviceStatus]?.[deviceId]?.capabilities?.location;
    }

    getDevicesStatus(profileHash) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.deviceStatus] ?? {};
    }

    getLocateNowPermission(profileHash, deviceId) {
        const deviceLocationStatus = this.getDeviceLocationStatus(profileHash, deviceId);
        const deviceLocationPermission = this.getDeviceLocationPermission(profileHash, deviceId);
        const deviceVpnPermission = this.getDeviceVpnPermission(profileHash, deviceId);

        return deviceLocationStatus === Capabilities.ON && deviceLocationPermission && deviceVpnPermission;
    }

    getParentalDevices(profileHash?) {
        return this.devices?.[profileHash]?.list ?? [];
    }

    getAssignableDevices(profileHash) {
        const devices = this.devicesService.getDevicesListArray();
        const assignableDevices = [];
        for (const device of devices) {
            const profileId = device.profile_id;
            const os = device.device_os;
            if (profileId !== this.profilesNcc?.[profileHash]?.profile_id && this.parentalNccValuesService.NON_IOT_PLATFORMS.has(os)) {
                assignableDevices.push(device);
            }
        }
        return assignableDevices;
    }

    /**
     * Returns true if parental advisor is installed and parental ncc is not installed
     * @param {string} deviceId
     * @returns {boolean}
     */
    public getDeviceNotUpdated(deviceId: string): boolean {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        return device?.processed?.hasParental && !device?.processed?.hasParentalNCC;
    }

    /**
     * Checks if new parental is compatible with the current iOS/macOS version of this device
     * @param {string} deviceId
     * @returns {NccAvailabilityType} status 'available' or 'unavailable'
     */
    public getNccAvailabilityStatus(deviceId: string): NccAvailabilityType {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        const osVersion = parseInt(device?.os_version?.split('.')?.[0], 10);
        if ((device?.device_os === OperatingSystems.IOS && osVersion < this.parentalNccValuesService.minIOSVersion)
            || (device?.device_os === OperatingSystems.OSX && osVersion < this.parentalNccValuesService.minMacOSVersion)) {
                return NccAvailabilityType.UNAVAILABLE;
        }
        return NccAvailabilityType.AVAILABLE;
    }

    /**
     * Returns if parental has been uninstalled (Windows, OsX)
     * or the user has logged out (Android, iOS) or the vpn is not active
     * or the device has not reported any status for over 24 hours
     * @param profileHash
     * @param deviceId
     * @returns parentalStatus
     */
    public getDeviceAtRiskParentalStatus(profileHash, deviceId): boolean {
        const refferenceToDevieInfo = this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.deviceStatus]?.[deviceId];
        const currentDate = new Date().getTime();
        let reportStatus = false;

        if (refferenceToDevieInfo?.last_updated) {
            reportStatus = refferenceToDevieInfo?.last_updated + this.parentalNccValuesService.hoursToMiliSecondsValues.entireDay < currentDate;
        }

        return refferenceToDevieInfo?.software?.parental === parentalNccDeviceSoftware.uninstalled
                || refferenceToDevieInfo?.software?.parental === parentalNccDeviceSoftware.logout
                || refferenceToDevieInfo?.permissions?.vpn === false
                || (refferenceToDevieInfo?.last_updated && reportStatus)
                || this.getDeviceNotUpdated(deviceId);
    }

    /**
     * Returns if the device does not have capabilities to access the location
     * @param profileHash
     * @param deviceId
     * @returns
     */
    public getDeviceAtRiskLocationCapability(profileHash, deviceId): boolean {
        const refferenceToDevieInfo = this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.deviceStatus]?.[deviceId];
        return refferenceToDevieInfo?.capabilities?.location === Capabilities.NA && this.subscriptionsService.hasParentalNCCPremium();
    }

    /**
     * Returns if the device has capabilities to access the location turned off or has no permissions set
     * @param profileHash
     * @param deviceId
     * @returns
     */
    public getDeviceAtRiskLocationPermission(profileHash, deviceId): boolean {
        const refferenceToDevieInfo = this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.deviceStatus]?.[deviceId];
        return (refferenceToDevieInfo?.capabilities?.location === Capabilities.OFF
                || refferenceToDevieInfo?.permissions?.location === false)
                && this.subscriptionsService.hasParentalNCCPremium();
    }

    /**
     * Returns the status of the installed app on the device.
     * Status list:
     *      noIssues: 0,
     *      parentalDisabled: 1,
     *      parentalUninstaled: 2,
     *      parentalOffline: 3,
     *      locationDisabled: 4
     * @param profileHash
     * @param deviceId
     * @returns number
     */
    public getInstalledDeviceStatus(profileHash, deviceId): number {
        const refferenceToDevieInfo = this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.deviceStatus]?.[deviceId];
        if (refferenceToDevieInfo?.software?.parental === parentalNccDeviceSoftware.uninstalled) {
            return this.parentalNccValuesService.deviceStatus.parentalUninstaled;
        }
        if (refferenceToDevieInfo?.software?.parental === parentalNccDeviceSoftware.logout || refferenceToDevieInfo?.permissions?.vpn === false) {
            return this.parentalNccValuesService.deviceStatus.parentalDisabled;
        }
        if (refferenceToDevieInfo?.last_updated) {
            const currentDate = new Date().getTime();
            const reportStatus = refferenceToDevieInfo?.last_updated + this.parentalNccValuesService.hoursToMiliSecondsValues.entireDay < currentDate;
            if (reportStatus) {
                return this.parentalNccValuesService.deviceStatus.parentalOffline;
            }
        }
        if ((refferenceToDevieInfo?.capabilities?.location === Capabilities.OFF
            || refferenceToDevieInfo?.capabilities?.location === Capabilities.NA
            || refferenceToDevieInfo?.permissions?.location === false)
            && this.subscriptionsService.hasParentalNCCPremium()) {
                return this.parentalNccValuesService.deviceStatus.locationDisabled;
        }

        return this.parentalNccValuesService.deviceStatus.noIssues;
    }

    /**
     * Verifies if profile has only iOS devices
     * @param {*} profileHash
     * @returns {boolean} True if profile has only iOS devices, false otherwise
     */
    public profileHasOnlyIosDevices(profileHash): boolean {
        return this.devices?.[profileHash]?.iosOnly;
    }

    /**
     * Verifies if profile has iOS and other devices (windows, android, mac, osx)
     * @param {*} profileHash
     * @returns {boolean} True if profile has iOS and other devices, false otherwise
     */
    public profileHasIosAndOtherDevices(profileHash): boolean {
        return this.devices?.[profileHash]?.iosAndOtherDevices;
    }

    /**
     * Verifies if profile has only other devices (windows, android, mac, osx)
     * @param {*} profileHash
     * @returns {boolean} True if profile has only other devices, false if profile has at least one iOS device
     */
    public profileHasOnlyOtherDevices(profileHash): boolean {
        return this.devices?.[profileHash]?.otherDevicesOnly;
    }

    profileHasNoDevices(profileHash) {
        return this.devices?.[profileHash]?.noDevicesAssigned;
    }

    setProfiles() {
        this.profilesNcc = this.profilesService.getParentalProfiles();
    }

    /**
     * Verifies if onboarding ncc modal can be displayed
     * (nccOnboardingComplete ncc setting is not true, at least 1 profile created,
     * at least 1 device with parentaladvisor installed or at least 1 device with parentaladvisor uninstalled and nccparental installed)
     * @returns {boolean} true if the modal can be displayed
     */
    public showOnboardingNccModal(): boolean {
        if (this.settingsService.getNccOnboardingComplete() || !this.profilesService.hasParentalProfiles()) {
            return false;
        }
        const devices = this.devicesService.getDevicesListArray();
        for (const device of devices) {
            if (device?.processed?.hasParental
                || (!device?.processed?.hasParental && device?.processed?.hasParentalNCC)) {
                return true;
            }
        }
        return false;
    }

    public unassignDevice(deviceId: string, profileId: string, profileHash: number): Observable<any> {
        return this.connectUserInfoService.profile_set_inactive({device_id: deviceId, profile_id: profileId})
        .pipe(
            map(() => {
                const device = this.devicesService.retrieveDeviceById(deviceId);
                if (device.profile_id) {
                    delete device.profile_id;
                }
                const markToUpdateName = this.getMarkToupdateName(parentalNccApiProfileFields.deviceStatus);
                this.parentalFields[profileHash][markToUpdateName] = true;

                this.messageService.sendMessage(this.valuesService.events.parentalDevicePageReload, {});
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    assignDeviceToProfile(profileId, deviceId, sid?): Observable<any> {
        const device = this.devicesService.retrieveDeviceById(deviceId);
        const params = {
            "profile_id": profileId,
            "device_id": deviceId
        };

        if (sid) {
            params["device_account_sid"] = sid;
        }

        return this.connectUserInfoService.profileSetActive(params)
        .pipe(
            map(() => {
                if (sid === 0 || sid) {
                    device.device_account_sid = sid;
                }
                device.profile_id = profileId;
                this.messageService.sendMessage(this.valuesService.events.profileChanges, {});
            }),
            catchError((err) => {
                throw err;
            })
        );
    }
    //#endregion

    //#region Profile Stats & NCC Web API
    listNccFields(profileHash, fields?: Array<parentalNccApiSettingsFields>): Observable<any> {
        fields = this.getNeededFields(profileHash, fields);
        if (!fields.length) {
            return of(true);
        }
        return this.parentalNccMgmt.getSettings(this.profilesNcc[profileHash]?.profile_id, fields)
        .pipe(
            map(resp => {
                if (!resp) {
                    throw resp;
                }
                this.proccessNccFields(profileHash, fields, resp)
                return true;
            }),
            catchError( (err)=> {
                for (const field of fields) {
                    if (this.parentalFields?.[profileHash]) {
                        const markToUpdateName = this.getMarkToupdateName(field);
                        this.parentalFields[profileHash][markToUpdateName] = true;
                    }
                }
                throw err;
            })
        );
    }

    listProfileStats(profileHash): Observable<any> {
        if (!this.parentalFields?.[profileHash]) {
            this.parentalFields[profileHash] = {};
        }

        if (!this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]) {
            this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats] = {};
        }
        const markToUpdateName = this.getMarkToupdateName(parentalNccApiProfileFields.profileStats);
        if (this.parentalFields?.[profileHash]?.[markToUpdateName] || this.parentalFields?.[profileHash]?.[markToUpdateName] === undefined) {
            this.parentalFields[profileHash][markToUpdateName] = false;
        } else {
            return of(true);
        }

        return this.parentalNccMgmt.getProfileStats(this.profilesNcc?.[profileHash]?.profile_id)
        .pipe(
            map(resp => {
                const deviceStatus = this.getDevicesStatus(profileHash);
                this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats] = resp;
                this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.deviceStatus] = deviceStatus;
                this.generateScreenTimeByDays(profileHash);
                this.generateCategoriesUsage(profileHash);
                this.generateTopicsUsage(profileHash);
                return true;
            }),
            catchError( (err)=> {
                this.parentalFields[profileHash][markToUpdateName] = true;
                throw err;
            })
        );
    }

    listNeedMoreTime(profileHash) {
        if (!this.parentalFields?.[profileHash]) {
            this.parentalFields[profileHash] = {};
        }

        if (!this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.needMoreTime]) {
            this.parentalFields[profileHash][parentalNccApiProfileFields.needMoreTime] = {};
        }

        const markToUpdateName = this.getMarkToupdateName(parentalNccApiProfileFields.needMoreTime);
        if (this.parentalFields?.[profileHash]?.[markToUpdateName] || this.parentalFields?.[profileHash]?.[markToUpdateName] === undefined) {
            this.parentalFields[profileHash][markToUpdateName] = false;
        } else {
            return of(true);
        }

        return this.parentalNccMgmt.getMoreTimeRequests(this.profilesNcc?.[profileHash]?.profile_id)
        .pipe(
            map(resp => {
                this.parentalFields[profileHash][parentalNccApiProfileFields.needMoreTime] = resp;
                return true;
            }),
            catchError( (err)=> {
                this.parentalFields[profileHash][markToUpdateName] = true;
                throw err;
            })
        );
    }

    getNeededFields(profileHash, fields?: Array<parentalNccApiSettingsFields>): Array<parentalNccApiSettingsFields> {
        if (!this.parentalFields?.[profileHash]) {
            this.parentalFields[profileHash] = {};
        }
        fields = fields ? fields : Object.values(parentalNccApiSettingsFields);
        const fieldsArray: Array<parentalNccApiSettingsFields> = [];
        for (const field of fields) {
            const markToUpdateName = this.getMarkToupdateName(field);
            if (this.parentalFields?.[profileHash]?.[markToUpdateName] || this.parentalFields?.[profileHash]?.[markToUpdateName] === undefined) {
                this.parentalFields[profileHash][markToUpdateName] = false;
                fieldsArray.push(field);
            }
        }
        return fieldsArray;
    }

    proccessNccFields(profileHash, fields, resp) {
        for (const field of fields) {
            if (!resp?.[field]) {
                continue;
            }

            switch (field) {
                case parentalNccApiSettingsFields.infoTopics:
                case parentalNccApiSettingsFields.infoCategories: {
                    this.createNccInfoObjects(profileHash, field, resp[field] ?? []);
                    this.parentalFields[profileHash][field] = resp[field];
                    break;
                }
                case parentalNccApiSettingsFields.routines: {
                    this.parentalFields[profileHash][field] = {};
                    for (const routine of resp[field]) {
                        if (routine?.routine) {
                            this.parentalFields[profileHash][field][routine.routine] = routine;
                        }
                    }
                    break;
                }
                default: {
                    this.parentalFields[profileHash][field] = resp[field];
                    break;
                }
            }
        }
    }

    computeDoghnutChartDataset(screenTime, usageColors, totalSpentTime, nrOfMandatoryDisplayedElements, nrOfPossibledisplayedElements) {
        let usageDataset: DoughnutChartDataset;
        let otherTimeSpent = 0;
        for (let i = 0; i < screenTime.length; i++) {
            if (i < nrOfMandatoryDisplayedElements) {
                usageDataset.data.push(screenTime.time_spent);
                usageDataset.backgroundColor.push(usageColors[i].rgb);
            } else if (nrOfPossibledisplayedElements > i) {
                const percentage = Math.round((screenTime.time_spent/totalSpentTime)*100);
                if (percentage <= 1) {
                    otherTimeSpent = otherTimeSpent + screenTime.time_spent;
                } else {
                    usageDataset.data.push(screenTime.time_spent);
                    usageDataset.backgroundColor.push(usageColors[i].rgb);
                }
            } else {
                otherTimeSpent = otherTimeSpent + screenTime.time_spent;
            }
        }
        if (otherTimeSpent) {
            usageDataset.data.push(otherTimeSpent);
            usageDataset.backgroundColor.push(this.parentalNccValuesService.otherUsageColor.rgb);
        }
        return usageDataset;
    }

    gatherAllUnknownTime(categories) {
        const unknownCategory = {
            time_spent: 0,
            category: EXCEPTION_UNKNOWN.id
        };
        const newCategoryUsage = [];
        for (const category of categories) {
            if (!this.parentalNccValuesService.contentFilteringCategoriesNames[category.category] || category.category === 'Unknown') {
                unknownCategory.time_spent = unknownCategory.time_spent + category.time_spent;
            } else {
                newCategoryUsage.push(category);
            }
        }
        if (unknownCategory.time_spent) {
            newCategoryUsage.push(unknownCategory);
        }
        return newCategoryUsage;
    }

    generateScreenTimeByDays(profileHash) {
        if (!this.screenTimeByDays?.[profileHash]) {
            this.screenTimeByDays[profileHash] = {};
        }

        let screenTime30Days = 0;
        let screenTime7Days = 0;
        let screenTime30DayIos = 0;
        let screenTime7DaysIos = 0;
        const screenTime = {};
        const last30Days = this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats]?.last_30d_by_day;
        const last30DaysIos = this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats]?.last_30d_by_day_ios;
        for (let i = 0; i < last30Days.length; i++) {
            const date = new Date(last30Days[i].day).toISOString().split('T')[0];
            if (!screenTime[date]) {
                const iosDevicesUsage = last30DaysIos[i].time_spent > 0 ? last30DaysIos[i].time_spent : 0;
                const devicesUsage = last30Days[i].time_spent > 0 ? last30Days[i].time_spent : 0;
                screenTime[date] = devicesUsage;

                screenTime30Days += devicesUsage;
                screenTime30DayIos += iosDevicesUsage;
                if (i < this.parentalNccValuesService.daysInWeek) {
                    screenTime7Days += devicesUsage;
                    screenTime7DaysIos += iosDevicesUsage;
                }
            }
        }
        this.screenTimeByDays[profileHash].screen_time = screenTime;
        this.screenTimeByDays[profileHash].screenTime30Days = screenTime30Days;
        this.screenTimeByDays[profileHash].screenTime7Days = screenTime7Days;
        this.screenTimeByDays[profileHash].screenTime30DaysIos = screenTime30DayIos;
        this.screenTimeByDays[profileHash].screenTime7DaysIos = screenTime7DaysIos;
    }

    sortUsageTime(usage) {
        if (Array.isArray(usage)) {
            usage.sort((a: any, b: any) => {
                if (a.time_spent < b.time_spent) {
                    return 1;
                } else if (a.time_spent > b.time_spent) {
                    return -1;
                } else {
                    return 0;
                }
            });
        }
        return usage;
    }

    generateTopicsUsage(profileHash) {
        const processedTopicsUsage = this.sortUsageTime(this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats]?.last_7d_by_topic ?? []);
        this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats].last_7d_by_topic_sorted = processedTopicsUsage;
        this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats].totalTopicsUsageTime =  this.screenTimeByDays[profileHash].screenTime7Days;

        if (!this.topicsUsage[profileHash]) {
            this.topicsUsage[profileHash] = {};
        }
        const processedTopicsUsage30 = this.sortUsageTime(this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats]?.last_30d_by_topic ?? []);
        this.topicsUsage[profileHash].last_30d_by_topic_sorted = processedTopicsUsage30;
        this.topicsUsage[profileHash].totalTopicsUsageTime =  this.screenTimeByDays[profileHash].screenTime30Days;
    }

    generateCategoriesUsage(profileHash) {
        let categories = this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats]?.last_7d_by_category ?? [];
        categories = this.gatherAllUnknownTime(categories);
        const processedCategoriesUsage = this.sortUsageTime(categories);
        this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats].last_7d_by_category_sorted = processedCategoriesUsage;
        this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats].totalCategoriesUsageTime =  this.screenTimeByDays[profileHash].screenTime7Days;

        if (!this.categoriesUsage[profileHash]) {
            this.categoriesUsage[profileHash] = {};
        }
        categories = this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats]?.last_30d_by_category ?? [];
        categories = this.gatherAllUnknownTime(categories);
        const processedCategoriesUsage30 = this.sortUsageTime(categories);
        this.categoriesUsage[profileHash].last_30d_by_category_sorted = processedCategoriesUsage30;
        this.categoriesUsage[profileHash].totalCategoriesUsageTime =  this.screenTimeByDays[profileHash].screenTime30Days;
    }

    getTimeLeftToday(profileHash) {
        const totalAllowedTime = this.getTotalAllowedTime(profileHash);
        const spentTime = this.getTimeSpentInSeconds(profileHash);
        return totalAllowedTime - spentTime;
    }

    getPendingTimeRequestsCount(profileHash) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.needMoreTime]?.active_requests?.length ?? 0;
    }

    getLastUpdateTimestamp(profileHash) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.timeSpentToday]?.current_time;
    }

    getTimeSpent(profileHash) {
        const timeSpent = this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.timeSpentToday]?.time_spent_today;
        if (Number.isInteger(timeSpent)) {
            return timeSpent;
        }
        return 0;
    }

    getTimeSpentTodayHourly(profileHash) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.time_spent_today_by_hour ?? [];
    }

    getTimeSpentYesterdayHourly(profileHash) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.time_spent_yesterday_by_hour ?? [];
    }

    getTimeSpentInSeconds(profileHash) {
        return this.getTimeSpent(profileHash) / 1000;
    }

    getTotalAllowedTime(profileHash) {
        return this.getTodayDtl(profileHash) + this.getGrantedTime(profileHash);
    }

    getGrantedTime(profileHash) {
        return Number.isInteger(this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.timeSpentToday]?.granted_time)
                ? this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.timeSpentToday]?.granted_time
                : 0
    }

    getLastWeekCategoryUsageSorted(profileHash) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.last_7d_by_category_sorted ?? [];
    }

    getLastWeekCategoryUsageTime(profileHash) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.totalCategoriesUsageTime ?? 0;
    }

    getLastWeekTopicUsageSorted(profileHash) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.last_7d_by_topic_sorted ?? [];
    }

    getLastWeekTopicUsageTime(profileHash) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.totalTopicsUsageTime ?? 0;
    }

    getScreenTimeByDay(profileHash, isoDate) {
        return this.screenTimeByDays?.[profileHash]?.screen_time?.[isoDate] ?? null;
    }

    /**
     * Gets total screen time for the past 7 days
     * @param {*} profileHash
     * @returns {number|null}
     */
    public getLastWeekScreenTime(profileHash): number {
        return this.screenTimeByDays?.[profileHash]?.screenTime7Days ?? null;
    }

    /**
     * Gets total screen time from ios devices for the past 7 days
     * @param {*} profileHash
     * @returns {number|null}
     */
    public getLastWeekScreenTimeIos(profileHash): number {
        return this.screenTimeByDays?.[profileHash]?.screenTime7DaysIos ?? null;
    }

    /**
     * Gets total screen time for the past month
     * @param {*} profileHash
     * @returns {number|null}
     */
    public getPastMonthScreenTime(profileHash): number {
        return this.screenTimeByDays?.[profileHash]?.screenTime30Days ?? null;
    }

    /**
     * Gets total screen time from ios devices for the past month
     * @param {*} profileHash
     * @returns {number|null}
     */
    public getPastMonthScreenTimeIos(profileHash): number {
        return this.screenTimeByDays?.[profileHash]?.screenTime30DaysIos ?? null;
    }

    getPastMonthTopicsUsageSorted(profileHash) {
        return this.topicsUsage?.[profileHash]?.last_30d_by_topic_sorted ?? [];
    }

    getPastMonthTopicsUsageTime(profileHash) {
        return this.topicsUsage?.[profileHash]?.totalTopicsUsageTime ?? 0;
    }

    getPastMonthCategoriesUsageSorted(profileHash) {
        return this.categoriesUsage?.[profileHash]?.last_30d_by_category_sorted ?? [];
    }

    getPastMonthCategoriesUsageTime(profileHash) {
        return this.categoriesUsage?.[profileHash]?.totalCategoriesUsageTime ?? 0;
    }

    /**
     * Get usage for specific tab and period
     * @param {string} profileHash
     * @param {string} tab
     * @param {Filters} period
     * @return {*}
     * @memberof ParentalNccService
     */
    public getUsage(profileHash: string, tab: string, period: Filters): any {
        if (tab === this.parentalNccValuesService.contentFilteringTabs.categories) {
            if (period === Filters.LAST_WEEK) {
                return this.getLastWeekCategoryUsageTime(profileHash);
            } else {
                return this.getPastMonthCategoriesUsageTime(profileHash);
            }
        } else {
            if (period === Filters.LAST_WEEK) {
                return this.getLastWeekTopicUsageTime(profileHash);
            } else {
                return this.getPastMonthTopicsUsageTime(profileHash);
            }
        }
    }

    getTopicsUsageSortedAndTime(profileHash, period: Filters) {
        if (period === Filters.LAST_WEEK) {
            return [
                this.getLastWeekTopicUsageSorted(profileHash),
                this.getLastWeekTopicUsageTime(profileHash)
            ];
        } else {
            return [
                this.getPastMonthTopicsUsageSorted(profileHash),
                this.getPastMonthTopicsUsageTime(profileHash)
            ];
        }
    }

    getCategoriesUsageSortedAndTime(profileHash, period: Filters) {
        if (period === Filters.LAST_WEEK) {
            return [
                this.getLastWeekCategoryUsageSorted(profileHash),
                this.getLastWeekCategoryUsageTime(profileHash)
            ];
        } else {
            return [
                this.getPastMonthCategoriesUsageSorted(profileHash),
                this.getPastMonthCategoriesUsageTime(profileHash)
            ];
        }
    }

    getInternetStatus(profileHash) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.exceptions]?.[NccExceptionType.block_all];
    }

    updateNeedMoreTime(profileHash) {
        const markToUpdateName = this.getMarkToupdateName(parentalNccApiProfileFields.needMoreTime);
        if (this.parentalFields?.[profileHash]?.[markToUpdateName] === false) {
            this.parentalFields[profileHash][markToUpdateName] = true;
        }
    }

    updateProfileStats(profileHash) {
        const markToUpdateName = this.getMarkToupdateName(parentalNccApiProfileFields.profileStats);
        if (this.parentalFields?.[profileHash]?.[markToUpdateName] === false) {
            this.parentalFields[profileHash][markToUpdateName] = true;
        }
    }

    allowMoreTime(profileHash, moreTime) {
        return this.parentalNccMgmt.grantMoreTime(this.profilesNcc?.[profileHash]?.profile_id, moreTime)
        .pipe(
            map(() => {
                if (!this.parentalFields?.[profileHash]) {
                    this.parentalFields[profileHash] = {};
                }
                if (!this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]) {
                    this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats] = {};
                }
                if (!this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.timeSpentToday]) {
                    this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday] = {};
                }
                const grantedTime = this.getGrantedTime(profileHash);
                if (grantedTime) {
                    this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday].granted_time = grantedTime + moreTime;
                } else {
                    this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday].granted_time = moreTime;
                }

                if (this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.needMoreTime]?.active_requests) {
                    this.parentalFields[profileHash][parentalNccApiProfileFields.needMoreTime].active_requests = [];
                }
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    toggleInternetStatus(profileHash) {
        const internetStatus = this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.exceptions]?.[NccExceptionType.block_all] ? false : true;
        return this.parentalNccMgmt.blockAllInternet(this.profilesNcc?.[profileHash]?.profile_id, internetStatus)
        .pipe(
            map((resp) => {
                if (this.parentalFields?.[profileHash]) {
                    this.parentalFields[profileHash][parentalNccApiSettingsFields.exceptions][NccExceptionType.block_all] = internetStatus;
                    return resp;
                }
            }),
            catchError((err) => {
                throw err;
            })
        );
    }
    //#endregion

    //#region Routines
    getRoutines(profileHash) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.routines] ?? {};
    }

    getRoutineIsEnabled(profileHash, routineType) {
        return !!this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.routines]?.[routineType]?.enabled;
    }

    getRoutineContentFilteringfield(profileHash, routineType, field) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.routines]?.[routineType]?.[field] ?? {};
    }

    getRoutineFilters(profileHash, routineType) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.routines]?.[routineType] ?? {};
    }

    getRoutineSchedule(profileHash, routineType) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.routines]?.[routineType]?.schedule ?? {};
    }

    getRoutineStartTime(profileHash) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.timeSpentToday]?.active_routine?.start_time ?? 0;
    }

    getRoutineId(profileHash) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.timeSpentToday]?.active_routine?.id;
    }

    getRoutineEndTime(profileHash) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.timeSpentToday]?.active_routine?.end_time ?? 0;
    }

    getActiveRoutineId(profileHash) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.timeSpentToday]?.active_routine?.id ?? '';
    }

    getActiveRoutine(profileHash) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.timeSpentToday]?.active_routine;
    }

    getAtLeastOneDayIsSet(profileHash, routineType) {
        const schedule = this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.routines]?.[routineType]?.schedule ?? {};
        const nrDays = (Object.keys(schedule)).length;
        return nrDays > 0;
    }

    nothingChangedForRoutineSchedule(profileHash, routineType, updates: IUpdateRoutine[]) {
        const schedule = this.getRoutineSchedule(profileHash, routineType);
        for (const update of updates) {
            if (schedule[update.week_day]?.start_time !== update.start_time || schedule[update.week_day]?.end_time !== update.end_time) {
                return false;
            }
        }
        return true;
    }

    isContentFilteringRoutineDefault(profileHash, routineType) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.routines]?.[routineType]?.default;
    }

    isInternetBlockedOnRoutine(profileHash, routineType) {
        return !!this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.routines]?.[routineType]?.[NccExceptionType.block_all];
    }

    setActiveRoutine(profileHash, activeRoutine) {
        if (this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.timeSpentToday]) {
            this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday].active_routine = activeRoutine;
        }

    }
    //#endregion

    //#region Content Filtering
    getTopicInfo(profileHash, topicId) {
        return this.parentalFields?.[profileHash]?.["info_topics_object"]?.[topicId];
    }

    getAllTopicInfo(profileHash) {
        return this.parentalFields?.[profileHash]?.["info_topics_object"] ?? {};
    }

    isContentFilteringDefault(profileHash) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.exceptions]?.default;
    }

    getContentFilteringBlacklistedCategories(profileHash, routineType) {
        return routineType
                ? (this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.routines]?.[routineType]?.[NccExceptionType.categories]?.blacklist ?? [])
                : (this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.exceptions]?.[NccExceptionType.categories]?.blacklist ?? []);
    }

    getContentFilteringWhitelistedCategories(profileHash, routineType) {
        return routineType
                ? (this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.routines]?.[routineType]?.[NccExceptionType.categories]?.whitelist ?? [])
                : (this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.exceptions]?.[NccExceptionType.categories]?.whitelist ?? []);
    }

    getContentFilteringBlacklistedTopics(profileHash, routineType) {
        return routineType
                ? (this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.routines]?.[routineType]?.[NccExceptionType.topics]?.blacklist ?? [])
                : (this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.exceptions]?.[NccExceptionType.topics]?.blacklist ?? []);
    }

    getContentFilteringWhitelistedTopics(profileHash, routineType) {
        return routineType
                ? (this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.routines]?.[routineType]?.[NccExceptionType.topics]?.whitelist ?? [])
                : (this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.exceptions]?.[NccExceptionType.topics]?.whitelist ?? []);
    }

    getContentFilteringBlacklistedWebsites(profileHash, routineType) {
        return routineType
                ? (this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.routines]?.[routineType]?.[NccExceptionType.websites]?.blacklist ?? [])
                : (this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.exceptions]?.[NccExceptionType.websites]?.blacklist ?? []);
    }

    getContentFilteringWhitelistedWebsites(profileHash, routineType) {
        return routineType
                ? (this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.routines]?.[routineType]?.[NccExceptionType.websites]?.whitelist ?? [])
                : (this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.exceptions]?.[NccExceptionType.websites]?.whitelist ?? []);
    }

    getTodaySeconds() {
        const today = new Date();
        const seconds = today.getHours()*3600 + today.getMinutes()*60 + today.getSeconds();
        return {
            day: today.getDay(),
            seconds
        };
    }

    getContentFilteringInfoTopics(profileHash) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.infoTopics] ?? [];
    }

    addExtraTopic(profileHash, addedTopic) {
        const existingTopic = this.getTopicInfo(profileHash, addedTopic.id);
        if (!existingTopic) {
            const topicsInfo = this.getContentFilteringInfoTopics(profileHash);
            topicsInfo.push(addedTopic);
            const topicsInfoObject = this.getAllTopicInfo(profileHash);
            topicsInfoObject[addedTopic.id] = addedTopic;
        }
    }

    createTimeLimitOptions(profileHash) {
        const totalAllowedTime = this.getTotalAllowedTime(profileHash);
        const timeLimitOptions = [];
        const secondsInDay = this.parentalNccValuesService.hoursInDay*this.parentalNccValuesService.minutesInHour*this.parentalNccValuesService.secondsInMinute;
        for (let i = 0; i <= this.parentalNccValuesService.hoursInDay*2; i++) {
            const extraTime = i*this.parentalNccValuesService.HALF_HOUR_IN_SEC;
            if (totalAllowedTime + extraTime <= secondsInDay) {
                timeLimitOptions.push(extraTime);
            }
        }
        return timeLimitOptions;
    }

    generateAddTopicExceptionObject(topics: any[]) {
        let updates: IUpdateException[] = [];
        for (const topic of topics) {
            if (topic?.isAllowed !== undefined) {
                updates.push({
                    action: NccUpdateActionType.set,
                    type: NccExceptionType.topics,
                    value: topic.id,
                    allow: topic.isAllowed
                });
            }
        }
        return updates;
    }

    checkForDuplicateException(profileHash, field, value, routineType) {
        const name = value?.id ?? value?.url;
        const blackList = routineType
                        ? (this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.routines]?.[routineType]?.[field]?.blacklist ?? [])
                        : (this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.exceptions]?.[field]?.blacklist ?? []);
        if (blackList?.includes(name)) {
            return true;
        }

        const whiteList = routineType
                        ? (this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.routines]?.[routineType]?.[field]?.whitelist ?? [])
                        : (this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.exceptions]?.[field]?.whitelist ?? []);
        if (whiteList?.includes(name)) {
            return true;
        }
        return false;
    }

    setLastUpdateTimestampFromStorage(profileHash, prefix, routineType) {
        if (routineType) {
            prefix = prefix.concat('-', routineType);
        }
        const storageName = prefix.concat('-', profileHash);
        const timestamp = new Date().getTime();
        localStorage.setItem(storageName, timestamp.toString());
    }

    checkInfoCategories(profileHash, categoryName) {
        return !!this.parentalFields?.[profileHash]?.['info_categories_object']?.[categoryName];
    }
    //#endregion

    //#region DTL
    /**
     *
     * NCC Managemnt request to get edit DTL Schedule for a profile
     * @param {*} profileHash
     * @param {*} newSchedule Object of days as Set and time for evrey day
     * @return {*}
     * @memberof ParentalNccService
     */
    editDtl(profileHash, newSchedule) {

        const updateDtl: IUpdateDtl[] =  [];
        for(const day of newSchedule?.days) {
            const action: IUpdateDtl = {
                action: NccUpdateActionType.set,
                week_day: day,
                limit: newSchedule?.time
            };
            updateDtl.push(action);
        }

        return this.parentalNccMgmt.updateDtl(this.profilesNcc?.[profileHash]?.profile_id, updateDtl)
        .pipe(
            map(() => {
                if (!this.parentalFields?.[profileHash]) {
                    this.parentalFields[profileHash] = {};
                }
                if (!this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]) {
                    this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats] = {};
                }
                if (!this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.timeSpentToday]) {
                    this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday] = {};
                }
                if (!this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.dtl]) {
                    this.parentalFields[profileHash][parentalNccApiSettingsFields.dtl] = {};
                }
                for(const day of newSchedule?.days) {
                    this.parentalFields[profileHash][parentalNccApiSettingsFields.dtl].limits[day] = newSchedule?.time;
                }
                const today  = new Date().getDay();
                const newDtl = this.parentalFields[profileHash][parentalNccApiSettingsFields.dtl].limits[today];
                this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday].daily_time_limit = newDtl;
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    /**
     *
     * NCC Managemnt request to get edit DTL Status for a profile
     * @param {*} profileHash
     * @param {boolean} enable New status
     * @return {*}
     * @memberof ParentalNccService
     */
    setDtlStatus(profileHash, enable: boolean) {
        return this.parentalNccMgmt.setDtlStatus(this.profilesNcc?.[profileHash]?.profile_id, enable) .pipe(
            map(resp => {
                if(resp?.status === 0) {
                    if (!this.parentalFields?.[profileHash]) {
                        this.parentalFields[profileHash] = {};
                    }
                    if (!this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]) {
                        this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats] = {};
                    }
                    if (!this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.timeSpentToday]) {
                        this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday] = {};
                    }
                    if (!this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.dtl]) {
                        this.parentalFields[profileHash][parentalNccApiSettingsFields.dtl] = {};
                    }
                    this.parentalFields[profileHash][parentalNccApiSettingsFields.dtl].is_enabled = enable;
                    this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday].daily_time_limit_enabled = enable;

                    if (enable) {
                        const today  = new Date().getDay();
                        const newDtl = this.parentalFields[profileHash][parentalNccApiSettingsFields.dtl].limits[today];
                        this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday].daily_time_limit = newDtl;
                    } else {
                        this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday].daily_time_limit = 0;
                    }

                } else {
                    throw new Error(JSON.stringify(resp));
                }
            }),
            catchError((err)=> {
                throw err;
            })
        );
    }

    /**
     *
     * NCC Managemnt request to remove a limit for a day
     * @param {*} profileHash
     * @param {number} dayToRemove (0 - 6) Starting with Sunday
     * @return {*}  {Observable<any>}
     * @memberof ParentalNccService
     */
    removeDtl(profileHash, dayToRemove: number): Observable<any> {

        return this.parentalNccMgmt.removeLimitDtl(this.profilesNcc?.[profileHash]?.profile_id, dayToRemove)
        .pipe(
            map(() => {
                if (!this.parentalFields?.[profileHash]) {
                    this.parentalFields[profileHash] = {};
                }

                if (!this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]) {
                    this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats] = {};
                }

                if (!this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.timeSpentToday]) {
                    this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday] = {};
                }


                if (!this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.dtl]) {
                    this.parentalFields[profileHash][parentalNccApiSettingsFields.dtl] = {};
                }

                this.parentalFields[profileHash][parentalNccApiSettingsFields.dtl].limits[dayToRemove] = this.parentalNccValuesService.secondsInDay;

                const today  = new Date().getDay();
                const newDtl = this.parentalFields[profileHash][parentalNccApiSettingsFields.dtl].limits[today];
                this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday].daily_time_limit = newDtl;
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    /**
     *
     * NCC Managemnt request to clear limit for evrey day
     * @param {*} profileHash
     * @return {*}  {Observable<any>}
     * @memberof ParentalNccService
     */
    clearAllDaysDtl(profileHash) : Observable<any> {

        return this.parentalNccMgmt.recomandedAllDaysDtl(this.profilesNcc?.[profileHash]?.profile_id)
        .pipe(
            map(() => {
                if (!this.parentalFields?.[profileHash]) {
                    this.parentalFields[profileHash] = {};
                }

                if (!this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]) {
                    this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats] = {};
                }

                if (!this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.timeSpentToday]) {
                    this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday] = {};
                }


                if (!this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.dtl]) {
                    this.parentalFields[profileHash][parentalNccApiSettingsFields.dtl] = {};
                }

                for (let i = 0; i< this.parentalNccValuesService.daysInWeek; i++) {
                    this.parentalFields[profileHash][parentalNccApiSettingsFields.dtl].limits[i] = this.parentalNccValuesService.dtlDefaultSeconds;
                }

                const today  = new Date().getDay();
                const newDtl = this.parentalFields[profileHash][parentalNccApiSettingsFields.dtl].limits[today];
                this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday].daily_time_limit = newDtl;
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    getDtlIsEnabled(profileHash) {
        return !!this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.dtl]?.is_enabled;
    }

    getAllDtlIsEnabled(profileHash) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.time_spent_today?.daily_time_limit_enabled
            && this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.time_spent_today?.daily_time_limit > 0;
    }

    /**
     *
     * Return an array(7) of limits for each day from DTL Limits object
     * @param {*} profileHash
     * @return {*}
     * @memberof ParentalNccService
     */
    getAllDtls(profileHash) {
        const limits = this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.dtl]?.limits ?? {};
        const newLimits = [];
        for (let i = 0; i < this.parentalNccValuesService.daysOfWeekShort.length; i++) {
            if (!limits?.[i]) {
                newLimits.push(this.parentalNccValuesService.secondsInDay);
            } else {
                newLimits.push(limits[i]);
            }
        }
        return newLimits;
    }

    /**
     * Return current DTL from Profile Stats
     * @param profileHash
     * @returns
     */
    getTodayDtl(profileHash) {
        return Number.isInteger(this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.timeSpentToday]?.daily_time_limit)
                ? this.parentalFields?.[profileHash]?.[parentalNccApiProfileFields.profileStats]?.[parentalNccApiProfileFields.timeSpentToday]?.daily_time_limit
                : 0;
    }
    //#endregion

    //#region Exceptions & Routines
    /**
     *
     * NCC Managemnt request to update exceptions and routine exception
     * @param {*} profileHash
     * @param {IUpdateException[]} updates
     * @param {string} [routineType]
     * @return {*}
     * @memberof ParentalNccService
     */
    updateExceptions(profileHash, updates: IUpdateException[], routineType?: string) {
        return this.parentalNccMgmt.updateExceptions(this.profilesNcc?.[profileHash]?.profile_id, updates, routineType)
        .pipe(
            map(resp => {
                if (resp?.status === 0) {
                    this.processUpdateExceptionAction(profileHash, updates, routineType);
                    this.messageService.sendMessage(this.valuesService.events.profileChanges, {});
                    return resp;
                } else {
                    throw new Error(JSON.stringify(resp));
                }
            }),
            catchError((err)=> {
                throw err;
            })
        );
    }

    /**
     *
     * Process updated exception or routine exceptions - used after the update request is complete
     * @param {*} profileHash
     * @param {IUpdateException[]} updates
     * @param {string} [routineType]
     * @memberof ParentalNccService
     */
    processUpdateExceptionAction(profileHash, updates: IUpdateException[], routineType?: string) {
        let exceptionRef = this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.exceptions];
        if (routineType) {
            exceptionRef = this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.routines]?.[routineType];
        }
        exceptionRef.default = false;

        for(const update of updates) {
            let updateRef = exceptionRef?.[update.type];

            if (update.action === NccUpdateActionType.set) {
                this.processExceptionSet(updateRef, update);
            } else {
                this.processExceptionClear(updateRef, update);
            }
        }


    }

    /**
     *
     * Process updated exception or routine exceptions with set action
     * Add exception in WhiteList or BlackList.
     * @param {*} exceptionRef Reference to updated object (Exceptions or Routines)
     * @param {IUpdateException} update
     * @memberof ParentalNccService
     */
    processExceptionSet(exceptionRef, update: IUpdateException) {
        if (update.allow) {
            const index = exceptionRef?.blacklist.indexOf(update.value);
            if (index > -1) {
                exceptionRef?.blacklist.splice(index, 1);
            }
            exceptionRef?.whitelist.push(update.value);
        } else {
            const index = exceptionRef?.whitelist.indexOf(update.value);
            if (index > -1) {
                exceptionRef?.whitelist.splice(index, 1);
            }
            exceptionRef?.blacklist.push(update.value);
        }
    }

    /**
     *
     * Process updated exception or routine exceptions with clear action.
     * Remove exception from WhiteList or BlackList.
     * Categories can't be removed.
     * @param {*} exceptionRef Reference to updated object (Exceptions or Routines)
     * @param {IUpdateException} update
     * @memberof ParentalNccService
     */
    processExceptionClear(exceptionRef, update: IUpdateException) {
        if (update.type !== NccExceptionType.categories) {
            let index = exceptionRef?.whitelist.indexOf(update.value);
            if (index > -1) {
                exceptionRef?.whitelist.splice(index, 1);
            } else {
                index = exceptionRef?.blacklist.indexOf(update.value);
                exceptionRef?.blacklist.splice(index, 1);
            }
        }
    }

    /**
     *
     *
     * @param {*} profileHash
     * @param {*} routine
     * @param {IUpdateRoutine[]} updates
     * @return {*}
     * @memberof ParentalNccService
     */
    updateRoutineSchedule(profileHash, routine, updates: IUpdateRoutine[]) {
        return this.parentalNccMgmt.updateRoutinesSchedule(this.profilesNcc?.[profileHash]?.profile_id, routine, updates)
        .pipe(
            map(resp => {
                if (resp?.status === 0) {
                    this.proceesdUpdateRoutineSchedule(profileHash, updates, routine);
                    this.messageService.sendMessage(this.valuesService.events.profileChanges, {});
                    return resp;
                } else {
                    throw new Error(JSON.stringify(resp));
                }
            }),
            catchError((err)=> {
                throw err;
            })
        );
    }

     /**
     *
     * Process updated routine schedule - used after the update request is complete
     * @param {*} profileHash
     * @param {IUpdateRoutine} update
     * @param {string} routineType
     * @memberof ParentalNccService
     */
    proceesdUpdateRoutineSchedule(profileHash, updates: IUpdateRoutine[], routineType: string) {
        const scheduleRef = this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.routines]?.[routineType]?.schedule;

        if (!this.parentalFields?.[profileHash]) {
            this.parentalFields[profileHash] = {}
        }

        if (!this.parentalFields?.[profileHash][parentalNccApiProfileFields.profileStats]) {
            this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats] = {}
        }

        if (!this.parentalFields?.[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday]) {
            this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday] = {}
        }

        for(const update of updates) {
            if (update.action === NccUpdateActionType.set) {
                scheduleRef[update.week_day] = {
                    start_time: update?.start_time,
                    end_time: update?.end_time
                };
            } else {
                delete scheduleRef?.[update.week_day];
            }
        }

        this._setActiveRoutine(profileHash, routineType);
        this.parentalFields[profileHash][parentalNccApiSettingsFields.routines][routineType].default = false;
    }

    /**
     * Sets the active routine if it is in the range of that day's schedule.
     * @param {string} profileHash
     * @param {string} routineType focustime | dinnertime | bedtime
     */
    private _setActiveRoutine(profileHash: string, routineType: string): void {
        if (this.parentalFields[profileHash][parentalNccApiSettingsFields.routines][routineType].enabled) {
            const today = this.getTodaySeconds();
            const scheduleForToday = this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.routines]?.[routineType]?.schedule?.[today.day];

            const yesterday = (this.parentalNccValuesService.daysInWeek + today.day - 1) % this.parentalNccValuesService.daysInWeek;
            const scheduleForYesterday = this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.routines]?.[routineType]?.schedule?.[yesterday];

            if (scheduleForYesterday && scheduleForYesterday.start_time > scheduleForYesterday.end_time && scheduleForYesterday.end_time > today.seconds) {
                this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday].active_routine = {
                    id: routineType,
                    week_day: yesterday,
                    ...scheduleForYesterday
                };
            } else if (scheduleForToday && scheduleForToday.start_time < today.seconds
                        && (scheduleForToday.end_time > today.seconds
                            || scheduleForToday.start_time > scheduleForToday.end_time)) {
                this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday].active_routine = {
                    id: routineType,
                    week_day: today.day,
                    ...scheduleForToday
                };
            } else if (this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday].active_routine?.id === routineType){
                this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday].active_routine = null;
            }
        } else {
            this.parentalFields[profileHash][parentalNccApiProfileFields.profileStats][parentalNccApiProfileFields.timeSpentToday].active_routine = null;
        }
    }
    /**
     *
     * NCC Management request to toggle or set current status
     * @param {*} profileHash
     * @param {*} routine
     * @param {boolean} enable
     * @return {*}
     * @memberof ParentalNccService
     */
    toogleRoutineStatus(profileHash, routine, enable?: boolean) {
        const currentStatus = enable ?? this.getRoutineIsEnabled(profileHash, routine);
        return this.parentalNccMgmt.setRoutinesStatus(this.profilesNcc?.[profileHash]?.profile_id, routine, !currentStatus)
        .pipe(
            map(resp => {
                if (resp?.status === 0) {
                    this.parentalFields[profileHash][parentalNccApiSettingsFields.routines][routine].enabled = !currentStatus;
                    this._setActiveRoutine(profileHash, routine);
                    this.messageService.sendMessage(this.valuesService.events.profileChanges, {});
                    return resp;
                } else {
                    throw new Error(JSON.stringify(resp));
                }
            }),
            catchError((err)=> {
                throw err;
            })
        );
    }

    /**
     *
     * NCC Managemnet request to toogle block_all_internet for exceptions or routine
     * @param {*} profileHash
     * @param {boolean} blockAllInternet
     * @param {string} [routineType]
     * @return {*}
     * @memberof ParentalNccService
     */
    toogleInternetAccess(profileHash, blockAllInternet: boolean, routineType?: string) {
        return this.parentalNccMgmt.blockAllInternet(this.profilesNcc?.[profileHash]?.profile_id, blockAllInternet, routineType)
        .pipe(
            map(resp => {
                if (resp?.status === 0) {
                    if (routineType) {
                        this.parentalFields[profileHash][parentalNccApiSettingsFields.routines][routineType][NccExceptionType.block_all] = blockAllInternet;
                        this.parentalFields[profileHash][parentalNccApiSettingsFields.routines][routineType].default = false;
                    } else {
                        this.parentalFields[profileHash][parentalNccApiSettingsFields.exceptions][NccExceptionType.block_all] = blockAllInternet;
                    }
                    this.messageService.sendMessage(this.valuesService.events.profileChanges, {});
                    return resp;
                } else {
                    throw new Error(JSON.stringify(resp));
                }
            }),
            catchError((err)=> {
                throw err;
            })
        );
    }

    /**
     *
     * NCC Management request to reset exceptions or routine exceptions.
     * Update timestamp in local storage after the request is complete.
     * @param {*} profileHash
     * @param {string} [routine]
     * @return {*}
     * @memberof ParentalNccService
     */
    resetExceptions(profileHash, routine?: string) {
        return this.parentalNccMgmt.resetExceptions(this.profilesNcc?.[profileHash]?.profile_id, routine)
        .pipe(
            map(resp => {
                if (resp?.status === 0) {
                    if (routine) {
                        this.parentalFields[profileHash][parentalNccApiSettingsFields.routines][routine] = resp?.default_routine;
                        this._setActiveRoutine(profileHash, routine);
                    } else {
                        this.parentalFields[profileHash][parentalNccApiSettingsFields.exceptions] = resp?.[parentalNccApiSettingsFields.exceptions];
                        this.parentalFields[profileHash][parentalNccApiSettingsFields.exceptions].default = true;
                    }
                    this.setLastCategoryUpdateTimestamp(profileHash, routine);
                    this.setLastExceptionUpdateTimestamp(profileHash, routine);

                    this.messageService.sendMessage(this.valuesService.events.profileChanges, {});
                    return true;
                } else {
                    throw new Error(JSON.stringify(resp));
                }
            }),
            catchError((err)=> {
                throw err;
            })
        );
    }
    //#endregion

    //#region Replacements
    updateReplacement(profileHash, replacement: NccReplacementsType, enabled: boolean) {
        let update: IUpdateReplacements = {
            replacement,
            action: NccUpdateActionType.clear
        };
        if (enabled) {
            update.action = NccUpdateActionType.set;
            update.enabled = enabled;
        }

        return this.parentalNccMgmt.updateReplacements(this.profilesNcc?.[profileHash]?.profile_id, [update])
        .pipe(
            map(() => {
                this.parentalFields[profileHash][parentalNccApiSettingsFields.replacements][replacement] = enabled;
            }),
            catchError( (err)=> {
                throw err;
            })
        );
    }

    getContentFilteringReplacements(profileHash) {
        return this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.replacements] ?? {};
    }

    nothingChangedForReplacements(profileHash, newReplacements) {
        if (!!this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.replacements]?.google !== newReplacements.google) {
            return false;
        }
        if (!!this.parentalFields?.[profileHash]?.[parentalNccApiSettingsFields.replacements]?.youtube !== newReplacements.youtube) {
            return false;
        }
        return true;
    }
    //#endregion

    //#region Generic Info
    getHostInfo(profileHash, hostName) : Observable<any> {
        return this.parentalNccMgmt.getHostInfo(hostName)
        .pipe(
            map(resp => {
                if (!resp) {
                    throw new Error(JSON.stringify(resp));
                }
                if(resp?.topics_info) {
                    for (const topic of resp?.topics_info) {
                        this.addExtraTopic(profileHash, topic);
                    }
                    return resp;
                }
            }),
            catchError( (err)=> {
                throw err;
            })
        );
    }
    //#endregion

    //#region Blocked Content
    /**
     *
     * List Blocked Cotegories for dashboard
     * @private
     * @param {string} profileHash
     * @param {Filters} period
     * @return {*}  {Observable<any>}
     * @memberof ParentalNccService
     */
    private _listBlockedCategoriesDashboard(profileHash: string, period: Filters): Observable<any> {
        const referenceObj = this.parentalFields[profileHash][NccUsageCardTypes.blockContent];
        const markToUpdateName = this.getMarkToupdateName([parentalNccBlockedContentFields.topCategories, '_', period]);
        if (referenceObj?.[markToUpdateName] || referenceObj?.[markToUpdateName] === undefined) {
            referenceObj[markToUpdateName] = false;
        } else {
            return of(true);
        }

        return this.parentalNccMgmt.getTopBlockedContent(this.profilesNcc?.[profileHash]?.profile_id, period, NccBlockedContentType.categories)
        .pipe(
            map(resp => {
                if (!resp) {
                    throw resp;
                }
                if (!referenceObj?.[parentalNccBlockedContentFields.topCategories]) {
                    referenceObj[parentalNccBlockedContentFields.topCategories] = {};
                }
                referenceObj[parentalNccBlockedContentFields.topCategories][period] = resp?.[parentalNccBlockedContentFields.topCategories];
                return true;
            }),
            catchError(err => {
                referenceObj[markToUpdateName] = true;
                throw err;
            })
        );
    }

    /**
     *
     * List Blocked Apps & Websites for dashboard
     * @private
     * @param {string} profileHash
     * @param {Filters} period
     * @return {*}  {Observable<any>}
     * @memberof ParentalNccService
     */
    private _listBlockedAppsDashboard(profileHash: string, period: Filters): Observable<any> {
        const referenceObj = this.parentalFields[profileHash][NccUsageCardTypes.blockContent];
        const markToUpdateNameApps = this.getMarkToupdateName([parentalNccBlockedContentFields.topApps, '_', period]);
        const markToUpdateNameWebsites = this.getMarkToupdateName([parentalNccBlockedContentFields.topWebsites, '_', period]);

        if (referenceObj?.[markToUpdateNameApps]
            || referenceObj?.[markToUpdateNameApps] === undefined
            || referenceObj?.[markToUpdateNameWebsites]
            || referenceObj?.[markToUpdateNameWebsites] === undefined) {
            referenceObj[markToUpdateNameApps] = false;
            referenceObj[markToUpdateNameWebsites] = false;
        } else {
            return of(true);
        }

        return this.parentalNccMgmt.getTopBlockedContent(this.profilesNcc?.[profileHash]?.profile_id, period, NccBlockedContentType.apps)
        .pipe(
            map(resp => {
                if (!resp) {
                    throw resp;
                }
                if (!referenceObj?.[parentalNccBlockedContentFields.topApps]) {
                    referenceObj[parentalNccBlockedContentFields.topApps] = {};
                }
                if (!referenceObj?.[parentalNccBlockedContentFields.topWebsites]) {
                    referenceObj[parentalNccBlockedContentFields.topWebsites] = {};
                }
                referenceObj[parentalNccBlockedContentFields.topApps][period] = resp?.[parentalNccBlockedContentFields.topApps];
                referenceObj[parentalNccBlockedContentFields.topWebsites][period] = resp?.[parentalNccBlockedContentFields.topWebsites];
                return true;
            }),
            catchError(err => {
                referenceObj[markToUpdateNameApps] = true;
                referenceObj[markToUpdateNameWebsites] = true;
                throw err;
            })
        );
    }

    /**
     *
     * List all blocked content for dashboard based on config
     * @param {string} profileHash
     * @return {*}  {Observable<boolean>}
     * @memberof ParentalNccService
     */
    public listBlockedContentDashboard(profileHash: string): Observable<boolean> {
        if (!this.parentalFields?.[profileHash]) {
            this.parentalFields[profileHash] = {};
        }
        if (!this.parentalFields?.[profileHash]?.[NccUsageCardTypes.blockContent]) {
            this.parentalFields[profileHash][NccUsageCardTypes.blockContent] = {};
        }

        let toList: Observable<any>[] = [];
        if (this.configService.getNCCBlockedContentHasCategories()) {
            toList.push(this._listBlockedCategoriesDashboard(profileHash, Filters.LAST_WEEK).pipe(
                catchError( err => of(err))
            ));
            toList.push(this._listBlockedCategoriesDashboard(profileHash, Filters.LAST_MONTH).pipe(
                catchError( err => of(err))
            ));

        }

        if (this.configService.getNCCBlockedContentHasTopics() || this.configService.getNCCBlockedContentHasWebsites()) {
            toList.push(this._listBlockedAppsDashboard(profileHash, Filters.LAST_WEEK).pipe(
                catchError( err => of(err))
            ));
            toList.push(this._listBlockedAppsDashboard(profileHash, Filters.LAST_MONTH).pipe(
                catchError( err => of(err))
            ));
        }
        if (toList.length > 0) {
            return forkJoin( toList )
            .pipe(
                map(() => {
                    return true;
                }),
                catchError(err => {
                    throw err;
                })
            );
        } else {
            return of(true);
        }
    }

    /**
     *
     * List all blocked categories
     * @param {string} profileHash
     * @param {Filters} period
     * @return {*}  {Observable<any>}
     * @memberof ParentalNccService
     */
    public listBlockedCategories(profileHash: string, period: Filters): Observable<any> {
        if (!this.parentalFields?.[profileHash]) {
            this.parentalFields[profileHash] = {};
        }
        if (!this.parentalFields?.[profileHash]?.[NccUsageCardTypes.blockContent]) {
            this.parentalFields[profileHash][NccUsageCardTypes.blockContent] = {};
        }
        const referenceObj = this.parentalFields[profileHash][NccUsageCardTypes.blockContent];
        const markToUpdateName = this.getMarkToupdateName([parentalNccBlockedContentFields.allCategories, '_', period]);
        if (referenceObj?.[markToUpdateName] || referenceObj?.[markToUpdateName] === undefined) {
            referenceObj[markToUpdateName] = false;
        } else {
            return of(true);
        }

        return this.parentalNccMgmt.getAllBlockedContent(this.profilesNcc?.[profileHash]?.profile_id, NccBlockedContentType.categories, period)
        .pipe(
            map(resp => {
                if (!resp) {
                    throw resp;
                }
                if (!referenceObj?.[parentalNccBlockedContentFields.allCategories]) {
                    referenceObj[parentalNccBlockedContentFields.allCategories] = {};
                }
                referenceObj[parentalNccBlockedContentFields.allCategories][period] = resp?.[parentalNccBlockedContentFields.allCategories];
                return true;
            }),
            catchError(err => {
                referenceObj[markToUpdateName] = true;
                throw err;
            })
        );
    }

    /**
     *
     * List Blocked Apps or Websites
     * @param {string} profileHash
     * @param {parentalNccBlockedContentFields} field
     * @param {Filters} period
     * @param {number} page
     * @param {number} [limit]
     * @return {*}  {Observable<boolean>}
     * @memberof ParentalNccService
     */
    public listBlockedAppsOrWebsites(profileHash: string, field: parentalNccBlockedContentFields, period: Filters, page: number, limit?: number): Observable<boolean> {
        if (!limit) {
            limit = this.parentalNccValuesService.blockedContentPageLimit;
        }
        if (!this.parentalFields?.[profileHash]) {
            this.parentalFields[profileHash] = {};
        }
        if (!this.parentalFields?.[profileHash]?.[NccUsageCardTypes.blockContent]) {
            this.parentalFields[profileHash][NccUsageCardTypes.blockContent] = {};
        }
        let type: NccBlockedContentType;
        if (field === parentalNccBlockedContentFields.apps) {
            type = NccBlockedContentType.apps;
        } else {
            type = NccBlockedContentType.websites;
        }

        const referenceObj = this.parentalFields[profileHash][NccUsageCardTypes.blockContent];
        return this.parentalNccMgmt.getAllBlockedContent(this.profilesNcc?.[profileHash]?.profile_id, type, period, page, limit)
        .pipe(
            map(resp => {
                if (!resp) {
                    throw resp;
                }
                if (!referenceObj?.[field]) {
                    referenceObj[field] = {};
                }
                referenceObj[field][period] = resp?.[field];
                return true;
            }),
            catchError(err => {
                 throw err;
            })
        );
    }
    //#endregion

    //#region Private Functions
    /**
     * Create info_topics_object & info_categories_object
     * @param field
     * @param fieldValue
     */
     private createNccInfoObjects(profileHash, field, fieldValues) {
        const name = field.concat('_object');
        const infoObject = {};
        for (const entry of fieldValues) {
            if (!entry?.error) {
                infoObject[entry.id] = entry;
            } else {
                const index = fieldValues.indexOf(entry);
                fieldValues.splice(index, 1);
            }
        }
        this.parentalFields[profileHash][name] = infoObject;
    }

    private setLastExceptionUpdateTimestamp(profileHash, routineType?) {
        this.setLastUpdateTimestampFromStorage(profileHash, this.parentalNccValuesService.contentFiltering.lastExceptionUpdate, routineType);
    }

    private setLastCategoryUpdateTimestamp(profileHash, routineType?) {
       this.setLastUpdateTimestampFromStorage(profileHash, this.parentalNccValuesService.contentFiltering.lastCategoryUpdate, routineType);
    }
    //#endregion

}
