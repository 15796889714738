import { Injectable } from '@angular/core';
import { NotificationsService } from '../../process/notifications/notifications.service';
import {
    NotificationActions,
    ServiceWorkerMessageEvent,
    DynamicProperties,
    PermissionStatuses,
    ServiceWorkerCommands,
    ServiceWorkerCommand,
    ServiceWorkerNotificationCommand
} from '../../../../common/models/Pwa.model';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Browsers } from '../../../values/values.service';
import { ConfigService } from '../../../config/config.service';

@Injectable({
    providedIn: 'root'
})

export class PwaNotificationsService {

    private notificationsAllowed = false;
    private readonly messageHandlers = {
        [ServiceWorkerMessageEvent.PUSH]: (event) => this.handlePushMsg(event),
        [ServiceWorkerMessageEvent.VERSION_READY]: (event) => this.handleNewVersionReady(event),
        [ServiceWorkerMessageEvent.NOTIFICATION_CLICK]: (event) => this.handleNotificationClick(event)
    };

    private notificationActionHandlers = {
        [NotificationActions.UPDATE_APP]: (event) => this.updateApp(event),
        [NotificationActions.NOTIFICATION_DETAILS]: (event) => this.showNotificationDetails(event)
    };

    constructor(
        private readonly notificationsService: NotificationsService,
        private readonly translateService: TranslateService,
        private readonly deviceDetectorService: DeviceDetectorService,
        private readonly configService: ConfigService
    ) {}

    /**
     * Generic messenger - it sends messages (commands) to the service worker
     * right now it only sends one command via the notificationMsg method (SHOW_NOTIFICATION)
     * but it could send other commands in the future
     * @private
     * @param {ServiceWorkerCommand} message
     */
    private sendMessageToServiceWorker(message: ServiceWorkerCommand): Promise<any> {
        if (!navigator?.serviceWorker?.controller) {
            return;
        }
        return new Promise((resolve, reject) => {
            const messageChannel = new MessageChannel();
            messageChannel.port1.onmessage = (event) => {
                if (event.data.error) {
                    reject(event.data.error);
                } else {
                    resolve(event.data);
                }
            };

            // This sends the message data as well as transferring messageChannel.port2 to the service worker.
            // The service worker can then use the transferred port to reply via postMessage(), which
            // will in turn trigger the onmessage handler on messageChannel.port1.
            // See https://html.spec.whatwg.org/multipage/workers.html#dom-worker-postmessage
            navigator.serviceWorker.controller.postMessage(message, [messageChannel.port2]);
        });
    }

    /**
     * Method used for aquiring permision to show notifications
     * User can reject browser notification, so persistent notification will not be shown in those cases
     * @private
     */
    private requestNotificationsPermission(): void {
        const currentBroser = this.deviceDetectorService.browser.toLowerCase() as Browsers;
        const userGestureRequiringBrowsers = new Set([Browsers.FIREFOX, Browsers.SAFARI]);
        if (!(DynamicProperties.NOTIFICATION in window) || userGestureRequiringBrowsers.has(currentBroser)) {
            return;
        }

        Notification.requestPermission().then(
            (permision) => {
                this.notificationsAllowed = permision === PermissionStatuses.GRANTED;
                console.log('Notifications are allowed', permision);
            },
            (_err) => {
                console.log('Notifications were blocked');
            }
        );
    }

    //*
    //*  Message handlers - called deppending on the message sent by the service wroker
    //*
    //#region

    /**
     * Method used for handling the PUSH message sent by the service worker
     * for now it's only used for testing persistent notification behaviour
     * @private
     * @param {any} event
     */
    private handlePushMsg(event: any): void {
        this.showPersistentNotification(
            'New push message',
            {
                body: event.data.title,
                actions: [{
                    action: NotificationActions.UPDATE_APP,
                    title: 'Update app'
                }]
            }
        );
    }

    /**
     * Method used for handling the VERSION_READY message sent by the service worker
     * @private
     * @param {any} event
     */
    private handleNewVersionReady(_event: any): void {
        this.showPersistentNotification(
            this.translateService.instant('pwa.newVersionReady.title'),
            {
                body: this.translateService.instant('pwa.newVersionReady.description'),
                // requireInteraction: true, this setting works only when notifications are set to alert mode in user's settings
                data: {},
                actions: [{
                    title: this.translateService.instant('pwa.newVersionReady.cta'),
                    action: NotificationActions.UPDATE_APP
                }]
            }
        );
    }

    /**
     * Method used for handling the NOTIFICATION_CLICK message sent by the service worker
     * @private
     * @param {any} event
     */
    private handleNotificationClick(event: any): void {
        let action;
        if (event.data.action) {
            action = event.data.action;
        } else {
            action = event.data.notification.actions[0]?.action;
        }
        this.notificationActionHandlers[action]?.(event);
    }
    //#endregion

    //*
    //*  Persistent notification actions handlers - called deppending on the action set on the notification
    //*
    //#region

    /**
     * Method used for updating the app when UPDATE_APP was called
     * @private
     * @param {any} _event
     */
    private updateApp(_event: any): void {
        window.location.reload();
    }

    /**
     * Method used for opening notification details drawer when NOTIFICATION_DETAILS action was called
     * @private
     * @param {any} event
     */
    private showNotificationDetails(event: any): void {
        this.notificationsService.sendShowNotificationDetailsEvent(event.data.notification);
    }
    //#region

    /**
     * Method used for showing persistent notifications
     * It sends SHOW_NOTIFICATION command to the service worker
     * @public
     * @param {string} title
     * @param {NotificationOptions} options
     */
    public showPersistentNotification(title: string, options?: NotificationOptions) {
        if (!this.notificationsAllowed) {
            return;
        }

        const notificationCommand: ServiceWorkerNotificationCommand = {
            command: ServiceWorkerCommands.SHOW_NOTIFICATION,
            commandData: {
                title: title,
                options: {
                    // requireInteraction: true, for important notification that need the user to take action (it does not go away by itself)
                    // tag: 'renotify',
                    // renotify: true, use these 2 (tag + renotify) for notification that need to updated after some time (notification already shown to the user)
                    icon: './assets/icons/android/512.png',
                    badge: './ux/images/favicons/favicon.ico',
                    // image: './assets/icons/android/512.png',
                    ... options,
                    data: {
                        siteUrl: this.configService.getSiteUrl(),
                        ...options?.data
                    }
                }
            }
        };
        this.sendMessageToServiceWorker(notificationCommand);
    }

    /**
     * Method used for listening to the service worker message events.
     */
    public listenToServiceWorkerMessageEvents(): void {
        if (DynamicProperties.SERVICE_WORKER in navigator) {
            this.requestNotificationsPermission();
            navigator.serviceWorker.addEventListener('message', (event) => {
                // event is a MessageEvent object, maybe event source could be used do pass client id to handlers
                console.log('The service worker sent me a message', event.data);
                const swReply = event.data;
                this.messageHandlers[swReply.type]?.(swReply);
            });
        }
    }
}