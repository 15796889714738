// External
import { ElementRef, Injectable } from '@angular/core';

// Internal

@Injectable({
    providedIn: 'root'
})

export class TooltipService {

    tooltipsList: any[] = [];

    addTooltip(trigger: ElementRef, title: string, position? : string, color? : string, width? : number){
        this.tooltipsList.push({
            ref: trigger,
            title: title,
            position: position || 'top',
            color: color || 'primary',
            width: width
        });
    }

    // there can be only one at a time, so no need for index or id or other identifiers
    removeTooltip(){
        this.tooltipsList.length = 0;
    }
}
