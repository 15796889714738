import { Injectable } from '@angular/core';
import { ValuesService } from '../../values/values.service';
import { SubscriptionsService } from '../process/subscriptions/subscriptions.service';
import { PasswordManagerService } from '../process/password-manager/password-manager.service';
import { PrivacyService } from '../process/privacy/privacy.service';
import { ProfilesService } from '../process/profiles/profiles.service';
import { GroupManagementService } from '../process/subscriptions/group-management.service';
import { LanguageService } from './language.service';

enum HotjarEvents {
    TRIGGER_NPS_VSB_SURVEY = 'nps-vsb'
}

interface IHotjarSurvey {
    trigger: () => boolean;
    isActive: boolean;
    languages: Set<string>;
}

interface IServiceDetails {
    configured: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class HotjarService {
    private hotjarSurveys: {[key in HotjarEvents]?: IHotjarSurvey} = {
        [HotjarEvents.TRIGGER_NPS_VSB_SURVEY]: {
            trigger: () => this.triggerVSBSurvey(),
            isActive: false,
            languages: this.valuesService.englishLanguages
        }
    };

    private servicesConfiguration: {[key: string]: IServiceDetails} = {
        [this.valuesService.appSecurity]: {
            configured: false
        },
        [this.valuesService.appWebmailProtection]: {
            configured: false
        },
        [this.valuesService.appPassManager]: {
            configured: false
        },
        [this.valuesService.appDIP]: {
            configured: false
        }
    };

    constructor(
        private readonly valuesService: ValuesService,
        private readonly subscriptionsService: SubscriptionsService,
        private readonly passwordManagerService: PasswordManagerService,
        private readonly privacyService: PrivacyService,
        private readonly profilesService: ProfilesService,
        private readonly groupManagementService: GroupManagementService,
        private readonly languageService: LanguageService
    ) {}

    /**
     * Computes configuration of the services that are used to trigger the surveys
     * @private
     * @param {none}
     * @returns {void}
     */
    private computeServicesConfiguration(): void {
        this.servicesConfiguration[this.valuesService.appSecurity].configured = this.subscriptionsService.hasSecurityConfigured();
        this.servicesConfiguration[this.valuesService.appWebmailProtection].configured = this.subscriptionsService.hasWebmailProtectionConfigured();
        this.servicesConfiguration[this.valuesService.appPassManager].configured = this.passwordManagerService.getSaferpassUserRegistered();
        this.servicesConfiguration[this.valuesService.appDIP].configured = this.privacyService.onboardingIsDone();
    }

    /**
     * Checks if the VSB survey should be triggered
     * @private
     * @param {none}
     * @returns {boolean} - true if the survey should be triggered, false otherwise
     */
    private triggerVSBSurvey(): boolean {
        const isVSBAdmin = this.profilesService.isOwnerVSBPrimaryAdmin();
        const activeGroupMembers = this.groupManagementService.getGroupMembers().filter(member => member.invite_accepted);

        if (!isVSBAdmin || activeGroupMembers.length < 2) {
            return false;
        }

        this.computeServicesConfiguration();
        for (const service in this.servicesConfiguration) {
            if (this.servicesConfiguration[service].configured === true) {
                return true;
            }
        }

        return false;
    }

    /**
     * Triggers hotjar surveys
     * @public
     * @param {none}
     * @returns {void}
     */
    public triggerSurveys(): void {
        if (!window.hasOwnProperty('hj')) {
            return;
        }

        for (const survey in this.hotjarSurveys) {
            const lang = this.languageService.getLang();
            if (this.hotjarSurveys[survey].isActive || !this.hotjarSurveys[survey].languages.has(lang)) {
                continue;
            }

            const triggerSurvey = this.hotjarSurveys[survey]?.trigger();
            if (triggerSurvey) {
                window.hj('event', survey);
                this.hotjarSurveys[survey].isActive = true;
            }
        }
    }
}