import { Injectable } from '@angular/core';
import { Observable, map, catchError } from 'rxjs';
import { ConfigService } from '../../../../common/config/config.service';
import { ValuesService } from '../../../../common/values/values.service';
import { RequestsService, POST, Errors } from '../../global/request-service/requests.service';
import { LinksService } from '../../../../common/links/links.service';


@Injectable({
    providedIn: 'root'
})

export class V1GmailTokensService {
    constructor(
        private readonly requestsService: RequestsService,
        private readonly valuesService: ValuesService,
        private readonly configService: ConfigService,
        private readonly linksSrvice: LinksService
    ) {}

    /**
     * Gets the tokens from the server.
     * @param {string} code The code to be sent to the server.
     * @returns {Observable<any>} The response from the server.
     */
    public listTokens(code: string): Observable<any> {
        const webmailProtectionStaticPagePath = this.valuesService.centralPaths.services.path.concat(this.valuesService.centralPaths.services.webmailprotection.path);
        const json = {
            redirect_url: `${location.protocol}//${location.host}${webmailProtectionStaticPagePath}`,
            code,
            scopes: this.linksSrvice.getWebmailProtectionGmailOauth2Scopes()
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.v1GmailTokens,
            json,
            POST
        ).pipe(
            map(resp => {
                if (resp?.status === Errors.ERROR || !resp.access_token) {
                    throw resp;
                }
                return resp;
            }),
            catchError(error => {
                throw(error);
            })
        );
    }
}