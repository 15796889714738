import { AssetSubtypes } from '../business/Business.model';


export class IdentityModel {
    identity_id: string;
    status: number;
    date_created: Date;
    onboarding?: string;
    init_started: boolean;
    init_done: boolean;
    scan_info: ScanInfo;
    first_name: string;
    last_name: string;
    middle_name: string;
    emails: Metadata[];
    credit_card: Metadata[];
    domain: Metadata[];
    phones: Metadata[];
    sm: Metadata[];
    lang: string;
    validate_required: any[];
    required_metadata: any;
    feature_set: FeatureSetEnum[];
    allowed_metadata;
    migrated: boolean;
    parking_details?: ParkingDetailsModel;
    partner_id;
}

export class ParkingDetailsModel {
    active: boolean;
    remove_at: Date;
}

export enum FeatureSetEnum {
    EXPOSURE = "exposure",
    BREACH = "breach",
    IMPERSONATION = "impersonation",
    DATABROKERS = "databrokers",
    EDUCATION = "education"
}

export class Metadata {
    validated?: boolean;
    value?: any;
    encryptedValue?: string;
    info_id?: string;
    type?: MetadataType;
    subtype?: MetadataPhoneSubtype|AssetSubtypes[];
    source?: MetadataSource;
    metadata_id?: string;
    authorized_scopes?: Array<string>;
    remove?: boolean;
    masked?: string;

    computed_canBeConnected?: boolean;
    computed_isGmailAccount?: boolean;
    computed_isGmailConnectedForServiceDiscovery?: boolean;
    computed_isGmailConnectedForSend?: boolean;
    computed_isOutlookAccount?: boolean;
    computed_isOutlookConnectedForServiceDiscovery?: boolean;
    computed_isOutlookConnectedForSend?: boolean;
}

export class ScanInfo {
    status: ScanStatus;
    next_scan: Date;
    previous_scan: Date;
}

export enum ScanStatus {
    DONE = 'done',
    IN_PROGRESS = 'in_progress'
}

export enum MetadataSource {
    ONBOARDING = 'ONBOARDING',
    CONFIRMED = 'USER_CONFIRMATION',
    ADDED = 'USER_ADDED'
}

export enum MetadataPhoneType {
    MOBILE = 'phone',
    LANDLINE = 'landline'
}

export enum MetadataPhoneSubtype {
    MOBILE = 'mobile',
    LANDLINE = 'landline'
}

export enum MetadataType {
    NAMES = 'names',
    ADDRESSES = 'addresses',
    PHONES = 'phones',
    EMAILS = 'emails',
    USERNAMES = 'usernames',
    JOBS = 'jobs',
    EDUCATIONS = 'educations',
    IMAGES = 'images',
    GENDER = 'gender',
    USER_IDS = "user_ids",
    URLS = "urls",
    DOB = "dob",
    LANGUAGES = 'languages',
    REFERENCES = 'references',
    //custom type - only for interface
    EMAIL_CONNECTIONS = 'emailConnections',
    CREDIT_CARD = 'credit_card',
    CREDIT_CARD_10 = 'credit_card_10',
    DOMAIN = 'domain'
}

export enum MetadataTypeSingular {
    NAMES = 'name',
    ADDRESSES = 'address',
    PHONES = 'phone',
    EMAILS = 'email',
    USERNAMES = 'username',
    JOBS = 'job',
    EDUCATIONS = 'education',
    IMAGES = 'image',
    GENDER = 'gender',
    USER_IDS = "user id",
    URLS = "url",
    DOB = "dob",
    LANGUAGES = 'language',
    REFERENCES = 'reference',
    DOMAIN = 'domain',
    CREDIT_CARD = 'credit_card',
    CREDIT_CARD_10 = 'credit_card_10'
}

export enum MetaDataEmailTypes {
    GMAIL = 'gmail',
    OUTLOOK = 'outlook',
}

export enum EmailDomains {
    Google = '@gmail.com',
    Hotmail = '@hotmail.com',
    Outlook = '@outlook.com'
}

export enum GmailConnectionState {
    SERVICE_DISCOVERY = 'https://www.googleapis.com/auth/gmail.readonly', //email read
    SEND_MAIL = 'https://www.googleapis.com/auth/gmail.send'
}

export enum OutlookConnectionState {
    SERVICE_DISCOVERY = 'https://graph.microsoft.com/mail.readbasic', //email read
    SEND_MAIL = 'https://graph.microsoft.com/mail.send'
}

export enum MonitoringStateStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}

export enum EmailConnectionScope {
    READ = 'read',
    SEND = 'send'
}

export interface MetadataInterface {
    label: string;
    description: string;
    icon: {
        type: string;
        name: string;
    };
    btnText?: string;
    btnAction?: () => void;
    showAction?: boolean;
    maxValidations: number;
    loading: boolean;
}

export type MonitoredInfoInterface = {
    [key in MetadataType]?: MetadataInterface;
}

export interface IErrorText {
    translationId: string;
    props?: object
}
