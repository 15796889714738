export interface Theme {
    name: string;
    type: ThemeType;
    properties: any;
    colors: any;
}

export enum ThemeType {
    dark = 'dark',
    light = 'light'
}

export enum Color {
    elevation_0 = 'elevation-0',
    elevation_1 = 'elevation-1',
    elevation_2 = 'elevation-2',
    elevation_3 = 'elevation-3',

    primary_5 =  'primary-5',
    primary_10 = 'primary-10',
    primary_15 = 'primary-15',
    primary = 'primary',
    primary_20 = 'primary-20',
    primary_30 = 'primary-30',
    primary_40 = 'primary-40',

    // success (emerald)
    success_5 =  'success-5',
    success_10 = 'success-10',
    success_15 = 'success-15',
    success =    'success',
    success_20 = 'success-20',
    success_30 = 'success-30',
    success_40 = 'success-40',

    // danger (chili)
    danger_5 =  'danger-5',
    danger_10 = 'danger-10',
    danger_15 = 'danger-15',
    danger =    'danger',
    danger_20 = 'danger-20',
    danger_30 = 'danger-30',
    danger_40 = 'danger-40',

    // warning (apricot)
    warning_5 =  'warning-5',
    warning_10 = 'warning-10',
    warning_15 = 'warning-15',
    warning =    'warning',
    warning_20 = 'warning-20',
    warning_30 = 'warning-30',
    warning_40 = 'warning-40',

    // a1 (mulberry)
    a1_5 =  'a1-5',
    a1_10 = 'a1-10',
    a1_15 = 'a1-15',
    a1 =    'a1',
    a1_20 = 'a1-20',
    a1_30 = 'a1-30',
    a1_40 = 'a1-40',

    // a2 (amethist)
    a2_5 =  'a2-5',
    a2_10 = 'a2-10',
    a2_15 = 'a2-15',
    a2 =    'a2',
    a2_20 = 'a2-20',
    a2_30 = 'a2-30',
    a2_40 = 'a2-40',

    // a3 (azure)
    a3_5 =  'a3-5',
    a3_10 = 'a3-10',
    a3_15 = 'a3-15',
    a3 =    'a3',
    a3_20 = 'a3-20',
    a3_30 = 'a3-30',
    a3_40 = 'a3-40',

    //  a4 (jade)
    a4_5 =  'a4-5',
    a4_10 = 'a4-10',
    a4_15 = 'a4-15',
    a4 =    'a4',
    a4_20 = 'a4-20',
    a4_30 = 'a4-30',
    a4_40 = 'a4-40',

    // a5 (turquoise)
    a5_5 =  'a5-5',
    a5_10 = 'a5-10',
    a5_15 = 'a5-15',
    a5 =    'a5',
    a5_20 = 'a5-20',
    a5_30 = 'a5-30',
    a5_40 = 'a5-40',

    // a6 (lime)
    a6_5 =  'a6-5',
    a6_10 = 'a6-10',
    a6_15 = 'a6-15',
    a6 =    'a6',
    a6_20 = 'a6-20',
    a6_30 = 'a6-30',
    a6_40 = 'a6-40',

    // a7 (peach)
    a7_5 =  'a7-5',
    a7_10 = 'a7-10',
    a7_15 = 'a7-15',
    a7 =    'a7',
    a7_20 = 'a7-20',
    a7_30 = 'a7-30',
    a7_40 = 'a7-40',

    // a8 (lemon)
    a8_5 =  'a8-5',
    a8_10 = 'a8-10',
    a8_15 = 'a8-15',
    a8 =    'a8',
    a8_20 = 'a8-20',
    a8_30 = 'a8-30',
    a8_40 = 'a8-40',

    // theme independent colors (same on all themes)
    light = 'light',
    dark = 'dark',
    brand = 'brand',

    shade = 'shade'
}

export const light: Theme = {
    name: "default",
    type: ThemeType.light,
    properties: {},
    colors: {
        // elevation
        [Color.elevation_0]: '#F5F5F7',
        [Color.elevation_1]: '#ffffff',
        [Color.elevation_2]: '#ffffff',
        [Color.elevation_3]: '#ffffff',

        // primary (cobalt)
        [Color.primary_5]:  '#F2F7FF',
        [Color.primary_10]: '#B9D4FF',
        [Color.primary_15]: '#81B1FF',
        [Color.primary]:    '#0F6AFF',
        [Color.primary_20]: '#094FC2',
        [Color.primary_30]: '#043586',
        [Color.primary_40]: '#011D49',

        // success (emerxald)
        [Color.success_5]:  '#F2FFF8',
        [Color.success_10]: '#A3E1BF',
        [Color.success_15]: '#60C38D',
        [Color.success]:    '#058840',
        [Color.success_20]: '#036931',
        [Color.success_30]: '#014A22',
        [Color.success_40]: '#002C14',

        // danger (chili)
        [Color.danger_5]:  '#FFF2F4',
        [Color.danger_10]: '#FAB2BC',
        [Color.danger_15]: '#F47586',
        [Color.danger]:    '#EA0220',
        [Color.danger_20]: '#B30118',
        [Color.danger_30]: '#7B0111',
        [Color.danger_40]: '#440009',

        // warning (apricot)
        [Color.warning_5]:  '#FFF8F2',
        [Color.warning_10]: '#FCD3B4',
        [Color.warning_15]: '#FAAF77',
        [Color.warning]:    '#F46A00',
        [Color.warning_20]: '#BA5100',
        [Color.warning_30]: '#803800',
        [Color.warning_40]: '#471F00',

        // a1 (mulberry)
        [Color.a1_5]:  '#FFF2FC',
        [Color.a1_10]: '#E7B2DB',
        [Color.a1_15]: '#D079BC',
        [Color.a1]:    '#B84A9F',
        [Color.a1_20]: '#96307F',
        [Color.a1_30]: '#510D42',
        [Color.a1_40]: '#2F0425',

        // a2 (amethist)
        [Color.a2_5]:  '#F7F2FF',
        [Color.a2_10]: '#D2BDF5',
        [Color.a2_15]: '#B08CEA',
        [Color.a2]:    '#8F5EE0',
        [Color.a2_20]: '#6B3DB6',
        [Color.a2_30]: '#2F1061',
        [Color.a2_40]: '#180537',

        // a3 (azure)
        [Color.a3_5]:  '#F2FBFF',
        [Color.a3_10]: '#9AD4F4',
        [Color.a3_15]: '#4AB0E8',
        [Color.a3]:    '#008FDD',
        [Color.a3_20]: '#0074B3',
        [Color.a3_30]: '#003E60',
        [Color.a3_40]: '#002336',

        //  a4 (jade)
        [Color.a4_5]:  '#F2FFFE',
        [Color.a4_10]: '#90E1DA',
        [Color.a4_15]: '#40C4B8',
        [Color.a4]:    '#02A698',
        [Color.a4_20]: '#01877C',
        [Color.a4_30]: '#004044',
        [Color.a4_40]: '#002B28',

        // a5 (turquoise)
        [Color.a5_5]:  '#F2FDFF',
        [Color.a5_10]: '#96E1ED',
        [Color.a5_15]: '#45C6DA',
        [Color.a5]:    '#00ACC8',
        [Color.a5_20]: '#008CA3',
        [Color.a5_30]: '#004C58',
        [Color.a5_40]: '#002B32',

        // a6 (lime)
        [Color.a6_5]:  '#F2FFF2',
        [Color.a6_10]: '#AEE3AE',
        [Color.a6_15]: '#74C674',
        [Color.a6]:    '#44AA44',
        [Color.a6_20]: '#2C8B2C',
        [Color.a6_30]: '#0C4C0C',
        [Color.a6_40]: '#042C04',

        // a7 (peach)
        [Color.a7_5]:  '#FFF3F2',
        [Color.a7_10]: '#FCBFBD',
        [Color.a7_15]: '#F88C89',
        [Color.a7]:    '#F55B56',
        [Color.a7_20]: '#C73C38',
        [Color.a7_30]: '#6A120F',
        [Color.a7_40]: '#3B0604',

        // a8 (lemon)
        [Color.a8_5]:  '#FFFEF2',
        [Color.a8_10]: '#FFF4A2',
        [Color.a8_15]: '#FFEA51',
        [Color.a8]:    '#FFE000',
        [Color.a8_20]: '#9E8B00',
        [Color.a8_30]: '#6E6100',
        [Color.a8_40]: '#3D3600',

        [Color.light]: '#ffffff',
        [Color.dark]: '#000000',
        [Color.brand]: '#d90201',

        [Color.shade]: '#000000'
    }
}

export const dark: Theme = {
    name: "dark",
    type: ThemeType.dark,
    properties: {},
    colors: {
        // elevation
        [Color.elevation_0]: '#030814',
        [Color.elevation_1]: '#141517',
        [Color.elevation_2]: '#202225',
        [Color.elevation_3]: '#2C2E33',

        // primary (cobalt)
        [Color.primary_40]:  '#F2F7FF',
        [Color.primary_30]: '#B9D4FF',
        [Color.primary_20]: '#81B1FF',
        [Color.primary]:    '#0F6AFF',
        [Color.primary_15]: '#094FC2',
        [Color.primary_10]: '#043586',
        [Color.primary_5]: '#011D49',

        // success (emerxald)
        [Color.success_40]:  '#F2FFF8',
        [Color.success_30]: '#A3E1BF',
        [Color.success_20]: '#60C38D',
        [Color.success]:    '#058840',
        [Color.success_15]: '#036931',
        [Color.success_10]: '#014A22',
        [Color.success_5]: '#002C14',

        // danger (chili)
        [Color.danger_40]:  '#FFF2F4',
        [Color.danger_30]: '#FAB2BC',
        [Color.danger_20]: '#F47586',
        [Color.danger]:    '#EA0215',
        [Color.danger_15]: '#B30118',
        [Color.danger_10]: '#7B0111',
        [Color.danger_5]: '#440009',

        // warning (apricot)
        [Color.warning_40]:  '#FFF8F2',
        [Color.warning_30]: '#FCD3B4',
        [Color.warning_20]: '#FAAF77',
        [Color.warning]:    '#F46A00',
        [Color.warning_15]: '#BA5100',
        [Color.warning_10]: '#803800',
        [Color.warning_5]: '#471F00',

        // a1 (mulberry)
        [Color.a1_40]:  '#FFF2FC',
        [Color.a1_30]: '#E7B2DB',
        [Color.a1_20]: '#D079BC',
        [Color.a1]:    '#B84A9F',
        [Color.a1_15]: '#96307F',
        [Color.a1_10]: '#510D42',
        [Color.a1_5]: '#2F0425',

        // a2 (amethist)
        [Color.a2_40]:  '#F7F2FF',
        [Color.a2_30]: '#D2BDF5',
        [Color.a2_20]: '#B08CEA',
        [Color.a2]:    '#8F5EE0',
        [Color.a2_15]: '#6B3DB6',
        [Color.a2_10]: '#2F1061',
        [Color.a2_5]: '#180537',

        // a3 (azure)
        [Color.a3_40]:  '#F2FBFF',
        [Color.a3_30]: '#9AD4F4',
        [Color.a3_20]: '#4AB0E8',
        [Color.a3]:    '#008FDD',
        [Color.a3_15]: '#0074B3',
        [Color.a3_10]: '#003E60',
        [Color.a3_5]: '#002336',

        //  a4 (jade)
        [Color.a4_40]:  '#F2FFFE',
        [Color.a4_30]: '#90E1DA',
        [Color.a4_20]: '#40C4B8',
        [Color.a4]:    '#02A698',
        [Color.a4_15]: '#01877C',
        [Color.a4_10]: '#004044',
        [Color.a4_5]: '#002B28',

        // a5 (turquoise)
        [Color.a5_40]:  '#F2FDFF',
        [Color.a5_30]: '#96E1ED',
        [Color.a5_20]: '#45C6DA',
        [Color.a5]:    '#00ACC8',
        [Color.a5_15]: '#008CA3',
        [Color.a5_10]: '#004C58',
        [Color.a5_5]: '#002B32',

        // a6 (lime)
        [Color.a6_40]:  '#F2FFF2',
        [Color.a6_30]: '#AEE3AE',
        [Color.a6_20]: '#74C674',
        [Color.a6]:    '#44AA44',
        [Color.a6_15]: '#2C8B2C',
        [Color.a6_10]: '#0C4C0C',
        [Color.a6_5]: '#042C04',

        // a7 (peach)
        [Color.a7_40]:  '#FFF3F2',
        [Color.a7_30]: '#FCBFBD',
        [Color.a7_20]: '#F88C89',
        [Color.a7]:    '#F55B56',
        [Color.a7_15]: '#C73C38',
        [Color.a7_10]: '#6A120F',
        [Color.a7_5]: '#3B0604',

        // a8 (lemon)
        [Color.a8_5]:  '#FFFEF2',
        [Color.a8_10]: '#FFF4A2',
        [Color.a8_15]: '#FFEA51',
        [Color.a8]:    '#FFE000',
        [Color.a8_20]: '#9E8B00',
        [Color.a8_30]: '#6E6100',
        [Color.a8_40]: '#3D3600',

        [Color.light]: '#ffffff',
        [Color.dark]: '#000000',
        [Color.brand]: '#d90201',

        [Color.shade]: '#ffffff'
    }
}
