// External
import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subject, Observable } from 'rxjs';

// Internal
import { IToast } from './iToast.interface';

@Injectable({
    providedIn: 'root'
})

export class ToastService {

    private keepAfterRouteChange = false;
    private subject = new Subject<IToast>();

    constructor(private router: Router) {
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    this.keepAfterRouteChange = false;
                } else {
                    this.clear();
                }
            }
        });
    }

    getToast(): Observable<any> {
        return this.subject.asObservable();
    }

    success(message, duration = 3000, keepAfterRouteChange = true): void {
        this.toast(message, keepAfterRouteChange, 'success', duration);
    }

    error(message, duration = -1, keepAfterRouteChange = true): void {
        this.toast(message, keepAfterRouteChange, 'error', duration);
    }

    info(message: string, duration = 3000, keepAfterRouteChange = true): void {
        this.toast(message, keepAfterRouteChange, 'info', duration);
    }

    warn(message: string, duration = 3000, keepAfterRouteChange = true): void {
        this.toast(message, keepAfterRouteChange, 'warning', duration);
    }

    toast(message: string, keepAfterRouteChange = true, state = 'default', duration = 3000, dismissable = true): void {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next(<IToast>{ message: message, state: state, dismissable: dismissable, duration: duration });
    }

    clear() {
        this.subject.next(undefined);
    }
}
