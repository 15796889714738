// External
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from 'rxjs';
import { tv4 } from "tv4";
import { map, catchError } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";

// Internal
import { RequestsService } from "../../global/request-service/requests.service";
import { schemas } from "src/app/common/models/schemas";
import { ValuesService } from '../../../../common/values/values.service';
import { UtilsCommonService } from "src/app/common/utils/utils-common.service";
import { ConfigService } from "src/app/common/config/config.service";

@Injectable({
    providedIn: "root"
})
export class ParentalAppsMgmtService {
    constructor(
        private requestsService: RequestsService,
        private utilsCommonService: UtilsCommonService,
        private valuesService: ValuesService,
        private configService: ConfigService,
        private cookieService: CookieService,
        private router: Router
    ) { }
    
    /**
     * This method is used to get the apps on the devices belonging to a profile
     * @param {object} Contains mandatory `profile_id`, optional `fields`
     * @returns Response from server
     */
    getApps(info): Observable<any> {
        if (this.utilsCommonService.isEmptyObject(info) ||
            !tv4.validate(info.profile_id, schemas.schemaUuid)) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "get",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: this.valuesService.parentalControl.device_id,
                    app_id: this.valuesService.parentalControl.app_id
                },
                profile_id: info.profile_id
            }
        };

        if (info.fields && this.utilsCommonService.checkArray(info.fields)) {
            json.params["fields"] = info.fields;
        }

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalControlAppsService,
            json,
            "POST"
        ).pipe(
            map((resp) => {
                if (resp?.result) {
                    return resp.result;
                }
                throw resp;
            }),
            catchError((error) => {
                throw error;
            })
        );
    }


    /**
     * This method is used to get the blocked apps on the devices belonging to a profile
     * @param {object} Contains mandatory `profile_id`, `device_id`, `days_span`
     * @returns Response from server
     */
    getAppsUsage(info): Observable<any> {
        if (this.utilsCommonService.isEmptyObject(info) ||
            !tv4.validate(info.profile_id, schemas.schemaUuid) ||
            !info.days_span) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "getAppsUsage",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: this.valuesService.parentalControl.device_id,
                    app_id: this.valuesService.parentalControl.app_id
                },
                profile_id: info.profile_id,
                day: Math.floor(Date.now() / 1000),
                days_span: info.days_span
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalControlAppsService,
            json,
            "POST"
        ).pipe(
            map((resp) => {
                if (resp?.result) {
                    return resp.result;
                }
                throw resp;
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

     /**
     * This method is used to set apps monitor setting on a profile
     * @param {object} Contains mandatory `profile_id` and `state_feature`
     * @returns Response from server
     */
    setAppsMonitorSetting(info): Observable<any> {
        if (this.utilsCommonService.isEmptyObject(info) ||
                !tv4.validate(info.profile_id, schemas.schemaUuid || 
                !info.state_feature)) {
                console.error("Invalid params", tv4.error);
                return of("Invalid params");
        }
    
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "edit",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: this.valuesService.parentalControl.device_id,
                    app_id: this.valuesService.parentalControl.app_id
                },
                profile_id: info.profile_id,
                state_feature: info.state_feature
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalControlAppsService,
            json,
            "POST"
        ).pipe(
            map((resp) => {
                if (resp?.result) {
                    return resp.result;
                }
                throw resp;
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    /**
     * This method is used to edit(block, unblock) the apps on the devices belonging to a profile
     * @param {object} Contains mandatory `profile_id`, `restrictions`, `device_id`
     * @returns Response from server
     */
    editBlockedApps(info): Observable<any> {
        if (this.utilsCommonService.isEmptyObject(info) ||
                !tv4.validate(info.profile_id, schemas.schemaUuid || 
                this.utilsCommonService.isEmptyArray(info.restrictions) ||
                !info.device_id)) {
                console.error("Invalid params", tv4.error);
                return of("Invalid params");
        }
    
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "edit",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: info.device_id,
                    app_id: this.valuesService.parentalControl.app_id
                },
                profile_id: info.profile_id,
                restrictions: info.restrictions
            }
        };

        if (info.hasOwnProperty("state_applied")) {
            json.params["state_applied"] = info["state_applied"];
        }
        if (info.hasOwnProperty("state_feature")) {
            json.params["state_feature"] = info["state_feature"];
        }
        
        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.parentalControlAppsService,
            json,
            "POST"
        ).pipe(
            map((resp) => {
                if (resp?.result) {
                    return resp.result;
                }
                throw resp;
            }),
            catchError((error) => {
                throw error;
            })
        );
    }
}