// External
import { Injectable } from '@angular/core';
import { Observable, of, map, catchError, throwError } from 'rxjs';

// Internal
import { POST, Errors, RequestsService } from '../../global/request-service/requests.service';
import { UtilsCommonService } from '../../../utils/utils-common.service';
import { ValuesService } from '../../../values/values.service';
import { ConfigService } from '../../../../common/config/config.service';

@Injectable({
    providedIn: 'root'
})

export class CommercialIdsMgmtService {

    constructor(
        private readonly requestService: RequestsService,
        private readonly utilsCommonService: UtilsCommonService,
        private readonly valuesService: ValuesService,
        private readonly configService: ConfigService
    ) { }

    /**
     * Makes call to retreive commercialIds info
     * @public
     * @memberof CommercialIdsMgmtService
     * @param {Array} commercial An array of commercialIds
     * @returns {Observable} Response from server(a.k.a. response.result). If param is an array,
     * the response is an array of info containing the '.result' for every commercialId in the param array.
     * If commercialId does not have '.result', an empty Object will be added to the returned array.
     */
    public commercialDetails(commercial): Observable<any> {
        let json: any;

        if (!this.utilsCommonService.checkArray(commercial)) {
            if (!this.utilsCommonService.checkString(commercial) || !commercial) {
                return of(Errors.INVALID_PARAMS);
            }

            json = {
                id: parseInt((Math.random() * 100).toString(), 10),
                jsonrpc: this.valuesService.jsonrpc,
                method: 'get_commercial_id_details',
                params: {
                    commercial_details_id: commercial
                }
            };

            return this.requestService.make(
                this.configService.config.nimbusServer,
                this.valuesService.commercial_ids, json,
                POST
            ).pipe(
                map(resp => resp.result),
                catchError(error => {
                    throw error;
                })
            );
        } else {
            json = [];

            for (const i in commercial) {
                if (commercial.hasOwnProperty(i) && commercial[i] !== '') {
                    const info = {
                        id: i,
                        jsonrpc: this.valuesService.jsonrpc,
                        method: 'get_commercial_id_details',
                        params: {
                            commercial_details_id: commercial[i]
                        }
                    };
                    json.push(info);
                }
            }

            return this.requestService.make(
                this.configService.config.nimbusServer,
                this.valuesService.commercial_ids,
                json,
                POST
            ).pipe(
                map(resp => {
                    const results = [];
                    for (const element of resp) {
                        if (element.result) {
                            results.push(element.result);
                        } else {
                            results.push({});
                        }
                    }
                    return results;
                }),
                catchError(error => {
                    throw error;
                })
            );
        }
    }

    /**
     * Gets the conversion info for given bundle
     * @public
     * @memberof CommercialIdsMgmtService
     * @param {string} partnerId The partner id of the bundle
     * @param {string} commercialId The commercial id of the bundle
     * @returns {Observable} Response from server
     */
    public getConversionStatus(partnerId: string, commercialId: string): Observable<any> {
        if (!commercialId || !partnerId) {
            return throwError(() => new Error(Errors.INVALID_PARAMS));
        }

        const json: any = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'get_conversion',
            params: {
                partner_id: partnerId,
                commercial_id: commercialId
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.commercial_ids,
            json,
            POST
        ).pipe(
            map(resp => {
                if (resp?.result) {
                    return resp.result;
                }
                throw resp;
            }),
            catchError(error => {
                throw error;
            })
        );
    }

}
