//External
import { Injectable } from '@angular/core';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';

//Internal
import { ConnectTechassistService } from '../../requests/connect-techassist/connect-techassist.service';
import { ValuesService } from '../../../values/values.service';

@Injectable({
  providedIn: 'root'
})
export class TechassistService {

  _techassist: any = {};

  readonly onlistSchedules$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
  markToUpdate = true;

  constructor(
    readonly connectTechassistService: ConnectTechassistService,
    readonly valuesService: ValuesService,
  ) { }

  listSchedules(): Observable<any> {
        if(!this.markToUpdate) {
            return of(this._techassist);
        }

        if (this.onlistSchedules$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistSchedules$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        }
        else {
            this.onlistSchedules$.next(this.valuesService.processServiceState.INPROGRESS);

            return this.connectTechassistService.getSchedules()
            .pipe(
                map(
                    resp => {
                        if (resp) {
                            this._techassist.schedules = resp.result;
                            this.markToUpdate = false;
                            this.onlistSchedules$.next(this.valuesService.processServiceState.DONE);
                            return;
                        }

                        this.markToUpdate = true;
                        this.onlistSchedules$.next(this.valuesService.processServiceState.DONE);
                        this._techassist.schedules = [];
                        return of('malformed_request');
                    }),
                    catchError((err)=> {
                        this.markToUpdate = true;
                        this.onlistSchedules$.next(this.valuesService.processServiceState.DONE);
                        this._techassist.schedules = [];
                        return of(err);
                    })
            )
        }
    }
    
    cancelSchedule(scheduleId): Observable<any> {
        return this.connectTechassistService.cancelSchedule(scheduleId)
        .pipe(
            map(
                resp => {
                    if (resp) {
                        this.updateSchedules();
                        return;
                    }
                    
                    return of('malformed_request');
                }),
                catchError((err)=> {
                    throw err;
                })
        )
    }

    updateSchedules() {
        if (this.onlistSchedules$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate = true;
        }
    }

    get() {
        return this._techassist;
    }
}
