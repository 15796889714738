// Internal
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

// External
import { DecodeBitdefendercomHtmlPipe,
        ValueArrayPipe,
        SafePipe,
        FirstKeyPipe,
        TruncateText,
        OrderValuesByNamePipe,
        GroupByType,
        SortPipe,
        SortAlphabeticallyPipe,
        CallbackCountPipe,
        FilterByPropertyPipe,
        CountPipe,
        TimeFromMilisecondsPipe,
        TimeFromSecondsPipe,
        ParentalMorePipe,
        OrderByPropertyPipe
} from '../../common/pipes/pipes';
import { PipesModule } from '../pipes/pipes.module';
import { UiPlaceholderModule } from './ui/ui-placeholder/ui-placeholder.module';
import { CallbackItemPipe } from '../pipes/pipes';
import { WrapperDeviceDetector } from '../services/wrappers/wrapper-device-detector';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        PipesModule,
        UiPlaceholderModule
    ],
    declarations: [
        DecodeBitdefendercomHtmlPipe,
        ValueArrayPipe,
        CallbackCountPipe
    ],
    providers: [
        DecodeBitdefendercomHtmlPipe,
        ValueArrayPipe,
        TranslatePipe,
        SafePipe,
        {
            provide: DeviceDetectorService,
            useClass: WrapperDeviceDetector
        }
    ],
    exports: [
        TranslateModule,
        CommonModule,
        DecodeBitdefendercomHtmlPipe,
        HttpClientModule,
        ValueArrayPipe,
        FirstKeyPipe,
        TruncateText,
        OrderValuesByNamePipe,
        GroupByType,
        CallbackItemPipe,
        SortPipe,
        SortAlphabeticallyPipe,
        CallbackCountPipe,
        FilterByPropertyPipe,
        OrderByPropertyPipe,
        CountPipe,
        TimeFromMilisecondsPipe,
        TimeFromSecondsPipe,
        ParentalMorePipe
    ]
})

export class SharedModule { }
