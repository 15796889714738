import { Injectable } from "@angular/core";

export const modalTiming = 600;
export const modalAnimation = {
    // IN animations
    fromRight: {
        animation: [
            { transform: 'translateX(100%)', opacity: 0},
            { opacity: 0.8 },
            { transform: 'translateX(0)'}
        ],
        timing: {
            duration: modalTiming,
            easing: 'ease-in',
            // delay: 30
        }
    },
    fromLeft: {
        animation: [
            { transform: 'translateX(-100%)', opacity: 0},
            { opacity: 0.8 },
            { transform: 'translateX(0)'}
        ],
        timing: {
            duration: modalTiming,
            easing: 'ease-in',
            // delay: 30
        }
    },
    fromBottom: {
        animation: [
            { transform: 'translateY(100%)'},
            { transform: 'translateY(0)'}
        ],
        timing: {
            duration: modalTiming * 0.5,
            easing: 'ease-in'
        }
    },
    // OUT animations
    toRight: {
        animation: [
            { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' },
            { top: '50%', left: '50%', transform: 'translate(100%, -50%)', opacity: 0 }
        ],
        timing: {
            duration: modalTiming * 0.8,
            easing: 'ease-out'
        }
    },
    toLeft: {
        animation: [
            { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' },
            { top: '50%', left: '50%', transform: 'translate(-100%, -50%)', opacity: 0 }
        ],
        timing: {
            duration: modalTiming * 0.8,
            easing: 'ease-out'
        }
    },
    toBottom: {
        animation: [
            { transform: 'translateY(100%)'}
        ],
        timing: {
            duration: modalTiming * 0.5,
            easing: 'ease-out'
        }
    }
};

@Injectable({
    providedIn: 'root'
})

export class ModalValues {

    constructor() {}

}