export enum AccountSetting {
    showTwoFaAlert = 'showTwoFaAlert',
    showMasterPasswordAlert = 'showMasterPasswordAlert',
    boarded = 'boarded',
    onbordingComplete = 'onbordingComplete',
    snapPhoto = 'snapPhoto',
    device_list_banner_appearance = 'device_list_banner_appearance',
    showSharedSubscriptionAlert = 'showSharedSubscriptionAlert',
    securityWasAccessed = 'securityWasAccessed',
    showParentalUpgradeAlert = 'showParentalUpgradeAlert'
}

export interface AccountSettings {
    [AccountSetting.showTwoFaAlert]?: boolean;
    [AccountSetting.showMasterPasswordAlert]?: boolean;
    [AccountSetting.boarded]?: boolean;
    [AccountSetting.onbordingComplete]?: boolean;
    [AccountSetting.snapPhoto]?: boolean;
    [AccountSetting.device_list_banner_appearance]?: string;
    [AccountSetting.showSharedSubscriptionAlert]?: boolean;
    [AccountSetting.securityWasAccessed]?: boolean;
    [AccountSetting.showParentalUpgradeAlert]?: boolean;
}

export enum DeviceSetting {
    antitheftPin = 'antitheft.android.apppin',
    network = 'network',
    automaticUpgrade = 'automatic_upgrade'
}

export interface DeviceSettings {
    [DeviceSetting.antitheftPin]?: string;
    [DeviceSetting.network]?: number;
    [DeviceSetting.automaticUpgrade]?: boolean;
}

export enum NccSetting {
    ncc_pin = 'ncc_pin',
    nccOnboardingComplete = 'nccOnboardingComplete',
    showUpdateDevicesAlert = 'showUpdateDevicesAlert'
}

export interface NccSettings {
    [NccSetting.ncc_pin]?: number;
    [NccSetting.nccOnboardingComplete]?: boolean;
    [NccSetting.showUpdateDevicesAlert]?: boolean;
}
