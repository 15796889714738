import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessageService {
    private subject = new Subject<any>();
    private behaviorSubject = new BehaviorSubject<any>(false);
    private dynamicSubjectList = {}

    sendMessage(message: string, options: any) {
        this.subject.next({ text: message, options: options });
    }

    clearMessages() {
        this.subject.next(undefined);
    }

    sendBehaviorMessage(message: string, options: any) {
        this.behaviorSubject.next({ text: message, options: options });
    }

    clearBehaviorMessages() {
        this.behaviorSubject.next(false);
    }

    getBehaviorMessage(): Observable<any> {
        return this.behaviorSubject.asObservable();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    /**
     * Select subject from dynamically maintained list based on key (instantiate one if it does not exist).
     *
     * @param key string
     * @returns Observable
     */
    getDynamicSubject(key) {
        if (!this.dynamicSubjectList[key]) {
            this.dynamicSubjectList[key] = new BehaviorSubject<any>(false);
        }

        return this.dynamicSubjectList[key].asObservable();
    }

    /**
     * Select subject from dynamically maintained list based on key (instantiate one if it does not exist).
     * Send message to the selected subject.
     *
     * Used by data resolver and app organiser components to notify and store latest sent value. Also, because
     * there are multiple events, we need a specific subject for each individual event.
     *
     * @param key string
     * @param options any
     */
    sendDynamicSubjectMessage(key, options?) {
        if (!this.dynamicSubjectList[key]) {
            this.dynamicSubjectList[key] = new BehaviorSubject<any>(false);
        }

        this.dynamicSubjectList[key].next(options);
    }
}