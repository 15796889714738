import { Injectable, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subject, takeUntil } from 'rxjs';
import { ServiceWorkerMessageEvent } from '../../../../common/models/Pwa.model';
import { getPwaActive } from '../../../../../app/app.module';
import { ServiceWorkerHash } from '../../../../common/values/values.service';

@Injectable({providedIn: 'root'})

export class LogUpdateService implements OnDestroy {
    private readonly onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private readonly swUpdate: SwUpdate,
    ) {
        if (getPwaActive()) {
            this.swUpdate.versionUpdates
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(event => {
                switch (event.type) {
                    case ServiceWorkerMessageEvent.NO_NEW_VERSION_DETECTED:
                        console.log('No new app version detected.');
                        this.deleteOldCaches(event.version.hash);
                        break;
                    case ServiceWorkerMessageEvent.VERSION_DETECTED:
                        console.log(`Downloading new app version: ${event.version.hash}`);
                        break;
                    case ServiceWorkerMessageEvent.VERSION_READY:
                        console.log(`Current app version: ${event.currentVersion.hash}`);
                        console.log(`New app version ready for use: ${event.latestVersion.hash}`);
                        break;
                    case ServiceWorkerMessageEvent.VERSION_INSTALLATION_FAILED:
                        console.log(`Failed to install app version '${event.version.hash}': ${event.error}`);
                        break;
                }
            });
        }
    }

    /**
     * Method used to delete old caches.
     * @param {string} currentServiceWorkerHash The hash of the current service worker.
     */
    private deleteOldCaches(currentServiceWorkerHash: string): void {
        const storedServiceWorkerHash = localStorage.getItem(ServiceWorkerHash);
        if (currentServiceWorkerHash && storedServiceWorkerHash !== currentServiceWorkerHash) {
            caches.keys()
            .then(cacheNames => {
                return Promise.all(
                    cacheNames.map(cacheName => {
                        if (cacheName.indexOf(currentServiceWorkerHash) === -1
                            && cacheName !== 'ngsw:/:db:control'
                            && cacheName.indexOf('ngsw:/:1:') === -1
                            && cacheName.indexOf('ngsw:/:db:1:') === -1) {
                            return caches.delete(cacheName);
                        }
                    })
                );
            })
            .finally(() => {
                localStorage.setItem(ServiceWorkerHash, currentServiceWorkerHash);
            })
            .catch(error => {
                console.error('Failed to delete old caches: ', error);
            });
        }
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

}