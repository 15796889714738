// External
import { NgModule } from '@angular/core';
import { UiAlertComponent } from './ui-alert.component';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../../directives.module';


@NgModule({
    declarations: [
        UiAlertComponent,
    ],
    imports: [
        CommonModule,
        DirectivesModule
    ],
    
    exports: [ UiAlertComponent ]
    
})

export class UiAlertModule {}
