// External
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiButtonComponent } from './ui-button.component';
import { UiIconModule } from '../ui-icon/ui-icon.module';


@NgModule({
    declarations: [
        UiButtonComponent
    ],
    imports: [
        CommonModule,
        UiIconModule
    ],

    exports: [ UiButtonComponent ]

})

export class UiButtonModule {}
