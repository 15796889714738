import { Injectable } from '@angular/core';
import { Observable, catchError, from, map, of } from 'rxjs';
import { Browsers, DeviceArchitecture, DeviceArchitectureBits, DeviceArchitectureTypes, HighEntropyValues } from '../../../values/values.service';
import { DeviceDetectorService } from 'ngx-device-detector';

interface DeviceInfo {
    architecture: DeviceArchitecture;
    platform: string;
    platformVersion: string;
    mobile: string;
    currentBrowser: Browsers;
}

@Injectable({
    providedIn: 'root'
})

export class DeviceInfoService {
    public deviceInfo: DeviceInfo;

    constructor(
        private readonly deviceDetectorService: DeviceDetectorService
    ) {}

    /**
     * Makes a request to get device information (architecture, browser, platform) using user-agent client hints, and stores it in a variable.
     * @returns {Observable<any>} device info
     */
    public list(): Observable<any> {
        if (this.deviceInfo) {
            return of(this.deviceInfo);
        }

        const highEntropyValues = [HighEntropyValues.ARCHITECTURE, HighEntropyValues.BITNESS, HighEntropyValues.PLATFORM_VERSION];
        const promise = (<any>navigator).userAgentData?.getHighEntropyValues(highEntropyValues) ?? Promise.resolve();

        return from(promise)
        .pipe(
            map((res: any) => {
                let deviceArch;
                let browser;
                if (res?.architecture === DeviceArchitectureTypes.ARM) {
                    if (res?.bitness === DeviceArchitectureBits.BIT64) {
                        deviceArch = DeviceArchitecture.ARM64;
                    }
                    else if (res?.bitness === DeviceArchitectureBits.BIT32) {
                        deviceArch = DeviceArchitecture.ARM32;
                    }
                } else {
                    deviceArch = res?.architecture;
                }

                const brands = res?.brands ?? [];
                for (const brandVersion of brands) {
                    const brand = brandVersion.brand.toLowerCase();
                    if (brand === Browsers.GOOGLE_CHROME) {
                        browser = Browsers.CHROME;
                    } else if (brand === Browsers.MICROSOFT_EDGE) {
                        browser = Browsers.MS_EDGE_CHROMIUM;
                    }
                }

                this.deviceInfo = {
                    architecture: deviceArch,
                    platform: res?.platform,
                    platformVersion: res?.platformVersion,
                    mobile: res?.mobile,
                    currentBrowser: browser
                };
            }),
            catchError(() => {
                return of(true);
            })
        );
    }

    /**
     * Gets the device architecture information the app user is currently on
     * @returns {DeviceArchitecture} device architecture
     */
    public getDeviceArchitecture(): DeviceArchitecture {
        return this.deviceInfo?.architecture;
    }

    /**
     * Gets the device current browser
     * @returns {string} browser
     */
    public getDeviceBrowser(): string {
        return this.deviceInfo?.currentBrowser ?? this.deviceDetectorService.browser.toLowerCase();
    }
}
