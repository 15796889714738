import { UiButton } from '../../components/ui/ui-button/ui-button.models';
import { DataBrokerAction } from './DataBrokers.model';
import { PrivacyFootprintActionType } from './PrivacyFootprint.model';

export class RemediationServiceDetails {
    name: string;
    primaryMethod: DataBrokerAction;
    thumbnail: {
        katastif_id?: string,
        url: string
    };
    methodText: string;
    title: string;
    descriptionShown: string;
    disclaimer: string;
    alert?: any;
    primaryMethodData: RemediationMethodDetails;
}

export class RemediationMethodDetails {
    type: RemediationPrimaryMethod | DataBrokerAction;
    primaryMethodData?: RemediationPrimaryMethod;
    primary_action: {
        type: RemediationPrimaryMethod | DataBrokerAction;
        url: string;
        email_address?: string;
        value?: string;
    };
    method_text?: string;
    title?: string;
    description_shown?: string;
    service_domain?: string;
    actions?: RemediationActionDetails[];
}

export class RemediationActionDetails {
    type: RemediationActionType;
    text: string;
    url: string;
    text_shown?: string;
}

export enum RemediationPrimaryMethod {
    privacySettings = 'privacy_settings',
    personalInformation = 'personal_information',
    email = 'email',
    link = 'link',
    url = 'url',
    request = 'request',
    none = 'none'
}

export enum RemediationActionType {
    REMOVE_PERSONAL_INFO = 'remove_personal_info',
    MANAGE_MENTIONS = 'manage_mentions',
    MANAGE_SEARCH = 'manage_search',
    MANAGE_PERSONALISATION = 'manage_personalisation',
    MANAGE_COOKIES = 'manage_cookies',
    DATA_DOWNLOAD_EOS = 'data_download_eos',
    MANAGE_SERVICE_INFO = 'manage_service_info',
    MANAGE_SETTINGS_PRIVACY = 'manage_settings_privacy',
    MANAGE_SETTINGS_ADS = 'manage_settings_ads',
    DATA_USAGE = 'data_usage',
    DATA_THIRD_PARTY = 'data_third_party',
    PREFERENCES_ADS = 'preferences_ads',
    MANAGE_SERVICES = 'manage_services',
    DATA_COLLECTED = 'data_collected',
    PREFERENCES_JOB_SEEKING = 'preferences_job_seeking',
    DATA_DOWNLOAD = 'data_download',
    DO_NOT_SELL = 'do_not_sell',
    MANAGE_BROWSING_HISTORY = 'manage_browsing_history',
    MANAGE_ADS_PREFERENCES = 'manage_ads_preferences',
    DATA_REQUEST = 'data_request',
    DATA_DOWNLOAD_ARCHIVE = 'data_download_archive',
    OTHER_DISCOVERABILITY_AND_CONTACTS = 'other_discoverability_and_contacts',
    MANAGE_ADD_PREFERENCES = 'manage_add_preferences',
    OTHER_DISABLE_ACTIVITY_TRACKING = 'other_disable_activity_tracking',
    DATA_DO_NOT_SHARE = 'data_do_not_share',
    MANAGE_LOCATION_INFO = 'manage_location_info',
    FILTER_PUBLIC_POSTS = 'filter_public_posts',
    CHECK_YOUR_SECURITY_SETTINGS = 'check_your_security_settings',
    DELETE_ACCOUNT = 'delete_account',
    ACTIVATE_2FA = 'activate_2fa',
    CHANGE_PASSWORD = 'change_password'
}
export interface IUserRemediationsTypes {
    [key: string]: IUserRemediationTypesData;
}

export interface IUserRemediationTypesData {
    reviewed_at: Date;
    status?: PrivacyFootprintActionType;
    new?: boolean;
    yearsPassed?: number;
    daysPassed?: number;
    show?: boolean;
}

export type IRemediationAction = {
    [key in PrivacyFootprintActionType]: IRemediationActionObject;
};

export interface IRemediationActionObject {
    status: RemediationActionStatus;
    type?: RemediationPrimaryMethod;
    value?: string;
    timestamp?: Date;
}

export enum RemediationActionStatus {
    NEW = 'new',
    REQUEST_SENT = "request_sent",
    DONE = 'done',
    UNDO = 'undo'
}

export enum ChoosedActionType {
    sendMail = 'send_email',
    fillForm = 'fill_form'
}

export interface IRemediationInfo {
    status: RemediationActionStatus;
    template_id: string;
    timestamp: Date;
    type: RemediationPrimaryMethod;
    value: string;
    choosedActionType: ChoosedActionType;
}

export interface IRemediationActionInterface {
    isVisible?: boolean;
    icon: {
        type: string;
        name: string;
        color?: string;
    };
    secondaryIcon?:{
        type: string;
        name: string;
    };
    title: string;
    titleNoMetadata?: string;
    description: string;
    hasNoMainAction?: boolean,
    actionText: string;
    actionMethod: () => void;
    actionType: PrivacyFootprintActionType;
    secondaryActionText?: string;
    secondaryActionMethod?: () => void;
    mainBtnType?: UiButton.Model;
    infoText?: IInfoText;
    daysAgo?: number;
    hasFeedback: boolean,
    feedbackSent: boolean;
    feedbackHasError: boolean;
    actionError?: boolean,
    actionInProgress?: boolean
}

export interface IInfoText {
    multiple: string;
    single: string;
    none: string;
}

export type IRemediationActionData = {
    [key in RemediationActionStatus]?: IRemediationActionInterface;
}

export interface IRemediationActionDataExtended extends IRemediationActionData {
    info: IRemediationInfo;
}

export type IRemediationActionItem = {
    [key1 in RemediationPrimaryMethod]?: {
        [key2 in PrivacyFootprintActionType]?: IRemediationActionDataExtended;
    }
}

export enum ConnectedStateFrom {
    DEFAULT = 'default',
    FROM_REMEDIATIONS = 'fromRemediations'
}

export enum EmailConnectErrorType {
    GENERAL = 'general_error',
    MISSING_PERMISSION = 'missing_permissions',
    ACCOUNT_LINKED = 'account_already_linked',
    EMAIL_APP_NOT_SUPPORTED = 'email_app_not_supported'
}

export type EmailConnectedInterfaceType = {
    [key in EmailConnectErrorType]?: EmailConnectedInterfaceFrom
}

export type EmailConnectedInterfaceFrom = {
    [key in ConnectedStateFrom]?: EmailConnectedInterface
}

export interface EmailConnectedInterface {
    img: string;
    title: string;
    desc: string;
    action?: () => void;
    actionTitle?: string;
    actionLoading?: boolean;
    actionError?: boolean;
    hasCloseBtn?: boolean;
}

export enum RemediationsFlow {
    SimpleRemediation = 'remediationFlow',
    EmailConnected = 'isEmailConnectedFlow',
    NoRemediation = 'isNoRemediationFlow',
    EmailOrLink = 'isEmailOrLinkFlow'
}

export enum RemediationsFlowsSteps {
    Request = 'requestStep',
    RequestSummary = 'requestSummaryStep',
    ConnectGmail = 'connectGmailStep',
    Choose = 'chooseStep'
}

export enum RemediationType {
    DeleteData = 'deleteData',
    KnowData = 'knowData'
}

export enum ContentTypeEnum {
    PLAIN = 'plain',
    HTML = 'html'
}

export interface AuthorizedRequestInterface {
    metadata_id: string;
    service_provider_email: string;
    service: string;
    email: {
        subject: string;
        body: string;
        content_type: ContentTypeEnum;
    }
}

/**
 * Objects used in manage data modal component
 */
export interface NoticePeriodInterface {
    title: string;
    desc: string;
    icon: {
        type: string;
        name: string;
        color?: string;
    };
    articleLink?: string;
}

export interface RequestSentInterface {
    dateValue: Date;
    date: string;
    title: string;
    desc: string;
    icon: {
        type: string;
        name: string;
        color?: string;
    };
    articleLink?: string;
    undoBtnText: string;
    okBtnText: string;
    actionError: boolean;
    actionInProgress: boolean;
}

export interface ConnectionDetail {
    icon: {
        type: string;
        name: string;
    };
    description: string;
}

export interface SendEmailInterface {
    img?: {
        link: string;
        alt: string;
    };
    howTo?: HowToInterface;
    title?: string;
    desc?: string;
    descFinal?: string;
    noticePeriod?: NoticePeriodInterface;
    requestSent?: RequestSentInterface;
    step1?: string;
    step2?: string;
    step3?: string;
    step4?: string;
    recipientsTitle?: string;
    recipientsCopyBtnText?: string;
    subjectTitle?: string;
    subjectCopyBtnText?: string;
    subjectDefaultText?: string;
    messageTitle?: string;
    messageCopyBtnText?: string;
    messageDefaultText?: string;
    messageDefaultTextNoEmail?: string;
    btnText?: string;
    mainBtnText?: string;
    secondaryBtnText?: string;
    tip?: string;
    emailHref?: string;
    serviceLink?: string;
    actionError?: boolean;
    actionInProgress?: boolean;
    articleLink?: string;
}

export interface InfoTextInterface {
    multiple: string;
    single: string;
    none: string;
}
export interface ManageInterface {
    title: string;
    desc: string;
    undoBtnText: string;
    okBtnText: string;
    daysAgo: number;
    infoText: InfoTextInterface;
    actionError: boolean;
    actionInProgress: boolean;
}

export interface FillFormInterface {
    img?: {
        link: string;
        alt: string;
    };
    title?: string;
    desc: string;
    btnText?: string;
    manage?: ManageInterface;
    tip?: string;
    actionError?: boolean;
    actionInProgress?: boolean;
    articleLink?: string;
}

export interface RequestSummaryInterface {
    send_email?: SendEmailInterface;
    fill_form?: FillFormInterface;
}

export interface HowToInterface {
    icon: {
        type: string;
        name: string;
        color?: string;
    };
    title: string;
    desc: string;
}

export interface RequestInterface {
    title: string;
    desc: string;
    goBackBtnText: string;
    send_email?: SendEmailInterface;
    fill_form?: FillFormInterface;
}

export interface ChooseInterface {
    title: string;
    desc: string;
    send_email: SendEmailInterface;
    fill_form: FillFormInterface;
    articleLink?: string;
}

export interface RemediationMethodInterface {
    choose?: ChooseInterface;
    title: string;
    desc?: string;
    desc2?: string;
    request: RequestInterface | SendEmailInterface;
    requestSummary?: RequestSummaryInterface;
    emailConnected?: EmailConnectedInRemediationMethodInterface;
    articleLink?: string;
}

export interface ReasonInterface {
    desc: string;
    icon: {
        type: string;
        name: string;
        color?: string;
    };
}

export interface EmailConnectedInRemediationMethodInterface {
    title: string;
    desc: string;
    subtitle: string;
    subdesc: string;
    why: string;
    reason1: ReasonInterface;
    reason2: ReasonInterface;
    sendBtnTitle: string;
    connectGmailBtnTitle?: string;
    connectOutlookBtnTitle?: string;
    alertDesc: string;
    alertDescActionTitle: string;
    actionError: boolean;
    actionInProgress: boolean;
}

export type FootprintAction2RemediationMethodInterface = {
    [key in RemediationPrimaryMethod]?: RemediationMethodInterface;
}

export type FootprintActionInterface = {
    [key in PrivacyFootprintActionType]?: RemediationMethodInterface | FootprintAction2RemediationMethodInterface;
}