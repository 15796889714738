// External
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, catchError, map, skipWhile } from 'rxjs';

// Internal
import { ValuesService } from '../../../values/values.service';
import { SubscriptionsService } from './subscriptions.service';
import { SubscriptionInviteService } from '../../requests/subscription-invite-service/subscription-invite.service';
import { MessageService } from '../../core/message.service';
import { GroupMgmtErrorCodes } from '../../../../common/models/subscriptions/Groups.model';
import { OnboardingErrorDetails } from '../../../../common/models/Onboarding.model';

@Injectable({
    providedIn: 'root'
})

export class InvitesService {

    private readonly onlistInvites$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private markToUpdateInvites = true;
    private invites = [];

    constructor(
        private readonly valuesService                  : ValuesService,
        private readonly subscriptionsService           : SubscriptionsService,
        private readonly subscriptionInviteService      : SubscriptionInviteService,
        private readonly messageService                 : MessageService
    ) {
        /* Used for updating the request in order to write them into th db by the service worker */
        this.messageService.getDynamicSubject(this.valuesService.events.resetRequestsForServiceWorker)
        .subscribe({
            next: () => {
                this.updateInvites();
            }
        });
    }

    /**
     * List all pending invites for shared subscription
     *
     * @public
     * @memberof InvitesService
     * @param {none}
     * @returns {Observable} server response
     */
    public list(): Observable<any> {
        if (!this.subscriptionsService.hasSharedSubscription()) {
            return of(this.invites);
        }

        if (!this.markToUpdateInvites) {
            return of(this.invites);
        }

        if (this.onlistInvites$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistInvites$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.onlistInvites$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.subscriptionInviteService.retrieveInvites()
            .pipe(
                map(resp => {
                    if (resp?.['sent_invites']) {
                        this.invites = resp['sent_invites'];
                    }

                    this.markToUpdateInvites = false;
                    this.onlistInvites$.next(this.valuesService.processServiceState.DONE);
                    return of(this.invites);
                }),
                catchError(err => {
                    this.markToUpdateInvites = true;
                    this.onlistInvites$.next(this.valuesService.processServiceState.DONE);
                    return of(err);
                })
            );
        }
    }

    /**
     * Marks list pending invites request to be updated
     *
     * @public
     * @memberof InvitesService
     * @param {none}
     * @returns {void}
     */
    public updateInvites(): void {
        if (this.onlistInvites$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdateInvites = true;
        }
    }

    /**
     * Returns all pending invites
     *
     * @public
     * @memberof InvitesService
     * @param {none}
     * @returns {Array}
     */
    public getInvites(): Array<any> {
        return this.invites;
    }

    /**
     * Returns all pending invites for given service id
     *
     * @public
     * @memberof InvitesService
     * @param {string} serviceId
     * @returns {Array}
     */
    public getInvitesByServiceId(serviceId: string): Array<any> {
        const invites = this.getInvites();
        return invites.filter(bundle => bundle.service_id === serviceId);
    }

    /**
     * Handles error response from 'invite_system' and returns custom error details or default one
     *
     * @public
     * @memberof InvitesService
     * @param {Object} errorObject representing error.data from error response
     * @returns {Object} representing the custom error object
     */
    public handleInviteServiceErrorResponse(errorObject): object {
        const errorCode = errorObject?.code;
        const errorMessage = errorObject?.data;

        if (this.valuesService.inviteSystemErrorMessages[errorCode] && this.valuesService.inviteSystemOnboardingErrors[errorMessage]) {
            return this.valuesService.inviteSystemOnboardingErrors[errorMessage];
        } else {
            return this.valuesService.inviteSystemOnboardingErrors.default;
        }
    }

    /**
     * Handles error message from connect/group_mgmt service and decides what error to be displayed
     * @public
     * @memberof UsefulService
     * @param {object} errorObject The error object
     * @returns {string} The error message to be displayed
     */
    public handleJoinGroupInviteErrorResponse(errorObject): OnboardingErrorDetails {
        let errorMessage = this.valuesService.joinGroupErrorDetails[GroupMgmtErrorCodes.DEFAULT];

        if (errorObject) {
            const internalCode =  errorObject.message?.internal_code ?? errorObject.internal_code;
            const dataCode = errorObject.message?.internal_data?.code ?? errorObject.code;
            const code = errorObject.message?.code;

            if (this.valuesService.joinGroupErrorDetails[internalCode]?.[dataCode]) {
                errorMessage = this.valuesService.joinGroupErrorDetails[internalCode][dataCode];
            } else if (this.valuesService.joinGroupErrorDetails[code]) {
                errorMessage = this.valuesService.joinGroupErrorDetails[code];
            }
        }
        return errorMessage as OnboardingErrorDetails;
    }
}
