export interface IPrivacyScore {
    status: number;
    empty_state: boolean;
    community_average: number;
    score: number;
    average: number;
    last_update: number;
    scoreHistory: string;
    risk_map: IPrivacyScoreRiskMap;
    checkedHistory: boolean;
    colors: Array<string>;
    yourScoreColor: string;
    score_changes: Array<IPrivacyScoreChanges>;
    last_issues: IPrivacyScoreLastIssues,
    computed_increaseScore: number;
    max_possible_score: number;
    all_breaches_score: number;
    all_services_score: number;
    quarters: any;

    value: number;
    risk: PrivacyScoreStatus;
    computedMaxValue: number;
    computedMaxValueRisk: PrivacyScoreStatus;
    computedBreachesScore: number;
    computedServicesScore: number;
    computedQuarter: ScoreQuarters;
}

export enum ScoreQuarters {
    Q1 = 'q1',
    Q2 = 'q2',
    Q3 = 'q3',
    Q4 = 'q4'
}

export interface IPrivacyScoreLastIssues {
    breach: number,
    impersonation: number,
    exposure: number
}

export interface IPrivacyScoreChanges {
    event_type: string,
    action_value: string,
    action_type: string,
    text: string,
    object_type: string,
    object_value: string,
    status: boolean
}

export interface IPrivacyScoreRiskMap {
    email: Array<number>,
    email_and_decoded_password: Array<number>,
    email_and_encoded_password: Array<number>,
    email_and_plaintext_password: Array<number>,
    email_name: Array<number>,
    ip_address: Array<number>,
    overall: Array<number>,
    phone_number: Array<number>,
    phone_number_and_name: Array<number>
}

export enum PrivacyScoreStatus {
    HIGH = 'high_risk',
    MEDIUM = 'medium_risk',
    LOW = 'low_risk',
    EMPTY = 'empty',
}

export enum PrivacyScoreThreats {
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
}

export enum PrivacyRiskMapType {
    OVERALL = 'overall',
    DASHBOARD = 'dashboard',
    OVERVIEW = 'overview',
    EXPERT = 'expert',
    YAXYS = 0,
    XAXYS = 1,
    AVERAGE = 3
}

export enum PrivacyRiskMapLikelihoodLevels {
    RARE = 'rare',
    COMMON = 'common',
    FREQUENT = 'frequent',
}

export enum PrivacyRiskMapImpactLevels {
    LOW = 'low',
    MODERATE = 'moderate',
    HIGH = 'high'
}

export enum BreakdownTooltipType {
    OTHERS = 'others',
    FOOTPRINT = 'footprint',
    BREACHES = 'breaches'
}

export interface TextInterface {
    text: string;
    min?: number;
    max?: number;
    value?: number;
}

export interface TooltipInterface {
    bgcolor: string,
    color?: string,
    title?: string | TextInterface,
    desc?: string | TextInterface,
    value?: number,
    style: {
        width: string,
        left: string
    },
    tooltipPossition: number
}

export interface StatusInterface {
    risk: string,
    riskDesc: string,
    lower: {
        color: string,
        title: string,
        desc: string
    },
    upper: {
        color: string,
        title: string,
        desc: string
    }
}

export type BreakdownTooltipsInterface = {
    [key in BreakdownTooltipType]?: TooltipInterface;
}

export type CommunityTooltipsInterface = {
    [key in ScoreQuarters]?: TooltipInterface;
}

export type ScoreStatusInterface = {
    [key in PrivacyScoreStatus]?: StatusInterface;
}

export type ScoreQuartersLimits = {
    [key in ScoreQuarters]: number[];
}

export interface IScoreInterface {
    colors: string[],
    yourScoreColor: string;
    risk: PrivacyScoreStatus
}