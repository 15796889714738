import { Steps } from '../../components/ui/ui-steps/ui-steps.component';
import { Security } from '../security/Security.model';
import { CustomValidatorErrorInterface } from '../../services/global/useful/useful.service';
import { MetadataType } from '../privacy/Identity.model';

// Threats
export enum ThreatTypes {
    FILES = 'files',
    WEBSITES = 'websites',
    EMAILS = 'emails'
}

export interface ThreatDetails {
    totalThreats: {
        total: number;
        websites: number;
        files: number;
        emails: number;
    };
    threatsPerDay: number[];
    days: ThreatsPerDay;
    labels: any[];
}

export type ThreatsCategories = {
    [Key in ThreatTypes]: {
        label: string;
        color: string;
    };
};

export type ThreatsInterface = {
    [Key in Security.Overview.Filter]: ThreatDetails;
};

export interface ThreatPerDay {
    threatsInfoPerDay: {
        websites: number;
        files: number;
        emails: number;
    };
    label: string;
    totalThreats: number;
}

export interface ThreatsPerDay {
    [key: string] : ThreatPerDay
}

export interface ThreatsChartTooltip {
    title: string;
    body: string[];
}

export interface ThreatsChartInfo {
    datasets: Array<Object|ThreatsChartTooltip>;
    labels: Array<number|ThreatsChartTooltip>;
    tooltips: Array<ThreatsChartTooltip>;
    max: number;
}

export type ThreatsChartInterface = {
    [Key in Security.Overview.Filter]: ThreatsChartInfo;
};

export class ThreatStatsChartInfo {
    data: Array<number>;
    backgroundColor: Array<string>;
}

export type ThreatsStatsChartInterface = {
    [Key in Security.Overview.Filter]: ThreatStatsChartInfo;
};

export interface ThreatCategoryInfo {
   type: string;
   label: string;
   value: number;
   color: string;
}

export type ThreatsCategoryInterface = {
    [Key in Security.Overview.Filter]: ThreatCategoryInfo[];
};

// Assets
export enum AssetTypes {
    EMAIL = 'email',
    CREDIT_CARD = 'credit_card',
    SOCIAL_ACCOUNTS = 'social_account'
}

export type AssetType = typeof AssetTypes[keyof typeof AssetTypes];

export enum AssetSubtypes {
    BUSINESS = 'business',
    YOUTUBE = 'youtube',
    FACEBOOK = 'facebook',
    INSTAGRAM = 'instagram',
    LINKEDIN = 'linkedin',
    TIKTOK = 'tiktok',
    WHATSAPP = 'whatsapp',
    PINTEREST = 'pinterest',
    SNAPCHAT = 'snapchat',
    REDDIT = 'reddit',
    X = 'x'
}

export enum AssetScenarios {
    EMAIL = 'email',
    CARD = 'card',
    SOCIAL = 'social',
    EDIT_SOCIAL = 'edit_social'
}

export type ValidationAbuseModel = {
    hasWaitingPeriod: string;
    withoutWaitingPeriod: string;
};

export type AddBusinessAssetsErrorCodeTexts = {
    [key in any]: string|ValidationAbuseModel;
};

export interface ActiveScenario {
    step: Steps,
    flow: Array<number>
}

export interface ModalFlags {
    loading: boolean,
    hasError: boolean,
    isMobile: boolean,
    hasNoSocialAccount: boolean,
    hasAllAccountsMonitored: boolean
}

export interface ErrorObj {
    text: string,
    placeholders?: object
}

export type BusinessAssetsFormHints = {
    [key in BusinessAssetsFormFields]: CustomValidatorErrorInterface;
};

export enum BusinessAssetsFormFields {
    EMAIL = 'email',
    EMAIL_CODE = 'emailCode',
    CARD = 'card'
}

export enum MonitoredAssetsStatus {
    NONE_MONITORED = 'none_monitored',
    ONE_MONITORED = 'one_monitored',
    SOME_MONITORED = 'some_monitored',
    ALL_MONITORED = 'all_monitored'
}

export class SocialMediaAccountInfo {
    value: AssetSubtypes;
    name: string;
    icon: object;
    isChecked: boolean;
    isDisabled: boolean;
}

type FilteredSocialAccounts = Exclude<AssetSubtypes, AssetSubtypes.BUSINESS>;

export type SocialMediaAccountIcons = {
    [Key in FilteredSocialAccounts]: object
};

class BusinessAssetIconModel {
    type: string;
    icon: string;
    color: string;
    bg: string;
}

export type BusinessAssetIcons = {
    [Key in AssetScenarios]: BusinessAssetIconModel
};

export type BusinessAssetHeaders = {
    [Key in AssetScenarios]: {
        title: string;
        image: BusinessAssetIconModel;
    }
};

interface AssetIcon {
    type: string;
    value: string;
}

interface AssetConfig {
    icon: AssetIcon;
    baseColor: string;
}

export type AssetsConfig = {
    [key in AssetTypes]: AssetConfig
};

interface AssetConfigEmpty extends AssetConfig {
    strokeHasGradient?: boolean;
    invertedColor?: boolean;
}

export type AssetsConfigEmpty = {
    [key in AssetTypes]: AssetConfigEmpty[]
};

interface AsssetDetailsCta {
    label: string;
    action: Function;
}

interface AssetDetailsUnmonitored {
    title: string;
    description: string;
    cta: AsssetDetailsCta;
}

interface AssetDetailsBreachConsequences {
    title: string;
    details: string[];
}

interface AssetDetailsClean {
    description: string;
}

interface AssetDetals {
    unmonitored: AssetDetailsUnmonitored;
    breachConsequences: AssetDetailsBreachConsequences;
    clean: AssetDetailsClean;
}

export type AssetsDetails = {
    [key in AssetTypes]: AssetDetals
};

export type AssestsFlows = {
    [key in AssetScenarios]: Array<Steps>
};

interface BusinessAssetScenariosButton {
    text: string;
    cta: Function;
}

interface BusinessAssetScenariosDetails {
    description: string;
    info?: string;
    button?: BusinessAssetScenariosButton;
}

type BusinessAssetStepDetails = {
    [key in AssetScenarios]?: BusinessAssetScenariosDetails
};

export type BusinessAssetStepsDetails = {
    [key in Steps]?: BusinessAssetStepDetails
};

export enum ActionStatus {
    NEW = 'new',
    DONE = 'done'
}

export enum AssetsActions {
    REMOVE_ASSET = 'removeAsset',
    EDIT_ACCOUNT = 'editAccount'
}

export enum AssetsActionType {
    DONE = 'done',
    UNDO = 'undo'
}

interface BreachAction {
    name: string;
    action: AssetsActions;
}

export type BreachActionsType = {
    [Key in AssetsActions]: BreachAction
};

export type BreachActionIcons = {
    [Key in ActionStatus]: AssetIcon
};

export type AssetsStatistics = {
    [Key in AssetTypes]: AssetStatistic
};

export type BreachActionsByType = {
    [Key in AssetTypes]: BreachAction[]
};

type RecommendedAction = {
    name: string;
    action: AssetsActionType;
};

export type RecommendedActions = {
    [Key in AssetsActionType]: RecommendedAction
};

export type RecommendedActionsByStatus = {
    [Key in ActionStatus]: RecommendedAction
};

export type RecommendedStatusByType = {
    [Key in AssetsActionType]: ActionStatus
};

export class AssetStatistic {
    totalBreaches: number;
    totalActions: number;
    totalSocialAccounts?: number;
    totalBreachedEmails?: number;
}

export type AssetsInterface = {
    [Key in AssetTypes]: AssetInterface
};

export class AssetInterface {
    type: AssetTypes;
    isMonitored: boolean;
    hasBreaches: boolean;
    hasNewBreaches: boolean;
    hasDomain?: boolean;
    hasActionError?: boolean;
    breaches: AssetBreach[];
    recommendations: AssetRecommendation[];
}

export class AssetBreach {
    value: string;
    encryptedValue?: string;
    icon: {
        type: string;
        value: string;
    };
    type: MetadataType;
    subtype?: string[];
    actions?: BreachAction[];
    showMoreBtn: boolean;
    showLessBtn: boolean;
    breachInfo: AssetBreachInfo[];
    exposedInfo: AssetExposedInfo[];
    metadataId: string;
}

export class AssetBreachInfo {
    reportedAt: string;
    issueId: string;
    title: string;
    isNew: boolean;
}

export class AssetExposedInfo {
    isNew: boolean;
    name: string;
    icon: string;
}

export enum AssetActionCategories {
    BUSINESS_EMAIL_OR_DOMAIN = 'business_email_or_domain',
    BUSINESS_CREDIT_CARD = 'business_credit_card',
    SOCIAL_MEDIA_ACCOUNTS_EMAILS = 'social_media_account_emails'
}

export enum AssetActionCategoryTypes {
    USE_DIP = 'use_dip',
    USE_PASSWORD_MANAGER = 'use_password_manager',
    USE_SECURITY_SOLUTIONS = 'use_security_solutions',
    USE_SCAM_COPILOT = 'use_scam_copilot',
    MONITOR_TRANSACTIONS = 'monitor_transactions',
    ENABLE_2FA ='enable_2fa'
}

class ActionModel {
    info: {
        title: string;
        description: string;
        options: string[];
    };
    status: ActionStatus;
    type: string;
    updated_at: string;
}

class CategoriesModel {
    category: string;
    category_actions: ActionModel[];
}

export interface ActionsModel {
    categories: CategoriesModel[];
    count: number;
    status: number;
}

export interface AssetRecommendation {
    icon?: AssetIcon;
    status: string;
    type: string;
    category: string;
    title?: string;
    button?: RecommendedAction;
}

export type AssetActionTitleByCategoryTypeStatus = {
    [Category in AssetActionCategories]?: {
        [Type in AssetActionCategoryTypes]?: {
            [Status in ActionStatus]: string;
        }
    }
};

export interface GroupThreatsParams {
    group_id: string,
    from_date: number,
    to_date: number,
    count?: number,
    offset?: number
}
