// External
import {Component, Input, Renderer2, OnInit, ElementRef, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { UiButton } from './ui-button.models';
import { UiIcon } from '../ui-icon/ui-icon.models';

@Component({
    selector: '[ui-button]',
    template: `
        <div *ngIf="!iconMid;else btnIcon" class="btn-label justify-content-{{alignment}}" [ngClass]="{'ellipsis': ellipsis}">
            <ui-icon class="left" *ngIf="iconLeft" [rotate]="iconLeft.rotate"  [type]="iconLeft.type" [icon]="iconLeft.value" [size]="iconLeft.size ?? 16" shape="icon" [color]="iconLeft?.color"></ui-icon>
            <div><ng-content></ng-content></div>
            <ui-icon class="right" *ngIf="iconRight" [rotate]="iconRight.rotate"  [type]="iconRight.type" [icon]="iconRight.value" [size]="iconRight.size ?? 16" shape="icon" [color]="iconRight?.color"></ui-icon>
        </div>
        <ng-template #btnIcon>
            <ui-icon class="mid" [rotate]="iconMid.rotate" [type]="iconMid.type" [icon]="iconMid.value" [size]="iconMid?.size ?? 16" shape="icon" [color]="iconMid?.color"></ui-icon>
        </ng-template>
        `,
    host: {
        '[class.ellipsis]': 'ellipsis',
    }
})

export class UiButtonComponent implements OnInit, OnChanges, UiButton.Component {
    @Input() model = UiButton.Model.SOLID;
    @Input() color: string;
    @Input() size = UiButton.Size.LG;
    @Input() ellipsis = false;

    @Input() iconLeft: UiIcon.Svg;
    @Input() iconRight: UiIcon.Svg;
    @Input() iconMid: UiIcon.Svg;

    @Input() alignment: UiButton.Alignment = UiButton.Alignment.CENTER;

    constructor(
        private renderer: Renderer2, private el: ElementRef
    ) {}

    ngOnInit() {
        this.renderer.addClass(this.el.nativeElement, 'btn');
        if (this.color || this.model === 'link') {
            this.renderer.addClass(this.el.nativeElement, this.model);
        }
        this.renderer.addClass(this.el.nativeElement, this.size);
        if (this.el.nativeElement.attributes.icon !== undefined) {
            this.renderer.addClass(this.el.nativeElement, 'icon');
        }
        if (this.iconMid) {
            this.renderer.addClass(this.el.nativeElement, 'btn-icon');
        }
        this.setColor();
    }

    ngOnChanges(changes: SimpleChanges){
        if (changes.color && !changes.color.firstChange) {
            this.setColor(changes.color.currentValue, changes.color.previousValue);
        }

        if (changes.model && !changes.model.firstChange) {
            this.renderer.removeClass(this.el.nativeElement, changes.model.previousValue);
            this.renderer.addClass(this.el.nativeElement, changes.model.currentValue);
            this.setColor(this.color, this.color);
        }
    }

    /**
     *
     * Change button color
     * @private
     * @param {string} [newColor]
     * @param {string} [oldColor]
     * @memberof UiButtonComponent
     */
    private setColor(newColor?: string, oldColor?: string): void {
        if (!newColor) {
            newColor = this.color;
        }
        if (oldColor) {
            this.renderer.removeClass(this.el.nativeElement, `bg-${oldColor}`);
            this.renderer.removeClass(this.el.nativeElement, `color-${oldColor}`);
            this.renderer.removeClass(this.el.nativeElement, `clear`);
        }

        if (this.model === UiButton.Model.SOLID) {
            this.renderer.addClass(this.el.nativeElement, `bg-${newColor}`);
        } else {
            this.renderer.addClass(this.el.nativeElement, newColor ? `color-${newColor}` : 'clear');
        }
    }
}
