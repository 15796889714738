// External
import { CookieService } from "ngx-cookie-service";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { tv4 } from "tv4";

// Internal
import { RequestsService } from "../../global/request-service/requests.service";
import { UtilsCommonService } from "src/app/common/utils/utils-common.service";
import { ValuesService } from "../../../values/values.service";
import { schemas } from "src/app/common/models/schemas";
import { ConfigService } from "src/app/common/config/config.service";
import { catchError, map } from "rxjs/operators";
@Injectable({
    providedIn: "root"
})
export class AppMgmtService {
    constructor(
        private cookieService: CookieService,
        private requestService: RequestsService,
        private utilsCommonService: UtilsCommonService,
        private valuesService: ValuesService,
        private configService: ConfigService
    ) { }

    /**
     * This call enables a specified application on a device
     * @param {object} Contains mandatory `device_id` and `app_id`
     * @returns Response from server
     */
    enableApp(device_id, app_id): Observable<any> {
        if (!tv4.validate(device_id, schemas.schemaUuid) 
            || !this.utilsCommonService.checkString(app_id)
            || !app_id) {
            console.error("Invalid params", tv4.error);
            return throwError("Invalid params");
        }

        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "enable_app",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: device_id,
                    app_id: app_id
                },
                app_id: app_id
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.appMgmtService,
            json,
            "POST"
        ).pipe(
            map((resp) => {
                if (resp?.result?.status === "ok") {
                    return resp.result;
                } else {
                    throw resp;
                }
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    /**
     * This call disables a specified application on a device
     * @param {object} Contains mandatory `device_id` and `app_id`
     * @returns Response from server
     */
    disableApp(device_id, app_id): Observable<any> {
        if (
            !tv4.validate(device_id, schemas.schemaUuid) ||
            !this.utilsCommonService.checkString(app_id) ||
            !app_id
        ) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "disable_app",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: device_id
                },
                app_id: app_id
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.appMgmtService,
            json,
            "POST"
        ).pipe(
            map((resp) => {
                if (resp?.result?.status === "ok") {
                    return resp.result;
                }
                throw resp;
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    /**
     * This method allows a client to find out the latest version for an app
     * @param {object} Contains mandatory `device_id` and `app_id`
     * @returns Response from server
     */
    getLatest(device_id, app_id): Observable<any> {
        if (
            !tv4.validate(device_id, schemas.schemaUuid) ||
            !this.utilsCommonService.checkString(app_id) ||
            !app_id
        ) {
            console.error("Invalid params", tv4.error);
            return of("Invalid params");
        }
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "get_latest_version",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: device_id,
                    app_id: app_id
                },
                app_id: app_id
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.appMgmtService,
            json,
            "POST"
        );
    }
}
