import { NgModule } from '@angular/core';
import { ConnectionStatusComponent } from './connection-status.component';
import { CommonModule } from '@angular/common';
import { UiTooltipModule } from '../ui/ui-tooltip/ui-tooltip.module';
import { UiIconModule } from '../ui/ui-icon/ui-icon.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [ ConnectionStatusComponent],
    imports: [
        CommonModule,
        UiTooltipModule,
        UiIconModule,
        TranslateModule
    ],
    exports: [
        ConnectionStatusComponent
    ]
})
export class ConnectionStatusModule { }